import React from 'react'

const ExternalLink = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={18}
      height={18}
      viewBox='0 0 18 18'
      {...props}
    >
      <g transform='translate(-1083.024 -1019.412)'>
        <g transform='translate(1083.774 1024.574)'>
          <g>
            <path
              d='M5.625,0H3.219A3.121,3.121,0,0,0,0,3.384v5.35a3.118,3.118,0,0,0,3.219,3.384H8.9a3.119,3.119,0,0,0,3.22-3.384V6.142'
              transform='translate(0)'
              fill='none'
              stroke='currentColor'
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth={1.5}
            />
          </g>
        </g>
        <path
          d='M0,10.919V0'
          transform='translate(1089.832 1030.633) rotate(-135)'
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth={1.5}
        />
        <path
          d='M6.278,0,3.139,3.153,0,0'
          transform='translate(1097.544 1027.361) rotate(-135)'
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth={1.5}
        />
      </g>
    </svg>
  )
}

export default ExternalLink
