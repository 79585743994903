import React from 'react'

const SvgZoomInIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={18}
      height={18}
      viewBox='0 0 18 18'
      {...props}
    >
      <g>
        <path
          d='M16.938,17.873l-.075-.063-2.815-2.745a8.731,8.731,0,0,1-5.4,1.854A8.566,8.566,0,0,1,0,8.46,8.566,8.566,0,0,1,8.65,0,8.566,8.566,0,0,1,17.3,8.46a8.325,8.325,0,0,1-2.278,5.714L17.8,16.887a.642.642,0,0,1,0,.922.678.678,0,0,1-.868.064ZM1.332,8.46A7.246,7.246,0,0,0,8.65,15.616,7.247,7.247,0,0,0,15.968,8.46,7.247,7.247,0,0,0,8.65,1.3,7.246,7.246,0,0,0,1.332,8.46Z'
          fill='currentColor'
        />
      </g>
      <path
        d='M.647,0a.647.647,0,0,1,.641.56l.006.088V6.971a.647.647,0,0,1-1.289.088L0,6.971V.647A.647.647,0,0,1,.647,0Z'
        transform='translate(8.074 4.542)'
        fill='currentColor'
      />
      <path
        d='M6.977,0a.647.647,0,0,1,.088,1.289l-.088.006H.647A.647.647,0,0,1,.56.006L.647,0Z'
        transform='translate(4.909 7.704)'
        fill='currentColor'
      />
    </svg>
  )
}

export default SvgZoomInIcon
