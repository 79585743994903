/* eslint-disable no-debugger */
import styled from 'styled-components'
import { theme } from '../Theme'
export const Test = styled.div`
  width: 500px;
  height: 500px;
  display: flex;
  justify-content: center;
  align-content: center;
  background-color: lightblue;
  flex-direction: column;
`
export const Wrapper = styled.div`
  width: 238px;
  height: 30px;
  border-radius: ${theme.borderRadius.tags};
  font-family: 'Inter';
  font-weight: ${theme.fontWeight.semibold};
`

export const CheckboxInput = styled.input`
  display: none;
  &:checked + label > div {
    &:after {
      left: 0;
      content: attr(data-unchecked);
    }

    &:before {
      color: white;
      left: 50%;
      content: attr(data-checked);
    }
  }
`
export const Label = styled.label`
  height: 100%;
  width: 100%;
`

export const InnerLabel = styled.div`
  cursor: pointer;
  width: 100%;
  height: 100%;
  position: relative;
  background-color: white;
  color: ${theme.palette.gray4};
  transition: all 0.5s ease;
  border-radius: ${theme.borderRadius.tags};

  &:before,
  &:after {
    background-color: white;
    border-radius: 2px;
    height: 100%;
    width: 50%;
    display: flex;
    align-items: center;
    position: absolute;
    justify-content: center;
    transition: all 0.5s ease;
    border-radius: ${theme.borderRadius.tags};
    font-size: 14px;
    line-height: 30px;
  }

  &:before {
    background-color: white;
    color: white;
    box-shadow: 0 0 4px 4px rgba(#000, 0.2);
    left: 0px;
    z-index: 10;
    content: attr(data-unchecked);
    background-color: ${theme.palette.highlight.blue};
    border-radius: ${theme.borderRadius.tags};
  }

  &:after {
    right: 0;
    content: attr(data-checked);
  }
`
