import React from 'react'
import { SvgProps } from './typings/SvgProps'

const AddCircleSmall: React.FC<SvgProps> = ({ color = 'currentColor', size, ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} viewBox="0 0 16 16" {...rest}>
    <path
      id="Caminho_27550"
      data-name="Caminho 27550"
      d="M20,0a8,8,0,1,0,8,8A8,8,0,0,0,20,0Zm3.99,8.72H20.72v3.27a.72.72,0,1,1-1.44,0V8.72H16.01a.72.72,0,1,1,0-1.44h3.27V4.01a.72.72,0,1,1,1.44,0V7.28h3.27a.72.72,0,0,1,0,1.44Z"
      transform="translate(-12)"
      fill={color}
    />
  </svg>
)

export default AddCircleSmall
