import React from 'react';

const SvgChecked = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 65 65' width={65} height={65} {...props}>
      <defs>
        <style>{'.checked_svg__a{fill:#378ff7}'}</style>
      </defs>
      <path
        className='checked_svg__a'
        d='M63.064 9.255a2.551 2.551 0 00-3.608 0L28.849 39.778 17.81 27.783a2.551 2.551 0 00-3.754 3.456l12.842 13.946a2.548 2.548 0 001.823.822h.054a2.554 2.554 0 001.8-.744l32.487-32.4a2.551 2.551 0 00.002-3.608z'
      />
      <path
        className='checked_svg__a'
        d='M62.55 30.05a2.45 2.45 0 00-2.45 2.45A27.6 27.6 0 1132.5 4.9a2.45 2.45 0 100-4.9A32.5 32.5 0 1065 32.5a2.45 2.45 0 00-2.45-2.45z'
      />
    </svg>
  )
}

export default SvgChecked
