import React from 'react'
import { SvgProps } from './typings/SvgProps'

const Align: React.FC<SvgProps> = ({ color = 'currentColor', size, ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} {...rest} viewBox="0 0 15 12">
    <g transform="translate(0 -6)">
      <g transform="translate(1 7)">
        <path d="M0,0H10.006" fill="none" stroke={color} strokeLinecap="round" strokeWidth="2" />
        <line
          x2="10"
          transform="translate(0 10)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <line
          x2="13"
          transform="translate(0 5)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </g>
  </svg>
)

export default Align
