import React, { useMemo } from 'react'
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Legend,
  Tooltip,
} from 'recharts'
import { theme } from '@cockpit/levi-ui/theme'
import {
  defaultLineChartColor,
  defaultUterineHeightChart,
  defaultYAxis,
  defaultXAxis,
} from './constants'
import { IUterineHeightChart } from './typing'
import { CustomizedDot } from './CustomizedDot'
import { CustomTooltip } from './CustomizedTooltip'
import { Container } from './styles'

const UterineHeightChart: React.FC<IUterineHeightChart> = ({
  width = '100%',
  data,
  lineChartData,
  aspect = 1.5,
  lineChartColor = defaultLineChartColor,
  yAxis = defaultYAxis,
  xAxis = defaultXAxis,
}: IUterineHeightChart) => {
  const multipleLines = useMemo(() => {
    if (!lineChartData && data) {
      defaultUterineHeightChart[0].data = data
    }

    if (lineChartData) return lineChartData

    return defaultUterineHeightChart
  }, [data, lineChartData])

  const handleLastNumber = (array: number[]): number => {
    return array.at(-1) as number
  }

  const yAxisDomain = useMemo(() => {
    return [yAxis[0], handleLastNumber(yAxis)]
  }, [yAxis])

  const xAxisDomain = useMemo(() => {
    return [xAxis[0], handleLastNumber(xAxis) + 1]
  }, [xAxis])

  return (
    <Container data-testid="uterine-height-chart-responsive-container">
      <ResponsiveContainer width={width} aspect={aspect}>
        <LineChart margin={{ bottom: 25, top: 25 }}>
          {multipleLines.map(line => {
            return (
              <Line
                dot={<CustomizedDot />}
                connectNulls
                type="monotone"
                dataKey="value"
                data={line.data}
                name={line.name}
                key={line.name}
                stroke={lineChartColor.find(item => item.name === line.name)?.color}
              />
            )
          })}
          <CartesianGrid strokeDasharray="1" vertical={false} />
          <YAxis
            fontSize={theme.font.size[2]}
            ticks={yAxis}
            tickCount={yAxis.length}
            interval={0}
            domain={yAxisDomain}
            width={100}
            label={{
              value: 'Altura uterina (cm)',
              angle: -90,
              position: 'outside',
              fill: theme.colors.neutral[80],
              fontWeight: theme.font.weight[7],
              fontSize: theme.font.size[2],
            }}
          />
          <Legend
            align="right"
            verticalAlign="top"
            layout="vertical"
            iconSize={5}
            iconType="circle"
            wrapperStyle={{
              lineHeight: 2,
            }}
          />
          <XAxis
            padding={{ left: 30, right: 10 }}
            dataKey="xAxis"
            fontSize={theme.font.size[2]}
            type="number"
            ticks={xAxis}
            domain={xAxisDomain}
            label={{
              value: 'Semanas de gestação',
              offset: -20,
              position: 'insideBottom',
              fill: theme.colors.neutral[80],
              fontWeight: theme.font.weight[7],
              fontSize: theme.font.size[2],
            }}
            allowDuplicatedCategory={false}
          />
          <Tooltip content={<CustomTooltip />} filterNull />
        </LineChart>
      </ResponsiveContainer>
    </Container>
  )
}

export default UterineHeightChart
