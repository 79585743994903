import React from 'react'
import { SvgProps } from './typings/SvgProps'

const Link: React.FC<SvgProps> = ({
  color = 'currentColor',
  size,
  ...rest
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    {...rest}
    viewBox="0 0 13.2 13.635"
  >
    <g transform="translate(-1.395 -1.188)">
      <path
        d="M10,8.605a3.051,3.051,0,0,0,4.572.324l1.819-1.8a2.98,2.98,0,0,0,0-4.244,3.053,3.053,0,0,0-4.288,0L11.061,3.911"
        transform="translate(-3.284)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
      />
      <path
        d="M9.274,10.2A3.051,3.051,0,0,0,4.7,9.874l-1.819,1.8a2.98,2.98,0,0,0,0,4.244,3.053,3.053,0,0,0,4.288,0l1.037-1.027"
        transform="translate(0 -2.794)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
      />
    </g>
  </svg>
)

export default Link
