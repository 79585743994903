import React from 'react'

const SvgErrorIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={20}
      height={20}
      viewBox='0 0 22 22'
      {...props}
    >
      <defs>
        <clipPath id='error-icon_svg__a'>
          <path fill='none' d='M1 1h24v24H1z' />
        </clipPath>
      </defs>
      <g
        transform='translate(-1 -1)'
        clipPath='url(#error-icon_svg__a)'
        style={{
          isolation: 'isolate'
        }}
      >
        <g
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          strokeWidth={1.5}
        >
          <path d='M15.395 10.595l-4.792 4.792' />
          <path d='M15.401 15.393l-4.8-4.8' />
          <path d='M17.335 3.75H8.666c-3.021 0-4.915 2.139-4.915 5.166v8.168c0 3.027 1.885 5.166 4.915 5.166h8.668c3.031 0 4.917-2.139 4.917-5.166V8.916c0-3.027-1.886-5.166-4.916-5.166z' />
        </g>
      </g>
    </svg>
  )
}

export default SvgErrorIcon
