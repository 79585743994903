import React from 'react';

const SvgIconEdit = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={18} height={18} viewBox='0 0 21 21' {...props}>
      <g data-name='Iconly/Light-outline/Edit Square'>
        <path
          data-name='Edit Square'
          d='M5.309 17.5A4.645 4.645 0 01.5 12.686V5.547A4.667 4.667 0 015.114.535h3.321a.626.626 0 01.085 1.243l-.085.005H5.309a3.4 3.4 0 00-3.551 3.565v7.139a3.418 3.418 0 003.365 3.759H12.689a3.4 3.4 0 003.553-3.563V9.124a.627.627 0 011.248-.085l.01.085v3.361a4.663 4.663 0 01-4.619 5.015h-.2zm.291-4.54a.627.627 0 01-.627-.642l.077-3.154A2.427 2.427 0 015.764 7.5l6.247-6.235a2.624 2.624 0 013.7 0l1.017 1.016a2.612 2.612 0 010 3.7l-6.272 6.265a2.428 2.428 0 01-1.727.715zm1.051-4.573A1.18 1.18 0 006.3 9.2l-.06 2.507h2.489a1.2 1.2 0 00.753-.269l.087-.079 4.876-4.869L11.5 3.549zm8.681-2.78l.514-.513a1.361 1.361 0 000-1.928l-1.017-1.015a1.368 1.368 0 00-1.932 0l-.513.513z'
          fill='currentColor'
          stroke='currentColor'
        />
      </g>
    </svg>
  )
}

export default SvgIconEdit
