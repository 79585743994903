import React from 'react'
import { IAvatar } from './typing'
import { iconSize, StyledAvatar } from './styles'
import { Icon } from '../../atoms'

const Avatar = ({ size = 'md', icon, fallbackIcon = 'Close', name, ...rest }: IAvatar) => {
  function initials(fullName: string) {
    const nameList = fullName.split(' ')
    const firstName = nameList[0]
    const lastName = nameList.length > 1 ? nameList[nameList.length - 1] : ''
    return firstName && lastName
      ? `${firstName.charAt(0)}${lastName.charAt(0)}`
      : firstName.charAt(0)
  }

  return (
    <StyledAvatar data-testid="avatar-element" size={size} {...rest}>
      {name && initials?.(name)}
      {icon && !name && <Icon icon={icon} size={iconSize[size]} />}
      {!icon && !name && <Icon icon={fallbackIcon} size={iconSize[size]} />}
    </StyledAvatar>
  )
}

export default Avatar
