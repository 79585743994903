import React from 'react'
import { SvgProps } from './typings/SvgProps'

const LoadingAnimated: React.FC<SvgProps> = ({ color = 'currentColor', size, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill={color}
    width={size}
    {...rest}
    preserveAspectRatio="xMidYMid"
    viewBox="0 0 100 100"
  >
    <g transform="rotate(0 50 50)">
      <rect x="47" y="20.5" rx="3" ry="3.5700000000000003" width="6" height="21">
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="0.819672131147541s"
          begin="-0.7285974499089253s"
          repeatCount="indefinite"
        />
      </rect>
    </g>
    <g transform="rotate(40 50 50)">
      <rect x="47" y="20.5" rx="3" ry="3.5700000000000003" width="6" height="21">
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="0.819672131147541s"
          begin="-0.6375227686703097s"
          repeatCount="indefinite"
        />
      </rect>
    </g>
    <g transform="rotate(80 50 50)">
      <rect x="47" y="20.5" rx="3" ry="3.5700000000000003" width="6" height="21">
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="0.819672131147541s"
          begin="-0.5464480874316939s"
          repeatCount="indefinite"
        />
      </rect>
    </g>
    <g transform="rotate(120 50 50)">
      <rect x="47" y="20.5" rx="3" ry="3.5700000000000003" width="6" height="21">
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="0.819672131147541s"
          begin="-0.4553734061930783s"
          repeatCount="indefinite"
        />
      </rect>
    </g>
    <g transform="rotate(160 50 50)">
      <rect x="47" y="20.5" rx="3" ry="3.5700000000000003" width="6" height="21">
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="0.819672131147541s"
          begin="-0.36429872495446264s"
          repeatCount="indefinite"
        />
      </rect>
    </g>
    <g transform="rotate(200 50 50)">
      <rect x="47" y="20.5" rx="3" ry="3.5700000000000003" width="6" height="21">
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="0.819672131147541s"
          begin="-0.27322404371584696s"
          repeatCount="indefinite"
        />
      </rect>
    </g>
    <g transform="rotate(240 50 50)">
      <rect x="47" y="20.5" rx="3" ry="3.5700000000000003" width="6" height="21">
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="0.819672131147541s"
          begin="-0.18214936247723132s"
          repeatCount="indefinite"
        />
      </rect>
    </g>
    <g transform="rotate(280 50 50)">
      <rect x="47" y="20.5" rx="3" ry="3.5700000000000003" width="6" height="21">
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="0.819672131147541s"
          begin="-0.09107468123861566s"
          repeatCount="indefinite"
        />
      </rect>
    </g>
    <g transform="rotate(320 50 50)">
      <rect x="47" y="20.5" rx="3" ry="3.5700000000000003" width="6" height="21">
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="0.819672131147541s"
          begin="0s"
          repeatCount="indefinite"
        />
      </rect>
    </g>
  </svg>
)

export default LoadingAnimated
