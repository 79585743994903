import React from 'react'
import { SvgProps } from './typings/SvgProps'

const Gear: React.FC<SvgProps> = ({ color = 'currentColor', size, ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} {...rest} viewBox="0 0 17.3 17.3">
    <g transform="translate(0.65 0.65)">
      <g transform="translate(-1072.5 -95.5)">
        <path
          d="M16.195,6.794h-2.1a.611.611,0,0,1-.432-1.043l1.486-1.485a.306.306,0,0,0,0-.432L13.166,1.853a.306.306,0,0,0-.432,0L11.249,3.339a.611.611,0,0,1-1.043-.432V.805A.305.305,0,0,0,9.9.5H7.1a.305.305,0,0,0-.305.305v2.1a.61.61,0,0,1-1.042.432L4.266,1.853a.306.306,0,0,0-.432,0L1.853,3.834a.306.306,0,0,0,0,.432L3.339,5.751a.611.611,0,0,1-.432,1.043H.805A.305.305,0,0,0,.5,7.1V9.9a.305.305,0,0,0,.305.305h2.1a.61.61,0,0,1,.432,1.042L1.853,12.734a.306.306,0,0,0,0,.432l1.981,1.981a.306.306,0,0,0,.432,0l1.486-1.486a.61.61,0,0,1,1.042.432v2.1A.305.305,0,0,0,7.1,16.5H9.9a.305.305,0,0,0,.305-.305v-2.1a.611.611,0,0,1,1.043-.432l1.485,1.486a.306.306,0,0,0,.432,0l1.981-1.981a.306.306,0,0,0,0-.432l-1.486-1.486a.61.61,0,0,1,.432-1.042h2.1A.305.305,0,0,0,16.5,9.9V7.1A.305.305,0,0,0,16.195,6.794Z"
          transform="translate(1072 95)"
          fill="none"
          stroke={color}
          strokeMiterlimit="10"
          strokeWidth="1.3"
        />
        <g transform="translate(1069.52 92.52)" fill="none" strokeMiterlimit="10">
          <path
            d="M14.04,10.98a3.06,3.06,0,1,1-3.06-3.06A3.06,3.06,0,0,1,14.04,10.98Z"
            stroke="none"
          />
          <path
            d="M 10.97999954223633 9.319999694824219 C 10.06412029266357 9.319999694824219 9.319000244140625 10.06466960906982 9.319000244140625 10.97999954223633 C 9.319000244140625 11.89532947540283 10.06412029266357 12.63999938964844 10.97999954223633 12.63999938964844 C 11.89532947540283 12.63999938964844 12.63999938964844 11.89532947540283 12.63999938964844 10.97999954223633 C 12.63999938964844 10.06466960906982 11.89532947540283 9.319999694824219 10.97999954223633 9.319999694824219 M 10.97999954223633 7.919999122619629 C 12.67000007629395 7.919999122619629 14.03999996185303 9.289999961853027 14.03999996185303 10.97999954223633 C 14.03999996185303 12.66999912261963 12.67000007629395 14.03999900817871 10.97999954223633 14.03999900817871 C 9.288999557495117 14.03999900817871 7.918999671936035 12.66999912261963 7.918999671936035 10.97999954223633 C 7.918999671936035 9.289999961853027 9.288999557495117 7.919999122619629 10.97999954223633 7.919999122619629 Z"
            stroke="none"
            fill={color}
          />
        </g>
      </g>
    </g>
  </svg>
)

export default Gear
