import React, { forwardRef, ReactNode } from 'react'
import { FormGroup, Radio as MUIRadio, RadioGroupProps, FormControl } from '@mui/material'
import {
  RadioGroup as RadioGroupComponent,
  RadioLabel,
  ButtonBase,
  RadioUnchecked,
  RadioChecked,
  Label,
  HelperText,
} from './styles'

export interface IOptions {
  id?: string
  value: string
  label: string | ReactNode
}

export interface IRadioGroup
  extends Pick<RadioGroupProps, 'onChange' | 'value' | 'name' | 'onBlur' | 'onFocus'> {
  options: IOptions[]
  disabled?: boolean
  className?: string
  vertical?: boolean
  error?: boolean
  message?: string
  label?: string | ReactNode
  onClick?: React.MouseEventHandler<HTMLButtonElement>
}

const RadioGroup = forwardRef<RadioGroupProps, IRadioGroup>(
  (
    {
      options,
      className,
      disabled,
      vertical,
      error,
      message,
      value,
      label,
      onClick = () => {},
      ...props
    },
    ref,
  ) => (
    <FormControl>
      {label && (
        <Label data-testid="levi-radiogroup-label" className="levi-radiogroup-label">
          {label}
        </Label>
      )}
      <RadioGroupComponent
        ref={ref}
        className={className}
        row={!vertical}
        value={value ?? ''}
        {...props}
      >
        {options.map((option, index) => (
          <ButtonBase
            data-testid="radio-component"
            id={option.id ?? `radio-element-${index}-${props.name}`}
            centerRipple
            disabled={disabled}
            key={option.value}
          >
            <FormGroup>
              <RadioLabel
                className="levi-radio-label"
                value={option.value}
                label={option.label}
                control={
                  <MUIRadio
                    icon={<RadioUnchecked className="levi-radio-unchecked" />}
                    checkedIcon={<RadioChecked className="levi-radio-checked" />}
                    onClick={onClick}
                  />
                }
              />
            </FormGroup>
          </ButtonBase>
        ))}
        {message && (
          <HelperText className="levi-radio-message" error={error}>
            {message}
          </HelperText>
        )}
      </RadioGroupComponent>
    </FormControl>
  ),
)

export default RadioGroup
