interface ICustomizeDot {
  cx: number
  cy: number
  name: string
  payload: IUrineHeight
}

interface ICustomToolTipPayload {
  payload: {
    chartType?: string
    color: string
    dataKey: string
    fill: string
    formatter?: string
    name: string
    payload: {
      xAxis: number
      value: number
    }
    stroke: string
    strokeWidth: number
    type?: string
    unit?: number
    value: number
  }[]
  active: boolean
}

export enum ELineChartTitle {
  P90 = 'P90',
  P10 = 'P10',
  title = 'Altura uterina',
}

interface IUrineHeight {
  xAxis: number
  value: number
}

interface ILineChart {
  name: ELineChartTitle
  data: IUrineHeight[]
}

interface ILineChartColor {
  name: ELineChartTitle
  color: string
}

interface IUterineHeightChart {
  /**
   * Lista utilizada para exibir a linha 'azul' do gráfico
   * que indica os dados do paciente
   * Utiliza valores default em P10 e P90.
   */
  data?: IUrineHeight[]
  /**
   * Define a largura do gráfico, default 100% de largura
   */
  width?: number | string
  /**
   * Número passado para dimensionar a altura pela largura do gráfico, default de 1.5
   */
  aspect?: number
  /**
   * Lista que sobrescreve todos os valores defaults das linhas do gráfico
   */
  lineChartData?: ILineChart[]
  /**
   * Customização de cor das linhas do gráfico e legendas
   */
  lineChartColor?: ILineChartColor[]
  /**
   * Sobrescreve valores default no eixo X
   */
  xAxis?: number[]
  /**
   * Sobrescreve valores default no eixo Y
   */
  yAxis?: number[]
}

export type {
  IUterineHeightChart,
  IUrineHeight,
  ILineChart,
  ILineChartColor,
  ICustomToolTipPayload,
  ICustomizeDot,
}
