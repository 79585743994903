import React, { forwardRef } from 'react'
import { FormGroup } from '@mui/material'
import { Label, SwitchComponent, SwitchContainer } from './styles'

export interface ISwitch {
  label: string
  checked: boolean
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  className?: string
  disabled?: boolean
}

const Switch = forwardRef<HTMLButtonElement, ISwitch>(
  ({ label, checked, onChange, className, ...rest }, ref) => (
    <FormGroup className={className}>
      <Label
        control={
          <SwitchContainer>
            <SwitchComponent
              checked={checked}
              ref={ref}
              onChange={onChange}
              data-testid="switch-component"
              {...rest}
            />
          </SwitchContainer>
        }
        label={label}
      />
    </FormGroup>
  ),
)

export default Switch
