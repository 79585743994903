import {
  FormControlLabel,
  RadioGroup as MUIRadioGroup,
  ButtonBase as MUIButtonBase,
  InputLabel,
  FormHelperText,
} from '@mui/material'
import styled from '@emotion/styled'
import { getColors } from '@cockpit/levi-ui/helpers'
import { theme } from '@cockpit/levi-ui/theme'

export const RadioUnchecked = styled.span`
  width: 18px;
  height: 18px;
  border-radius: 100%;
  border: 1px solid ${getColors('neutral')};
  background: ${getColors('neutral', 10)};
`

export const RadioChecked = styled.span`
  width: 18px;
  height: 18px;
  border-radius: 100%;
  border: 1px solid ${getColors('blue')};
  background: ${getColors('neutral', 10)};
  position: relative;

  &::before {
    content: ' ';
    width: 12px;
    height: 12px;
    border-radius: 100%;
    position: absolute;
    top: 3px;
    left: 3px;
    background-color: ${getColors('blue')};
    animation: fade 0.3s;

    @keyframes fade {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }
`

export const RadioGroup = styled(MUIRadioGroup)`
  &.MuiFormGroup-root {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  &.MuiFormGroup-row {
    align-items: center;
  }
  &:not(.MuiFormGroup-row) {
    align-items: flex-start;
    justify-content: center;
  }
  .levi-radio-message {
    width: 100%;
    font-size: ${theme.font.size[3]};
  }

  span {
    box-sizing: content-box;
  }
`

export const RadioLabel = styled(FormControlLabel)`
  .MuiTypography-root {
    font-size: ${theme.font.size[2]};
    color: ${getColors('neutral', 60)};
  }
`

export const ButtonBase = styled(MUIButtonBase)`
  transition: 0.3s;
  border-radius: 0.25rem;
  &:hover {
    background-color: ${getColors('neutral', 20)};
  }

  label {
    margin: 0;
    padding-right: ${theme.spacings[2]};
  }

  &[disabled] {
    opacity: 0.6;
    .levi-radio-checked,
    .levi-radio-unchecked {
      background-color: ${getColors('neutral', 30)};
    }
    .levi-radio-checked {
      border-color: ${getColors('neutral', 60)};
      &::before {
        background-color: ${getColors('neutral', 60)};
      }
    }
  }
`

export const Label = styled(InputLabel)`
  transform: none;
  position: relative;
  font-size: ${theme.font.size[2]};
  margin-bottom: ${theme.spacings[1]};
  font-weight: 600;
  color: ${getColors('neutral', 60)};
`

export const HelperText = styled(FormHelperText)`
  margin-left: 0.5rem;
`
