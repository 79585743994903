import React from 'react'
import { SvgProps } from './typings/SvgProps'

const BackChevron: React.FC<SvgProps> = ({ color = 'currentColor', size, ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} {...rest} viewBox="0 0 9.587 16.119">
    <g transform="translate(0.75 1.06)">
      <path
        d="M-11715-10164.226l7.777,7-7.777,7"
        transform="translate(-11707.223 -10150.226) rotate(180)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </g>
  </svg>
)

export default BackChevron
