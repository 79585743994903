import React from 'react'
import { SvgProps } from './typings/SvgProps'

const Items: React.FC<SvgProps> = ({ color = 'currentColor', size, ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} {...rest} viewBox="0 0 19 19">
    <g transform="translate(-678 -487)">
      <g transform="translate(683 484)">
        <g transform="translate(1 7)">
          <path d="M0,0H10.006" fill="none" stroke={color} strokeLinecap="round" strokeWidth="2" />
          <line
            x2="10"
            transform="translate(0 10)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <line
            x2="10"
            transform="translate(0 5)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
        </g>
      </g>
      <g transform="translate(678 487)">
        <path d="M0,0H19V19H0Z" fill="none" />
        <path
          d="M2,14.376H3.6v.4H2.8v.8h.8v.4H2v.8H4.394V13.577H2Zm.8-7.183h.8V4H2v.8h.8ZM2,9.587H3.437L2,11.263v.718H4.394v-.8H2.958L4.394,9.507V8.789H2Z"
          transform="translate(-0.526 -1.418)"
          fill={color}
        />
      </g>
    </g>
  </svg>
)

export default Items
