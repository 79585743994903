import styled from 'styled-components'

export const IconContainer = styled.div<{
  margin?: string | undefined
  iconsize?: string | number | undefined
}>`
  margin: ${(props) => props.margin || 'unset'};
  display: flex;
  justify-content: center;
  align-items: center;
`
