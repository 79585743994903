import styled from 'styled-components'
import { theme } from '../Theme/theme'

interface PaginationContainerProps {
  noAvailable: boolean
}

export const PaginationContainer = styled.div<PaginationContainerProps>`
  display: ${({ noAvailable }) => (noAvailable ? 'none' : 'flex')};
  justify-content: flex-end;
  align-items: center;
  width: auto;
  user-select: none;
`

export const PaginationBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;

  @media (max-width: 320px) {
    margin-right: 8px;
  }
`

export const TotalPages = styled.div`
  font: 300 14px sans-serif;
  color: ${theme.colors.gray4};
`
export const ContainerStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 87vh;
`
