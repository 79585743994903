import styled from 'styled-components'
import { theme } from '../../Theme'

export const Container = styled.div<{ modalHeight?: string | number }>`
  display: flex;
  padding: 0 30px;
  flex-direction: column;
  height: ${({ modalHeight }) => modalHeight ?? '580px'};
  overflow: auto;
  overflow-x: hidden;
  row-gap: 4px;
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${theme.colors.gray8};
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track-piece {
    background-color: transparent;
  }
`

export const TabCard = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  padding-bottom: 30px;

  :is(:first-child) {
    margin-top: 20px;
  }

  :not(:first-child) {
    border-top: 1px solid #dfe3e8;
    padding-top: 30px;
  }
`

export const TabTitle = styled.div`
  width: 175px;
  color: #6b7786;
  font-size: 14px;
  font-weight: ${theme.fontWeight.semibold};
  line-height: 16px;
`

export const TabItens = styled.div<{ active?: boolean }>`
  width: 175px;
  height: 190px;
  display: flex;
  justify-content: center;
  border: 1px solid;
  border-radius: 4px;
  border-color: ${({ active }) => (active ? '#378FF7' : '#E9ECF3')};
  background-color: #e9ecf3;

  .tabDocument {
    margin: 25px;
  }
`

export const ButtonPrint = styled.div`
  all: unset;
  display: flex;
  align-items: center;
  column-gap: 4px;
  margin-top: 4px;
  width: fit-content;
  cursor: pointer;

  p {
    margin: 0;
    font-size: 14px;
    color: #6b7786;
    transition: all ease 0.2s;
  }

  div {
    width: 18px;
    height: 18px;
  }

  svg {
    fill: #6b7685;
    transition: all ease 0.2s;
  }

  :hover {
    p {
      color: #378ff7;
    }

    svg {
      fill: #378ff7;
    }
  }
`
