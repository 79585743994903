import * as React from 'react'
import { ToolbarButtonDropDown } from './dropDown'
import { ToolbarButton } from './button'
import { Container } from './styles'
import { alignOptions, colorOptions, ERichText, fontSizeOptions } from '../constants'
import { useToolbar } from './hook'

export const Toolbar = () => {
  const {
    toolbarRef,
    isBold,
    isItalic,
    blockType,
    isUnderline,
    onFormatBold,
    onFormatItalic,
    onFormatUnderline,
    onFontColorSelect,
    onFontSizeSelect,
    onAlignSelect,
    onFormatBulletList,
    onFormatNumberedList,
  } = useToolbar()

  return (
    <Container ref={toolbarRef}>
      <ToolbarButton
        icon="Bold"
        onClick={onFormatBold}
        selected={isBold}
        tooltipTitle="Negrito"
        size={12}
      />
      <ToolbarButton
        icon="Italic"
        onClick={onFormatItalic}
        selected={isItalic}
        tooltipTitle="Itálico"
        size={12}
      />
      <ToolbarButton
        icon="Underline"
        onClick={onFormatUnderline}
        selected={isUnderline}
        tooltipTitle="Sublinhado"
        size={12}
      />
      <ToolbarButtonDropDown
        icon="TextSize"
        tooltipTitle="Tamanho"
        options={fontSizeOptions}
        callback={onFontSizeSelect}
        size={14}
      />
      <ToolbarButtonDropDown
        icon="TextColor"
        tooltipTitle="Cor do texto"
        callback={onFontColorSelect}
        isColorDropDown
        options={colorOptions}
        size={14}
      />
      <ToolbarButtonDropDown
        icon="Align"
        tooltipTitle="Alinhar"
        options={alignOptions}
        callback={onAlignSelect}
        size={14}
      />
      <ToolbarButton
        icon="List"
        onClick={onFormatBulletList}
        selected={blockType === ERichText.ul}
        tooltipTitle="Marcadores"
        size={14}
      />
      <ToolbarButton
        icon="Items"
        onClick={onFormatNumberedList}
        selected={blockType === ERichText.ol}
        tooltipTitle="Numeração"
        size={16}
      />
    </Container>
  )
}
