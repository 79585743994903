import styled from 'styled-components'
import { theme } from '../Theme'

interface IColumnList {
  width?: string
  fontWeight?: number
}

interface IOptionsRow {
  index: number
}

export const Container = styled.div`
  padding: 15px;
  width: 100%;
  * {
    font-family: inherit;
  }
  @media (min-width: 576px) {
    padding: 30px;
  }
`

export const Box = styled.div``

export const ButtonClose = styled.button`
  position: absolute;
  right: 0;
  top: -35px;
  background-color: transparent;
  border: 0px solid;
  cursor: pointer;
`

export const HeaderList = styled.div`
  display: none;
  padding: 0 16px 8px 16px;
  @media (min-width: 576px) {
    display: flex;
  }
`

export const ColumnList = styled.div<IColumnList>`
  font-weight: 600;
  color: ${theme.palette.gray4};
  padding-right: 30px;
  max-width: ${({ width }) => width ?? '100%'};
  width: 100%;
  line-height: 20px;
  font-size: 14px;
`

export const Row = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 16px 0 16px;
  background: ${theme.colors.gray1};
  margin-bottom: 5px;
  position: relative;
  flex-direction: column;
  @media (min-width: 576px) {
    flex-direction: row;
    padding: 16px;
  }
  .btn-edit {
    display: none;
    @media (min-width: 576px) {
      display: block;
    }
  }
`

export const RowInfo = styled.div<IColumnList>`
  width: 100%;
  padding-right: 50px;
  margin-bottom: 16px;
  overflow: hidden;
  @media (min-width: 576px) {
    margin-bottom: 0px;
    max-width: ${({ width }) => width ?? '100%'};
  }
  &:last-child {
    margin: 0;
  }
  .name-column {
    font-weight: normal;
    color: ${theme.palette.gray4};
    line-height: 20px;
    font-size: 13px;
    display: block;
    @media (min-width: 576px) {
      display: none;
    }
  }
  .name {
    line-height: 20px;
    font-size: 15px;
    overflow: hidden;
    color: ${theme.palette.black};
    font-weight: 400;
    @media (min-width: 576px) {
      font-weight: ${({ fontWeight }) => fontWeight || 600};
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    &.default {
      color: #5a6a7e;
      font-weight: normal;
    }
  }
`

export const OptionsRow = styled.div<IOptionsRow>`
  display: flex;
  align-items: center;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: ${({ index }) => index + 1};
  @media (min-width: 992px) {
    top: 50%;
    transform: translateY(-50%);
  }
  .btn-options {
    background: transparent;
    border: 0px solid;
    cursor: pointer;
    padding: 0px;
    svg {
      fill: ${theme.palette.black};
      color: ${theme.palette.black};
    }
  }
  .btn-edit {
    background: transparent;
    border: 0px solid;
    cursor: pointer;
    transition: all 0.2s linear;
    padding: 0px;
    svg {
      margin-top: 2px;
      fill: ${theme.palette.black};
      color: ${theme.palette.black};
    }
    @media (min-width: 1000px) {
      display: block;
      opacity: 1;
    }
    @media (max-width: 575px) {
      display: none;
    }
  }
  .options-web {
    position: absolute;
    right: 0;
    top: 100%;
    width: 200px;
    padding: 10px;
    background: ${theme.palette.white};
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.161);
    border-radius: 4px;
    z-index: 1000;
    display: none;
    @media (min-width: 576px) {
      display: block;
    }
    button {
      width: 100%;
      padding: 10px;
      background: transparent;
      border: 0px solid;
      text-align: left;

      line-height: 20px;
      font-size: 14px;
      color: ${theme.colors.gray8};
      transition: all 0.2s linear;
      cursor: pointer;
      display: flex;
      align-items: center;
      img,
      svg {
        margin-right: 10px;
        fill: ${theme.colors.gray8};
        color: ${theme.colors.gray8};
      }
      &:hover {
        background: ${theme.palette.gray2};
      }
    }
  }
  .options-mobile {
    display: block;
    @media (min-width: 576px) {
      display: none;
    }
    button.close {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      z-index: 100;
      background: rgba(0, 0, 0, 0.3);
      border: 0px solid;
    }
    .content {
      position: fixed;
      bottom: 0;
      left: 0;
      border-radius: 4px 4px 0 0;
      width: 100%;
      padding: 10px;
      background: ${theme.palette.white};
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.161);
      z-index: 1000;
      button {
        &.edit-desk {
          display: none;
          @media (min-width: 576px) {
            display: flex;
          }
        }
        &.edit-mobile {
          display: flex;
          @media (min-width: 576px) {
            display: none;
          }
        }
        width: 100%;
        padding: 10px;
        background: transparent;
        border: 0px solid;
        text-align: left;

        line-height: 20px;
        font-size: 14px;
        color: ${theme.colors.gray8};
        transition: all 0.2s linear;
        cursor: pointer;
        display: flex;
        align-items: center;
        img,
        svg {
          margin-right: 10px;
          fill: ${theme.colors.gray8};
          color: ${theme.colors.gray8};
        }
        &:hover {
          background: ${theme.palette.gray2};
        }
      }
    }
  }
`

export const EditItem = styled.div`
  width: 100%;
  padding: 10px;
  display: none;
  @media (min-width: 576px) {
    display: block;
    padding: 24px;
  }
  .title {
    text-align: left;
    font-size: 16px;
    line-height: 20px;
    color: ${theme.palette.black};
    font-weight: 600;
    margin-bottom: 16px;
  }

  .bottom {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .btn-cancel {
      border: 0px solid;
      &:hover {
        background: transparent;
        color: ${theme.colors.blue4};
      }
    }
  }
`

export const OptionsEdit = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  svg {
    fill: ${theme.colors.gray8};
    color: ${theme.colors.gray8};
  }
  button.btn-delete,
  button.btn-move {
    background: transparent;
    border: 0px solid;
    margin-right: 5px;
    border: 1px solid ${theme.colors.gray8};
    border-radius: 4px;
    padding: 5px;
    cursor: pointer;
    @media (min-width: 992px) {
      border: 0px solid;
    }
    * {
      cursor: pointer;
    }
  }
`

export const ModalContent = styled.div`
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 70px);
  .title-modal {
    font-size: 16px;
    color: ${theme.palette.black};
    font-weight: bold;
    margin-bottom: 30px;
  }
  .bottom-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .btn-option {
      color: ${theme.colors.blue4};
      background: transparent;
      display: flex;
      align-items: center;
      border: 0px solid;
      margin-bottom: 25px;
      svg {
        margin-right: 15px;
        color: ${theme.colors.blue4};
        * {
          stroke: ${theme.colors.blue4};
        }
      }
    }
    .btn-confirm {
      margin-bottom: 15px;
    }
  }
`
