import React from 'react'
import { ButtonWrapper, ChildrenWrapper } from './styles'
import { ThreeDots } from 'react-loader-spinner'

interface Props extends React.ComponentPropsWithRef<'button'> {
  color?: 'primary' | 'success' | 'error' | 'warning' | 'disabled'
  variant?: 'outlined' | 'contained'
  isLoading?: boolean
  width?: string
  children: React.ReactNode
  minWidth?: 'small' | 'medium' | 'large'
  onClick?: () => void
}

export const Button: React.FC<Props> = ({
  children,
  color,
  variant,
  isLoading,
  width,
  onClick,
  ...props
}) => {
  const handleClick = () => {
    if (onClick) {
      onClick()
    }
  }

  return (
    <ButtonWrapper
      type={props.type}
      color={color}
      variant={variant}
      loading={isLoading}
      onClick={() => handleClick()}
      styles={{ width }}
      {...props}
    >
      <ChildrenWrapper>
        {isLoading && (
          <ThreeDots
            color={variant === 'outlined' ? '#257CE5' : '#fff'}
            height={30}
            width={30}
          />
        )}
        {!isLoading && children}
      </ChildrenWrapper>
    </ButtonWrapper>
  )
}
