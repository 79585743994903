import React from 'react'

const SvgZoomOutIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={18}
      height={18}
      viewBox='0 0 18 18'
      {...props}
    >
      <g>
        <path
          d='M16.938,17.873l-.075-.063-2.815-2.745a8.731,8.731,0,0,1-5.4,1.854A8.566,8.566,0,0,1,0,8.46,8.566,8.566,0,0,1,8.65,0,8.566,8.566,0,0,1,17.3,8.46a8.325,8.325,0,0,1-2.278,5.714L17.8,16.887a.642.642,0,0,1,0,.922.678.678,0,0,1-.868.064ZM1.332,8.46A7.246,7.246,0,0,0,8.65,15.616,7.247,7.247,0,0,0,15.968,8.46,7.247,7.247,0,0,0,8.65,1.3,7.246,7.246,0,0,0,1.332,8.46Z'
          fill='currentColor'
        />
      </g>
      <path
        d='M7.519,0a.7.7,0,0,1,.095,1.389L7.519,1.4H.7A.7.7,0,0,1,.6.006L.7,0Z'
        transform='translate(4.629 7.658)'
        fill='currentColor'
      />
    </svg>
  )
}

export default SvgZoomOutIcon
