import styled from 'styled-components'
import { theme } from '../../../../Theme'

export const DotsMenuStyle = styled.div`
  position: relative;
  color: #6b7786;
  z-index: 20;
  outline: none;
  svg {
    // TODO - algum recurso está forçando SVG com margens dando instablidade ao código
    //  e forçando uso de !important
    // Sugestão: é preciso rever se SVGs precisam de configuração de margens, para melhor uso dos desenvolvedores.
    margin: 0 !important;
  }
`

export const MenuStyle = styled.div`
  width: 220px;
  background-color: ${theme.colors.white};
  border-radius: 4px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.4);
  user-select: none;
  border: none;
  transition: all 0.5s;
`
export const OptionStyle = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 12px 12.57px;
  font-weight: 400;
  font-size: 14px;

  svg {
    // TODO - algum recurso está forçando SVG com margens dando instablidade ao código
    //  e forçando uso de !important
    // Sugestão: é preciso rever se SVGs precisam de configuração de margens, para melhor uso dos desenvolvedores.
    margin: 0 14.17px 0 0 !important;
  }

  &:hover,
  &:active {
    background-color: ${theme.colors.gray2};

    color: ${theme.colors.blue3};
  }
`
