import React, {
  InputHTMLAttributes,
  forwardRef,
  useCallback,
  ReactNode,
  useMemo,
  KeyboardEvent,
  Ref,
} from 'react'
import { InputBaseComponentProps } from '@mui/material'
import Icon from 'components/atoms/Icon'
import { IconOptions } from 'components/atoms/Icon/icon-list'
import { Input, Label, HelperText, Adornment, FormControl, PopperContainer } from './styles'
import Tooltip from '../../atoms/Tooltip'

export interface ITextField extends InputHTMLAttributes<HTMLInputElement> {
  label?: string | ReactNode
  className?: string
  error?: boolean
  message?: string
  disableTrim?: boolean
  icon?: IconOptions
  iconOptions?: {
    icon?: IconOptions
    position?: 'start' | 'end'
    adornment?: 'string' | ReactNode
  }
  inputComponent?: React.ElementType<InputBaseComponentProps>
  variant?: 'standard' | 'condensed'
  disabled?: boolean
  onKeyDown?: (e: KeyboardEvent) => void
  'data-testid'?: string
  multiline?: boolean
  minRows?: number
  parentRef?: Ref<HTMLDivElement>
  tooltipOnEllipsis?: boolean
  endAdornment?: ReactNode
  startAdornment?: ReactNode
}

const TextField = forwardRef<HTMLInputElement, ITextField>(
  (
    {
      value,
      className,
      label,
      error,
      message,
      disableTrim,
      onChange,
      icon,
      iconOptions,
      inputComponent,
      variant = 'standard',
      disabled,
      onKeyDown,
      'data-testid': testId,
      multiline,
      minRows = 3,
      parentRef,
      startAdornment,
      endAdornment,
      tooltipOnEllipsis,
      ...props
    },
    ref,
  ) => {
    const trimEventValue = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
      event.target.value = event.target.value.trimStart().replace(/\s\s+/g, ' ')
    }, [])

    const [isLabelOverflowing, setIsLabelOverflowing] = React.useState<boolean>(false)

    // eslint-disable-next-line max-len
    const onLabelRefChange = useCallback(
      node => setIsLabelOverflowing(node?.scrollWidth > node?.clientWidth),
      [],
    )

    const conditionStartIcon = useMemo(
      () =>
        (icon || iconOptions?.adornment || iconOptions?.icon) &&
        (iconOptions?.position === 'start' || !iconOptions?.position) &&
        (iconOptions?.adornment ? (
          <Adornment className="levi-textfield-adornment">{iconOptions?.adornment}</Adornment>
        ) : (
          <Icon icon={(icon || iconOptions?.icon) as IconOptions} />
        )),
      [icon, iconOptions],
    )

    const conditionEndIcon = useMemo(
      () =>
        (icon || iconOptions?.adornment || iconOptions?.icon) &&
        iconOptions?.position === 'end' &&
        (iconOptions?.adornment ? (
          <Adornment className="levi-textfield-adornment">{iconOptions?.adornment}</Adornment>
        ) : (
          <Icon icon={(icon || iconOptions?.icon) as IconOptions} />
        )),
      [icon, iconOptions],
    )

    return (
      <FormControl error={error} fullWidth variant="standard">
        {label && (
          <Tooltip
            placement="top-start"
            element={
              <Label ref={onLabelRefChange} shrink data-testid="textfield-label">
                {label}
              </Label>
            }
            componentsProps={{
              popper: {
                style: {
                  display: isLabelOverflowing && tooltipOnEllipsis ? undefined : 'none',
                },
              },
            }}
            title={<PopperContainer data-testid="textfield-label-tooltip">{label}</PopperContainer>}
          />
        )}
        <Input
          value={value}
          className={`${className} input-text-${variant}`}
          inputProps={{
            'data-testid': testId || 'textfield-component',
            ref,
            ...props,
          }}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            if (!disableTrim) trimEventValue(event)
            if (onChange) onChange(event)
          }}
          startAdornment={conditionStartIcon || startAdornment}
          endAdornment={conditionEndIcon || endAdornment}
          inputComponent={inputComponent}
          disabled={disabled}
          onKeyDown={onKeyDown}
          multiline={multiline}
          minRows={multiline ? minRows : undefined}
          ref={parentRef}
        />
        {message && <HelperText>{message}</HelperText>}
      </FormControl>
    )
  },
)

export default TextField
