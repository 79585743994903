import React from 'react'
import { SvgProps } from './typings/SvgProps'

const ChevronRight: React.FC<SvgProps> = ({ color = 'currentColor', size, ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} {...rest} viewBox="0 0 5.811 10.121">
    <g transform="translate(1.061 1.061)">
      <g transform="translate(-4 10) rotate(-90)">
        <g>
          <g transform="translate(2 4)">
            <g>
              <path
                d="M8,0,4,4,0,0"
                fill="none"
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="1.5"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default ChevronRight
