import React from 'react';

const SvgInfoIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={16.745}
      height={16.745}
      viewBox='0 0 21 21'
      {...props}
    >
      <g data-name='Iconly/Light/Info Circle'>
        <g
          data-name='Info Circle'
          fill='none'
          stroke='#378ff7'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          strokeWidth={1.5}
        >
          <path
            data-name='Stroke 1'
            d='M8.372.75A7.622 7.622 0 11.75 8.372 7.623 7.623 0 018.372.75z'
          />
          <path data-name='Stroke 3' d='M8.368 11.5V7.859' />
          <path data-name='Stroke 5' d='M8.373 5.244h0' />
        </g>
      </g>
    </svg>
  )
}

export default SvgInfoIcon
