import { theme } from '@cockpit/levi-ui/theme'
import styled from '@emotion/styled'
import { FormControl as FormControlMUI, FormHelperText, InputBase, InputLabel } from '@mui/material'
import { getColors } from 'helpers'

export const Input = styled(InputBase)`
  --color: ${getColors('neutral', 30)};
  --primary-color: ${({ theme: themeM }) => themeM.palette.primary.main};
  --error-color: ${({ theme: themeM }) => themeM.palette.error.main};
  --v-spacing: ${theme.spacings[2]};
  --h-spacing: ${theme.spacings[4]};
  border: 1px solid var(--color);
  border-radius: 0.25rem;
  transition: border-color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  width: 100%;
  padding: 0 var(--h-spacing);
  color: ${getColors('neutral', 50)};
  background: ${getColors('neutral', 10)};
  &.Mui-disabled {
    background: ${getColors('neutral', 20)};
    input,
    textarea {
      color: ${getColors('neutral', 80)};
      -webkit-text-fill-color: ${getColors('neutral', 80)};
    }
  }
  &:hover {
    border-color: ${getColors('neutral')};
  }
  input,
  textarea {
    padding: var(--v-spacing) 0;
    font-size: ${theme.font.size[2]};
    color: ${getColors('neutral', 80)};
    &::placeholder {
      color: ${getColors('neutral', 50)};
      opacity: 1;
    }
  }
  &.Mui-focused:not(.Mui-error) {
    &.MuiInputBase-colorPrimary {
      border-color: var(--primary-color);
    }
  }
  &.Mui-error {
    border-color: var(--error-color);
    input,
    textarea {
      color: var(--error-color);
    }
  }
  & > svg {
    max-height: calc(2rem - (var(--v-spacing) * 2));
    &:first-child {
      padding-right: var(--h-spacing);
    }
    &:last-child {
      padding-left: var(--h-spacing);
    }
  }

  &:not(.MuiInputBase-multiline).input-text-standard {
    height: 2.5rem;
  }
  &:not(.MuiInputBase-multiline).input-text-condensed {
    height: 2rem;
  }
`

export const Label = styled(InputLabel)`
  transform: none;
  position: relative;
  font-size: ${theme.font.size[2]};
  width: fit-content;
  margin-bottom: ${theme.spacings[1]};
  font-weight: 600;
  color: ${getColors('neutral', 60)};
`

export const PopperContainer = styled.div`
  max-width: 300px;
  word-break: break-word;
`

export const HelperText = styled(FormHelperText)`
  font-size: ${theme.font.size[1]};
  position: absolute;
  left: 0;
  bottom: -24px;
  top: initial;
`

export const Adornment = styled.span`
  font-size: ${theme.font.size[2]};
`

export const FormControl = styled(FormControlMUI)`
  position: relative;
`
