import React from 'react'

const SvgDoubleLeftArrow = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={24}
      height={24}
      viewBox='0 0 24 24'
      {...props}
    >
      <g
        id='Grupo_161734'
        data-name='Grupo 161734'
        transform='translate(11943 1270)'
      >
        <rect
          id='Retângulo_140885'
          data-name='Retângulo 140885'
          width={24}
          height={24}
          transform='translate(-11943 -1270)'
          fill='none'
        />
        <g
          id='Grupo_161732'
          data-name='Grupo 161732'
          transform='translate(-11946.418 -1268.419)'
        >
          <g
            id='União_10'
            data-name='União 10'
            transform='translate(3.417 0.418)'
            fill='none'
            strokeLinecap='round'
            strokeMiterlimit='10'
          >
            <path
              d='M8.544,19.509.478,11.226a1.813,1.813,0,0,1,0-2.451L8.544.491a1.6,1.6,0,0,1,2.3,0,1.706,1.706,0,0,1,0,2.367L3.894,10l6.954,7.142a1.706,1.706,0,0,1,0,2.367,1.6,1.6,0,0,1-2.3,0Z'
              stroke='none'
            />
            <path
              d='M 9.695713996887207 20.00003623962402 C 9.278766632080078 20.00003623962402 8.861871719360352 19.83645629882812 8.543713569641113 19.50929832458496 L 0.4779245257377625 11.2257194519043 C 0.1493982076644897 10.88787746429443 -0.009496527723968029 10.44214057922363 0.001187682850286365 10.00003528594971 C -0.009496527723968029 9.557877540588379 0.1493982076644897 9.112351417541504 0.4779245257377625 8.774983406066895 L 8.543713569641113 0.4905093014240265 C 9.17997932434082 -0.1629124581813812 10.21140003204346 -0.1629109978675842 10.8477144241333 0.4905093014240265 C 11.48397731781006 1.143877744674683 11.48397731781006 2.20408821105957 10.8477144241333 2.85750937461853 L 3.893871784210205 10.00066757202148 L 10.8477144241333 17.14229965209961 C 11.48397731781006 17.7966136932373 11.48397731781006 18.85587692260742 10.8477144241333 19.50929832458496 C 10.52955627441406 19.83645629882812 10.11260890960693 20.00003623962402 9.695713996887207 20.00003623962402 Z'
              stroke='none'
              fill='currentColor'
            />
          </g>
          <g
            id='União_9'
            data-name='União 9'
            transform='translate(16.092 0.418)'
            fill='none'
            strokeLinecap='round'
            strokeMiterlimit='10'
          >
            <path
              d='M8.544,19.509.478,11.226a1.813,1.813,0,0,1,0-2.451L8.544.491a1.6,1.6,0,0,1,2.3,0,1.706,1.706,0,0,1,0,2.367L3.894,10l6.954,7.142a1.706,1.706,0,0,1,0,2.367,1.6,1.6,0,0,1-2.3,0Z'
              stroke='none'
            />
            <path
              d='M 9.695713996887207 20.00003623962402 C 9.278766632080078 20.00003623962402 8.861871719360352 19.83645629882812 8.543713569641113 19.50929832458496 L 0.4779245257377625 11.2257194519043 C 0.1493982076644897 10.88787746429443 -0.009496527723968029 10.44214057922363 0.001187682850286365 10.00003528594971 C -0.009496527723968029 9.557877540588379 0.1493982076644897 9.112351417541504 0.4779245257377625 8.774983406066895 L 8.543713569641113 0.4905093014240265 C 9.17997932434082 -0.1629124581813812 10.21140003204346 -0.1629109978675842 10.8477144241333 0.4905093014240265 C 11.48397731781006 1.143877744674683 11.48397731781006 2.20408821105957 10.8477144241333 2.85750937461853 L 3.893871784210205 10.00066757202148 L 10.8477144241333 17.14229965209961 C 11.48397731781006 17.7966136932373 11.48397731781006 18.85587692260742 10.8477144241333 19.50929832458496 C 10.52955627441406 19.83645629882812 10.11260890960693 20.00003623962402 9.695713996887207 20.00003623962402 Z'
              stroke='none'
              fill='currentColor'
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default SvgDoubleLeftArrow
