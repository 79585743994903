import React, { forwardRef } from 'react'
import TextField, { ITextField } from 'components/molecules/TextField'
import { NumericFormat } from 'react-number-format'
import { NumberFormatValues } from 'react-number-format/types/types'

export interface INumberField extends Omit<ITextField, 'value' | 'type' | 'defaultValue'> {
  value?: string | number | null
  defaultValue?: string | number | null
  maxValue?: number
  thousandSeparator?: boolean | string
  decimalSeparator?: string
  allowedDecimalSeparators?: Array<string>
  thousandsGroupStyle?: 'lakh' | 'wan' | 'none'
  decimalScale?: number
  fixedDecimalScale?: boolean
  allowNegative?: boolean
  allowLeadingZeros?: boolean
  suffix?: string
  prefix?: string
}

const NumberField = forwardRef<HTMLInputElement, INumberField>(
  (
    {
      maxValue = 9999,
      allowNegative = false,
      allowedDecimalSeparators = [',', '.'],
      decimalSeparator = ',',
      allowLeadingZeros = false,
      decimalScale = 0,
      ...rest
    },
    ref,
  ) => {
    const withValueCap = (inputObj: NumberFormatValues) => {
      const { value } = inputObj
      if (value.charAt(0) === '0') {
        if (value.charAt(1) && value.charAt(1) !== '.') {
          return false
        }
      }
      if (maxValue && +value <= maxValue) return true
      return false
    }
    return (
      <NumericFormat
        {...rest}
        getInputRef={ref}
        allowNegative={allowNegative}
        allowedDecimalSeparators={allowedDecimalSeparators}
        decimalSeparator={decimalSeparator}
        allowLeadingZeros={allowLeadingZeros}
        decimalScale={decimalScale}
        isAllowed={withValueCap}
        onValueChange={(value: NumberFormatValues) => {
          const event = {
            target: {
              value: value.formattedValue,
            },
            name: rest.name,
          } as never
          rest.onChange?.(event)
        }}
        customInput={TextField}
      />
    )
  },
)

export default NumberField
