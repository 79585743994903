import React from 'react'
import { TagWrapper } from './styles'

interface Props {
  text: string
  type: string
  fontWeight?: string
  selected?: boolean
  onClick?: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void
  backgroundColor?: string
}

export const TagStatic: React.FC<Props> = ({
  text,
  type,
  fontWeight,
  selected,
  ...props
}) => {
  return (
    <TagWrapper
      selected={selected}
      fontWeight={fontWeight}
      className='tagStaticWrapper'
      type={type}
      {...props}
    >
      {text}
    </TagWrapper>
  )
}
