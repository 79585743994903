import React from 'react';

const SvgIdentity = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={20.207}
      height={16.708}
      viewBox='0 0 21 18'
      {...props}
    >
      <path d='M17.197 0H3.009A3.013 3.013 0 000 3.01v10.688a3.013 3.013 0 003.009 3.01h14.188a3.013 3.013 0 003.01-3.01V3.01A3.013 3.013 0 0017.197 0zm1.51 13.7a1.513 1.513 0 01-1.51 1.51H3.009A1.512 1.512 0 011.5 13.7V3.01A1.511 1.511 0 013.009 1.5h14.188a1.512 1.512 0 011.51 1.51z' />
      <path d='M6.849 8.372a2.64 2.64 0 10-2.64-2.641 2.643 2.643 0 002.64 2.641zm0-3.781a1.14 1.14 0 11-1.14 1.14 1.141 1.141 0 011.14-1.14zM8.205 8.674H5.444a2.92 2.92 0 00-2.845 2.842v1.386a.75.75 0 001.5 0v-1.386a1.409 1.409 0 011.359-1.342h2.747a1.423 1.423 0 011.394 1.342v1.386a.75.75 0 001.5 0v-1.386a2.907 2.907 0 00-2.894-2.842zM16.516 5.102h-3.594a.75.75 0 000 1.5h3.594a.75.75 0 000-1.5zM14.957 8.856h-2.035a.75.75 0 000 1.5h2.035a.75.75 0 000-1.5z' />
    </svg>
  )
}

export default SvgIdentity
