import styled from 'styled-components'
import { theme } from '../../Theme'
interface StyledIconProps {
  backgroundColor?: string
  iconColor?: string
}

export const IconWrapper = styled.i<StyledIconProps>`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  outline: none;
  transition: all 0.3s ease-in;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : theme.colors.gray7};

  svg {
    transition: all ease 0.2s;
    fill: ${(props) =>
      props.iconColor ? props.iconColor : theme.colors.blue3};
  }

  &:hover {
    background-color: ${(props) =>
      props.iconColor ? props.iconColor : theme.colors.blue3};

    svg {
      fill: ${(props) =>
        props.backgroundColor ? props.backgroundColor : theme.colors.gray7};
    }
  }
`
