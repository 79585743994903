/* eslint-disable no-debugger */
import styled from 'styled-components'
import { theme } from '../Theme'
interface ICheck {
  check: boolean
}
export const Wrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-family: inherit;
  font-size: 12px;
  width: 100%;
  height: 38px;
  border: 1px solid ${theme.palette.gray2};
  border-radius: 3px;
  box-sizing: border-box;
`
export const LabelWrapperCheck = styled.div<ICheck>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: inherit;
  font-size: 12px;
  font-weight: 500;
  width: auto;
  height: 100%;
  padding: 0px 26px 0px 26px;
  box-sizing: border-box;
  color: ${({ check }) => (check ? '#378FF7' : '#6B7786')};
  background-color: ${({ check }) => (check ? '#fff' : theme.palette.gray2)};
  border-radius: 3px;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.4s;
`
export const LabelWrapperUnCheck = styled.div<ICheck>`
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: inherit;
  font-size: 12px;

  width: auto;
  height: 100%;
  padding: 0px 26px 0px 26px;
  box-sizing: border-box;
  color: ${({ check }) => (check ? '#6B7786' : '#378FF7')};
  background-color: ${({ check }) => (check ? theme.palette.gray2 : '#fff')};
  font-family: inherit;
  font-size: 12px;
  font-weight: 500;
  border-radius: 3px;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.4s;
`

export const Label = styled.p`
  font-family: inherit;
`
