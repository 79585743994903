import React from 'react'

const SvgProntIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={24}
      height={24}
      viewBox='0 0 20 21.2'
      {...props}
    >
      <g transform='translate(-146.5 -866)'>
        <g transform='translate(165.323 866.948) rotate(90)'>
          <path
            id='Line_182'
            d='M0,0V6.68'
            transform='translate(5.23 5.614)'
            fill='none'
            stroke='currentColor'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            strokeWidth={1.5}
          />
          <path
            id='Line_183'
            d='M0,0V7.678'
            transform='translate(9.775 4.616)'
            fill='none'
            stroke='currentColor'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            strokeWidth={1.5}
          />
          <path
            id='Line_184'
            d='M0,0V3.15'
            transform='translate(14.245 8.965)'
            fill='none'
            stroke='currentColor'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            strokeWidth={1.5}
          />
          <path
            id='Path'
            d='M14.3,0H5.175C1.994,0,0,2,0,4.825v7.627c0,2.827,1.985,4.825,5.175,4.825H14.3c3.19,0,5.175-2,5.175-4.825V4.825C19.475,2,17.49,0,14.3,0Z'
            fill='none'
            stroke='currentColor'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit={10}
            strokeWidth={1.5}
          />
        </g>
      </g>
    </svg>
  )
}

export default SvgProntIcon
