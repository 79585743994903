import { theme } from '../../Theme'
import { rgba } from 'polished'
import styled, { keyframes } from 'styled-components'

const Fade = keyframes`
  0% {
    opacity: 0
  }

  100% {
    opacity: 1
  }
`
interface IIconWrapper {
  rotate: boolean
  error?: boolean
}

export const FakeInput = styled.input`
  width: 0;
  height: 0;
  border: none;
  position: absolute;
`

export const MultiCheckContainer = styled.div`
  font: 15px 'Inter', sans-serif;
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
`

export const ExpandedContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: ${theme.colors.white};
  border: 1px solid ${theme.colors.gray6};
  margin-top: -2px;
  z-index: 9999;
  position: absolute;
  width: 100%;
  top: 41px;

  max-height: 30vh;
  padding: 0 0 1rem 0;

  overflow-y: auto;

  -webkit-box-shadow: 0px 0px 6px 0px ${rgba(theme.colors.gray6, 0.4)};
  -moz-box-shadow: 0px 0px 6px 0px ${rgba(theme.colors.gray6, 0.4)};
  box-shadow: 0px 0px 6px 0px ${rgba(theme.colors.gray6, 0.4)};
  transition: all ease 0.2s;
  animation: ${Fade} 0.2s linear;
`

export const WrapperFakeInput = styled.div<{ error?: boolean }>`
  align-items: baseline;
  background: ${theme.colors.white};
  cursor: pointer;
  border-radius: ${(props) => props.theme.borderRadius.fields};
  user-select: none;
  height: 40px;
  padding: 10px;
  color: ${theme.colors.gray5};
  width: 100%;
  border: 1px solid ${theme.colors.gray6};
  box-shadow: 0px 0px 6px 0px ${rgba(theme.colors.gray6, 0.4)};
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 0.5rem;
  align-items: center;
  min-width: 160px;
  border: ${({ error }) =>
      error ? theme.palette.highlight.red : theme.palette.gray2}
    1px solid;
`

export const OptionsDisplay = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const CheckboxWrapper = styled.div`
  font-weight: ${theme.fontWeight.regular};
  user-select: none;
  position: relative;
  margin: 17px 15px 0px 14px;
  display: flex;
  align-items: center;

  input[type='checkbox']:checked + label {
    color: ${theme.colors.blue3};

    &:before {
      background: ${theme.colors.blue3};
      border: 1px solid ${theme.colors.blue3};
    }

    &:after {
      border-bottom: 2px solid ${theme.colors.white};
      border-left: 2px solid ${theme.colors.white};
    }
  }
`

export const CheckboxElement = styled.input`
  visibility: hidden;
  width: 5px;
`

export const Label = styled.label`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-left: 22px;
  color: ${theme.colors.gray4};

  &:hover:before {
    border: 1px solid ${theme.colors.blue3};
  }

  &:before {
    content: '';
    width: 20px;
    height: 20px;
    position: absolute;
    background: ${theme.colors.white};
    border: 1px solid #b9c2ce;
    left: 0;
    border-radius: ${theme.borderRadius.checkbox};
    transition: all 0.2s;
  }

  &:after {
    content: '';
    width: 8px;
    height: 4px;
    border-bottom: 2px solid transparent;
    border-left: 2px solid transparent;

    transform: rotate(-45deg);

    position: absolute;
    left: 6px;
    top: 5px;
  }
`

export const IconWrapper = styled.div<IIconWrapper>`
  display: grid;
  place-items: center;
  transform: rotate(0deg);
  transition: all 0.3s ease-out;
  transform-origin: top 0 bottom;
  transform: ${(props: IIconWrapper) =>
    props.rotate ? 'rotate3d(1, 0, 0, -0.5turn)' : ''};
  color: ${({ error }: IIconWrapper) =>
    error ? theme.palette.highlight.red : theme.palette.button.icons};
  cursor: pointer;
  min-width: fit-content;
`

export const ErrorContainer = styled.span`
  color: ${theme.colors.red1};
  font-size: 12px;
  padding-top: 4px;
  position: absolute;
  top: 100%;
`
