import styled from 'styled-components'
import { theme } from '../Theme'

export const Title = styled.div`
  font-weight: ${theme.fontWeight.semibold};
  font-family: Inter;
  font-size: 20px;
  margin-top: 15px;
`

interface ISeparator {
  marginTop?: string
  marginBottom?: string
}

export const Separator = styled.div<ISeparator>`
  width: 100%;
  height: 2px;
  background-color: ${theme.colors.gray3};
  margin-top: ${(props) => props.marginTop ?? '20px'};
  margin-bottom: ${(props) => props.marginBottom ?? '30px'};
`

export const Container = styled.div`
  display: flex;
`
export const AvatarContent = Container

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2;
  margin-left: 10px;
`
export const Name = styled.div`
  font-weight: ${theme.fontWeight.semibold};
  font-family: Inter;
  font-size: 20px;
  margin-top: 3px;
  margin-bottom: 10px;
`

export const Item = styled.div`
  margin-top: 10px;
  font-size: 12px;
  display: flex;
  height: 20px;
  color: ${theme.colors.gray4};
`

export const ItemPatient = styled.div`
  margin-top: -2px;
  font-size: 14px;
  font-weight: ${theme.fontWeight.semibold};
  font-family: 'Inter';
  display: flex;
  margin-left: 5px;
  color: ${theme.colors.black};
`
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  button:nth-child(1) {
    margin-right: 10px;
    width: 150px;
  }

  button:nth-child(2) {
    margin-left: 10px;
  }
`
