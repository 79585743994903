import styled from 'styled-components'

import { IPropsTheme } from '../../Theme'

export const Content = styled.nav<{ isopen?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: start;
  position: fixed;
  left: ${({ isopen }) => (isopen ? '0' : '-330px')};
  top: 0;
  width: 300px;
  height: 100%;
  z-index: 105;
  margin: 0;
  padding-bottom: 10px;
  box-shadow: 0 3px 6px #00000029;
  background: ${({ theme }: IPropsTheme) => theme.menu.background};
  transition: left 0.2s;
`

export const Header = styled.div`
  width: 100%;
  height: 80px;
  padding: 0 32px 0 39px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-bottom: 1px solid ${({ theme }: IPropsTheme) => theme.menu.divider};
`

export const MenuContainer = styled.div`
  width: 100%;
  margin-top: 20px;
  padding-left: 15px;
`

export const Divider = styled.span`
  height: 0.5px;
  width: 100%;
  background: ${({ theme }: IPropsTheme) => theme.menu.divider};
  margin-top: 15px;
`

export const LogoContainer = styled.div<{ isopen?: boolean }>`
  width: 163px;
  height: 29px;
  position: absolute;
  left: 39px;
  color: ${({ theme }: IPropsTheme) => theme.menu.iconContainerColorHover};
`

export const CloseMenu = styled.div`
  width: 15px;
  cursor: pointer;
  color: ${({ theme }: IPropsTheme) => theme.menu.iconContainerColor};
`
