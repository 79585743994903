import React from 'react'
import { SvgProps } from './typings/SvgProps'

const Italic: React.FC<SvgProps> = ({ color = 'currentColor', size, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    {...rest}
    viewBox="0 0 6.5 11.5"
  >
    <g transform="translate(44.75 17.75)">
      <g transform="translate(-44 -17)">
        <g transform="translate(0 0)">
          <g transform="translate(0)">
            <path
              d="M0,10H0ZM3.333,0h0L1.667,10Z"
              transform="translate(0 0)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default Italic
