import React from 'react'
import { SvgProps } from './typings/SvgProps'

const Logout: React.FC<SvgProps> = ({ color = 'currentColor', size, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    {...rest}
    viewBox="0 0 53.28 51"
  >
    <g transform="translate(0.5 0.5)">
      <g transform="translate(0 0)">
        <path
          d="M52.2,23.626,44.78,16.351a1.961,1.961,0,0,0-2.769.025,1.91,1.91,0,0,0,0,2.725l4.064,3.975H35.56v3.85H46.075L42.011,30.9a1.91,1.91,0,0,0,0,2.725,1.954,1.954,0,0,0,2.769,0L52.2,26.351A1.839,1.839,0,0,0,52.78,25a1.894,1.894,0,0,0-.584-1.375"
          fill={color}
        />
      </g>
      <path
        d="M18.064,25a1.943,1.943,0,0,1,1.99-1.925H35.559V11.1A11.293,11.293,0,0,0,24.111,0H11.5A11.326,11.326,0,0,0,0,11.125V38.9A11.3,11.3,0,0,0,11.474,50H24.059a11.326,11.326,0,0,0,11.5-11.125V26.925H20.054A1.959,1.959,0,0,1,18.064,25"
        fill={color}
        stroke={color}
        strokeWidth="1"
      />
    </g>
  </svg>
)

export default Logout
