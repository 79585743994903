import React from 'react'

const DragAndDrop = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width='6'
      height='10'
      viewBox='0 0 6 10'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <circle cx='1' cy='1' r='1' fill='#6B7786' />
      <circle cx='5' cy='9' r='1' fill='#6B7786' />
      <circle cx='5' cy='5' r='1' fill='#6B7786' />
      <circle cx='1' cy='9' r='1' fill='#6B7786' />
      <circle cx='1' cy='5' r='1' fill='#6B7786' />
      <circle cx='5' cy='1' r='1' fill='#6B7786' />
    </svg>
  )
}

export default DragAndDrop
