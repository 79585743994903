import React from 'react'
import { SvgProps } from './typings/SvgProps'

const Send: React.FC<SvgProps> = ({ color = 'currentColor', size, ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill={color} width={size} {...rest} viewBox="0 0 15 15">
    <g transform="translate(0.604 0.609)">
      <g>
        <path
          d="M10,3.983,5.593,8.418.436,5.243A.919.919,0,0,1,.659,3.58L12.835.037A.914.914,0,0,1,13.96,1.181L10.348,13.345a.909.909,0,0,1-1.651.213l-3.1-5.141"
          transform="translate(0 0)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.2}
        />
      </g>
    </g>
  </svg>
)

export default Send
