import { FormControlLabel, Switch } from '@mui/material'
import styled from '@emotion/styled'
import { getColors } from 'helpers'
import { theme } from '@cockpit/levi-ui/theme'

export const SwitchContainer = styled.div`
  padding: ${theme.spacings[4]};
  display: flex;
`

export const SwitchComponent = styled(Switch)`
  width: 30px;
  height: 16px;
  box-sizing: content-box;
  padding: 0;
  .MuiButtonBase-root {
    top: 2px;
    left: 2px;
    padding: 0;
    &.Mui-disabled {
      color: ${getColors('neutral', 30)};
    }
    .MuiSwitch-thumb {
      width: 12px;
      height: 12px;
      box-shadow: none;
    }
    &.Mui-checked {
      transform: translateX(14px);
      .MuiSwitch-thumb {
        background: ${getColors('neutral', 10)};
      }
      & + .MuiSwitch-track {
        background-color: ${getColors('blue')};
        opacity: 1;
      }
    }
  }

  .MuiSwitch-track {
    background-color: ${getColors('neutral', 50)};
    opacity: 1;
    border-radius: ${theme.spacings[6]};
  }
`

export const Label = styled(FormControlLabel)`
  .MuiTypography-root {
    font-size: ${theme.font.size[2]};
    color: ${getColors('neutral', 60)};
  }
`
