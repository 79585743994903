import styled from '@emotion/styled/macro'
import { theme } from '@cockpit/levi-ui/theme'
import { IconButton, LinearProgress } from '@mui/material'
import { getColors } from 'helpers'
import { TextField } from '../../molecules'
import { Button } from '../../atoms'

const Container = styled.div`
  background-color: ${theme.colors.neutral[10]};
  padding: 24px;
  display: grid;
  grid-template-columns: 287px 1fr;
  grid-auto-rows: auto;
  grid-template-areas:
    'table-header table-header'
    'loader loader'
    'headers data';
  grid-auto-flow: column;
  border-radius: 4px;

  @media (max-width: 500px) {
    grid-template-columns: 159px 1fr;
    grid-template-areas:
      'table-header'
      'loader loader'
      'headers data';
  }
`

const EmptyContainer = styled.div`
  grid-column: 1 / -1;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${theme.spacings[1]};
  h3,
  p {
    margin: 0px 0px 0px 0px;
    text-align: left;
    letter-spacing: 0px;
  }
  h3 {
    font-family: Inter;
    color: ${theme.colors.neutral[80]};
  }
  p {
    font: 15px/17px Inter;
    color: ${theme.colors.neutral[50]};
    font-weight: 500;
  }
`

const Search = styled(TextField)`
  margin-left: ${theme.spacings[2]};
`

const Loader = styled(LinearProgress)`
  grid-area: loader;
`

const AddButton = styled(Button)`
  min-width: 20%;
  width: fit-content;
  font-size: 14px;
  font-weight: normal;
  justify-self: flex-start;
`

const PaginationContainer = styled.div`
  background-color: ${theme.colors.neutral[10]};
  grid-area: pagination;
  display: flex;
  justify-content: end;
  align-items: center;
  gap: ${theme.spacings[1]};

  button {
    min-width: 40px;
  }
`

const TableHeader = styled.div`
  height: 56px;
  grid-area: table-header;
  display: flex;
  margin-bottom: ${theme.spacings[5]};
  align-items: center;
  gap: ${theme.spacings[3]};
  justify-content: flex-end;

  ${Search} {
    max-width: 50%;
    justify-self: flex-start;
  }
  @media (max-width: 500px) {
    justify-content: flex-start;
    height: auto;
    width: 100%;
    display: grid;
    grid-template-areas:
      'Search Search'
      'AddButton ';

    ${Search} {
      max-width: 50%;
      max-width: 100%;
      justify-self: flex-start;
      margin-left: 0;
    }
  }
`

const ExamContainer = styled.div`
  height: 65px;
  padding-left: ${theme.spacings[4]};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: ${theme.spacings[4]};
`

const Exam = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
`

const ExamTitle = styled.h1`
  font: 14px/18px Inter;
  font-weight: 600;
  text-align: left;
  letter-spacing: 0px;
  color: ${theme.colors.neutral[80]};

  @media (max-width: 500px) {
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

const Measure = styled.p`
  text-align: left;
  font: normal normal normal 14px/18px Inter;
  letter-spacing: 0px;
  color: ${theme.colors.neutral[60]};
`

const ExamColumn = styled.div`
  grid-area: headers;
  display: flex;
  flex-direction: column;
  border-right: ${theme.colors.neutral[30]} 1px solid;

  ${ExamContainer}:nth-child(even) {
    background-color: ${theme.colors.neutral[10]};
  }

  ${ExamContainer}:nth-child(odd) {
    background-color: ${theme.colors.neutral[20]};
  }
`

const TextFieldExamResult = styled(TextField)`
  height: 65px !important;
  border: 1px solid transparent;
  background-color: transparent !important;
`

const DotButton = styled(IconButton)`
  padding: 0;
  span {
    margin-right: 0;
    margin-left: 0;
  }
`

const ExamResultContainer = styled.div<{ isEditable?: boolean; isMenuOpened?: boolean }>`
  height: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${theme.font.size[2]};
  color: ${({ isEditable }) => (isEditable ? getColors('neutral', 60) : getColors('neutral', 80))};
  font-weight: ${theme.font.weight[5]};
  &:not(:hover) {
    ${DotButton} {
      ${({ isMenuOpened }) => (isMenuOpened ? '' : 'display: none;')}
    }
  }

  ${TextFieldExamResult} {
    text-align: left;
    color: ${getColors('neutral', 80)};
    font-weight: ${theme.font.weight[6]};
  }
`
const ExamResultContent = styled.div`
  flex: 1;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  border-right: ${theme.colors.neutral[30]} 1px solid;
  min-width: 140px;

  ${ExamResultContainer}:nth-child(even) {
    background-color: ${theme.colors.neutral[10]};
  }

  ${ExamResultContainer}:nth-child(odd) {
    background-color: ${theme.colors.neutral[20]};
  }
`

const ResultColumns = styled.div`
  grid-area: data;
  display: grid;
  grid-template: auto / repeat(auto-fit, 1fr);
  grid-auto-flow: column;
  justify-content: start;

  overflow-y: hidden;
  overflow-x: overlay;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;

  ${Column}:not(:last-child) {
    scroll-snap-align: start;
  }

  ${Column}:last-child {
    border-right: none;
    scroll-snap-align: end;
  }

  &::-webkit-scrollbar {
    max-height: 10px;
    transition: max-height 2s;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: ${theme.colors.neutral[40]};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${theme.colors.neutral[50]};
    border-radius: ${theme.spacings[2]};
  }

  &::-webkit-scrollbar-track-piece {
    background-color: ${theme.colors.neutral[20]};
  }
`

const ColumnHeader = styled.div<{ lastIndex?: boolean; isLoading?: boolean }>`
  height: 56px;
  color: ${theme.colors.neutral[60]};
  text-align: center;
  background: ${theme.colors.neutral[20]};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${theme.spacings[1]};
  border-radius: ${({ lastIndex, isLoading }) =>
    !lastIndex ? 'unset' : `0 ${!isLoading ? '4px' : '0'} 0 0`};
`

const TrackingDate = styled.div`
  font: 14px/15px Inter;
  font-weight: 600;
`

const LaboratoryLocale = styled.div`
  font: 12px/15px Inter;
  font-weight: 500;
  font-style: italic;
`

const LeadingTitle = styled.div<{ isLoading?: boolean }>`
  padding-left: ${theme.spacings[4]};
  display: flex;
  align-items: center;
  font: 14px/16px Inter;
  letter-spacing: 0px;
  color: ${theme.colors.neutral[60]};
  opacity: 1;
  text-align: left;
  height: 56px;
  font-weight: 600;
  background: ${theme.colors.neutral[20]};
  border-radius: ${({ isLoading }) => (!isLoading ? '4px' : '0')} 0 0 0;
`

const Info = styled.div`
  font-weight: ${theme.font.weight[6]};
  font-size: ${theme.font.size[2]};
  padding-top: ${theme.spacings[3]};
  padding-bottom: ${theme.spacings[3]};
`

export {
  LeadingTitle,
  LaboratoryLocale,
  TrackingDate,
  Exam,
  ExamColumn,
  ExamContainer,
  ExamResultContainer,
  TextFieldExamResult,
  ExamTitle,
  ResultColumns,
  Column,
  ColumnHeader,
  Measure,
  PaginationContainer,
  Container,
  TableHeader,
  Search,
  Loader,
  EmptyContainer,
  AddButton,
  ExamResultContent,
  Info,
  DotButton,
}
