import { theme } from '../Theme'
import styled from 'styled-components'

export interface AvatarProps {
  image?: string
  size?: string
  fontSize?: string
}

export const Avatar = styled.div<AvatarProps>`
  font-size: ${({ fontSize }) => fontSize || ''};
  height: ${({ size }) => size || '40px'};
  width: ${({ size }) => size || '40px'};
  background: ${({ image }) =>
    image ? 'url("' + image + '") no-repeat' : '#d3e6fb'};
  background-size: ${({ image }) => (image ? 'cover' : 'none')};
  background-position: ${({ image }) => (image ? 'center' : 'none')};
  border-radius: 50%;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${theme.colors.blue3};
  cursor: default;

  margin: 0 16px 0 0;

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    opacity: 0;
  }
`
