import styled from 'styled-components'

export const SearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  padding: 0 10px;

  svg {
    color: #8391b1;
  }

  .selectFilter {
    input {
      width: 80px;
    }
  }

  input {
    outline: none;
    border: none;
  }

  div {
    box-shadow: none;
  }
`

export const SubmitButton = styled.button`
  appearance: none;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
`

export const ResetButton = styled.button`
  appearance: none;
  background: ${(props) => props.theme.colors.gray2};
  border: none;
  padding: 0;
  cursor: pointer;
  padding: 8px;
  border-radius: 4px;
  color: ${(props) => props.theme.colors.gray5};
  font-size: 0;
`
