/* eslint-disable no-unneeded-ternary */
import { theme } from '../Theme'
import styled from 'styled-components'
import { Radio as RadioComponent, IRadio } from '../Form/Radio'

export const Container = styled.div``

export const RadioContainer = styled.div`
  display: flex;
  padding-bottom: ${theme.spacing.tiny};
`
export const Radio = styled(RadioComponent)<IRadio>`
  margin-right: ${theme.spacing.medium};
`

export interface IErrorProps {
  fontSizeError?: string
  fontWeight?: string
}

export const ErrorMessage = styled.span<IErrorProps>`
  color: ${theme.colors.red1};
  font-size: ${({ fontSizeError }) => (fontSizeError ? fontSizeError : '12px')};
  font-weight: ${({ fontWeight }) =>
    fontWeight ? fontWeight : theme.fontWeight.regular};
`
