import { SvgProps } from '@cockpit/levi-ui/icons/typings/SvgProps'
import React from 'react'

const FileSearching: React.FC<SvgProps> = ({ color = 'currentColor', size, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    fill={color}
    {...rest}
    height="144.08"
    viewBox="0 0 196.109 144.08"
  >
    <g
      id="File_searching-rafiki"
      data-name="File searching-rafiki"
      transform="translate(-30.716 -81.25)"
    >
      <g id="freepik--background-simple--inject-5" transform="translate(30.716 81.25)">
        <path
          id="Path_13942"
          data-name="Path 13942"
          d="M167.624,5.477C160.758.806,146-1.588,137.682,1.17,127.064,4.7,127.437,12.432,119.71,17.219c-20.873,12.919-42.061,4.232-64.119-1.332C6.709,3.537-23.9,50.571,24.1,79.487,52.971,96.864,113.59,109.561,120.416,63.5c2.953-19.913-7.7-21.428,17.053-25.758C147.24,36.023,198.728,26.648,167.624,5.477Z"
          transform="matrix(0.966, 0.259, -0.259, 0.966, 24.665, 0)"
          fill="#f6f7f9"
        />
      </g>
      <g id="freepik--Shadow--inject-5" transform="translate(50.934 215.275)">
        <ellipse
          id="freepik--path--inject-5"
          cx="86.106"
          cy="5.027"
          rx="86.106"
          ry="5.027"
          fill="#f6f7f9"
        />
      </g>
      <g id="freepik--Folder--inject-5" transform="translate(62.304 132.709)">
        <path
          id="Path_14000"
          data-name="Path 14000"
          d="M199.289,224.916h0a5.64,5.64,0,0,1,5.2-3.7h35.55A4.2,4.2,0,0,1,244.357,226l-9.184,77.056a5.587,5.587,0,0,1-5.449,4.765h-112.8a4.192,4.192,0,0,1-4.312-4.765l7.7-64.741a5.587,5.587,0,0,1,5.449-4.765h61.383A13.176,13.176,0,0,0,199.289,224.916Z"
          transform="translate(-98.866 -220.23)"
          fill="#dee1e8"
        />
        <path
          id="Path_14001"
          data-name="Path 14001"
          d="M117.23,308.977H235.1l6.715-79.307H123.949Z"
          transform="translate(-101.456 -224.931)"
          fill="#fff"
        />
        <path
          id="Path_14002"
          data-name="Path 14002"
          d="M117.23,308.977H235.1l4.858-79.307H123.949Z"
          transform="translate(-101.456 -224.931)"
          fill="#dee1e8"
        />
        <path
          id="Path_14003"
          data-name="Path 14003"
          d="M117.23,305.825H235.1L239.148,224H121.285Z"
          transform="translate(-101.456 -221.779)"
          fill="#fff"
        />
        <path
          id="Path_14004"
          data-name="Path 14004"
          d="M117.23,305.825H235.1L236.7,224H121.285Z"
          transform="translate(-101.456 -221.779)"
          fill="#dee1e8"
        />
        <path
          id="Path_14005"
          data-name="Path 14005"
          d="M117.23,303.046H235.1L235.8,219H117.936Z"
          transform="translate(-101.456 -219)"
          fill="#fff"
        />
        <path
          id="Path_14006"
          data-name="Path 14006"
          d="M115.157,305.036H233.025L230.6,222.58H113.5Z"
          transform="translate(-99.383 -220.99)"
          fill="#dee1e8"
        />
        <path
          id="Path_14007"
          data-name="Path 14007"
          d="M112.855,305.825H230.723L227.224,224H109.36Z"
          transform="translate(-97.082 -221.779)"
          fill="#fff"
        />
        <path
          id="Path_14008"
          data-name="Path 14008"
          d="M110.259,307.371H228.127l-5.573-80.591H104.69Z"
          transform="translate(-94.486 -223.325)"
          fill="#dee1e8"
        />
        <path
          id="Path_14009"
          data-name="Path 14009"
          d="M109.147,308.232H227.015l-6.462-79.9H102.69Z"
          transform="translate(-93.374 -224.187)"
          fill="#fff"
        />
        <path
          id="Path_14010"
          data-name="Path 14010"
          d="M156.511,236.073h0a3.842,3.842,0,0,1,4.019-3.788h35.55a6.217,6.217,0,0,1,5.835,4.885l14.047,71.944a3.908,3.908,0,0,1-3.93,4.885H99.231a6.216,6.216,0,0,1-5.84-4.885L81.8,249.8a3.908,3.908,0,0,1,3.93-4.885h61.388C152.452,244.919,156.329,241.251,156.511,236.073Z"
          transform="translate(-81.712 -226.383)"
          fill="#dee1e8"
        />
      </g>
    </g>
  </svg>
)

export default FileSearching
