import React from 'react'
import { SvgProps } from './typings/SvgProps'

const Bold: React.FC<SvgProps> = ({ color = 'currentColor', size, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={size}
    height={size}
    {...rest}
    viewBox="0 0 8.942 11.647"
  >
    <g transform="translate(0 0)">
      <g transform="translate(0 0)" clipPath="url(#clip-path)">
        <path
          d="M7.155,5.648A3.029,3.029,0,0,0,8.527,3.327,3.28,3.28,0,0,0,5.3,0H0V11.647H5.857a3.156,3.156,0,0,0,1.3-6M2.5,2.079h2.5a1.248,1.248,0,0,1,0,2.5H2.5ZM5.408,9.567H2.5v-2.5H5.408a1.248,1.248,0,0,1,0,2.5"
          transform="translate(0 0.001)"
          fill={color}
        />
      </g>
    </g>
  </svg>
)

export default Bold
