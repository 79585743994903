import React, { forwardRef } from 'react'
import { Paper, Popper, useAutocomplete } from '@mui/material'
import Loader from 'react-loader-spinner'
import { theme } from '@cockpit/levi-ui/theme'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import { TextField, ITextField } from '../../molecules'
import {
  AddableWrapper,
  AutocompleteContainer,
  AutocompleteInputWrapper,
  AutocompleteOption,
  HighlightSpan,
  ListBox,
  LoadingWrapper,
  NoOptionsWrapper,
  OptionLabelWrapper,
} from './styles'
import { IAutocomplete, OptionDefault } from './typing'

export const Autocomplete = forwardRef<HTMLInputElement, IAutocomplete>(
  (
    {
      open,
      options,
      getOptionLabel = option => option?.label,
      isLoading = false,
      highlightWords = false,
      renderOption: renderOptionProp,
      onInputChange = inputValue => inputValue,
      noOptionText = 'Lista vazia',
      addable = false,
      renderAddable,
      onClickAddable,
      onSelectOption,
      clearOnBlur = false,
      defaultOption,
      onReachListEnd = () => {},
      ...rest
    },
    ref,
  ) => {
    const {
      getRootProps,
      getInputProps,
      getListboxProps,
      getOptionProps,
      groupedOptions,
      anchorEl,
      setAnchorEl,
      popupOpen,
      inputValue,
    } = useAutocomplete({
      id: 'use-levi-autocomplete',
      options,
      getOptionLabel,
      clearOnBlur,
      defaultValue: defaultOption,
    })

    const thresholdOffset = 0.5

    React.useEffect(() => {
      onInputChange(inputValue)
    }, [inputValue])

    const handleReachListEnd = (scrollTop: number, clientHeight: number, scrollHeight: number) => {
      const scrollNumberCheck = scrollTop + clientHeight
      if (scrollNumberCheck + thresholdOffset >= scrollHeight) {
        onReachListEnd()
      }
    }

    const defaultRenderOption = (
      defaultOptionProps: React.HTMLAttributes<HTMLLIElement>,
      option: OptionDefault,
    ) => {
      const matches = match(getOptionLabel(option), inputValue, {
        insideWords: true,
      })
      const parts = parse(getOptionLabel(option), matches)

      if (highlightWords) {
        return (
          <AutocompleteOption {...defaultOptionProps}>
            <OptionLabelWrapper
              data-testid="default-highlight-option"
              onClick={e => {
                if (onSelectOption) {
                  onSelectOption(e, option)
                }
              }}
              role="presentation"
            >
              {parts.map((part, index) => (
                <HighlightSpan key={index} fontWeight={part.highlight ? 600 : 400}>
                  {part.text}
                </HighlightSpan>
              ))}
            </OptionLabelWrapper>
          </AutocompleteOption>
        )
      }

      return (
        <AutocompleteOption {...defaultOptionProps}>
          <OptionLabelWrapper
            data-testid="default-option"
            onClick={e => {
              if (onSelectOption) {
                onSelectOption(e, option)
              }
            }}
            role="presentation"
          >
            {getOptionLabel(option)}
          </OptionLabelWrapper>
        </AutocompleteOption>
      )
    }

    const renderOption = renderOptionProp || defaultRenderOption

    const renderListOption = (option: OptionDefault, index: number): Element | React.ReactNode => {
      const optionProps = getOptionProps({ option, index })

      return renderOption({ ...optionProps }, option, inputValue)
    }

    return (
      <AutocompleteContainer data-testid="autocomplete-component">
        <AutocompleteInputWrapper ref={setAnchorEl} {...getRootProps()}>
          <TextField
            data-testid="text-input-autocomplete"
            parentRef={ref}
            endAdornment={
              isLoading && (
                <Loader
                  type="TailSpin"
                  height={20}
                  width={20}
                  style={{ marginTop: '6px' }}
                  color={theme.colors.blue[40]}
                />
              )
            }
            {...(getInputProps() as ITextField)}
            {...rest}
          />
        </AutocompleteInputWrapper>
        {anchorEl && (
          <Popper
            open={open || popupOpen}
            role="presentation"
            data-testid="popper-autocomplete"
            anchorEl={anchorEl}
            disablePortal
            style={{
              width: anchorEl ? anchorEl.clientWidth : 0,
              zIndex: 1000000,
            }}
          >
            <Paper>
              {isLoading && (
                <LoadingWrapper data-testid="loader-wrapper">
                  <Loader type="ThreeDots" height={30} width={30} className="levi-button-spinner" />
                </LoadingWrapper>
              )}
              {groupedOptions.length === 0 && !isLoading && !addable && (
                <NoOptionsWrapper>{noOptionText}</NoOptionsWrapper>
              )}
              {groupedOptions.length === 0 && !isLoading && addable && (
                <AddableWrapper
                  as="li"
                  className="LeviAutocomplete-addableRoot"
                  data-testid="addable-wrapper"
                  onClick={() => {
                    if (onClickAddable) {
                      onClickAddable(inputValue)
                    }
                  }}
                  {...getListboxProps()}
                >
                  {renderAddable ? renderAddable(inputValue) : `Adicionar '${inputValue}'`}
                </AddableWrapper>
              )}
              {groupedOptions.length > 0 && !isLoading && (
                <ListBox
                  data-testid="listbox-wrapper"
                  {...getListboxProps()}
                  onScroll={e => {
                    handleReachListEnd(
                      e.currentTarget.scrollTop,
                      e.currentTarget.clientHeight,
                      e.currentTarget.scrollHeight,
                    )
                  }}
                >
                  {(groupedOptions as typeof options).map(
                    (option, index) =>
                      // eslint-disable-next-line implicit-arrow-linebreak
                      renderListOption(option, index),
                    // eslint-disable-next-line function-paren-newline
                  )}
                </ListBox>
              )}
            </Paper>
          </Popper>
        )}
      </AutocompleteContainer>
    )
  },
)
