import React from 'react'
import { SvgProps } from './typings/SvgProps'

const LightLocation: React.FC<SvgProps> = ({ color = 'currentColor', size, ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} {...rest} viewBox="0 0 16.356 19.326">
    <g transform="translate(0.5 0.5)">
      <g transform="translate(-0.739 -0.739)">
        <path
          d="M0,7.652A7.678,7.678,0,1,1,15.357,7.7v.087a11.338,11.338,0,0,1-3.478,7.3,20.183,20.183,0,0,1-3.591,2.957.93.93,0,0,1-1.217,0,19.817,19.817,0,0,1-5.052-4.73A9.826,9.826,0,0,1,0,7.678Z"
          transform="translate(0.739 0.739)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1"
        />
        <circle
          cx="2.461"
          cy="2.461"
          r="2.461"
          transform="translate(5.957 6.078)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1"
        />
      </g>
    </g>
  </svg>
)

export default LightLocation
