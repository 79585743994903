import styled, { css } from 'styled-components'

import { theme } from '../Theme'

interface ITab {
  active?: boolean
  error?: boolean
}

interface IBtnIcon {
  left?: boolean
  color?: string
}

export const Container = styled.div`
  display: flex;
  border-bottom: 1px solid ${theme.palette.gray5};
  margin-bottom: 20px;
  min-width: 100%;
  width: 1px;
  align-items: center;
`

export const GridTabs = styled.div`
  display: flex;
  align-items: center;
  overflow-x: auto;
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: ${theme.palette.gray5};
    border-radius: 100px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: ${theme.palette.gray5};
  }
`

export const Tab = styled.div<ITab>`
  padding: 0 25px;
  height: 50px;
  display: flex;
  position: relative;
  button.btn-close {
    position: absolute;
    right: 7px;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;
    pointer-events: none;
    background: transparent;
    border: 0px solid;
    transition: all 0.2s linear;
    cursor: pointer;
    &:hover {
      img {
        fill: ${theme.colors.blue3} !important;
      }
    }
    @media (max-width: 1280px) {
      img {
        fill: ${theme.colors.blue3} !important;
      }
    }
  }
  button.btn-page {
    font-family: inherit;
    font-size: 14px;
    font-weight: 600;
    padding: 0 10px;
    display: flex;
    align-items: center;
    border: 0px solid;
    outline: none;
    height: 100%;
    background: transparent;
    cursor: pointer;
    transition: all 0.2s linear;
    text-align: center;
    white-space: nowrap;
    text-align: center;
    ${({ active, error }) =>
      active && !error
        ? css`
            box-shadow: 0 -3px 0px 0px inset ${theme.colors.blue3};
            color: ${theme.colors.blue3};
          `
        : error
        ? css`
            color: ${theme.colors.red1};
            ${active && `box-shadow: 0 -3px 0px 0px inset ${theme.colors.red1}`}
          `
        : css`
            color: ${theme.palette.gray4};
          `}
  }
  &:hover {
    button.btn-close {
      opacity: 1;
      pointer-events: all;
    }
    button.btn-page {
      color: ${(props) =>
        props.error ? theme.colors.red1 : theme.colors.blue3};
    }
  }
  &:before {
    content: '';
    display: block;
    width: 1px;
    height: 25px;
    position: absolute;
    right: 0;
    top: 12px;
    background: ${theme.palette.gray5};
  }
`

export const BtnIcon = styled.button<IBtnIcon>`
  display: block;
  border: 0px solid;
  cursor: pointer;
  padding: 5px;
  border-radius: 5px;
  transition: all 0.2s linear;
  background: transparent;
  ${({ left }) =>
    left &&
    css`
      transform: rotate(180deg);
    `}
  &:hover {
    background-color: ${({ color }) => color ?? theme.palette.gray5};
  }
`

export const Plus = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
`

export const NextPrev = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-left: 15px;
  &:before {
    content: '';
    display: block;
    width: 1px;
    height: 25px;
    position: absolute;
    left: 0;
    top: -2px;
    background: ${theme.palette.gray5};
  }
`
