import React from 'react'
import { SvgProps } from '../../../icons/typings/SvgProps'
import IconList, { IconOptions } from './icon-list'

export type IIcon = SvgProps & {
  icon: IconOptions
}

const Icon: React.FC<IIcon> = ({ icon, color = 'currentColor', ...rest }) => {
  const Component: React.FC<SvgProps> = IconList[icon]
  return <Component color={color} {...rest} />
}

export default Icon
