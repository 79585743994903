import React from 'react';

const SvgDots = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      data-name='Component 428 \u2013 27'
      xmlns='http://www.w3.org/2000/svg'
      width={32}
      height={32}
      viewBox='0 0 32 32'
      {...props}
    >
      <path data-name='Rectangle 3843' fill='none' d='M0 0h32v32H0z' />
      <circle
        data-name='Ellipse 933'
        cx={1}
        cy={1}
        r={1}
        transform='translate(15 15)'
        fill='none'
        stroke='#6d7785'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={2}
      />
      <circle
        data-name='Ellipse 934'
        cx={1}
        cy={1}
        r={1}
        transform='translate(15 8)'
        fill='none'
        stroke='#6d7785'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={2}
      />
      <circle
        data-name='Ellipse 935'
        cx={1}
        cy={1}
        r={1}
        transform='translate(15 22)'
        fill='none'
        stroke='#6d7785'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={2}
      />
    </svg>
  )
}

export default SvgDots
