import CryptoJS from 'crypto-js'

const LEVI_CRYPTO_MANAGER_SECRET = 'a08810c215484f06da4824f8b957f3e1'

interface CryptoUtilities {
  encrypt(obj: unknown): string
  decryptPlainText(ciphertext: string): string
  decryptObject<T>(ciphertext: string): T
}

class CryptoManager implements CryptoUtilities {
  private secret!: string

  constructor(secret: string) {
    this.secret = secret
  }

  private encryptObject(obj: unknown): string {
    return CryptoJS.AES.encrypt(JSON.stringify(obj), this.secret).toString()
  }

  private encryptPlainText(text: string): string {
    return CryptoJS.AES.encrypt(text, this.secret).toString()
  }

  public decryptPlainText(ciphertext: string) {
    const bytes: CryptoJS.lib.WordArray = CryptoJS.AES.decrypt(ciphertext, this.secret)
    return bytes.toString(CryptoJS.enc.Utf8)
  }

  public decryptObject<T>(ciphertext: string) {
    const bytes: CryptoJS.lib.WordArray = CryptoJS.AES.decrypt(ciphertext, this.secret)
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8)) as T
  }

  public encrypt(obj: unknown): string {
    return typeof obj === 'string' ? this.encryptPlainText(obj) : this.encryptObject(obj)
  }
}

const LeviCryptoManagerSecret = LEVI_CRYPTO_MANAGER_SECRET as string

const LeviCryptoManager = new CryptoManager(LeviCryptoManagerSecret)

export default LeviCryptoManager

export { CryptoManager, LeviCryptoManagerSecret }
