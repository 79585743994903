import React from 'react';

const SvgQueue = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='currentColor'
      width={17.799}
      height={15.799}
      viewBox='0 0 18 16'
      {...props}
    >
      <path d='M14.4 9a3.4 3.4 0 00-3.34 2.8H.9a.9.9 0 00-.9.9.9.9 0 00.9.9h10.329a3.4 3.4 0 003.171 2.2 3.4 3.4 0 003.4-3.4A3.4 3.4 0 0014.4 9zm0 5a1.6 1.6 0 01-1.6-1.6 1.6 1.6 0 011.6-1.6 1.6 1.6 0 011.6 1.6 1.6 1.6 0 01-1.6 1.6zM.9 4.6h10.329A3.4 3.4 0 0014.4 6.8a3.4 3.4 0 003.4-3.4A3.4 3.4 0 0014.4 0a3.4 3.4 0 00-3.34 2.8H.9a.9.9 0 00-.9.9.9.9 0 00.9.9zm13.5-2.8A1.6 1.6 0 0116 3.4 1.6 1.6 0 0114.4 5a1.6 1.6 0 01-1.6-1.6 1.6 1.6 0 011.6-1.601z' />
    </svg>
  )
}

export default SvgQueue
