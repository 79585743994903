import React from 'react'

const iconVideo = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={19}
    height={14}
    viewBox='0 0 19 14'
    fill='currentColor'
    {...props}
  >
    <g transform='translate(0.625 0.625)'>
      <g>
        <path
          d='M12.575,9.961a3.122,3.122,0,0,1-3.346,3.267H3.336A3.114,3.114,0,0,1,0,9.961V3.275A3.124,3.124,0,0,1,3.346,0H9.229a3.129,3.129,0,0,1,3.346,3.275Z'
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit='10'
          strokeWidth={1.25}
        />
        <path
          d='M0,3.441,3.884.219A.95.95,0,0,1,5.435.966v6.6a.95.95,0,0,1-1.552.746L0,5.091'
          transform='translate(12.575 2.348)'
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit='10'
          strokeWidth={1.25}
        />
      </g>
    </g>
  </svg>
)

export default iconVideo
