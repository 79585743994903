import React from 'react';

const SvgFaceSmile = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={17} height={17} viewBox="0 0 17 17" {...props}>
      <g transform="translate(19.75 -12.25)">
        <g transform="translate(-19.489 12.51)">
          <path d="M17.5,9.5a8,8,0,1,1-8-8A8,8,0,0,1,17.5,9.5Z" transform="translate(-1.01 -1.01)" fill="none" stroke="currentColor" strokeLinecap="round" strokeMiterlimit={10} strokeWidth={1.5}/>
          <line y2="0.731" transform="translate(6.358 6.393)" fill="none" stroke="currentColor" strokeLinecap="round" strokeMiterlimit={10} strokeWidth={1.5}/>
          <line y2="0.731" transform="translate(10.621 6.393)" fill="none" stroke="currentColor" strokeLinecap="round" strokeMiterlimit={10} strokeWidth={1.5}/>
          <path d="M21.022,31.245a5.678,5.678,0,0,1-7.965.064" transform="translate(-8.55 -20.415)" fill="none" stroke="currentColor" strokeLinecap="round" strokeMiterlimit={10} strokeWidth={1.5}/>
        </g>
      </g>
    </svg>
  )
}

export default SvgFaceSmile
