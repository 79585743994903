import { theme } from '../../Theme'
import styled, { css } from 'styled-components'
import { IShowFilter } from '../models'

const scrollModal = css`
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 1px ${theme.colors.gray4};
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${theme.colors.gray4};
  }

  &::-webkit-scrollbar-track-piece {
    background-color: ${theme.colors.gray6};
  }
`

export const Modal = styled.div<IShowFilter>`
  display: ${({ show }) => (show ? 'flex' : 'none')};
  width: ${(props) => props.width};
  position: absolute;
  top: calc(100% + ${theme.spacing.base});
  ${({ direction }) =>
    direction === undefined || direction === 'left'
      ? 'left: 0px'
      : 'right: 0px'};
  padding: ${theme.spacing.large}
    calc(${theme.spacing.large} + ${theme.spacing.xSmall});
  box-shadow: 0px 13px 20px ${theme.colors.gray8};
  background-color: #fff;
  z-index: 10010;
  border-radius: ${theme.borderRadius.modal};
  flex-direction: column;

  @media (max-width: ${(props) => props.responsiveSize}px) {
    position: fixed;
    top: 0;
    height: 100vh;
    max-height: 100vh;
    width: 100vw;
    min-width: 100vw;
    padding: 0 calc(${theme.spacing.large} + ${theme.spacing.xSmall});
    overflow-y: auto;
    ${scrollModal}
  }
`

export const Header = styled.div<{ responsiveSize: number }>`
  display: none;
  align-items: center;
  justify-content: flex-end;
  height: 50px;
  min-height: 50px;
  border-bottom: 1px solid ${theme.colors.gray3};
  margin-bottom: 30px;

  button {
    width: 35px;
    height: 70%;
    background: transparent;
    border: none;
  }

  @media (max-width: ${(props) => props.responsiveSize}px) {
    display: flex;
  }
`

export const ModalContent = styled.div`
  display: flex;
  flex-direction: row;
`
