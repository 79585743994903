import React from 'react'
import { SvgProps } from './typings/SvgProps'

const Check: React.FC<SvgProps> = ({ color = 'currentColor', size, ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} {...rest} viewBox="0 0 9 6.495">
    <path
      id="Path"
      d="M8.741,1.5l-4.82,4.75a.9.9,0,0,1-1.248,0L.259,3.875a.872.872,0,0,1,0-1.24.9.9,0,0,1,1.258,0l1.776,1.75L7.483.255a.9.9,0,0,1,1.258,0,.872.872,0,0,1,0,1.24"
      fill={color}
    />
  </svg>
)

export default Check
