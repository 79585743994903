import React from 'react'
import { ContainerChildren } from '../styles'

interface AccordionCollapsedContentProps {
  id?: any
  unique?: boolean
}

interface ExpandedProps extends AccordionCollapsedContentProps {
  children: any
}

const Expanded: React.FC<ExpandedProps> = ({ children, unique }): any => {
  return !unique && <ContainerChildren>{children}</ContainerChildren>
}

export default Expanded
