import React from 'react';

const SvgSupport = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={20} height={20} viewBox='0 0 21 21' {...props}>
      <g
        data-name='Group 6160'
        transform='translate(2.478 2.478)'
        fill='none'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={1.5}
      >
        <circle data-name='Ellipse 9' cx={7.93} cy={7.93} r={7.93} />
        <circle
          data-name='Ellipse 10'
          cx={3.965}
          cy={3.965}
          r={3.965}
          transform='translate(3.965 3.965)'
        />
        <path data-name='Path 885' d='M10.903 4.956l2.478-2.478' />
        <path data-name='Path 886' d='M10.903 10.903l2.478 2.478' />
        <path data-name='Path 887' d='M4.956 10.903l-2.478 2.478' />
        <path data-name='Path 888' d='M4.956 4.956L2.478 2.478' />
      </g>
      <path data-name='Rectangle 1770' fill='rgba(0,0,0,0)' d='M0 0h20v20H0z' />
    </svg>
  )
}

export default SvgSupport
