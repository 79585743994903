import React from 'react'
import { SvgProps, WithSecondaryColor } from './typings/SvgProps'

const CheckFilled: React.FC<SvgProps & WithSecondaryColor> = ({
  color = 'currentColor',
  secondaryColor = '#fff',
  size,
  ...rest
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} {...rest} viewBox="0 0 16 16">
    <g transform="translate(-4 -4)">
      <circle cx="8" cy="8" r="8" transform="translate(4 4)" fill={color} />
      <path
        d="M7.223,1.253,3.24,5.236a.739.739,0,0,1-1.031,0l-2-1.987a.735.735,0,0,1,1.04-1.04L2.721,3.676,6.183.214a.735.735,0,0,1,1.04,1.04"
        transform="translate(8.282 9.277)"
        fill={secondaryColor}
      />
    </g>
  </svg>
)

export default CheckFilled
