export interface DefaultTheme {
  palette: {
    gray1: string
    gray2: string
    gray3: string
    gray4: string
    gray5: string
    white: string
    black: string
    main: {
      background: string
      externalInformation: string
      patientInformation: string
    }
    button: {
      primary: string
      error: string
      success: string
      warning: string
      disabled: string
      mainHover: string
      hover1: string
      hover2: string
      icons: string
    }
    queue: {
      waiting: string
      onConsultation: string
      notFinished: string
      backToQueue: string
    }
    highlight: {
      red: string
      blue: string
    }
  }
  colors: {
    white: string
    black: string

    // From light to dark
    gray1: string
    gray2: string
    gray3: string
    gray4: string
    gray5: string
    gray6: string
    gray7: string
    gray8: string

    blue1: string
    blue2: string
    blue3: string
    blue4: string
    blue5: string
    blue6: string
    blue7: string
    blue8: string
    blue9: string
    blue10: string

    red1: string

    green1: string

    orange1: string
    orange2: string
  }
  borderRadius: {
    patientInformation: string
    modal: string
    fields: string
    buttons: string
    notifications: string
    checkbox: string
    tags: string
  }
  spacing: {
    tiny: string
    xSmall: string
    small: string
    base: string
    medium: string
    large: string
    xLarge: string
    big: string
  }
  iconSizes: {
    tiny: string
    xSmall: string
    small: string
    base: string
    medium: string
    large: string
    xLarge: string
    big: string
  }
  font: {
    root: string
  }
  fontWeight: {
    bold: string
    semibold: string
    medium: string
    regular: string
  }
  menu: {
    background: string
    divider: string
    iconContainer: string
    iconContainerColor: string
    iconContainerColorHover: string
  }
  responsiveSize: {
    // string ex.: '320px'
    fold: string
    small: string
    medium: string
    big: string
    tablet: string
    bigTablet: string
    // number ex.: '320'
    numberFold: number
    numberSmall: number
    numberMedium: number
    numberBig: number
    numberTablet: number
    numberBigTablet: number
  }
}

export interface IPropsTheme {
  theme: DefaultTheme
}

export const theme: DefaultTheme = {
  palette: {
    gray1: '#EAF4FF',
    gray2: '#E9ECF3',
    gray3: '#BCC9D9',
    gray4: '#6B7786',
    gray5: '#DFE3E8',
    white: '#FFFFFF',
    black: '#2D3139',
    main: {
      background: '#F3F5F9',
      externalInformation: '#E9ECF3',
      patientInformation: '#FFFFFF'
    },
    button: {
      primary: '#378FF7',
      error: '#FF2424',
      success: '#4CAF50',
      warning: '#FF8729',
      disabled: '#378ff778',
      mainHover: '#257CE5',
      hover1: '#F6F7FB',
      hover2: '#004F92',
      icons: '#6B7786'
    },
    queue: {
      waiting: '#D7E9FE',
      onConsultation: '#4CAF50',
      notFinished: '#FF8729',
      backToQueue: '#AAB0BE'
    },
    highlight: {
      red: '#FF2424',
      blue: '#378FF7'
    }
  },
  colors: {
    white: '#ffffff',
    black: '#2D3139',

    // From light to dark
    gray1: '#f6f7fb',
    gray2: '#f3f5f9',
    gray3: '#e9ecf3',
    gray4: '#6B7786',
    gray5: '#6b7786',
    gray6: '#dfe3e8',
    gray7: '#f0f2f5',
    gray8: '#a8abb1',

    blue1: '#eaf4ff',
    blue2: '#bcc9d9',
    blue3: '#378ff7',
    blue4: '#257ce9',
    blue5: '#004f92',
    blue6: '#ebf4f3',
    blue7: '#d8dee8',
    blue8: '#5a6a7e',
    blue9: '#63768d',
    blue10: '#1d2c3e',

    red1: '#FF2424',

    green1: '#4CAF50',

    orange1: '#FFE7D2',
    orange2: '#FFB324'
  },
  borderRadius: {
    patientInformation: '4px',
    modal: '4px',
    fields: '4px',
    buttons: '4px',
    notifications: '4px',
    checkbox: '5.5px',
    tags: '15px'
  },
  spacing: {
    tiny: '4px',
    xSmall: '8px',
    small: '12px',
    base: '16px',
    medium: '24px',
    large: '32px',
    xLarge: '48px',
    big: '64px'
  },
  iconSizes: {
    tiny: '4px',
    xSmall: '8px',
    small: '12px',
    base: '16px',
    medium: '24px',
    large: '32px',
    xLarge: '48px',
    big: '64px'
  },
  font: {
    root: 'Inter'
  },
  fontWeight: {
    bold: '700',
    semibold: '600',
    medium: '500',
    regular: '400'
  },
  menu: {
    background: '#fff',
    divider: '#e9ecf3',
    iconContainer: '#F2F8FD',
    iconContainerColor: '#9BA8BA',
    iconContainerColorHover: '#004f92'
  },
  responsiveSize: {
    fold: '280px',
    small: '320px',
    medium: '375px',
    big: '414px',
    tablet: '768px',
    bigTablet: '1024px',
    // number version
    numberFold: 280,
    numberSmall: 320,
    numberMedium: 375,
    numberBig: 414,
    numberTablet: 768,
    numberBigTablet: 1024
  }
}
