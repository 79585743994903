import React, { PropsWithChildren } from 'react'
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles'
import { ThemeProvider } from '@emotion/react'
import { theme, muiTheme } from './theme'

const Provider: React.FC<PropsWithChildren<object>> = ({ children }) => (
  <MuiThemeProvider theme={muiTheme}>
    <ThemeProvider theme={{ ...muiTheme, ...theme }}>{children}</ThemeProvider>
  </MuiThemeProvider>
)

export default Provider
