import { getColors } from '@cockpit/levi-ui/helpers'
import styled from '@emotion/styled'
import { theme } from '@cockpit/levi-ui/theme'
import { autocompleteClasses } from '@mui/material/Autocomplete'

export const AutocompleteContainer = styled.div``

export const AutocompleteInputWrapper = styled.div``

export const AutocompleteOption = styled('li')`
  font-size: ${theme.font.size[3]};
`

export const OptionLabelWrapper = styled.div``

export const HighlightSpan = styled.span<{ fontWeight?: number }>`
  font-weight: ${({ fontWeight }) => fontWeight ?? 400};
`

export const ListBox = styled.ul`
  margin: 2px 0 0;
  padding: 0;
  list-style: none;
  background-color: ${getColors('neutral', 10)};
  overflow: auto;
  max-height: 250px;
  border-radius: ${theme.spacings[1]};
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);

  & li {
    padding: ${theme.spacings[2]} ${theme.spacings[4]};
    display: flex;
    color: ${getColors('neutral', 80)};

    & span {
      flex-grow: 1;
    }

    & svg {
      color: transparent;
    }
  }

  & li[aria-selected='true'] {
    background-color: #fafafa;

    & svg {
      color: ${getColors('blue', 40)};
    }
  }

  & li.${autocompleteClasses.focused} {
    background-color: ${getColors('blue', 10)};
    cursor: pointer;
    transition: 0.4s;

    & svg {
      color: currentColor;
    }
  }
`

export const LoadingWrapper = styled.div`
  display: flex;
  width: 100%;

  align-items: center;
  justify-content: center;
  padding: ${theme.spacings[4]} 0;

  svg {
    display: flex;
    align-items: center;
    fill: ${getColors('blue', 40)};
  }
`

export const NoOptionsWrapper = styled.div`
  display: flex;
  width: 100;

  align-items: center;
  justify-content: center;
  padding: ${theme.spacings[2]} 0;

  font-size: ${theme.font.size[3]};
  font-weight: 400;
  color: ${getColors('neutral', 60)};
`

export const AddableWrapper = styled.ul`
  display: flex;
  width: 100;
  background-color: inherit;

  &:hover {
    cursor: pointer;
    background-color: ${getColors('blue', 10)};
  }

  align-items: center;
  justify-content: center;
  padding: ${theme.spacings[2]} 0;

  font-size: ${theme.font.size[3]};
  font-weight: 400;
  color: ${getColors('neutral', 60)};

  transition: 0.4s;
`
