import React from 'react';

const SvgHospital = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={22.143}
      height={17.937}
      viewBox='0 0 22 17'
      {...props}
    >
      <path d='M7.884 7.977h.667v.666a.75.75 0 001.5 0v-.666h.666a.75.75 0 000-1.5h-.666V5.81a.75.75 0 00-1.5 0v.667h-.667a.75.75 0 000 1.5z' />
      <path d='M21.393 16.437h-.332v-7.7a2.722 2.722 0 00-2.719-2.719h-1.794v-3.3a2.722 2.722 0 00-2.719-2.719H5.077a2.722 2.722 0 00-2.718 2.719v12.5a2.689 2.689 0 00.3 1.218H.749a.75.75 0 000 1.5h20.643a.75.75 0 000-1.5zM3.859 15.218v-12.5a1.22 1.22 0 011.218-1.219h8.752a1.221 1.221 0 011.219 1.219v12.5a1.22 1.22 0 01-1.219 1.218h-1.046V13.45a.75.75 0 00-.75-.75H6.569a.75.75 0 00-.75.75v2.987h-.742a1.22 1.22 0 01-1.218-1.219zm6.192-1.019h1.232v2.237h-1.232zm-1.5 2.237H7.319V14.2h1.232zm7.708 0c.011-.022.016-.048.026-.071a2.638 2.638 0 00.136-.37c.019-.061.043-.12.057-.183a2.7 2.7 0 00.07-.594v-7.7h1.794a1.221 1.221 0 011.219 1.219v7.7z' />
    </svg>
  )
}

export default SvgHospital
