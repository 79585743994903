import React, { forwardRef, useMemo } from 'react'

import { LexicalComposer } from '@lexical/react/LexicalComposer'
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin'
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin'
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin'
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table'
import { ListItemNode, ListNode } from '@lexical/list'
import { CodeHighlightNode, CodeNode } from '@lexical/code'
import { AutoLinkNode, LinkNode } from '@lexical/link'
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin'
import { MarkdownShortcutPlugin } from '@lexical/react/LexicalMarkdownShortcutPlugin'
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin'
import { TRANSFORMERS } from '@lexical/markdown'
import { EditorState, LexicalEditor } from 'lexical'
import { HeadingNode, QuoteNode } from '@lexical/rich-text'
import { $generateHtmlFromNodes } from '@lexical/html'

import AutoLinkPlugin from './plugins/AutoLinkPlugin'
import CodeHighlightPlugin from './plugins/CodeHighlightPlugin'
import ListMaxIndentLevelPlugin from './plugins/ListMaxIndentLevelPlugin'

import { Container, Content, ContentEditableContainer, PlaceHolderText } from './styles'
import theme from './themes'
import { Toolbar } from './toolbar'
import { ListPlugin } from './plugins/ListPlugin'

interface RichTextProps {
  placeholder?: string
  onChange: (value: { html: string; json: string }) => void
  name: string
  value?: string
}

const RichText = forwardRef<HTMLDivElement, RichTextProps>(
  ({ placeholder, name, value, onChange }: RichTextProps, ref) => {
    const editorConfig = useMemo(
      () => ({
        namespace: name,
        editorState: !value ? undefined : value,
        theme,
        /* istanbul ignore next */
        onError(error: Error) {
          throw error
        },
        nodes: [
          HeadingNode,
          ListNode,
          ListItemNode,
          QuoteNode,
          CodeNode,
          CodeHighlightNode,
          TableNode,
          TableCellNode,
          TableRowNode,
          AutoLinkNode,
          LinkNode,
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }),
      [],
    )

    const handleOnChange = (editorState: EditorState, editor: LexicalEditor) => {
      editor.update(() => {
        const result = {
          json: JSON.stringify(editorState.toJSON()),
          html: $generateHtmlFromNodes(editor, null).toString(),
        }
        const event = {
          target: {
            value: result,
          },
          name,
        } as never
        onChange(event)
      })
    }

    return (
      <LexicalComposer initialConfig={editorConfig}>
        <Container ref={ref} data-testid="rich-text-container">
          <Toolbar />
          <Content>
            <RichTextPlugin
              contentEditable={<ContentEditableContainer testid="rich-text-content-editable" />}
              placeholder={<PlaceHolderText>{placeholder}</PlaceHolderText>}
            />
            <OnChangePlugin onChange={handleOnChange} />
            <HistoryPlugin />
            <AutoFocusPlugin />
            <ListPlugin />
            <LinkPlugin />
            <AutoLinkPlugin />
            <CodeHighlightPlugin />
            <ListMaxIndentLevelPlugin maxDepth={7} />
            <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
          </Content>
        </Container>
      </LexicalComposer>
    )
  },
)

export default RichText
