import React, { forwardRef, PropsWithChildren } from 'react'
import Loader from 'react-loader-spinner'
import { IButton } from './typing'
import { MuiButton } from './styles'

const Button = forwardRef<HTMLButtonElement, PropsWithChildren<IButton>>(
  ({ children, variant, isLoading, type = 'button', ...rest }, ref) => (
    <MuiButton
      data-testid="levi-button-component"
      {...{
        type,
        variant,
        isLoading,
        ref,
      }}
      {...rest}
    >
      {isLoading ? (
        <Loader type="ThreeDots" height={30} width={30} className="levi-button-spinner" />
      ) : (
        children
      )}
    </MuiButton>
  ),
)

export default Button
