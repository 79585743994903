import { ButtonBase as MuiButtonBase, FormControlLabel } from '@mui/material'
import styled from '@emotion/styled'
import { getColors } from '@cockpit/levi-ui/helpers'
import { theme } from '@cockpit/levi-ui/theme'

export const ButtonBase = styled(MuiButtonBase)`
  width: 36px;
  height: 36px;
  position: relative;
  input {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
`

export const CheckboxComponent = styled.div<{ active: boolean }>`
  width: 18px;
  height: 18px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid;
  border-radius: 0.25rem;
  transition: 0.5s;
  background-color: ${({ active }) => (active ? theme.colors.blue[40] : theme.colors.neutral[10])};
  border-color: ${({ active }) => (active ? theme.colors.blue[40] : theme.colors.neutral[40])};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${getColors('neutral', 10)};
  cursor: pointer;

  &:hover {
    border-color: ${getColors('blue')};
  }
`

export const Label = styled(FormControlLabel)`
  .MuiTypography-root {
    font-size: ${theme.font.size[2]};
    color: ${getColors('neutral', 60)};
  }
`
