import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { theme } from '@cockpit/levi-ui/theme'

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
`

const TabContainer = styled.div`
  display: flex;
  place-content: center end;
  flex-wrap: wrap;
  gap: ${theme.font.size[1]};
`

const Tab = styled.div<{ active: boolean }>`
  user-select: none;
  cursor: pointer;
  font-size: ${theme.font.size[2]};
  font-family: 'Inter';
  font-weight: 600;
  letter-spacing: 0.3px;
  color: ${({ active }) => (active ? theme.colors.blue[40] : theme.colors.neutral[60])};
  &:hover {
    color: ${({ active }) => (active ? theme.colors.blue[40] : theme.colors.neutral[70])};
  }
  ${({ active }) =>
    active
      ? css`
          color: ${theme.colors.blue[40]};
          border-bottom: 1px solid #378ff7;
        `
      : ''}
`

const Wrapper = styled.div<{ active: boolean }>`
  display: ${({ active }) => (active ? '' : 'none')};
`

export { Container, TabContainer, Tab, Wrapper }
