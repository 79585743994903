import React from 'react'

const SvgReloadCorpoClinico = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      id='Componente_10071_17'
      data-name='Componente 10071 – 17'
      xmlns='http://www.w3.org/2000/svg'
      width='40'
      height='40'
      viewBox='0 0 40 40'
      {...props}
    >
      <g
        id='Retângulo_140960'
        data-name='Retângulo 140960'
        fill='#fff'
        stroke='#dfe3e8'
        strokeWidth='1'
      >
        <rect width='40' height='40' rx='4' stroke='none' />
        <rect x='0.5' y='0.5' width='39' height='39' rx='3.5' fill='none' />
      </g>
      <g
        id='Componente_7315_15'
        data-name='Componente 7315 – 15'
        transform='translate(11 11)'
      >
        <g id='noun_Refresh_3451198'>
          <path
            id='Caminho_26967'
            data-name='Caminho 26967'
            d='M21.587,6.454a.818.818,0,0,0-.818.818V8.6A8.893,8.893,0,0,0,13,4a9,9,0,1,0,8.476,12,.818.818,0,1,0-1.538-.548A7.363,7.363,0,1,1,13,5.636a7.273,7.273,0,0,1,6.545,4.091H17.906a.818.818,0,1,0,0,1.636h3.681a.818.818,0,0,0,.818-.818V7.272A.818.818,0,0,0,21.587,6.454Z'
            transform='translate(-3.99 -4)'
            fill='#a8abb1'
          />
        </g>
      </g>
    </svg>
  )
}

export default SvgReloadCorpoClinico
