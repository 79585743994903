import { theme } from '@cockpit/levi-ui/theme'
import { ELineChartTitle, ILineChart } from './typing'

export const defaultUterineHeightChart: ILineChart[] = [
  {
    name: ELineChartTitle.title,
    data: [],
  },
  {
    name: ELineChartTitle.P90,
    data: [
      { xAxis: 13, value: 12 },
      { xAxis: 19, value: 19.5 },
      { xAxis: 25, value: 25.5 },
      { xAxis: 39, value: 34 },
    ],
  },
  {
    name: ELineChartTitle.P10,
    data: [
      { xAxis: 13, value: 8 },
      { xAxis: 19, value: 14 },
      { xAxis: 25, value: 19.5 },
      { xAxis: 39, value: 30.5 },
    ],
  },
]

export const defaultYAxis = [5, 7, 9, 11, 13, 15, 17, 19, 21, 23, 25, 27, 29, 31, 33, 35]
export const defaultXAxis = [11, 13, 15, 17, 19, 21, 23, 25, 27, 29, 31, 33, 35, 37, 39]
export const defaultLineChartColor = [
  {
    color: theme.colors.blue[40],
    name: ELineChartTitle.title,
  },
  {
    color: theme.colors.orange[40],
    name: ELineChartTitle.P10,
  },
  {
    color: theme.colors.red[40],
    name: ELineChartTitle.P90,
  },
]
