import React from 'react'

const SvgRefresh = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={20}
      height={20}
      viewBox='3 3 21 19'
      {...props}
    >
      <path
        d='M21.587,6.454a.818.818,0,0,0-.818.818V8.6A8.893,8.893,0,0,0,13,4a9,9,0,1,0,8.476,12,.818.818,0,1,0-1.538-.548A7.363,7.363,0,1,1,13,5.636a7.273,7.273,0,0,1,6.545,4.091H17.906a.818.818,0,1,0,0,1.636h3.681a.818.818,0,0,0,.818-.818V7.272A.818.818,0,0,0,21.587,6.454Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default SvgRefresh
