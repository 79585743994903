import {
  ListItemNode,
  ListNode,
  $handleListInsertParagraph,
  indentList,
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  insertList,
  outdentList,
  REMOVE_LIST_COMMAND,
  removeList,
} from '@lexical/list'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'

import type { LexicalEditor } from 'lexical'

import { mergeRegister } from '@lexical/utils'
import {
  COMMAND_PRIORITY_LOW,
  INDENT_CONTENT_COMMAND,
  INSERT_PARAGRAPH_COMMAND,
  OUTDENT_CONTENT_COMMAND,
} from 'lexical'
import { useEffect, useLayoutEffect } from 'react'

export function useList(editor: LexicalEditor): void {
  useLayoutEffect(
    () =>
      mergeRegister(
        editor.registerCommand(
          INDENT_CONTENT_COMMAND,
          () => {
            indentList()
            return false
          },
          COMMAND_PRIORITY_LOW,
        ),
        editor.registerCommand(
          OUTDENT_CONTENT_COMMAND,
          () => {
            outdentList()
            return false
          },
          COMMAND_PRIORITY_LOW,
        ),
        editor.registerCommand(
          INSERT_ORDERED_LIST_COMMAND,
          () => {
            insertList(editor, 'number')
            return true
          },
          COMMAND_PRIORITY_LOW,
        ),
        editor.registerCommand(
          INSERT_UNORDERED_LIST_COMMAND,
          () => {
            insertList(editor, 'bullet')
            return true
          },
          COMMAND_PRIORITY_LOW,
        ),
        editor.registerCommand(
          REMOVE_LIST_COMMAND,
          () => {
            removeList(editor)
            return true
          },
          COMMAND_PRIORITY_LOW,
        ),
        editor.registerCommand(
          INSERT_PARAGRAPH_COMMAND,
          () => {
            const hasHandledInsertParagraph = $handleListInsertParagraph()

            if (hasHandledInsertParagraph) {
              return true
            }

            return false
          },
          COMMAND_PRIORITY_LOW,
        ),
      ),
    [editor],
  )
}

export function ListPlugin(): null {
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    if (!editor.hasNodes([ListNode, ListItemNode])) {
      throw new Error('ListPlugin: ListNode and/or ListItemNode not registered on editor')
    }
  }, [editor])

  useList(editor)

  return null
}
