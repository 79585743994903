import styled, { css } from 'styled-components'

interface IBodyStyle {
  isLoading?: boolean
  background?: string
}

export const BodyStyle = styled.div<IBodyStyle>`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  ${({ background }) =>
    css`
      background: ${background || 'transparent'};
    `}

  font-size: 14px;
  color: #6b7786;

  ${({ isLoading }) =>
    isLoading &&
    css`
      align-items: center;
    `}
`

interface Line {
  height?: string
  noHover?: boolean
  separator?: boolean
}

export const LineStyle = styled.div<Line>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  min-width: fit-content;
  height: ${({ height }) => (height ? `${height}` : '51px')};
  padding-left: 24px;
  line-height: 18px;

  ${({ separator }) =>
    separator
      ? css`
          & + & {
            border-top: 1px solid rgba(233, 238, 248, 0.8);
          }
        `
      : css`
          border-top: none !important;
        `}

  ${({ noHover }) =>
    !noHover &&
    css`
      cursor: pointer;
      &:hover,
      &:active {
        background-color: #f2f8fd;
      }
    `}
`

export interface Cell {
  width?: string
  minWidth?: string
  maxWidth?: string
  length: number
}

export const CellStyle = styled.div<Cell>`
  ${({ width }) =>
    width &&
    `
        width: ${width};
    `}

  ${({ minWidth }) =>
    minWidth &&
    `
        min-width: ${minWidth};
    `}

    ${({ maxWidth }) =>
    maxWidth &&
    `
        max-width: ${maxWidth};
    `}

    ${({ width, minWidth, maxWidth, length }) =>
    !minWidth &&
    !width &&
    !maxWidth &&
    `
        width: calc(100% / ${length});
    `}
`

export const P = styled.div`
  font-size: 14px;
`

export const IconDiv = styled.div`
  width: 32px;
  height: 100%;
  filter: invert(35%) sepia(23%) saturate(309%) hue-rotate(175deg)
    brightness(95%) contrast(91%);
`
