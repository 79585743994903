import React from 'react';

const SvgClipboardPatient = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='currentColor'
      width={17.376}
      height={20.535}
      viewBox='0 0 21 21'
      {...props}
    >
      <path d='M13.504 13.496H3.87a.5.5 0 000 1h9.634a.5.5 0 000-1zM11.343 16.514H6.031a.5.5 0 000 1h5.312a.5.5 0 000-1zM7.134 10.063h1.054v1.051a.5.5 0 001 0v-1.053h1.053a.5.5 0 000-1H9.188V8.014a.5.5 0 00-1 0v1.053H7.134a.5.5 0 000 1z' />
      <path d='M14.747 2.114h-.769a1.159 1.159 0 00-1.073-.726h-1.177a.228.228 0 01-.228-.228A1.161 1.161 0 0010.34 0h-3.3a1.161 1.161 0 00-1.16 1.16.228.228 0 01-.228.228H4.475a1.159 1.159 0 00-1.074.726h-.769A2.631 2.631 0 00.003 4.742v13.164a2.632 2.632 0 002.629 2.628h12.119a2.632 2.632 0 002.628-2.628V4.742a2.631 2.631 0 00-2.632-2.628zm-10.436.434a.16.16 0 01.16-.16h1.177A1.229 1.229 0 006.876 1.16a.16.16 0 01.16-.16h3.3a.16.16 0 01.16.16 1.229 1.229 0 001.227 1.228H12.9a.16.16 0 01.16.16v1.749a.16.16 0 01-.16.159H4.466a.16.16 0 01-.16-.159V2.548zm12.064 15.359a1.63 1.63 0 01-1.629 1.628H2.629A1.63 1.63 0 011 17.907V4.742a1.63 1.63 0 011.629-1.628h.683v1.182a1.161 1.161 0 001.16 1.16h8.434a1.161 1.161 0 001.16-1.16V3.114h.683a1.63 1.63 0 011.629 1.628z' />
    </svg>
  )
}

export default SvgClipboardPatient
