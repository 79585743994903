import styled from 'styled-components'
import { theme } from '../Theme'

export const ContainerAccordion = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const ContainerChildren = styled.div`
  padding: ${theme.spacing.xSmall} 0;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  border-bottom: 1px solid #dfe3e8;
`

export const AccordionHeader = styled.div`
  border-bottom: 1px solid #dfe3e8;
  color: #2d3139;
  font-weight: ${theme.fontWeight.medium};
  font-size: 16px;
  width: 100%;
  padding: ${theme.spacing.medium} ${theme.spacing.xSmall};
  cursor: pointer;
  user-select: none;
  display: flex;
  justify-content: space-between;
`
