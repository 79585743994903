import React from 'react'
import { SvgProps } from './typings/SvgProps'

const Underline: React.FC<SvgProps> = ({ color = 'currentColor', size, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={size}
    height={size}
    {...rest}
    viewBox="0 0 9.298 11.955"
  >
    <g clipPath="url(#clip-path)">
      <path
        d="M4.649,9.3A3.985,3.985,0,0,0,8.634,5.313V0H6.974V5.313a2.325,2.325,0,0,1-4.649,0V0H.664V5.313A3.985,3.985,0,0,0,4.649,9.3M0,11.955H9.3V10.627H0Z"
        fill={color}
      />
    </g>
  </svg>
)

export default Underline
