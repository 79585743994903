import React from 'react';

const SvgSurdo = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={16.657}
      height={17.307}
      viewBox='0 0 16 17'
      {...props}
    >
      <path d='M0 2.54l1.066-1.06 14.759 14.761-1.058 1.066-4.848-4.847a3.162 3.162 0 00-1.283 1.6 4.533 4.533 0 01-2.274 2.957 3.334 3.334 0 01-4.7-3.04h1.669A1.666 1.666 0 005 15.641a1.585 1.585 0 00.633-.125c.591-.292 1.008-.733 1.424-1.982a4.462 4.462 0 011.688-2.254l-5.38-5.375a3.327 3.327 0 00-.033.575H1.666a5.979 5.979 0 01.325-1.949L0 2.54m10.977 6.388a5.165 5.165 0 00.683-2.448 4.184 4.184 0 00-6.754-3.282L3.715 2.007A5.829 5.829 0 017.5.65a5.763 5.763 0 015.83 5.83 6.894 6.894 0 01-.891 3.248l-.383.625-1.2-1.208.125-.217m1.812-7.748L13.976 0a9.171 9.171 0 01.333 12.61l-1.174-1.183a7.5 7.5 0 001.857-4.947 7.4 7.4 0 00-2.2-5.3m-3.214 5.3a2.064 2.064 0 01-.308 1.091L6.4 4.706A2.083 2.083 0 019.578 6.48z' />
    </svg>
  )
}

export default SvgSurdo
