import { getColors } from '@cockpit/levi-ui/helpers'
import { theme } from '@cockpit/levi-ui/theme'
import styled from '@emotion/styled'

export const Container = styled.div`
  border-radius: ${theme.spacings[1]};
  overflow: hidden;
  box-shadow: 0px 0px 8px ${getColors('neutral', 30)};
  margin: ${theme.spacings[6]};
  background: ${getColors('neutral', 10)};
  &.floating {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 9000;
    animation: 0.5s open;
  }

  @keyframes open {
    0% {
      opacity: 0;
      transform: translateX(100%);
    }
    60% {
      opacity: 0.8;
      transform: translateX(-10%);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
`

export const Content = styled.div`
  padding: 0 ${theme.spacings[4]};
  border-left: ${theme.spacings[1]} solid;
  display: flex;
  align-items: center;
  svg {
    display: block;
  }

  &.error {
    border-color: ${getColors('red')};
  }
  &.warning {
    border-color: ${getColors('orange')};
  }
  &.success {
    border-color: ${getColors('green')};
  }

  button {
    color: ${getColors('neutral', 50)};
  }
`

export const IconContainer = styled.div`
  &.error {
    color: ${getColors('red')};
  }
  &.warning {
    color: ${getColors('orange')};
  }
  &.success {
    color: ${getColors('green')};
  }
`

export const Typography = styled.p`
  font-size: ${theme.font.size[3]};
  color: ${getColors('neutral', 80)};
  padding: 0 ${theme.spacings[4]};
  flex: 1;
  margin: ${theme.spacings[4]} 0;
  font-family: 'Inter', sans-serif;
`
