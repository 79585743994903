import React from 'react'
import { SvgProps } from './typings/SvgProps'

const Trash: React.FC<SvgProps> = ({ color = 'currentColor', size, ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} {...rest} viewBox="0 0 13.2 15.2">
    <g transform="translate(0.6 0.6)">
      <g transform="translate(-540 -598)">
        <path
          d="M10.208,0s-.391,5.366-.617,7.627a1.686,1.686,0,0,1-1.7,1.732c-1.877.037-3.755.04-5.631,0A1.672,1.672,0,0,1,.616,7.632C.388,5.352,0,0,0,0"
          transform="translate(540.896 602.613)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1.2"
        />
        <path
          d="M12,.5H0"
          transform="translate(540 599.76)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1.2"
        />
        <path
          d="M7.767,2.26a1.2,1.2,0,0,1-1.2-.858L6.382.615A.942.942,0,0,0,5.461,0H2.306a.942.942,0,0,0-.922.615L1.2,1.4A1.2,1.2,0,0,1,0,2.26"
          transform="translate(542.116 598)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1.2"
        />
      </g>
    </g>
  </svg>
)

export default Trash
