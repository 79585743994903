import React from 'react'
import { SvgProps } from './typings/SvgProps'

const CalendarToday: React.FC<SvgProps> = ({ color = 'currentColor', size, ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} {...rest} viewBox="0 0 16.302 18.842">
    <g transform="translate(0.15 0.15)">
      <g transform="translate(-557.999 -98.194)">
        <path
          d="M13224,1926.2h-1.647a2.368,2.368,0,0,1-2.354-2.375V1913.11a2.368,2.368,0,0,1,2.354-2.375h1.9v-.917a.616.616,0,0,1,.621-.625.61.61,0,0,1,.622.625v.917h4.959v-.917a.621.621,0,1,1,1.242,0v.917h1.9a2.439,2.439,0,0,1,2.4,2.418v10.666a2.369,2.369,0,0,1-2.358,2.375H13232v-1.126h1.768a1.026,1.026,0,0,0,1.033-1.04v-7.876h-13.6v7.876a1.025,1.025,0,0,0,1.032,1.04H13224v1.126Zm-1.81-14.208a1.237,1.237,0,0,0-1.159,1.04v2.043h13.646v-2.043a1.027,1.027,0,0,0-1.036-1.04h-2.023v1a.621.621,0,1,1-1.242,0v-1h-4.92v1a.619.619,0,1,1-1.238,0v-1Z"
          transform="translate(-12662.001 -1811)"
          fill={color}
          stroke={color}
          strokeWidth="0.3"
        />
        <line
          y2="7.008"
          transform="translate(565.984 109.178)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeWidth="1.4"
        />
        <g transform="translate(568.547 108.212) rotate(90)">
          <g transform="translate(2.563) rotate(90)">
            <g>
              <path
                d="M5.126,0,2.563,2.563,0,0"
                fill="none"
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="1.4"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default CalendarToday
