import React from 'react'
import { SvgProps } from './typings/SvgProps'

const CalendarDay: React.FC<SvgProps> = ({ color = 'currentColor', size, ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} {...rest} viewBox="0 0 16.3 17.367">
    <g transform="translate(0.15 0.15)">
      <g transform="translate(-52 -175.8)">
        <path
          d="M65.6,177.344H63.7v-.918a.62.62,0,1,0-1.24,0v.918H57.5v-.918a.62.62,0,1,0-1.24,0v.918h-1.9A2.367,2.367,0,0,0,52,179.722v10.766a2.366,2.366,0,0,0,2.357,2.378H65.643A2.367,2.367,0,0,0,68,190.488V179.764A2.438,2.438,0,0,0,65.6,177.344ZM54.191,178.6h2.026v1a.62.62,0,1,0,1.24,0v-1h4.92v1a.62.62,0,1,0,1.24,0v-1h2.026a1.028,1.028,0,0,1,1.034,1.043v2.045H53.034v-2.045A1.234,1.234,0,0,1,54.191,178.6Zm12.61,4.173v7.887a1.028,1.028,0,0,1-1.034,1.043H54.233a1.028,1.028,0,0,1-1.034-1.043v-7.887"
          transform="translate(0)"
          fill={color}
          stroke={color}
          strokeWidth="0.3"
        />
        <path
          d="M69.952,199.759h-.547v-3.311a.454.454,0,0,0-.444-.448h-.205a.6.6,0,0,0-.342.138c-.034,0-.034.034-.068.034l-1.093,1.1a.483.483,0,0,0,0,.655l.171.138a.472.472,0,0,0,.649,0l.239-.241v1.931h-.547a.469.469,0,0,0-.478.483v.207a.469.469,0,0,0,.478.483h2.221a.469.469,0,0,0,.478-.483v-.207A.55.55,0,0,0,69.952,199.759Z"
          transform="translate(-8.521 -11.253)"
          fill={color}
          stroke={color}
          strokeWidth="0.3"
        />
      </g>
    </g>
  </svg>
)

export default CalendarDay
