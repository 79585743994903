import React from 'react'

const SvgCalendarAlt = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      height={16.356}
      width={17.235}
      viewBox='0 0 16.356 17.235'
      {...props}
    >
      <g
        id='Iconly_Light_Home'
        data-name='Iconly/Light/Home'
        transform='translate(-1.333 -0.819)'
      >
        <g id='Home' transform='translate(2.083 1.667)'>
          <path
            id='Home-2'
            data-name='Home'
            d='M5.205,14.677v-2.4a1.115,1.115,0,0,1,1.113-1.11H8.575A1.115,1.115,0,0,1,9.7,12.279h0v2.405a.958.958,0,0,0,.94.953h1.5a2.7,2.7,0,0,0,2.715-2.688h0V6.128A1.907,1.907,0,0,0,14.1,4.639L8.959.536a2.487,2.487,0,0,0-3.084,0L.752,4.646A1.893,1.893,0,0,0,0,6.136v6.814a2.7,2.7,0,0,0,2.715,2.688h1.5a.966.966,0,0,0,.97-.961h0'
            fill='none'
            stroke='currentColor'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit='10'
            strokeWidth='1.5'
          />
        </g>
      </g>
    </svg>
  )
}

export default SvgCalendarAlt
