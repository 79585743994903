import { MenuItemProps, SelectProps } from '@mui/material'
import Icon from 'components/atoms/Icon'
import React, {
  FocusEventHandler,
  forwardRef,
  PropsWithChildren,
  ReactNode,
  SelectHTMLAttributes,
  useState,
} from 'react'
import {
  SelectStyled,
  Label,
  HelperText,
  FormControl,
  IconContainer,
  Option as OptionStyle,
} from './styles'

export interface IOption
  extends Pick<
    MenuItemProps,
    | 'className'
    | 'tabIndex'
    | 'children'
    | 'selected'
    | 'disabled'
    | 'autoFocus'
    | 'onFocusVisible'
    | 'value'
    | 'id'
  > {}

export const Option: React.FC<IOption> = ({ children, ...props }) => (
  <OptionStyle {...props}>{children}</OptionStyle>
)

export interface ISelect
  extends Omit<SelectHTMLAttributes<HTMLSelectElement>, 'onChange' | 'value'>,
    Pick<SelectProps, 'onChange'> {
  error?: boolean
  label?: string | ReactNode
  className?: string
  name?: string
  id?: string
  variant?: 'standard' | 'condensed'
  message?: string
  disabled?: boolean
  'data-testid'?: string
  value?: unknown
  options?: {
    value: string | number
    label: string
  }[]
}

const ChevronIcon = () => (
  <IconContainer>
    <Icon icon="ChevronDown" size={10} />
  </IconContainer>
)

const Select = forwardRef<HTMLInputElement, PropsWithChildren<ISelect>>(
  (
    {
      error,
      label,
      className,
      variant = 'standard',
      value,
      message,
      onChange,
      disabled,
      'data-testid': testId,
      children,
      onBlur,
      onFocus,
      name,
      id,
      placeholder,
      options,
    },
    ref,
  ) => {
    const [open, setOpen] = useState(false)
    return (
      <FormControl error={error} fullWidth variant="standard">
        {label && <Label shrink>{label}</Label>}
        <SelectStyled
          inputRef={ref}
          name={name}
          variant="outlined"
          id={id}
          className={`${className} select-${variant} ${
            /* istanbul ignore next */
            value === '' ? 'with-placeholder' : ''
          }`}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          MenuProps={{
            sx: {
              marginTop: '0.5rem',
              marginBottom: '0.5rem',
              display: !open ? 'none' : '',
            },
            disablePortal: true,
            disableScrollLock: true,
            // keepMounted: true,
          }}
          displayEmpty
          IconComponent={ChevronIcon}
          value={value}
          onChange={onChange}
          disabled={disabled}
          inputProps={{
            'data-testid': testId,
          }}
          data-testid="levi-select-button"
          onBlur={onBlur as FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined}
          onFocus={
            /* istanbul ignore next */
            onFocus as FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined
          }
        >
          {/* istanbul ignore next */}
          {placeholder && (
            <Option
              className="levi-select-placeholder"
              value=""
              disabled
              data-testid="levi-select-placeholder-option"
            >
              {placeholder}
            </Option>
          )}
          {options?.map((option, index) => (
            <Option
              id={`levi-select-option-${index}`}
              data-testid="levi-select-option"
              value={option.value}
            >
              {option.label}
            </Option>
          )) ?? children}
        </SelectStyled>
        {message && <HelperText>{message}</HelperText>}
      </FormControl>
    )
  },
)

export default Select
