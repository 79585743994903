import { theme } from '@cockpit/levi-ui/theme'
import styled from '@emotion/styled'
import { Tooltip as TooltipLevi } from 'components/atoms'
import { getColors } from 'helpers'

export const Container = styled.div``

export const ContainerTooltip = styled.div``

export const Button = styled.button<{ $selected?: boolean }>`
  display: flex;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  align-content: center;
  transition: all 250ms;
  margin-right: ${theme.spacings[2]};
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: ${({ $selected }) =>
    $selected ? `${theme.colors.neutral[20]}` : `${theme.colors.neutral[10]}`};
  :hover {
    background-color: ${getColors('neutral', 20)};
  }

  & svg {
    color: ${({ $selected }) =>
      $selected ? `${theme.colors.blue[40]}` : `${theme.colors.neutral[70]}`};
  }
`

export const Tooltip = styled(TooltipLevi)`
  padding-top: ${theme.spacings[2]};
`
