import React from 'react';

const SvgMonitorDoctor = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={26.224}
      height={20.7}
      viewBox='0 0 26 21'
      {...props}
    >
      <path d='M26.224 10.124a2.758 2.758 0 10-3.719 2.576 3.538 3.538 0 01-.165.645 3.342 3.342 0 01-2.925 1.973 3.16 3.16 0 01-3.064-1.363 4.407 4.407 0 01-.5-2.21 4.906 4.906 0 004.6-4.656V3.306a2.112 2.112 0 00-.677-1.648 2.48 2.48 0 00-1.764-.555h-.42V.862a.862.862 0 10-1.723 0v2.205a.862.862 0 101.723 0v-.243h.507a.835.835 0 01.531.123.525.525 0 01.1.361v3.744c-.016.309-.258 3.021-3.728 3.021s-3.711-2.712-3.726-2.982V3.306a.523.523 0 01.1-.361.963.963 0 01.618-.118h.509v.24a.862.862 0 001.724 0V.862a.862.862 0 00-1.724 0v.244h-.412a2.562 2.562 0 00-1.861.549 2.116 2.116 0 00-.677 1.649v3.817a4.9 4.9 0 004.57 4.618 6.172 6.172 0 00.745 3.088 4.742 4.742 0 004.181 2.229c.172 0 .346-.007.521-.023a5.061 5.061 0 004.389-3.091 5.444 5.444 0 00.27-1.182 2.753 2.753 0 001.997-2.636zm-2.758 1.034a1.034 1.034 0 111.035-1.034 1.036 1.036 0 01-1.035 1.034z' />
      <path d='M14.035 19.236H12.69l-.455-2.45h1.689a.736.736 0 100-1.471H2.933a1.474 1.474 0 01-1.473-1.473V4.536a1.473 1.473 0 011.473-1.472H8.264a.73.73 0 000-1.461H2.933A2.939 2.939 0 00-.003 4.539v9.308a2.939 2.939 0 002.936 2.936h3.925l-.456 2.456H5.06a.732.732 0 000 1.464h8.972a.732.732 0 000-1.464zm-6.142 0l.456-2.456h2.4l.456 2.456z' />
    </svg>
  )
}

export default SvgMonitorDoctor
