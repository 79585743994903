import styled, { css } from 'styled-components'
import { theme } from '../../Theme/theme'
import { EncounterStatus } from '../QueuePatient'

/**
 * TODO: Encounter status tem um union type aqui mas não tem na interface QueuePatient
 * verificar se os status de atentimendo são strings ou um lista pre definida (enum, union type)
 */

interface StatusAttendanceProps {
  encounterStatus: EncounterStatus
}

const statusPatientCss = {
  waiting: css`
    background: ${theme.palette.queue.waiting};
    color: ${theme.colors.blue3};
  `,
  attendance: css`
    background: ${theme.palette.queue.onConsultation};
    color: #fff;
  `,
  late: css`
    background: ${theme.colors.orange1};
    color: ${theme.colors.orange2};
  `
}

export const StatusAttendance = styled.div<StatusAttendanceProps>`
  width: 120px;
  text-align: center;
  margin-right: 20px;

  & div {
    ${({ encounterStatus }) => {
      switch (encounterStatus) {
        case 'Em espera':
          return statusPatientCss.waiting
        case 'Em atendimento':
          return statusPatientCss.attendance
        case 'Retorno':
          return statusPatientCss.late
      }
    }};

    width: 80px;
    font-weight: ${theme.fontWeight.bold};
    font-size: 14px;
    padding: 0 21px;
    height: 30px;
    line-height: 30px;
    border-radius: 50px;
    white-space: nowrap;
    margin: 3px auto;
  }

  & span {
    margin-top: 3px;
    font-size: 14px;
    color: ${theme.colors.gray4};
    white-space: nowrap;
  }
`
