import React from 'react'
import { SvgProps } from './typings/SvgProps'

const Ellipsis: React.FC<SvgProps> = ({ color = 'currentColor', size, ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} {...rest} viewBox="0 0 22 4">
    <g transform="translate(-5 -14)">
      <circle cx="2" cy="2" r="2" transform="translate(18 14) rotate(90)" fill={color} />
      <circle cx="2" cy="2" r="2" transform="translate(27 14) rotate(90)" fill={color} />
      <circle cx="2" cy="2" r="2" transform="translate(9 14) rotate(90)" fill={color} />
    </g>
  </svg>
)

export default Ellipsis
