import React from 'react';

const SvgChatDoctor = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={18.733}
      height={21.13}
      viewBox='0 0 18 21'
      {...props}
    >
      <path d='M14.687 11.008a2.459 2.459 0 102.46 2.46 2.463 2.463 0 00-2.46-2.46zm0 3.725a1.265 1.265 0 111.266-1.265 1.266 1.266 0 01-1.266 1.265zM16.024 16.51h-2.718a2.731 2.731 0 00-2.664 2.658v1.365a.6.6 0 101.194 0v-1.365a1.536 1.536 0 011.486-1.464h2.7a1.529 1.529 0 011.515 1.464v1.365a.6.6 0 101.194 0v-1.365a2.72 2.72 0 00-2.707-2.658zM4.046 13.06a2.459 2.459 0 10-2.46-2.46 2.462 2.462 0 002.46 2.46zm0-3.725A1.265 1.265 0 112.78 10.6a1.267 1.267 0 011.266-1.265zM5.382 13.642H2.664A2.732 2.732 0 000 16.3v1.366a.6.6 0 101.194 0V16.3a1.549 1.549 0 011.551-1.463h2.637a1.529 1.529 0 011.515 1.464v1.366a.6.6 0 101.194 0V16.3a2.721 2.721 0 00-2.709-2.658zM15.002 8.579a2.4 2.4 0 002.4-2.4V2.395a2.4 2.4 0 00-2.4-2.4H8.52a2.4 2.4 0 00-2.4 2.4v3.787a2.4 2.4 0 002.4 2.4h.641v1.295a.6.6 0 00.37.552.6.6 0 00.227.045.6.6 0 00.425-.178l1.7-1.714zm-3.8-1.017l-.85.859v-.439a.6.6 0 00-.6-.6H8.514a1.2 1.2 0 01-1.2-1.2V2.395a1.2 1.2 0 011.2-1.2h6.482a1.2 1.2 0 011.2 1.2v3.787a1.2 1.2 0 01-1.2 1.2h-3.372a.6.6 0 00-.419.18z' />
      <path d='M13.428 3.595h-1.072V2.522a.6.6 0 00-1.2 0v1.073h-1.069a.6.6 0 000 1.194h1.069v1.07a.6.6 0 001.2 0v-1.07h1.07a.6.6 0 000-1.194z' />
    </svg>
  )
}

export default SvgChatDoctor
