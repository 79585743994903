import React from 'react'
import { theme } from '@cockpit/levi-ui/theme'
import { CustomTooltipContent } from './styles'
import { ICustomToolTipPayload } from './typing'

export const CustomTooltip = (item: unknown) => {
  const { active, payload } = item as ICustomToolTipPayload
  if (active && payload && payload.length) {
    const orderedPayload = payload.sort((a, b) => b.payload.value - a.payload.value)
    return (
      <div
        data-testid="uterine-height-chart-customized-tooltip"
        style={{
          display: 'grid',
          backgroundColor: theme.colors.neutral[10],
          padding: theme.spacings[1],
          fontSize: theme.font.size[2],
          border: `1px solid ${theme.colors.neutral[40]}`,
        }}
      >
        {orderedPayload.map(({ name, payload, color }, index) => (
          <CustomTooltipContent
            key={`uterine-height-chart-custom-tooltip-${index}-${name}`}
            style={{ color }}
          >
            {name}: {payload.value}cm
          </CustomTooltipContent>
        ))}
      </div>
    )
  }

  return null
}
