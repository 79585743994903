import React from 'react';

const SvgCheckRounded = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='currentColor'
      width={21}
      height={21}
      viewBox='0 0 21 21'
      {...props}
    >
      <path d='M10.5 21A10.5 10.5 0 1121 10.5 10.511 10.511 0 0110.5 21zm0-19.865a9.365 9.365 0 109.365 9.365A9.376 9.376 0 0010.5 1.136z' />
      <path d='M9.329 14.482a.569.569 0 01-.4-.166l-3.421-3.415a.567.567 0 11.8-.8l3.014 3.008 5.36-5.527a.568.568 0 11.814.791l-5.76 5.94a.565.565 0 01-.4.173z' />
    </svg>
  )
}

export default SvgCheckRounded
