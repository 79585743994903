import React, {
  MutableRefObject,
  useState,
  useRef,
  isValidElement,
  useEffect
} from 'react'
import {
  TooltipArrow,
  TooltipCall,
  TooltipTemplate,
  TooltipText,
  TooltipWrapper
} from './styles'
import { useGetPosition } from './useGetPosition'

export type TPosition = 'top' | 'left' | 'right' | 'bottom'
export interface ITooltip {
  children: React.ReactNode
  description: string | JSX.Element
  position?: TPosition
  custom?: boolean
  background?: string
  opacity?: number
  isVisible?: boolean
  textMaxWidth?: string
  delay?: number
  hasArrow?: boolean
  margin?: string
  cursor?: string
}

const Tooltip: React.FC<ITooltip> = ({
  children,
  description,
  position,
  background,
  custom,
  opacity,
  isVisible,
  textMaxWidth,
  delay = 0.3,
  hasArrow,
  margin,
  cursor
}) => {
  const [show, setShow] = useState<boolean>(false)
  const [childDisable, setChildDisable] = useState<boolean>(false)
  const element: MutableRefObject<null | HTMLElement> = useRef(null)
  const { positionX, positionY, tooltip } = useGetPosition()

  useEffect(() => {
    if (isValidElement(children)) {
      setChildDisable(children.props?.disabled)
      setShow(false)
    }
  }, [children])

  return (
    <TooltipCall
      data-testid='tooltip-call'
      ref={element}
      onMouseOver={() => setShow(true)}
      onMouseOut={() => setShow(false)}
      cursor={cursor}
    >
      {childDisable && <TooltipTemplate />}
      {custom ? description : children}
      <TooltipWrapper
        custom={custom}
        background={background}
        position={position}
        opacity={opacity}
        show={show}
        isVisible={isVisible}
        ref={tooltip}
        positionX={positionX}
        positionY={positionY}
        delay={delay}
        hasArrow={hasArrow}
        margin={margin}
      >
        <TooltipText textMaxWidth={textMaxWidth}>
          {custom ? children : description}
        </TooltipText>
        {hasArrow && (
          <TooltipArrow
            position={position}
            background={background}
            data-testid='tooltip-arrow'
          />
        )}
      </TooltipWrapper>
    </TooltipCall>
  )
}

export default Tooltip
