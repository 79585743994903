import React from 'react';

const SvgUser = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      stroke='currentColor'
      width={17.8}
      height={19.8}
      viewBox='0 0 18 19'
      {...props}
    >
      <g
        transform='translate(-3.1 -2.1)'
        fill='none'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={1.8}
      >
        <path d='M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2' />
        <circle cx={4} cy={4} r={4} transform='translate(8 3)' />
      </g>
    </svg>
  )
}

export default SvgUser
