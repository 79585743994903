import { theme } from '@cockpit/levi-ui/theme'
import styled from '@emotion/styled'
import { getColors } from 'helpers'

interface BulletProps {
  variant: string
}

export const BulletContainer = styled.div`
  min-width: 100px;
  height: 28px;
  background-color: ${getColors('neutral', 30)};
  border-radius: ${theme.spacings[4]};
  padding: 0 ${theme.spacings[2]};
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
export const Label = styled.span<BulletProps>`
  margin: 8px 0;
  font-weight: 900;
  font-size: ${theme.font.size[2]};
  text-align: left;
  letter-spacing: 0px;
  color: ${({ variant }) =>
    variant === 'primary' ? theme.colors.neutral[70] : theme.colors.blue[40]};
  opacity: 1;
`
