import React, {
  JSXElementConstructor,
  ReactChild,
  ReactElement,
  ReactFragment,
  ReactPortal,
} from 'react'
import { Tooltip as TooltipMui, TooltipProps } from '@mui/material'
import { TooltipStyled } from './styles'

type Placement =
  | 'bottom-end'
  | 'bottom-start'
  | 'bottom'
  | 'left-end'
  | 'left-start'
  | 'left'
  | 'right-end'
  | 'right-start'
  | 'right'
  | 'top-end'
  | 'top-start'
  | 'top'

export type ITooltip = Pick<TooltipProps, 'componentsProps'> & {
  element: ReactElement<unknown, string | JSXElementConstructor<unknown>>
  title: ReactChild | ReactFragment | ReactPortal
  open?: boolean
  enterDelay?: number
  leaveDelay?: number
  followCursor?: boolean
  id?: string
  placement?: Placement
  className?: string
  onClose?: () => void
  onOpen?: () => void
}

const Tooltip: React.FC<ITooltip> = ({
  placement = 'bottom',
  element,
  className = '',
  ...rest
}) => (
  <TooltipMui
    placement={placement}
    data-testid="tooltip-component"
    components={{
      Tooltip: TooltipStyled,
    }}
    {...rest}
    PopperProps={{
      className,
    }}
  >
    {element}
  </TooltipMui>
)

export default Tooltip
