import React, { forwardRef, useCallback, useMemo, useState } from 'react'
import { TextField as MuiTextField, Popper, Paper, ClickAwayListener } from '@mui/material'
import { StaticDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import ptBR from 'date-fns/locale/pt-BR'
import formatDate from 'date-fns/format'
import { Icon } from 'components/atoms'
import MaskedField, { IMaskedField } from 'components/molecules/MaskedField'
import { IconButton, PaperContent } from './styles'

export interface IDatePicker extends Omit<IMaskedField, 'onChange' | 'mask' | 'maskPlaceholder'> {
  disableFuture?: boolean
  disablePast?: boolean
  maxDate?: string
  minDate?: string
  openTo?: 'year' | 'month' | 'day'
  onChange: (value: string) => void
  views?: Array<'year' | 'month' | 'day'>
}

const DatePicker = forwardRef<HTMLInputElement, IDatePicker>(
  ({ disableFuture, disablePast, maxDate, minDate, openTo = 'day', views, ...rest }, ref) => {
    const [open, setOpen] = useState(false)
    const [anchor, setAnchor] = useState<HTMLDivElement | null>(null)
    const [dateValue, setDateValue] = useState<string | null>()

    const isOpenToYearMonth = useMemo(() => Array.isArray(views) && views[1] === 'month', [views])


    const handleClickAway = useCallback(
      e => {
        /* istanbul ignore next */
        if (open && !e.target.classList.contains('levi-picker-button')) setOpen(false)
      },
      [open],
    )

    /* istanbul ignore next */
    const handleEscapeEvent = useCallback(
      e => {
        if (e.keyCode === 27 && open) setOpen(false)
      },
      [open],
    )

    /* istanbul ignore next */
    const handleYearChange = useCallback(
      (year: string) => {
        setOpen(false)
        const formattedYear: string = formatDate(new Date((year as unknown) as Date), 'yyyy')
        rest.onChange(formattedYear)
      },
      [rest],
    )

    /* istanbul ignore next */
    const handleMonthChange = useCallback(
      (date: string | null) => {
        setOpen(false)
        const formattedDate: string = formatDate((date as unknown) as Date, 'MM/yyyy')
        rest.onChange(formattedDate)
      },
      [rest],
    )

    /* istanbul ignore next */
    const handleDayChange = useCallback(
      (date: string | null) => {
        setOpen(false)
        const formattedYear: string = formatDate((date as unknown) as Date, 'dd/MM/yyyy')
        rest.onChange(formattedYear)
      },
      [rest],
    )

    const getDataTestId = () => {
      if (isOpenToYearMonth) return 'yearMonthpicker-component'
      if (openTo === 'year') return 'yearpicker-component'
      return 'datepicker-content-component'
    }

    return (
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
        <MaskedField
          {...rest}
          ref={ref}
          data-testid="datepicker-component"
          mask={
            /* istanbul ignore next */
            // eslint-disable-next-line no-nested-ternary
            isOpenToYearMonth || openTo === 'month'
              ? '99/9999'
              : openTo === 'day'
              ? '99/99/9999'
              : '9999'
          }
          onChange={e => rest.onChange(e.target.value)}
          parentRef={el => setAnchor(el)}
          iconOptions={{
            adornment: (
              <IconButton
                data-testid="datepicker-picker-component"
                className="levi-picker-button"
                onClick={() => {
                  setDateValue(null)
                  setOpen(state => !state)
                }}
                onKeyDown={handleEscapeEvent}
                disabled={rest.disabled}
              >
                <Icon style={{ pointerEvents: 'none' }} icon="CalendarEmpty" />
              </IconButton>
            ),
            position: 'end',
          }}
          hideMask
        />
        <ClickAwayListener onClickAway={handleClickAway}>
          <Popper
            open={open}
            anchorEl={anchor}
            sx={{ zIndex: 1000000 }}
            placement="bottom-end"
            onKeyDown={handleEscapeEvent}
          >
            <Paper data-testid={getDataTestId()}>
              <StaticDatePicker
                components={{
                  /* istanbul ignore next */
                  PaperContent: openTo !== 'day' || isOpenToYearMonth ? PaperContent : undefined,
                }}
                displayStaticWrapperAs="desktop"
                openTo={openTo}
                views={views || (openTo === 'month' ? ['month', 'year'] : undefined)}
                value={dateValue}
                onChange={(v: string | null) => {
                  /* istanbul ignore next */
                  setDateValue(v)
                }}
                renderInput={
                  /* istanbul ignore next */
                  props => <MuiTextField {...props} />
                }
                onYearChange={
                  /* istanbul ignore next */
                  openTo === 'year' ? handleYearChange : undefined
                }
                onAccept={
                  // eslint-disable-next-line no-nested-ternary
                  isOpenToYearMonth || openTo === 'month'
                    ? handleMonthChange
                    : openTo === 'day'
                    ? handleDayChange
                    : undefined
                }
                disableFuture={disableFuture}
                disablePast={disablePast}
                maxDate={maxDate}
                minDate={minDate}
                disableHighlightToday
              />
            </Paper>
          </Popper>
        </ClickAwayListener>
      </LocalizationProvider>
    )
  },
)

export default DatePicker
