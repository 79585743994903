import React from 'react';

const SvgSoundOn = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={21} height={15} viewBox="0 0 21 15" {...props}>
      <g transform="translate(-7408.213 -4049.82)">
        <path d="M10.871,11.079a9.151,9.151,0,0,1-.252,1.511A1.692,1.692,0,0,1,8.98,13.749a2.148,2.148,0,0,1-1.143-.4L4.07,10.716h-2.1A1.725,1.725,0,0,1,.141,9.147a18.89,18.89,0,0,1,0-4.6A1.781,1.781,0,0,1,1.972,3.035h2.1L7.759.451A2.467,2.467,0,0,1,8.98,0,1.694,1.694,0,0,1,10.619,1.16a10.378,10.378,0,0,1,.252,1.512A68.77,68.77,0,0,1,10.871,11.079Z" transform="translate(7409 4051.125)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit={10} stroke-width={1.5}/>
        <path d="M0,0A5.776,5.776,0,0,1,1.453,3.883,5.774,5.774,0,0,1,0,7.765" transform="translate(7423.367 4053.936)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit={10} stroke-width={1.5}/>
        <path d="M0,0A11,11,0,0,1,2.341,6.943,11.006,11.006,0,0,1,0,13.887" transform="translate(7426.659 4050.875)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit={10} stroke-width={1.5}/>
      </g>
    </svg>
  )
}

export default SvgSoundOn
