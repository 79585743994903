import React from 'react'
import { SvgProps } from './typings/SvgProps'

const CalendarExclude: React.FC<SvgProps> = ({ color = 'currentColor', size, ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} {...rest} viewBox="0 0 16.811 17.811">
    <g transform="translate(-3075.25 -2977.68)">
      <g transform="translate(3076 2978.43)">
        <path
          d="M188.547,261.394h-4.4a1.382,1.382,0,0,1-1.266-1.474V249.028a1.382,1.382,0,0,1,1.266-1.474H195.2a1.382,1.382,0,0,1,1.266,1.474v4.091"
          transform="translate(-182.885 -246.039)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="1.5"
        />
        <g transform="translate(3.999 0)">
          <line
            y2="3.054"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="1.5"
          />
          <line
            y2="3.054"
            transform="translate(5.513)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="1.5"
          />
        </g>
        <line
          x2="6.059"
          y2="6.059"
          transform="translate(8.941 9.941)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="1.5"
        />
        <line
          x1="6.059"
          y2="6.059"
          transform="translate(8.941 9.941)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="1.5"
        />
        <line
          x2="13.584"
          transform="translate(0 5.452)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="1.5"
        />
      </g>
    </g>
  </svg>
)

export default CalendarExclude
