import React from 'react'
import { ListItemText, Menu } from '@mui/material'
import { IMenuDropdown } from './typing'
import { MenuItem, ListItemIcon } from './styles'

const CalendarMenu: React.FC<IMenuDropdown> = ({ options, ...rest }) => (
  <Menu
    MenuListProps={{
      'aria-labelledby': 'basic-button',
    }}
    {...rest}
  >
    {options.map((item, index) => (
      <MenuItem
        key={item.text}
        onClick={item.onClick}
        disabled={item.disabled ?? false}
        data-testid={`menudropdown-button-${index}`}
      >
        {item.startIcon && <ListItemIcon>{item.startIcon}</ListItemIcon>}
        <ListItemText>{item.text}</ListItemText>
        {item.endIcon && <ListItemIcon>{item.endIcon}</ListItemIcon>}
      </MenuItem>
    ))}
  </Menu>
)

export default CalendarMenu
