import { theme } from '../Theme'
import styled from 'styled-components'

interface IContainer {
  displayPhone?: boolean
}

export const Container = styled.div<IContainer>`
  display: flex;
  flex: 1;
  margin-top: ${({ displayPhone }) =>
    displayPhone || displayPhone === undefined ? '20px' : '40px'};
`

export const IconContainer = styled.div`
  margin-right: 30px;
  margin-bottom: 40px;
`
export const IconContent = styled.div`
  display: flex;
  width: 64px;
  height: 64px;
  background-color: ${theme.colors.gray1};
  border-radius: 16px;
  justify-content: center;
  & svg {
    margin-left: 3px;
    color: ${theme.colors.blue3};
  }
`

export const Content = styled.div`
  flex: 1;

  & input {
    height: 35px;
  }
`

export const InputContent = styled.div`
  margin-top: 20px;
`

export const TitleContent = styled.div`
  display: flex;
  font-weight: ${theme.fontWeight.semibold};
  font-size: 18px;
  color: ${theme.colors.gray4};
`

export const SwitchContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`

export const ButtonContainer = styled.div<IContainer>`
  margin-top: ${({ displayPhone }) => (displayPhone ? '0px' : '40px')};
  display: flex;
  justify-content: flex-end;

  & button:nth-child(1) {
    margin-right: 15px;
  }

  & button {
    min-width: 130px;
    height: 35px;
  }
`

export const SelectOptionsText = styled.div`
  margin-top: 15px;
  font-weight: ${theme.fontWeight.semibold};
  font-size: 14px;
  color: ${theme.colors.gray4};
`

export const Separator = styled.div`
  height: 1px;
  background-color: ${theme.colors.gray3};
  margin-top: 40px;
  margin-bottom: 40px;
`
