import React from 'react'
import { SvgProps } from './typings/SvgProps'

const Headset: React.FC<SvgProps> = ({ color = 'currentColor', size, ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} {...rest} viewBox="0 0 44 38">
    <g>
      <path
        d="M44,22.353c-.1.5-.174,1.006-.314,1.493a6.193,6.193,0,0,1-5.8,4.461c-.328.005-.656.009-.983,0a1.557,1.557,0,0,1-1.652-1.677c0-2.977.048-5.955-.014-8.931a15.527,15.527,0,0,0-3.253-9.766,12.249,12.249,0,0,0-8.737-4.654c-6.33-.574-12.192,3.461-13.743,10.5A24.491,24.491,0,0,0,9.012,18.2c-.079,2.329-.067,4.665-.01,7a9.772,9.772,0,0,0,4.013,8.1,7.513,7.513,0,0,0,4.5,1.463c1.082,0,2.164-.013,3.245.01a1.623,1.623,0,0,1,1.574,1.59,1.548,1.548,0,0,1-1.456,1.582c-2.3.065-4.625.208-6.852-.615a11.815,11.815,0,0,1-7.666-8.378c-.095-.343-.195-.474-.57-.49-4.314-.18-6.025-3.857-5.766-6.828a5.825,5.825,0,0,1,4.107-5.47,7.06,7.06,0,0,1,.86-.19c.288-.05.58-.076.849-.11.235-1.139.406-2.281.711-3.386,1.388-5.02,4.25-8.851,9.023-11.045A15.809,15.809,0,0,1,36.419,9.059a20.9,20.9,0,0,1,1.888,6.565,18.7,18.7,0,0,1,2.574,1.035,5.973,5.973,0,0,1,3.025,4.719,2.9,2.9,0,0,0,.094.38ZM5.818,19.1a2.862,2.862,0,0,0-2.673,3.229c.107,1.747,1.241,2.981,2.673,2.879Zm32.6,5.924a3.047,3.047,0,0,0,2.4-3.11,2.957,2.957,0,0,0-2.4-2.849Z"
        transform="translate(-0.001 0)"
        fill={color}
      />
      <path
        d="M209.023,207.565a1.719,1.719,0,0,1-1.709-1.69,1.69,1.69,0,1,1,3.379-.041,1.72,1.72,0,0,1-1.669,1.731"
        transform="translate(-186.885 -183.824)"
        fill={color}
      />
      <path
        d="M272.849,207.563a1.722,1.722,0,0,1-1.671-1.729,1.69,1.69,0,1,1,3.378.037,1.716,1.716,0,0,1-1.707,1.692"
        transform="translate(-244.456 -183.822)"
        fill={color}
      />
      <path
        d="M144.93,204.587a1.711,1.711,0,0,1,1.733,1.649,1.71,1.71,0,1,1-3.42.044,1.706,1.706,0,0,1,1.687-1.694"
        transform="translate(-129.128 -184.239)"
        fill={color}
      />
    </g>
  </svg>
)

export default Headset
