import React from 'react';

const SvgFila = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={17.703}
      height={12}
      viewBox='0 0 17 12'
      {...props}
    >
      <g data-name='Group 7'>
        <path
          data-name='Path 8956'
          d='M.565 9.579A.565.565 0 010 9.013V3.361a.565.565 0 01.978-.389l2.638 2.826a.563.563 0 010 .771L.978 9.399a.563.563 0 01-.413.18zm.565-4.784v2.784l1.3-1.392zm0 0'
          fill='currentColor'
        />
        <g
          data-name='Rectangle 1'
          transform='translate(3.702)'
          fill='none'
          stroke='currentColor'
          strokeWidth={1.5}
        >
          <rect width={14} height={5} rx={2} stroke='none' />
          <rect x={0.75} y={0.75} width={12.5} height={3.5} rx={1.25} />
        </g>
        <g
          data-name='Rectangle 2'
          transform='translate(3.702 7)'
          fill='none'
          stroke='currentColor'
          strokeWidth={1.5}
        >
          <rect width={14} height={5} rx={2} stroke='none' />
          <rect x={0.75} y={0.75} width={12.5} height={3.5} rx={1.25} />
        </g>
      </g>
    </svg>
  )
}

export default SvgFila
