import React from 'react'
import { SvgProps } from './typings/SvgProps'

const VideoOff: React.FC<SvgProps> = ({ color = 'currentColor', size, ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill={color} width={size} {...rest} viewBox="0 0 23 21">
    <defs>
      <clipPath>
        <rect width={23} height={21} fill="none" stroke={color} strokeWidth={1} />
      </clipPath>
    </defs>
    <g transform="translate(0 0)" clipPath="url(#clip-path)">
      <path
        d="M3.951,16.979h.7A3.614,3.614,0,0,1,.761,13.66a3.51,3.51,0,0,1-.008-.445V5.509A3.626,3.626,0,0,1,4.214,1.726a3.545,3.545,0,0,1,.447.008h6.871a3.63,3.63,0,0,1,3.319,1.632"
        transform="translate(0.08 0.185)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      />
      <path
        d="M14.676,7.759v4.809a3.622,3.622,0,0,1-3.466,3.772,3.461,3.461,0,0,1-.442-.008H7.888"
        transform="translate(0.845 0.831)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      />
      <path
        d="M14.019,8.143,18.558,4.43a1.107,1.107,0,0,1,1.812.86V12.9a1.107,1.107,0,0,1-1.812.86l-4.539-3.713"
        transform="translate(1.502 0.448)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      />
      <line
        y1="19.091"
        x2="15.44"
        transform="translate(2.464 0.83)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      />
    </g>
  </svg>
)

export default VideoOff
