import React from 'react';

const SvgCamera = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={20.507}
      height={15.293}
      viewBox='0 0 20 15'
      {...props}
    >
      <path d='M20.108 3.088a1.755 1.755 0 00-2.46-.236l-2.877 2.353v-1a3.933 3.933 0 00-.007-.485A4.014 4.014 0 0010.492.011H4.346a4.105 4.105 0 00-.5-.009A4.025 4.025 0 00.002 4.168v6.928a3.877 3.877 0 00.008.494 4.014 4.014 0 003.953 3.7c.1 0 .2 0 .308-.012h6.156a4.021 4.021 0 004.34-4.163v-1.029l2.873 2.35a1.747 1.747 0 001.114.4h.011a1.75 1.75 0 001.743-1.756V4.215a1.758 1.758 0 00-.4-1.127zm-7.5 9.892a2.505 2.505 0 01-1.752.813 2.828 2.828 0 01-.367-.008H4.213a2.532 2.532 0 01-2.7-2.31 2.7 2.7 0 01-.005-.343V4.139a2.526 2.526 0 012.411-2.634 3.24 3.24 0 01.37.007h6.264a2.542 2.542 0 012.718 2.325 2.815 2.815 0 010 .337v6.992a2.51 2.51 0 01-.663 1.814zm6.4-1.889a.244.244 0 01-.072.177.248.248 0 01-.177.074.255.255 0 01-.162-.059l-3.826-3.129V7.147L18.6 4.015a.252.252 0 01.352.033.246.246 0 01.057.166z' />
    </svg>
  )
}

export default SvgCamera
