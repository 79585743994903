import React from 'react';

const SvgPencilRounded = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='currentColor'
      width={21}
      height={21}
      viewBox='0 0 21 21'
      {...props}
    >
      <path d='M10.5 21A10.5 10.5 0 1121 10.5 10.512 10.512 0 0110.5 21zm0-19.865a9.365 9.365 0 109.365 9.365A9.376 9.376 0 0010.5 1.135z' />
      <path d='M5.784 16.147a1.037 1.037 0 01-1-1.3l.9-3.444a.553.553 0 01.146-.254l6.254-6.254a.576.576 0 01.791 0l3.389 3.389a.559.559 0 010 .791l-6.254 6.254a.56.56 0 01-.3.156l-3.746.645a1 1 0 01-.18.017zm.943-4.312l-.831 3.172 3.444-.594 5.733-5.732-2.6-2.6z' />
    </svg>
  )
}

export default SvgPencilRounded
