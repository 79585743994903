import React from 'react'

const SvgSatisfactionSurveyIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      viewBox='0 0 20 20'
      {...props}
    >
      <g
        id='Grupo_162553'
        data-name='Grupo 162553'
        transform='translate(20806.75 22110.75)'
      >
        <g
          id='Arrow_-_Right_Square'
          data-name='Arrow - Right Square'
          transform='translate(-20806.75 -22090.75) rotate(-90)'
        >
          <path
            id='Stroke_1'
            data-name='Stroke 1'
            d='M4.916,18.5h8.669c3.02,0,4.915-2.139,4.915-5.166V5.166C18.5,2.139,16.615,0,13.585,0H4.916C1.886,0,0,2.139,0,5.166v8.168C0,16.361,1.886,18.5,4.916,18.5Z'
            transform='translate(0.75 0.75)'
            fill='none'
            stroke='currentColor'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit='10'
            strokeWidth='1.5'
          />
        </g>
        <g id='Star' transform='translate(-20801.502 -22105.5)'>
          <path
            id='Star-2'
            data-name='Star'
            d='M5.107.22,6.265,2.55a.4.4,0,0,0,.3.219l2.593.375a.4.4,0,0,1,.264.153.385.385,0,0,1-.042.516L7.5,5.629a.381.381,0,0,0-.113.351l.45,2.564a.393.393,0,0,1-.326.446.434.434,0,0,1-.258-.04L4.944,7.739a.388.388,0,0,0-.371,0L2.247,8.956a.406.406,0,0,1-.539-.165.4.4,0,0,1-.041-.25l.45-2.564a.394.394,0,0,0-.113-.35L.116,3.81a.393.393,0,0,1,0-.556l0,0a.455.455,0,0,1,.226-.111l2.593-.375a.406.406,0,0,0,.3-.219L4.392.22a.393.393,0,0,1,.229-.2.4.4,0,0,1,.305.022A.41.41,0,0,1,5.107.22Z'
            transform='translate(0 0)'
            fill='none'
            stroke='currentColor'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit='10'
            strokeWidth='1.3'
          />
        </g>
      </g>
    </svg>
  )
}

export default SvgSatisfactionSurveyIcon
