import React from 'react'

const iconKebab = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={17}
    height={3}
    viewBox='0 0 17 3'
    fill='currentColor'
    {...props}
  >
    <g transform='translate(0 3) rotate(-90)'>
      <circle cx={1.5} cy={1.5} r={1.5} />
      <circle cx={1.5} cy={1.5} r={1.5} transform='translate(0 7)' />
      <circle cx={1.5} cy={1.5} r={1.5} transform='translate(0 14)' />
    </g>
  </svg>
)

export default iconKebab
