import React from 'react'
import { SvgProps, WithSecondaryColor } from './typings/SvgProps'

const Warning: React.FC<SvgProps & WithSecondaryColor> = ({
  color = 'currentColor',
  size,
  ...rest
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} {...rest} viewBox="0 0 16 16">
    <path
      d="M8,16a8,8,0,1,1,5.659-2.344A8.009,8.009,0,0,1,8,16Zm0-5.655a.7.7,0,0,0-.7.7.7.7,0,1,0,.7-.7ZM8,4.264a.714.714,0,0,0-.7.7V8.5a.7.7,0,0,0,1.4,0V4.968A.7.7,0,0,0,8,4.264Z"
      fill={color}
    />
  </svg>
)

export default Warning
