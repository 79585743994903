import React from 'react';

const SvgConfVideo = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={24.835}
      height={24.835}
      viewBox='0 0 25 25'
      {...props}
    >
      <g data-name='Iconly/Light/Chat' opacity={0.998}>
        <path
          data-name='Stroke 7'
          d='M12.441.75A11.675 11.675 0 00.75 12.435a12.349 12.349 0 001.575 5.83 1.226 1.226 0 01.082 1.053l-.782 2.617a.728.728 0 00.957.91l2.357-.7a1.98 1.98 0 011.738.421A11.664 11.664 0 1012.441.75z'
          fill='none'
          stroke='#c2cfdf'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          strokeWidth={1.5}
        />
        <g
          fill='none'
          stroke='#c2cfdf'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          strokeWidth={1.5}
          data-name='Iconly/Light/Video'
        >
          <path
            data-name='Stroke 1'
            d='M15.04 14.567a2.169 2.169 0 01-2.46 2.1H8.249a2.163 2.163 0 01-2.452-2.1v-4.3a2.17 2.17 0 012.46-2.1h4.324a2.173 2.173 0 012.46 2.105z'
          />
          <path
            data-name='Stroke 3'
            d='M15.04 11.925l2.855-1.924c.452-.307 1.145-.04 1.145.446v3.942c0 .486-.688.752-1.141.446l-2.859-1.924'
          />
        </g>
      </g>
    </svg>
  )
}

export default SvgConfVideo
