import React from 'react';

const SvgCalendarAlt = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg height={512} width={512} viewBox='0 0 512 512' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M446 40h-46V16c0-8.836-7.163-16-16-16s-16 7.164-16 16v24H144V16c0-8.836-7.163-16-16-16s-16 7.164-16 16v24H66C29.607 40 0 69.607 0 106v340c0 36.393 29.607 66 66 66h380c36.393 0 66-29.607 66-66V106c0-36.393-29.607-66-66-66zM66 72h46v16c0 8.836 7.163 16 16 16s16-7.164 16-16V72h224v16c0 8.836 7.163 16 16 16s16-7.164 16-16V72h46c18.748 0 34 15.252 34 34v38H32v-38c0-18.748 15.252-34 34-34zm380 408H66c-18.748 0-34-15.252-34-34V176h448v270c0 18.748-15.252 34-34 34z' />
    </svg>
  )
}

export default SvgCalendarAlt
