/* eslint-disable no-debugger */
import React from 'react'
import { Wrapper, CheckboxInput, Label, InnerLabel } from './styles'
import { withTheme } from '../hoc'
export interface Props {
  // TODO: Ajustar tipagem
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleChange: any
  uncheckedLabel?: string
  checkedLabel?: string
  initialValue?: boolean
  // TODO: Ajustar tipagem
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  style?: any
}
const Switch: React.FC<Props> = withTheme(
  ({
    handleChange,
    uncheckedLabel = 'Controlada',
    checkedLabel = 'Não controlada',
    initialValue = false,
    style = {}
  }) => {
    const [checked, setChecked] = React.useState(initialValue)
    const handleClick = (): void => {
      const changedValue = !checked
      setChecked(changedValue)
      if (handleChange) handleChange(changedValue)
    }
    return (
      <Wrapper style={{ ...style }}>
        <CheckboxInput
          id='status'
          type='checkbox'
          name='status'
          defaultChecked={checked}
          onClick={handleClick}
        />
        <Label htmlFor='status'>
          <InnerLabel
            data-unchecked={uncheckedLabel}
            data-checked={checkedLabel}
          />
        </Label>
      </Wrapper>
    )
  }
)

export default Switch
