import React from 'react'
import { SvgProps } from './typings/SvgProps'

const Computer: React.FC<SvgProps> = ({ color = 'currentColor', size, ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} {...rest} viewBox="0 0 24.181 19.1">
    <g transform="translate(-11.1 -28)">
      <g transform="translate(11.1 28)">
        <path
          d="M217.069,51.795a2.929,2.929,0,0,0-2.9-2.559H200.946a2.939,2.939,0,0,0-2.936,2.936V61.48a2.939,2.939,0,0,0,2.936,2.936h3.925l-.456,2.456h-1.342a.731.731,0,1,0,0,1.463h8.972a.731.731,0,0,0,0-1.463H210.7l-.455-2.45h1.689a.736.736,0,1,0,0-1.471H200.943a1.474,1.474,0,0,1-1.473-1.473V52.172a1.473,1.473,0,0,1,1.473-1.472H214.17a1.47,1.47,0,0,1,1.418,1.1v.907a.74.74,0,0,0,1.48,0V51.8Zm-10.71,12.621h2.4l.456,2.456H205.9Z"
          transform="translate(-198.01 -49.236)"
          fill={color}
        />
        <path
          d="M222.19,61a2.1,2.1,0,1,0-2.838,1.966,2.684,2.684,0,0,1-.126.492,2.551,2.551,0,0,1-2.232,1.506,2.411,2.411,0,0,1-2.337-1.04,3.372,3.372,0,0,1-.381-1.687,3.742,3.742,0,0,0,3.508-3.552V55.8a1.615,1.615,0,0,0-.516-1.258,1.9,1.9,0,0,0-1.347-.423H215.6v-.184a.658.658,0,1,0-1.316,0V55.62a.658.658,0,0,0,1.316,0v-.186h.386a.634.634,0,0,1,.406.093.406.406,0,0,1,.075.276v2.856c-.013.236-.2,2.305-2.845,2.305s-2.831-2.069-2.843-2.275V55.8a.4.4,0,0,1,.076-.276.734.734,0,0,1,.472-.09h.388v.184a.658.658,0,0,0,1.315,0V53.937a.658.658,0,0,0-1.315,0v.187H211.4a1.949,1.949,0,0,0-1.42.419,1.612,1.612,0,0,0-.517,1.258v2.912a3.734,3.734,0,0,0,3.487,3.523,4.709,4.709,0,0,0,.568,2.357,3.62,3.62,0,0,0,3.19,1.7,3.189,3.189,0,0,0,.4-.017,3.862,3.862,0,0,0,3.348-2.359,4.148,4.148,0,0,0,.206-.9A2.1,2.1,0,0,0,222.19,61Zm-2.1.79a.785.785,0,1,1,.558-.228.789.789,0,0,1-.558.228Z"
          transform="translate(-198.01 -49.236)"
          fill={color}
        />
      </g>
    </g>
  </svg>
)

export default Computer
