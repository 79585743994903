import styled from 'styled-components'
import { theme } from '../Theme'

interface FlexRow {
  align?: string
  width?: string
  margin?: string
  justify?: string
  direction?: string
  flex?: string
}

export const FlexRowItem = styled.div<FlexRow>`
  display: flex;

  width: ${({ width }) => width};
  margin: ${({ margin }) => margin};
  align-items: ${({ align }) => align || 'center'};
  justify-content: ${({ justify }) => justify || 'center'};
  flex-direction: ${({ direction }) => direction || 'row'};
  flex: ${({ flex }) => flex};

  transition: all 0.2s;
`

export const WrapperSimulation = styled.div`
  background: gray;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 300px;
`

export const CardWrapper = styled.div`
  height: 76px;
  border-radius: ${(props) => props.theme.borderRadius.patientInformation};
  background: #fff;

  display: flex;
  align-items: center;
  position: relative;
  padding: 0 0 0 30px;
`

export const Info = styled.div`
  display: flex;
  flex-direction: column;
`

export const PatientName = styled.p`
  font-size: 16px;
  font-weight: ${theme.fontWeight.medium};
  margin-bottom: 4px;
`

export const PatientData = styled.span`
  font-size: 14px;
  font-weight: ${theme.fontWeight.regular};
  color: ${theme.colors.gray4};
`

export const ObservationWrapper = styled.div`
  margin: 2.5px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    fill: ${({ color }) => color};
  }
`

export const ColumnTitle = styled.p`
  text-transform: uppercase;
  font-size: 12px;
  color: ${theme.colors.gray4};
  margin-bottom: 4px;
`

export const ColumnDescription = styled.p`
  font-size: 14px;
  color: #000;
`

export const StatusSubtitle = styled.span`
  margin-top: 3px;
  font-size: 14px;
  color: ${theme.colors.gray4};
  white-space: nowrap;
`

interface HoveredCard {
  hover: boolean
}

export const HiddenWrapper = styled(FlexRowItem)<HoveredCard>`
  right: 0;
  opacity: ${({ hover }) => (hover ? '1' : '0')};
  /* transition: all 0.5s ease-in-out; */
  position: absolute;
  height: 100%;
  margin: 0 0 0 20px;
  transition: opacity 0.6s;
`

export const InitAttendance = styled.button`
  background: ${theme.colors.blue3};
  border: none;

  align-items: center;
  justify-content: center;
  height: 100%;
  width: 45px;
  border-radius: 0 4px 4px 0;
  margin: 0 0 0 auto;
  cursor: pointer;

  &:focus {
    outline: 0;
  }

  &:active {
    outline: 0;
  }

  :hover {
    background: #2c80e3;
  }

  svg {
    fill: #fff;
    cursor: pointer;
  }
`

export const ButtonOptions = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  border: none;
  background: transparent;
  color: transparent;

  border-radius: 50%;
  background: ${theme.colors.gray4};
  width: 4px;
  height: 4px;
  position: relative;
  margin: 0 22px 0 0;
  outline: none;
  padding: inherit;

  &:before,
  &:after {
    content: '';
    position: absolute;
    background: ${theme.colors.gray4};
    width: 4px;
    height: 4px;
    border-radius: 50%;
  }

  &:before {
    top: -8px;
  }

  &:after {
    bottom: -8px;
  }
`

interface OptionsWrapperProps {
  show?: boolean
}

export const Options = styled.div`
  position: relative;
`

export const OptionsWrapper = styled(FlexRowItem)<OptionsWrapperProps>`
  display: ${({ show }) => (show ? 'flex' : 'none')};
  position: absolute;
  background: #fff;
  width: 145px;
  z-index: 1;

  right: 100%;

  border-radius: ${(props) => props.theme.borderRadius.patientInformation};
  padding: 5px 0;

  -webkit-box-shadow: 0px 3px 6px #00000029;
  -moz-box-shadow: 0px 3px 6px #00000029;
  box-shadow: 0px 3px 6px #00000029;
`

export const LineOption = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  font-size: 14px;
  width: 100%;
  padding: 0 10px;
  color: ${theme.colors.gray4};
  height: 40px;
  position: relative;
  cursor: pointer;

  :hover {
    color: ${theme.colors.blue3};
    background: #f3f5f9;
  }

  &:last-of-type {
    border: none;
  }

  svg {
    margin-right: 16px;
    fill: ${theme.colors.gray4};
  }

  &:after {
    content: '';
    position: absolute;
  }
`
