import { theme } from '@cockpit/levi-ui/theme'
import styled from '@emotion/styled'
import { getColors } from 'helpers'

export const TooltipStyled = styled.div`
  background: ${getColors('neutral', 80)};
  border-radius: 0.25rem;
  padding: ${theme.spacings[2]};
  color: ${getColors('neutral', 10)};
  font-size: ${theme.font.size[1]};
`
