import React from 'react';

const SvgPresential = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={21.25}
      height={25.001}
      viewBox='0 0 21 25'
      {...props}
    >
      <g data-name='Iconly/Light-outline/Location'>
        <path
          data-name='Location'
          d='M9.393 24.6l-.075-.06-.518-.34a25.948 25.948 0 01-5.668-5.32l-.395-.516A13.375 13.375 0 010 10.658l.006-.358A10.64 10.64 0 0110.625 0h.036l.319.006a10.645 10.645 0 0110.27 10.685v.128c-.063 3.3-1.671 6.712-4.65 9.865a26.463 26.463 0 01-4.681 3.868l-.062.052a2.111 2.111 0 01-2.465 0zm.962-22.7a8.748 8.748 0 00-8.463 8.723l.024.447a11.483 11.483 0 002.331 6.144 23.963 23.963 0 006.128 5.755l.1.078a.226.226 0 00.3 0l.106-.08a24.522 24.522 0 004.354-3.594c2.645-2.8 4.071-5.764 4.125-8.572v-.415a8.747 8.747 0 00-8.7-8.489zm-3.778 8.91a4.048 4.048 0 114.048 4.06 4.059 4.059 0 01-4.048-4.059zm1.891 0a2.157 2.157 0 102.157-2.163 2.162 2.162 0 00-2.157 2.164z'
          fill='currentColor'
        />
      </g>
    </svg>
  )
}

export default SvgPresential
