import React from 'react';

const SvgPdfIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      data-name='Componente 426 \u2013 14'
      xmlns='http://www.w3.org/2000/svg'
      width={31}
      height={33}
      viewBox='0 0 30 32'
      {...props}
    >
      <path
        data-name='Stroke 4'
        d='M19.173 7.5h-7.382C9.141 7.521 7.5 9.3 7.5 12v9c0 2.718 1.653 4.5 4.326 4.5h7.382c2.65-.02 4.292-1.8 4.292-4.5v-9c0-2.718-1.654-4.5-4.327-4.5z'
        fill='none'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit={10}
        strokeWidth={1.5}
      />
      <g data-name='Componente 423'>
        <path
          data-name='Caminho 5096'
          d='M14.53 15.083c-.212-.648-.68-2.717.556-2.751s.424 2.625.424 2.625a4.507 4.507 0 001.783 2.153c.441-.126 2.958-.4 2.6.841-.38 1.245-3.09.076-3.09.076a13.154 13.154 0 00-3.011.555c-1 1.775-2.163 2.549-2.64 1.8-.592-.934 1.881-2.137 1.881-2.137a9.969 9.969 0 001.492-3.163m.574.707a12.8 12.8 0 01-1.06 2.263 21.183 21.183 0 012.393-.614 4.947 4.947 0 01-1.333-1.649m2.777 1.821s1.545.547 1.58.328c.062-.227-1.174-.429-1.58-.328m-5 1.253c-.68.252-1.333 1.329-1.174 1.329a2.482 2.482 0 001.174-1.329m2.228-4.663c0-.042.38-1.413 0-1.455-.265-.025-.009 1.422 0 1.455z'
          fill='currentColor'
        />
      </g>
      <path data-name='Ret\xE2ngulo 3795' fill='none' d='M0 0h31v33H0z' />
    </svg>
  )
}

export default SvgPdfIcon
