import { IconButton as MUIIconButton } from '@mui/material'
import styled from '@emotion/styled'
import { getColors } from '@cockpit/levi-ui/helpers'

export const IconButton = styled(MUIIconButton)`
  width: 32px;
  height: 32px;
  color: ${getColors('neutral', 50)};
  svg {
    max-height: 16px;
    padding-right: 0;
  }
`

export const PaperContent = styled.div`
  .MuiCalendarPicker-root > div:first-of-type {
    display: none;
  }

  .MuiMonthPicker-root > button.Mui-selected {
    background: ${getColors('neutral', 10)};
    color: black;
  }

  .MuiMonthPicker-root > button:hover {
    background: ${getColors('neutral', 20)};
  }

  .MuiCalendarPicker-viewTransitionContainer {
    overflow: auto;
    display: flex;
    flex-direction: column-reverse;
  }
`
