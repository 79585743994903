import { theme } from '@cockpit/levi-ui/theme'
import styled from '@emotion/styled'
import {
  MenuItem as MenuItemMui,
  ListItemIcon as ListItemIconMui,
  ListItem as ListItemMui,
} from '@mui/material'

export const Container = styled.div`
  display: flex;
`

export const Header = styled.div`
  width: 100%;
  align-items: center;
  padding: 0 ${theme.spacings[4]};
  border-radius: 8px 8px 0 0;
  font-weight: medium;
`

export const Title = styled.p`
  font-weight: 500;
  font-size: ${theme.font.size[3]};
`

export const ListItemIcon = styled(ListItemIconMui)`
  &,
  &.MuiListItemIcon-root {
    display: flex;
    align-items: center;
    min-width: auto;
    svg {
      max-width: 2rem;
      min-width: 0.5rem;
    }
    &:first-child {
      justify-content: flex-start;
      margin-right: ${theme.spacings[2]};
    }
    &:not(:first-child) {
      justify-content: flex-end;
      margin-left: ${theme.spacings[2]};
    }
  }
`

export const MenuItem = styled(MenuItemMui)`
  .MuiTypography-root {
    font-size: ${theme.font.size[3]};
  }
`

export const ListItem = styled(ListItemMui)`
  .MuiTypography-root {
    font-size: ${theme.font.size[3]};
  }
`
