import styled, { css } from 'styled-components'
import { theme } from '../../Theme'

interface IMovePage {
  position: 'bottom-right' | 'left-top'
}

export const Box = styled.div``

export const ContainerWeb = styled.div<IMovePage>`
  position: absolute;
  width: 250px;
  background: ${theme.palette.white};
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.29);
  border-radius: 4px;
  z-index: 1000;
  display: none;
  ${({ position }) =>
    position === 'bottom-right' &&
    css`
      right: 0;
      top: 100%;
    `}
  ${({ position }) =>
    position === 'left-top' &&
    css`
      left: 100%;
      top: 0;
    `}
  @media(min-width: 576px) {
    display: block;
  }
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    span {
      text-align: left;
      font-size: 14px;
      color: ${theme.palette.gray4};
      opacity: 1;
      font-weight: 600;
    }
    button {
      font-size: 14px;
      color: ${theme.colors.blue3};
      opacity: 1;
      background: transparent;
      border: 0px solid;
      cursor: pointer;
    }
  }
  .list-pages {
    padding: 0 6px;
    button {
      width: 100%;
      padding: 10px;
      background: transparent;
      border: 0px solid;
      text-align: left;
      font-size: 14px;
      color: ${theme.palette.gray4};
      transition: all 0.2s linear;
      cursor: pointer;
      &:hover {
        background: ${theme.palette.button.hover1};
      }
    }
  }
  .new-page {
    padding: 6px;
    margin-top: 6px;
    border-top: 1px solid ${theme.palette.gray5};
    button {
      width: 100%;
      padding: 10px;
      background: transparent;
      border: 0px solid;
      text-align: left;

      font-size: 14px;
      color: ${theme.palette.gray4};
      transition: all 0.2s linear;
      cursor: pointer;
      &:hover {
        background: ${theme.palette.button.hover1};
      }
    }
  }
`

export const ContainerMobile = styled.div`
  display: block;
  @media (min-width: 576px) {
    display: none;
  }
  button.close {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    background: rgba(0, 0, 0, 0.3);
    border: 0px solid;
  }
  .content {
    position: fixed;
    bottom: 0;
    left: 0;
    border-radius: 4px 4px 0 0;
    width: 100%;
    padding: 10px;
    background: ${theme.palette.white};
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
    z-index: 1000;
    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px;
      span {
        text-align: left;

        font-size: 14px;
        color: ${theme.palette.gray4};
        opacity: 1;
        font-weight: 600;
      }
      button {
        font-size: 14px;
        color: ${theme.colors.blue3};
        opacity: 1;
        background: transparent;
        border: 0px solid;
        cursor: pointer;
      }
    }
    .list-pages {
      padding: 6px;
      button {
        width: 100%;
        padding: 10px;
        background: transparent;
        border: 0px solid;
        text-align: left;

        font-size: 14px;
        color: ${theme.palette.gray4};
        transition: all 0.2s linear;
        cursor: pointer;
        &:hover {
          background: ${theme.palette.button.hover1};
        }
      }
    }
    .new-page {
      margin-top: 10px;
      padding: 10px;
      border-top: 1px solid ${theme.palette.gray5};
      button {
        width: 100%;
        padding: 10px;
        background: transparent;
        border: 0px solid;
        text-align: left;

        font-size: 14px;
        color: ${theme.palette.gray4};
        transition: all 0.2s linear;
        cursor: pointer;
        &:hover {
          background: ${theme.palette.button.hover1};
        }
      }
    }
  }
`
