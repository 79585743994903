import { theme } from '@cockpit/levi-ui/theme'
import styled from '@emotion/styled'
import {
  FormControl as FormControlMUI,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem as MenuItemMUI,
} from '@mui/material'
import { getColors } from 'helpers'

export const SelectStyled = styled(Select)`
  --color: ${getColors('neutral', 30)};
  --primary-color: ${({ theme: themeM }) => themeM.palette.primary.main};
  --error-color: ${({ theme: themeM }) => themeM.palette.error.main};
  --v-spacing: ${theme.spacings[2]};
  --h-spacing: ${theme.spacings[4]};
  border: 1px solid var(--color);
  border-radius: 0.25rem;
  transition: border-color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  width: 100%;
  color: ${getColors('neutral', 50)};
  background: ${getColors('neutral', 10)};
  .MuiSelect-select {
    padding-top: 0;
    padding-bottom: 0;
    display: flex;
    align-items: center;
    font-size: ${theme.font.size[2]};
    color: ${getColors('neutral', 80)};
  }
  &.with-placeholder .MuiSelect-select {
    color: ${getColors('neutral', 50)};
  }
  &.Mui-disabled {
    background: ${getColors('neutral', 20)};
    .MuiSelect-select {
      color: ${getColors('neutral', 80)};
      -webkit-text-fill-color: ${getColors('neutral', 80)};
    }
  }
  &:hover {
    border-color: ${getColors('neutral')};
  }
  &.Mui-focused:not(.Mui-error) {
    &.MuiInputBase-colorPrimary {
      border-color: var(--primary-color);
    }
  }
  &.Mui-error {
    border-color: var(--error-color);
    .MuiSelect-select {
      color: var(--error-color);
    }
  }

  &.select-standard {
    height: 2.5rem;
    .MuiSelect-select {
      height: calc(2.5rem - 2px);
    }
  }
  &.select-condensed {
    height: 2rem;
    .MuiSelect-select {
      height: calc(2rem - 2px);
    }
  }

  .MuiSelect-select,
  fieldset {
    border: 0;
  }

  input + div {
    pointer-events: none;
  }
`

export const Label = styled(InputLabel)`
  transform: none;
  position: relative;
  font-size: ${theme.font.size[2]};
  margin-bottom: ${theme.spacings[1]};
  font-weight: 600;
  color: ${getColors('neutral', 60)};
`

export const HelperText = styled(FormHelperText)`
  font-size: ${theme.font.size[2]};
  position: absolute;
  left: 0;
  bottom: -24px;
  top: initial;
`

export const Adornment = styled.span`
  font-size: ${theme.font.size[2]};
`

export const FormControl = styled(FormControlMUI)`
  position: relative;
  .MuiPopover-root {
    width: 100%;
    position: absolute;
    margin: 0;
  }
  .MuiPaper-root {
    top: calc(100% + 8px) !important;
    left: initial !important;
    max-height: 175px !important;
  }
  .MuiMenuItem-root {
    white-space: break-spaces;
  }
`

export const Option = styled(MenuItemMUI)`
  font-size: ${theme.font.size[2]};
  margin: 0 ${theme.spacings[2]};
  width: calc(100% - 1rem);
  border-radius: 0.25rem;
  &:hover {
    background-color: ${getColors('neutral', 20)};
  }
`

export const IconContainer = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 0 ${theme.spacings[3]};
  position: absolute;
  right: 0;
`
