import styled from 'styled-components'
import { theme } from '../../../Theme'

interface ButtonProps {
  direction: 'forward' | 'backward'
  notAvailable: boolean
}

const { gray6, gray4 } = theme.colors

export const ButtonStyle = styled.a<ButtonProps>`
  display: ${({ notAvailable }) => (notAvailable ? 'none' : 'inline-block')};
  width: 30px;
  height: 30px;
  cursor: pointer !important;

  & div {
    display: ${({ notAvailable }) => (notAvailable ? 'none' : 'inline-block')};
    position: relative;
    width: 30px;
    height: 30px;
    border: 1px solid ${gray6};
    border-radius: 4px;
    text-align: center;
    cursor: pointer;
    background: transparent;
  }

  & svg {
    position: absolute;
    top: 50%;
    right: 50%;
    height: 6px;

    fill: ${gray4};
    margin-top: -3px;
    margin-right: -5px;
    transform: ${({ direction }) =>
      direction === 'backward' ? 'rotate(90deg)' : 'rotate(-90deg)'};
  }
`
