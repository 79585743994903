import React from 'react'

const SvgChevronOpen = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='12'
    height='12'
    viewBox='0 0 12 12'
    {...props}
  >
    <g
      id='chevron_up_down'
      data-name='chevron_up_down'
      transform='translate(0.182)'
    >
      <rect
        id='Retângulo_4632'
        data-name='Retângulo 4632'
        width='12'
        height='12'
        transform='translate(-0.182)'
        fill='none'
      />
      <g
        id='Iconly_Light_Arrow_-_Right_2'
        data-name='Iconly/Light/Arrow - Right 2'
        transform='translate(2.926 4.037) rotate(-90)'
      >
        <g
          id='Arrow_-_Right_2'
          data-name='Arrow - Right 2'
          transform='translate(0 5.852) rotate(-90)'
        >
          <path
            id='Stroke_1'
            data-name='Stroke 1'
            d='M5.852,0,2.926,2.926,0,0'
            fill='none'
            stroke='#a8abb1'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='1'
          />
        </g>
      </g>
      <g
        id='Iconly_Light_Arrow_-_Right_2-2'
        data-name='Iconly/Light/Arrow - Right 2'
        transform='translate(8.778 7.963) rotate(90)'
      >
        <g
          id='Arrow_-_Right_2-2'
          data-name='Arrow - Right 2'
          transform='translate(0 5.852) rotate(-90)'
        >
          <path
            id='Stroke_1-2'
            data-name='Stroke 1'
            d='M5.852,0,2.926,2.926,0,0'
            fill='none'
            stroke='#a8abb1'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='1'
          />
        </g>
      </g>
    </g>
  </svg>
)

export default SvgChevronOpen
