import React from 'react'
import { SvgProps } from './typings/SvgProps'

const ChevronDownCircle: React.FC<SvgProps> = ({ color = 'currentColor', size, ...rest }) => (
  <svg width={size} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <g>
      <path
        d="M0,9.25A9.25,9.25,0,1,0,9.25,0,9.25,9.25,0,0,0,0,9.25Z"
        transform="translate(0.75 0.75)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
      />
      <path
        d="M0,0,3.471,3.486,6.942,0"
        transform="translate(6.529 8.558)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
      />
    </g>
  </svg>
)

export default ChevronDownCircle
