import React from 'react'
import ThemeProvider from '../ThemeProvider'

export const withTheme = (
  // TODO: Ajustar tipagem
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  OriginalComponent: React.FC<any | string>
  // TODO: Ajustar tipagem
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): React.FC<any | string> => {
  // TODO: Ajustar tipagem
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (props: any) => {
    if (props.theme) {
      return <OriginalComponent {...props} />
    } else {
      return (
        <ThemeProvider>
          <OriginalComponent {...props} />
        </ThemeProvider>
      )
    }
  }
}
