import React from 'react';

const SvgHamburguer = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='currentColor'
      width={18}
      height={14.001}
      viewBox='0 0 18 14'
      {...props}
    >
      <path d='M1 14a1 1 0 01-1-1 1 1 0 011-1h9a1 1 0 011 1 1 1 0 01-1 1zm0-6a1 1 0 01-1-1 1 1 0 011-1h16a1 1 0 011 1 1 1 0 01-1 1zm0-6a1 1 0 01-1-1 1 1 0 011-1h16a1 1 0 011 1 1 1 0 01-1 1z' />
      <path d='M10 14.001H1a1 1 0 01-1-1 1 1 0 011-1h9a1 1 0 011 1 1 1 0 01-1 1zm7-6H1a1 1 0 01-1-1 1 1 0 011-1h16a1 1 0 011 1A1 1 0 0117 8zm0-6H1a1 1 0 01-1-1 1 1 0 011-1h16a1 1 0 011 1A1 1 0 0117 2z' />
    </svg>
  )
}

export default SvgHamburguer
