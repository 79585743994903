import React from 'react';

const SvgCornerUpRight = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={21} height={21} viewBox='0 0 21 21' {...props}>
      <path
        data-name='Path 5247'
        d='M17.415 9.139l-3.522-4.4a.88.88 0 00-1.374 1.092l2.378 2.973H7.043a2.641 2.641 0 00-2.641 2.642v4.4a.88.88 0 001.761 0v-4.4a.88.88 0 01.88-.88h7.854l-2.377 2.976a.878.878 0 101.374 1.092l3.522-4.4a.88.88 0 000-1.092z'
        fill='currentColor'
      />
    </svg>
  )
}

export default SvgCornerUpRight
