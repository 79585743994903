import React from 'react';

const SvgCheckdisable = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      data-name='Componente 1192 \u2013 38'
      xmlns='http://www.w3.org/2000/svg'
      width={40}
      height={40}
      {...props}
    >
      <rect
        data-name='Ret\xE2ngulo 4305'
        width={40}
        height={40}
        rx={20}
        fill='#d8dbe5'
      />
      <path
        data-name='Stroke 3'
        d='M26.967 14.299a1.565 1.565 0 012.365 2.042l-.152.176-9.9 9.9a1.566 1.566 0 01-2.038.152l-.176-.151-4.958-4.952a1.565 1.565 0 012.041-2.366l.176.152 3.842 3.844z'
        fill='#fff'
      />
    </svg>
  )
}

export default SvgCheckdisable
