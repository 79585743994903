import React from 'react'
import { SvgProps } from './typings/SvgProps'

const JumpOut: React.FC<SvgProps> = ({ color = 'currentColor', size, ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill={color} width={size} {...rest} viewBox="0 0 14 15">
    <g transform="translate(0.5 0.706)">
      <path
        d="M15.285,11.733v4.914a1.638,1.638,0,0,1-1.638,1.638H4.638A1.638,1.638,0,0,1,3,16.647V7.638A1.638,1.638,0,0,1,4.638,6H9.552"
        transform="translate(-3 -4.488)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1}
      />
      <g transform="translate(13.642 7.841) rotate(180)">
        <g transform="translate(0 0)">
          <path
            d="M0,0H4.914V4.914"
            transform="translate(4.914 7.84) rotate(180)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1}
          />
          <path
            d="M0,7.84,7.686,0"
            transform="translate(7.719 7.84) rotate(180)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeWidth={1}
          />
        </g>
      </g>
    </g>
  </svg>
)

export default JumpOut
