import { createTheme } from '@mui/material/styles'
import { ITheme } from './typing'

const blue = {
  10: '#EBF3FF',
  20: '#C2DAFF',
  30: '#5C9DFF',
  40: '#1C7CF9',
  50: '#1860CC',
  60: '#063D8F',
  70: '#071D3D',
}

const green = {
  10: '#EBF5EF',
  20: '#B2EBCE',
  30: '#7ACC96',
  40: '#12893A',
  50: '#146630',
  60: '#073D24',
  70: '#042916',
}

const red = {
  10: '#FFECEB',
  20: '#FFCAC2',
  30: '#F57E6C',
  40: '#D93B2A',
  50: '#B82116',
  60: '#8F1A11',
  70: '#3D020A',
}

const yellow = {
  10: '#FFF4D6',
  20: '#FFE9AD',
  30: '#F5D06C',
  40: '#E0A82D',
  50: '#B88216',
  60: '#8F6106',
  70: '#3D2902',
}

const orange = {
  10: '#FAEEE6',
  20: '#F5C7A6',
  30: '#F59958',
  40: '#E0782D',
  50: '#CC6018',
  60: '#A34D14',
  70: '#52270A',
}

const neutral = {
  10: '#FFFFFF',
  20: '#F6F7F9',
  30: '#DEE1E8',
  40: '#C7CAD1',
  50: '#969BA7',
  60: '#7A7F8B',
  70: '#4F5460',
  80: '#2D3139',
}

export const theme: ITheme = {
  colors: {
    primary: blue,
    blue,
    green,
    red,
    yellow,
    orange,
    neutral,
  },
  font: {
    family: 'Inter,sans-serif',
    baseSize: '16px',
    size: {
      0: '0.625rem',
      1: '0.75rem',
      2: '0.875rem',
      3: '1rem',
      4: '1.125rem',
      5: '1.25rem',
      6: '1.5rem',
      7: '2rem',
      8: '3rem',
      9: '4rem',
      10: '5.5rem',
    },
    weight: {
      1: '100',
      2: '200',
      3: '300',
      4: '400',
      5: '500',
      6: '600',
      7: '700',
      8: '800',
      9: '900',
    },
  },
  spacings: {
    0: '0rem',
    1: '0.25rem',
    2: '0.5rem',
    3: '0.75rem',
    4: '1rem',
    5: '1.5rem',
    6: '2rem',
    7: '2.5rem',
    8: '3rem',
    9: '3.5rem',
    10: '4rem',
    11: '4.5rem',
    12: '5rem',
    13: '5.5rem',
    14: '6rem',
    15: '6.5rem',
    16: '9rem',
    17: '12rem',
    18: '16rem',
    19: '32rem',
  },
}

export const muiTheme = createTheme({
  palette: {
    primary: {
      light: theme.colors.blue[30],
      main: theme.colors.blue[40],
      dark: theme.colors.blue[50],
    },
    error: {
      light: theme.colors.red[30],
      main: theme.colors.red[40],
      dark: theme.colors.red[50],
    },
    warning: {
      light: theme.colors.yellow[30],
      main: theme.colors.yellow[40],
      dark: theme.colors.yellow[50],
    },
    success: {
      light: theme.colors.green[30],
      main: theme.colors.green[40],
      dark: theme.colors.green[50],
    },
    info: {
      main: theme.colors.neutral[80],
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  typography: {
    fontFamily: [theme.font.family].join(','),
    fontSize: parseInt(theme.font.baseSize),
  },
})
