import React from 'react'

const SvgLogoCockpit = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={163}
      height={29}
      viewBox='0 0 163 29'
      {...props}
    >
      <g
        id='Grupo_161681'
        data-name='Grupo 161681'
        transform='translate(-697.858 -256.904)'
      >
        <g
          id='Grupo_10800'
          data-name='Grupo 10800'
          transform='translate(697.858 258.139)'
        >
          <path
            id='Caminho_13464'
            data-name='Caminho 13464'
            d='M708.514,267.488h4.113c-.422-3.415-3.326-5.788-7.118-5.788-4.432,0-7.651,3.067-7.651,7.293,0,4.242,3.219,7.322,7.651,7.322,3.792,0,6.7-2.371,7.118-5.789h-4.113a2.919,2.919,0,0,1-3.005,2.17,3.461,3.461,0,0,1-3.525-3.7,3.409,3.409,0,0,1,3.525-3.674A2.919,2.919,0,0,1,708.514,267.488Z'
            transform='translate(-697.858 -255.173)'
            fill='currentColor'
          />
          <path
            id='Caminho_13465'
            data-name='Caminho 13465'
            d='M715.752,261.7c-4.4,0-7.589,3.067-7.589,7.293a7.591,7.591,0,0,0,15.18,0C723.343,264.767,720.149,261.7,715.752,261.7Zm.031,11.027a3.486,3.486,0,0,1-3.5-3.7,3.487,3.487,0,1,1,3.5,3.7Z'
            transform='translate(-691.484 -255.173)'
            fill='currentColor'
          />
          <path
            id='Caminho_13466'
            data-name='Caminho 13466'
            d='M729.342,267.488h4.113c-.424-3.417-3.328-5.788-7.12-5.788-4.431,0-7.651,3.067-7.651,7.293,0,4.242,3.219,7.322,7.651,7.322,3.792,0,6.7-2.371,7.12-5.789h-4.113a2.922,2.922,0,0,1-3.007,2.17,3.461,3.461,0,0,1-3.525-3.7,3.409,3.409,0,0,1,3.525-3.674A2.922,2.922,0,0,1,729.342,267.488Z'
            transform='translate(-684.977 -255.173)'
            fill='currentColor'
          />
          <rect
            id='Retângulo_4081'
            data-name='Retângulo 4081'
            width='4.28'
            height='13.881'
            transform='translate(88.091 6.936)'
            fill='currentColor'
          />
          <path
            id='Caminho_13467'
            data-name='Caminho 13467'
            d='M739.357,269.222l5.053-5.437H739.4L734,269.608V258.922h-4.28v18.744H734v-2.66l2.319-2.493.188.275,3.368,4.878h5.115l-5.774-8.286Z'
            transform='translate(-678.154 -256.891)'
            fill='currentColor'
          />
          <path
            id='Caminho_13468'
            data-name='Caminho 13468'
            d='M749.33,261.725a6.964,6.964,0,0,0-3.81,1.057l-.409.265v-1h-4.283V282.62h4.283v-7.711l.409.264a7.187,7.187,0,1,0,3.81-13.448Zm-.685,11.027a3.524,3.524,0,0,1-3.535-3.744,3.528,3.528,0,1,1,3.535,3.744Z'
            transform='translate(-671.282 -255.157)'
            fill='currentColor'
          />
          <path
            id='Caminho_13469'
            data-name='Caminho 13469'
            d='M764.005,271.748v-5.025h3.625v-3.43h-3.755v-4.371H763.2l-6.547,6.971v.83h2.737v5.909c0,3.177,1.887,5.076,5.048,5.076h3.276v-3.977h-1.709A1.866,1.866,0,0,1,764.005,271.748Z'
            transform='translate(-661.494 -256.891)'
            fill='currentColor'
          />
          <path
            id='Caminho_13470'
            data-name='Caminho 13470'
            d='M754.559,257.667a2.488,2.488,0,1,0,2.489,2.489A2.492,2.492,0,0,0,754.559,257.667Z'
            transform='translate(-664.328 -257.667)'
            fill='currentColor'
          />
        </g>
        <g
          id='Grupo_10801'
          data-name='Grupo 10801'
          transform='translate(831.498 256.904)'
        >
          <path
            id='Caminho_6287'
            data-name='Caminho 6287'
            d='M796.547,263.212a3.559,3.559,0,0,0-2.651-1.3l-.206-.039a34.161,34.161,0,0,1,2.616,4.433c.073-.186.147-.333.184-.445a3.008,3.008,0,0,0,.055-2.653'
            transform='translate(-772.228 -253.828)'
            fill='currentColor'
          />
          <g id='Grupo_10767' data-name='Grupo 10767'>
            <path
              id='Caminho_6288'
              data-name='Caminho 6288'
              d='M788.942,270.387c.482,1.261.984,1.892,1.6,2.022.761.149,1.759-.469,3.245-2.022a12.843,12.843,0,0,0,.965-1.131,59.873,59.873,0,0,0-6.064.5c.055.112.259.63.259.63'
              transform='translate(-775.324 -249.264)'
              fill='currentColor'
            />
            <path
              id='Caminho_6289'
              data-name='Caminho 6289'
              d='M786.748,268.087a30.805,30.805,0,0,1,2.5,4.766c.222-.037.443-.055.667-.092a41.326,41.326,0,0,1,7.2-.408,49.082,49.082,0,0,0,3.169-5.249,31.353,31.353,0,0,0-3.451-5.229,37.035,37.035,0,0,0-6.843.092,27.585,27.585,0,0,1-1.633,3.286q-.913,1.588-1.609,2.834'
              transform='translate(-776.521 -253.9)'
              fill='currentColor'
            />
            <path
              id='Caminho_6290'
              data-name='Caminho 6290'
              d='M804.7,261.094a14.333,14.333,0,0,0-23.937,6.194,41.135,41.135,0,0,1,11.48-3.3c.3-.947.52-1.837.7-2.615.388-1.631.667-2.8,1.427-3.022.822-.223,2.112.667,4.47,3.077h0l1.8,2.112a21.724,21.724,0,0,1,2.726.353l.094.019a4.257,4.257,0,0,1,2.967,1.612,2.927,2.927,0,0,1,0,2.505c-.408,1.149-.853,2.169-1.316,3.167a27.362,27.362,0,0,1,1.02,3,.992.992,0,0,0,.037.206.626.626,0,0,1-.167.482l-.073.092-.039-.112a25.586,25.586,0,0,0-1.076-3.041,25.541,25.541,0,0,1-2.151,3.578,25.607,25.607,0,0,1,5.281.89,14.556,14.556,0,0,0,.947-5.1,14.3,14.3,0,0,0-4.192-10.1'
              transform='translate(-780.22 -256.904)'
              fill='currentColor'
            />
            <path
              id='Caminho_6291'
              data-name='Caminho 6291'
              d='M785.944,269.831c.333.5,1.28.649,3.061.469a33.3,33.3,0,0,0-2-4.253c-.945,1.8-1.483,3.135-1.055,3.784'
              transform='translate(-777.109 -251.249)'
              fill='currentColor'
            />
            <path
              id='Caminho_6292'
              data-name='Caminho 6292'
              d='M794.311,262.014c-1.706-2.021-2.69-2.8-3.375-2.688-.7.11-1.173,1.2-1.855,2.874l-.019.055a40.042,40.042,0,0,1,5.249-.241'
              transform='translate(-775.09 -255.412)'
              fill='currentColor'
            />
            <path
              id='Caminho_6293'
              data-name='Caminho 6293'
              d='M801.781,273.733c-.833,1.039-1.8,2.112-3.022,3.376-1.78,1.835-2.764,2.67-3.561,2.463-.557-.147-.965-.779-1.317-2.1-.316-1.092-.63-2.151-.982-3.133l-1.3.185c-2.934.426-4.562.186-5.137-.7-.822-1.3.7-3.821,2.8-6.992-.469-.686-.963-1.335-1.483-1.984a1,1,0,0,1-.13-.167l-.074-.092.112-.018a.679.679,0,0,1,.5.165q.7.753,1.372,1.614l.2-.316.945-1.428a15.492,15.492,0,0,0,1.34-2.593c-5.36.821-9.2,2.463-10.831,4.576a3.43,3.43,0,0,0-.7,2.949,14.572,14.572,0,0,0,4.155,8.717,14.12,14.12,0,0,0,10.107,4.155,14.351,14.351,0,0,0,10.884-5c.649-.872.853-1.614.576-2.188-.409-.872-1.912-1.372-4.452-1.484'
              transform='translate(-780.429 -253.743)'
              fill='currentColor'
            />
          </g>
        </g>
        <rect
          id='Retângulo_4082'
          data-name='Retângulo 4082'
          width='0.796'
          height='21.321'
          rx='0.135'
          transform='translate(818.048 260.577)'
          fill='currentColor'
        />
      </g>
    </svg>
  )
}

export default SvgLogoCockpit
