import { theme } from '@cockpit/levi-ui/theme'
import styled from '@emotion/styled'
import { getColors } from 'helpers'

export const Container = styled.div`
  display: flex;
  background: ${getColors('neutral', 10)};
  padding: ${theme.spacings[2]};
  border-bottom: 1px solid ${getColors('neutral', 30)};
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
`
