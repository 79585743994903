import React, { PropsWithChildren } from 'react'
import { Box, Divider, List, ListItemButton, ListItemText, Drawer } from '@mui/material'
import { Header, Title, ListItemIcon, ListItem } from './styles'
import { IMenuDropdown } from './typing'

const CalendarMenuMobile: React.FC<PropsWithChildren<IMenuDropdown>> = ({
  options,
  title,
  children,
  ...rest
}) => (
  <Drawer
    anchor="bottom"
    PaperProps={{
      sx: {
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        maxWidth: '100vw',
        overflow: 'hidden',
      },
    }}
    {...rest}
  >
    <Box role="presentation">
      {title && (
        <>
          <Header>
            <Title>{title}</Title>
          </Header>
          <Divider />
        </>
      )}
    </Box>
    {children || (
      <List>
        {options.map(item => (
          <ListItem key={item.text} disablePadding>
            <ListItemButton disabled={item.disabled ?? false} onClick={item.onClick}>
              {item.startIcon && <ListItemIcon>{item.startIcon}</ListItemIcon>}
              <ListItemText sx={{ fontSize: 16 }} primary={item.text} />
              {item.endIcon && <ListItemIcon>{item.endIcon}</ListItemIcon>}
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    )}
  </Drawer>
)

export default CalendarMenuMobile
