import React from 'react';

const SvgTimeRounded = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='currentColor'
      width={21}
      height={21}
      viewBox='0 0 21 21'
      {...props}
    >
      <path d='M14.492 13.8l-3.449-3.449V4.294a.68.68 0 10-1.36 0v6.343a.676.676 0 00.052.26.667.667 0 00.147.221l3.648 3.648a.68.68 0 00.962-.962z' />
      <path d='M12.702.243c-.006 0-.009 0-.014-.007l-.051-.011-.044-.009a9.611 9.611 0 00-2.078-.217h-.014a8.2 8.2 0 00-.938.053.555.555 0 00.148 1.1 7.036 7.036 0 01.818-.043 9.06 9.06 0 011.819.191c.023 0 .045.008.067.013a9.626 9.626 0 017.481 9.365 9.4 9.4 0 01-18.785.1 3.883 3.883 0 01.017-.4.553.553 0 10-1.1-.122 4.18 4.18 0 00-.017.242c0 .059-.009.117-.009.176v.106c0 .327.018.629.021.679a.49.49 0 00.017.064 10.5 10.5 0 0020.957-.848A10.733 10.733 0 0012.702.243z' />
      <g>
        <path d='M.806 8.16a.575.575 0 01.433.629.563.563 0 01-.619.452.532.532 0 01-.454-.633.524.524 0 01.64-.448zM1.532 6.096a.548.548 0 11-.735.288.547.547 0 01.735-.288zM2.694 4.241a.557.557 0 01.115.755.567.567 0 01-.747.141.53.53 0 01-.14-.772.538.538 0 01.772-.124zM4.241 2.694a.576.576 0 01-.053.763.569.569 0 01-.769-.036.529.529 0 01.044-.775.522.522 0 01.778.048zM6.096 1.532a.548.548 0 11-.754-.231.549.549 0 01.754.231zM8.16.809a.554.554 0 01-.381.66.565.565 0 01-.672-.354.531.531 0 01.37-.692.54.54 0 01.683.386z' />
      </g>
    </svg>
  )
}

export default SvgTimeRounded
