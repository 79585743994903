import { getColors } from '@cockpit/levi-ui/helpers'
import { theme } from '@cockpit/levi-ui/theme'
import styled from '@emotion/styled'
import { Dialog as MuiDialog } from '@mui/material'

export const Dialog = styled(MuiDialog)`
  .MuiPaper-root {
    &.MuiDialog-paperWidthSm {
      max-width: 460px;
    }
    &.MuiDialog-paperWidthMd {
      max-width: 768px;
    }
    &.MuiDialog-paperWidthLg {
      max-width: 1024px;
    }
    &.MuiDialog-paperFullScreen {
      max-width: 100vw;
    }
    .MuiDialogTitle-root {
      font-size: ${theme.font.size[5]};
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid ${getColors('neutral', 30)};
      padding-top: 0;
      padding-bottom: 0;
      position: relative;
      z-index: 1000001;
      background: ${getColors('neutral', 10)};
      & > .levi-modal-title-string {
        font-weight: 600;
      }
      & > .MuiIconButton-root {
        margin-top: ${theme.spacings[4]};
        margin-bottom: ${theme.spacings[4]};
      }
    }
    .MuiDialogContent-root {
      padding: ${theme.spacings[5]};
    }
    .MuiDialogActions-root {
      padding: ${theme.spacings[5]};
      border-top: 1px solid ${getColors('neutral', 30)};
      position: relative;
      z-index: 1000001;
      background: ${getColors('neutral', 10)};
    }
  }
`
