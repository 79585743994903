import React from 'react'

const SvgInterrogation = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={17.5}
      height={17.5}
      {...props}
    >
      <g
        data-name='Grupo 10983'
        fill='none'
        stroke='#8391b1'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={1.5}
      >
        <path
          data-name='Stroke 1'
          d='M8.75.75a8 8 0 11-8 8 8 8 0 018-8z'
          strokeMiterlimit={10}
        />
        <path
          data-name='Caminho 5065'
          d='M6.871 6.411a2.033 2.033 0 013.951.678c0 1.355-2.033 2.033-2.033 2.033'
        />
        <path data-name='Linha 2' d='M8.746 12.181h.007' />
        <path
          data-name='Stroke 5'
          d='M8.746 9.239v.735'
          strokeMiterlimit={10}
        />
      </g>
    </svg>
  )
}

export default SvgInterrogation
