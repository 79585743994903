import { ContentEditable } from '@lexical/react/LexicalContentEditable'
import styled from '@emotion/styled'
import { getColors } from 'helpers'
import { theme } from '@cockpit/levi-ui/theme'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  line-height: ${theme.font.size[5]};
  font-size: ${theme.font.size[2]};
  border: 1px solid ${getColors('neutral', 30)};
  border-radius: ${theme.spacings[1]};
`

export const Content = styled.div`
  background: ${getColors('neutral', 10)};
  position: relative;
`

export const PlaceHolderText = styled.div`
  color: #999;
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  top: 15px;
  left: 10px;
  font-size: ${theme.font.size[2]};
  user-select: none;
  display: inline-block;
  pointer-events: none;
`

export const ContentEditableContainer = styled(ContentEditable)`
  resize: none;
  position: relative;
  tab-size: 1;
  outline: 0;
  padding: ${theme.spacings[4]} ${theme.spacings[2]};
  transition: all 250ms;
  max-height: 300px;
  min-height: 100px;
  overflow-y: auto;

  ul {
    list-style: initial !important;
  }

  ul > ul {
    list-style: lower-alpha !important;
  }

  ol {
    list-style: decimal !important;
  }

  .editor-text-bold {
    font-weight: bold;
  }

  .editor-text-italic {
    font-style: italic;
  }

  .editor-text-underline {
    text-decoration: underline;
  }

  .editor-paragraph {
    margin: 0;
    margin-bottom: ${theme.spacings[2]};
    position: relative;
  }

  .editor-paragraph:last-child {
    margin-bottom: 0;
  }

  .editor-list-ol {
    padding: 0;
    margin: 0;
    margin-left: ${theme.spacings[4]};
  }

  .editor-list-ul {
    padding: 0;
    margin: 0;
    margin-left: ${theme.spacings[4]};
  }

  .editor-listitem {
    margin: ${theme.spacings[2]} ${theme.spacings[6]} ${theme.spacings[2]} ${theme.spacings[6]};
  }

  .editor-nested-listitem {
    list-style-type: none;
  }

  pre::-webkit-scrollbar {
    background: transparent;
    width: 10px;
  }

  pre::-webkit-scrollbar-thumb {
    background: #999;
  }
`
