import React from 'react'
import { SvgProps } from './typings/SvgProps'

const CalendarWeek: React.FC<SvgProps> = ({ color = 'currentColor', size, ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} {...rest} viewBox="0 0 16.299 17.368">
    <g transform="translate(0.15 0.15)">
      <path
        d="M11511.355-38.732a2.369,2.369,0,0,1-2.354-2.386V-51.866a2.369,2.369,0,0,1,2.354-2.386h1.9v-.92a.611.611,0,0,1,.619-.628.612.612,0,0,1,.619.628v.92h4.963v-.92a.611.611,0,0,1,.619-.628.612.612,0,0,1,.619.628v.92h1.9a2.441,2.441,0,0,1,2.4,2.427v10.708a2.369,2.369,0,0,1-2.357,2.386Zm-1.157-6.109v3.934a1.029,1.029,0,0,0,1.033,1.044h11.535a1.028,1.028,0,0,0,1.032-1.044v-7.908h-13.6Zm-.164-7.11V-49.9h13.641V-51.95a1.029,1.029,0,0,0-1.033-1.047h-2.024v1a.613.613,0,0,1-.619.628.613.613,0,0,1-.622-.628v-1h-4.92v1a.612.612,0,0,1-.619.628.613.613,0,0,1-.621-.628v-1h-2.025A1.234,1.234,0,0,0,11510.034-51.95Zm9.88,9.956v-4.729a.549.549,0,0,1,.535-.544.552.552,0,0,1,.538.544v4.729a.552.552,0,0,1-.538.544A.549.549,0,0,1,11519.914-41.995Zm-3.266,0v-4.729a.549.549,0,0,1,.535-.544.55.55,0,0,1,.538.544v4.729a.55.55,0,0,1-.538.544A.549.549,0,0,1,11516.648-41.995Zm-3.269,0v-4.729a.552.552,0,0,1,.538-.544.551.551,0,0,1,.538.544v4.729a.551.551,0,0,1-.538.544A.552.552,0,0,1,11513.38-41.995Z"
        transform="translate(-11509.001 55.8)"
        fill={color}
        stroke={color}
        strokeWidth="0.3"
      />
    </g>
  </svg>
)

export default CalendarWeek
