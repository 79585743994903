import React from 'react'

const iconMicroDesactive = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={16}
    height={19}
    viewBox='0 0 16 19'
    fill='currentColor'
    {...props}
  >
    <defs>
      <clipPath>
        <rect
          width={16}
          height={19}
          fill='none'
          stroke='currentColor'
          strokeWidth={1}
        />
      </clipPath>
    </defs>
    <g clipPath='url(#clip-path)'>
      <path
        d='M7.036,16.217a4.732,4.732,0,0,0,1.115.133,7.382,7.382,0,0,0,7.382-7.382'
        transform='translate(-0.021 -0.027)'
        fill='none'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={1.5}
      />
      <line
        y1='2.802'
        transform='translate(8.13 16.324)'
        fill='none'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={1.5}
      />
      <path
        d='M8.28.75A3.651,3.651,0,0,1,11.929,4.4v.878'
        transform='translate(-0.024 -0.002)'
        fill='none'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={1.5}
      />
      <path
        d='M4.368,5.278V4.4A3.65,3.65,0,0,1,8.017.75h.251'
        transform='translate(-0.013 -0.002)'
        fill='none'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={1.5}
      />
      <path
        d='M5.78,11.616A2.894,2.894,0,0,1,4.368,8.949V5.291'
        transform='translate(-0.013 -0.016)'
        fill='none'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={1.5}
      />
      <line
        y1='17.193'
        x2='13.905'
        transform='translate(0.845 1.743)'
        fill='none'
        stroke='currentColor'
        strokeLinecap='round'
        strokeWidth={1.5}
      />
      <path
        d='M11.934,9.244A3.654,3.654,0,0,1,9.756,12.3'
        transform='translate(-0.029 -0.027)'
        fill='none'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={1.5}
      />
      <path
        d='M.75,8.968a7.376,7.376,0,0,0,3.238,6.111'
        transform='translate(-0.002 -0.027)'
        fill='none'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={1.5}
      />
    </g>
  </svg>
)

export default iconMicroDesactive
