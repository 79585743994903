import styled from 'styled-components'
import { theme } from '../Theme'

export const AuthenticatorHeader = styled.div`
  text-align: center;
  font: normal normal 600 16px/24px Inter;
  letter-spacing: 0px;
  color: #2e384d;
  opacity: 1;
`

export const AuthenticatorSubTitle = styled.div`
  text-align: center;
  margin-bottom: 32px;
  font: normal normal 600 16px/24px Inter;
  letter-spacing: 0px;
  color: #2e384d;
  opacity: 1;
`

export const AuthenticatorContent = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  text-align: center;
  width: 100%;
`

export const AuthenticatorFooter = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
  margin-top: 24px;
  text-align: center;
  width: 80%;
`

export const ErrorMessage = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ff1a1e;
  filter: opacity(0.8);
  color: #fff;
  padding: 6px;
  border-radius: 3px;
  margin-top: 12px;
  width: 80%;
  min-width: 200px;
  height: 44px;
  text-align: center;
`

export const AuthenticatorContainer = styled.div`
  margin: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  @media (max-width: 414px) {
    height: 80vh;
  }

  .container {
    height: 45px;
    width: 300px;
    display: flex;
    column-gap: 8px;
  }

  @media screen and (max-width: 320px) {
    .container {
      width: 255px;
    }
  }
  .character {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #dfe3e8;
    border-radius: 4px;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font: normal normal 600 16px/15px Inter;
    letter-spacing: 0px;
    color: #2e384d;
    opacity: 1;
  }

  .character:first-child {
    margin-left: 0;
    border: 1px solid #dfe3e8;
  }

  .character--inactive {
    border: 1px solid #dfe3e8 !important;
    background: #ffffff 0% 0% no-repeat padding-box;
  }

  .character--selected {
    border: 1px solid #378ff7 !important;
  }
`

export const AuthenticatorInput = styled.input`
  border: 1px solid #dfe3e8;
  border-radius: 4px;
  width: 43px;
  height: 45px;
  text-align: center;
  font-size: 16px;
  color: #2e384d;
  transition: border 0.2s ease-in;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }

  &:focus {
    outline: none;
    border: 1px solid #378ff7;
  }
`

export const AuthenticatorInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
`

export const ContainerStyle = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 85vh;
  max-height: 100%;
  background-color: ${theme.colors.gray2};
  box-sizing: border-box;
  padding: 50px;
`
