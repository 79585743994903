import React from 'react'

const SvgPrescriptionIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      id='Grupo_161664'
      data-name='prescripition_icon'
      xmlns='http://www.w3.org/2000/svg'
      width={29.076}
      height={32.093}
      viewBox='0 0 29.076 32.093'
      {...props}
    >
      <path
        id='Subtração_4'
        data-name='Subtração 4'
        d='M3.2,14.081A3.2,3.2,0,0,1,.936,13.15a3.148,3.148,0,0,1,0-4.479l3.5-3.465L8.967,9.691l-3.5,3.459A3.208,3.208,0,0,1,3.2,14.081Zm6.59-5.207h0L5.258,4.391,8.76.926a3.233,3.233,0,0,1,4.529,0,3.15,3.15,0,0,1,0,4.484l-3.5,3.464Z'
        transform='matrix(0.999, 0.035, -0.035, 0.999, 14.858, 17.524)'
      />
      <g id='Grupo_9435' data-name='Grupo 9435'>
        <path
          id='Caminho_8861'
          data-name='Caminho 8861'
          d='M380.009,159.33v-7.441a6.367,6.367,0,0,0-6.4-6.333H362.651a6.367,6.367,0,0,0-6.4,6.333V163.7a6.367,6.367,0,0,0,6.4,6.333h5.479'
          transform='translate(-356.252 -145.556)'
          opacity='0.30'
        />
        <g
          id='Grupo_9116'
          data-name='Grupo 9116'
          transform='translate(3.784 6.511)'
        >
          <path
            id='Caminho_8862'
            data-name='Caminho 8862'
            d='M358.865,151.653l1.569,1.439,3.329-2.992'
            transform='translate(-358.865 -150.1)'
            fill='none'
            stroke='#fff'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='1.5'
          />
          <path
            id='Caminho_8863'
            data-name='Caminho 8863'
            d='M358.865,156.894l1.569,1.439,3.329-2.992'
            transform='translate(-358.865 -147.832)'
            fill='none'
            stroke='#fff'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='1.5'
          />
          <line
            id='Linha_385'
            data-name='Linha 385'
            x2='7.925'
            transform='translate(8.264 2.221)'
            fill='none'
            stroke='#fff'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='1.5'
          />
        </g>
      </g>
    </svg>
  )
}

export default SvgPrescriptionIcon
