import React, { Fragment, useMemo } from 'react'
import { Chunk, IHighlight, Options } from './typings'

const escapeRegexp = (term: string): string =>
  term.replace(/[|\\{}()[\]^$+*?.-]/g, (char: string) => `\\${char}`)

function buildRegex(query: string[]) {
  const _query = query.filter(text => text.length !== 0).map(text => escapeRegexp(text.trim()))
  if (!_query.length) {
    return null
  }

  return new RegExp(`(${_query.join('|')})`, 'ig')
}

function highlightWords({ text, query }: Options): Chunk[] {
  const regex = buildRegex(Array.isArray(query) ? query : [query])
  if (!regex) {
    return [{ text, match: false }]
  }
  const result = text.split(regex).filter(Boolean)
  return result.map(str => ({ text: str, match: regex.test(str) }))
}

export function useHighlight(props: Options) {
  const { text, query } = props
  return useMemo(() => highlightWords({ text, query }), [text, query])
}

export default function Highlight(props: IHighlight): JSX.Element {
  const { children, query, styles } = props

  if (typeof children !== 'string') {
    /* istanbul ignore next */
    throw new Error('The children prop of Highlight must be a string')
  }

  const chunks = useHighlight({ query, text: children })

  return (
    <>
      {chunks.map(chunk =>
        chunk.match ? (
          <mark
            key={chunk.text}
            style={{
              backgroundColor: 'transparent',
              whiteSpace: 'nowrap',
              ...styles,
            }}
          >
            {chunk.text}
          </mark>
        ) : (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <Fragment key={chunk.text}>{chunk.text}</Fragment>
        ),
      )}
    </>
  )
}
