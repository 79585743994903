import { theme } from '@cockpit/levi-ui/theme'
import styled from '@emotion/styled'
import { Accordion } from '@mui/material'
import { getColors } from 'helpers'

export const MUIAccordion = styled(Accordion)`
  background: transparent;
  &::before {
    display: none;
  }
  &.Mui-expanded {
    margin-top: 0;
  }
  .levi-accordion-summary {
    min-height: ${theme.spacings[8]};
    padding: 0 ${theme.spacings[2]};
    margin-left: -${theme.spacings[2]};
    margin-right: -${theme.spacings[2]};
    font-family: Inter, sans-serif;
    position: relative;

    border-radius: ${theme.spacings[1]};

    &::after {
      content: ' ';
      width: calc(100% - 1rem);
      position: absolute;
      left: ${theme.spacings[2]};
      bottom: 0;
      height: 1px;
      background-color: ${getColors('neutral', 30)};
    }

    &.Mui-expanded {
      min-height: ${theme.spacings[8]};
    }
    .MuiAccordionSummary-content.Mui-expanded {
      margin: 0.75rem 0;
    }
  }
  .levi-accordion-details {
    padding-left: 0;
    padding-right: 0;
  }

  .levi-accordion-primary {
    color: ${getColors('blue', 60)};
    font-size: ${theme.font.size[4]};
    font-weight: bold;

    &:hover {
      background-color: ${getColors('neutral', 30)};
    }

    .MuiAccordionSummary-expandIconWrapper {
      color: ${getColors('blue', 60)};
      &.Mui-expanded {
        color: ${getColors('blue')};
      }
    }
  }

  .levi-accordion-secondary {
    color: ${getColors('neutral', 80)};
    font-size: ${theme.font.size[3]};
    font-weight: 400;

    .MuiAccordionSummary-expandIconWrapper {
      color: ${getColors('neutral', 50)};
      &.Mui-expanded {
        color: ${getColors('neutral', 80)};
      }
    }
  }
`
