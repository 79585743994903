import React from 'react'

const SvgCustomIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      id='Iconly_Light_Document'
      data-name='Iconly/Light/Document'
      xmlns='http://www.w3.org/2000/svg'
      height={29.206}
      width={25}
      viewBox='0 0 25 29.206'
      {...props}
    >
      <g id='Document' transform='translate(0)'>
        <path
          id='Stroke_4'
          data-name='Stroke 4'
          d='M18.239,0,6.7.006C2.564.033,0,2.914,0,7.309V21.9c0,4.417,2.583,7.309,6.76,7.309l11.535,0C22.435,29.174,25,26.292,25,21.9V7.309C25,2.892,22.415,0,18.239,0Z'
          transform='translate(0 0)'
          opacity='0.30'
        />
        <path
          id='Stroke_1'
          data-name='Stroke 1'
          d='M10.831.5H0'
          transform='translate(7.119 20.875)'
          fill='#e1e7f2'
          stroke='#fff'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit='10'
          strokeWidth='1.5'
        />
        <path
          id='Stroke_2'
          data-name='Stroke 2'
          d='M10.831.5H0'
          transform='translate(7.119 14.233)'
          fill='#e1e7f2'
          stroke='#fff'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit='10'
          strokeWidth='1.5'
        />
        <path
          id='Stroke_3'
          data-name='Stroke 3'
          d='M4.133.5H0'
          transform='translate(7.119 7.607)'
          fill='#e1e7f2'
          stroke='#fff'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit='10'
          strokeWidth='1.5'
        />
      </g>
    </svg>
  )
}

export default SvgCustomIcon
