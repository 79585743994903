import React from 'react';

const SvgEditIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={15.696}
      height={15.642}
      viewBox='0 0 21 21'
      {...props}
    >
      <g
        fill='none'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit={10}
        strokeWidth={1.5}
        data-name='Iconly/Light/Edit'
      >
        <path d='M9.228 14.892h5.717' data-name='Stroke 1' />
        <path
          d='M8.471 1.496a1.746 1.746 0 012.571-.133l1.246.977a1.744 1.744 0 01.481 2.523l-7.432 9.483a1.328 1.328 0 01-1.031.509l-2.866.037-.649-2.793a1.332 1.332 0 01.248-1.122z'
          data-name='Stroke 3'
        />
        <path d='M7.079 3.271l4.3 3.369' data-name='Stroke 5' />
      </g>
    </svg>
  )
}

export default SvgEditIcon
