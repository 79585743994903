import React from 'react'
import { format } from 'date-fns'
import { EScrollDirection } from './constants'

/* istanbul ignore next */
const useExamResultTable = () => {
  const formatTrackingDate = React.useCallback(
    (trackingDate: string) => format(new Date(trackingDate), 'dd/MM/yyyy'),
    [],
  )

  const formatDate = React.useCallback((date: string) => {
    const [year, month, day] = date.split('-')
    return `${day}/${month}/${year}`
  }, [])

  const resultColumnsRef = React.useRef<HTMLDivElement | null>(null)
  const firstDataRef = React.useRef<HTMLDivElement | null>(null)

  const scrollBy = React.useCallback((direction: string) => {
    if (firstDataRef.current) {
      const position = firstDataRef.current.getBoundingClientRect().width

      if (resultColumnsRef.current) {
        resultColumnsRef.current?.scrollBy?.({
          left: direction === EScrollDirection.next ? position : -position,
          behavior: 'smooth',
        })
      }
    }
  }, [])

  return {
    resultColumnsRef,
    firstDataRef,
    scrollBy,
    formatTrackingDate,
    formatDate,
  }
}

export default useExamResultTable
