import { theme } from '@cockpit/levi-ui/theme'
import styled from '@emotion/styled'
import { getColors } from 'helpers'

export const DropDownContainer = styled.span`
  display: grid;
  justify-content: center;
`

export const DropDownItemContent = styled.div<{ $isColorDropDown?: boolean }>`
  display: grid;
  grid-template-columns: ${({ $isColorDropDown }) => ($isColorDropDown ? 'repeat(6, 1fr)' : '')};
  position: absolute;
  margin-top: 40px;
  min-height: 40px;
  z-index: 5;
  background-color: ${getColors('neutral', 10)};
  box-shadow: 0px 1px 3px ${getColors('neutral', 50)};
  border: 1px solid ${getColors('neutral', 30)};
  border-radius: 0px 0px 4px 4px;
  padding: ${theme.spacings[1]};
`

export const Item = styled.div`
  padding: ${theme.spacings[3]};
  min-width: 200px;
  transition: all 250ms;
  background-color: ${getColors('neutral', 10)};
  cursor: pointer;
  :hover {
    background-color: ${getColors('neutral', 20)};
  }
`

export const ItemColor = styled.div<{ $backgroundColor: string }>`
  width: 20px;
  height: 20px;
  margin: ${theme.spacings[1]} ${theme.spacings[1]} 0px ${theme.spacings[1]};
  transition: all 250ms;
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  border: 1px solid ${getColors('neutral', 30)};
  border-radius: 4px;
  cursor: pointer;
  :hover {
    border: 1px solid ${getColors('neutral', 50)};
  }
`
