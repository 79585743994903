import React from 'react';

const SvgUsers = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={21.384}
      height={16.843}
      viewBox='0 0 21 18'
      {...props}
    >
      <path d='M15.253 7.447a3.724 3.724 0 113.723-3.721 3.729 3.729 0 01-3.723 3.721zm0-5.947a2.224 2.224 0 102.223 2.226A2.226 2.226 0 0015.253 1.5zM5.727 7.882a3.316 3.316 0 113.316-3.316 3.32 3.32 0 01-3.316 3.316zm0-5.132a1.816 1.816 0 101.816 1.816A1.819 1.819 0 005.727 2.75zM.75 16.843a.75.75 0 01-.75-.75v-2.18a4.137 4.137 0 014.122-4.041h3.606a.75.75 0 010 1.5H4.122A2.65 2.65 0 001.5 13.913v2.18a.75.75 0 01-.75.75zM20.634 16.843a.75.75 0 01-.75-.75v-2.18a2.651 2.651 0 00-2.619-2.541H12.94a2.66 2.66 0 00-2.567 2.541v2.18a.75.75 0 01-1.5 0v-2.18a4.154 4.154 0 014.054-4.041h4.338a4.137 4.137 0 014.123 4.041v2.18a.75.75 0 01-.754.75z' />
    </svg>
  )
}

export default SvgUsers
