import React, { forwardRef, LegacyRef, Ref, useMemo } from 'react'
import MaskedInput from 'react-text-mask'
import TextField, { ITextField } from 'components/molecules/TextField'

const patterns = {
  '#': /[a-zA-Z]/,
  9: /\d/,
}

export interface IMaskedField extends ITextField {
  /**
   * Padrão de máscara:
   * - Utilizando o caractere '9' para indicar entrada numérica. `Ex: (99) 9999-9999`
   * 
   * - Utilizando o caractere '#' para indicar entrada de letras. `Ex: ###-9999`
   */
  mask: string
  /**
   * Caractere que é exibido antes de ser inserido conteúdo. `Ex: (__) ____-____`
   */
  maskPlaceholder?: string
  /**
   * Valor do campo.
   */
  value?: string | number
  /**
   * @deprecated O componente esconde a máscara por padrão
   */
  /**
   * Depreciado. O componente esconde a máscara por padrão
   */
  hideMask?: boolean
}

const MaskedField = forwardRef<HTMLInputElement, IMaskedField>(
  ({
    mask, maskPlaceholder = '_', disabled, onChange, onBlur, onFocus, value, hideMask, ...rest
  }, ref) => (
      <MaskedInput
        // default
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        // mask parameters
        mask={mask.split('').map((char) => (char === '#' || char === '9' ? patterns[char] : char))}
        guide={false}
        placeholderChar={maskPlaceholder}
        showMask={false}
        ref={ref as LegacyRef<MaskedInput>}
        render={(innerRef, props) => (
          <TextField
            ref={innerRef as Ref<HTMLInputElement>}
            disabled={disabled}
            {...props}
            {...rest}
          />
        )}
      />
  ),
)

export default MaskedField
