import React from 'react'
import { SvgProps } from './typings/SvgProps'

const CalendarUtilWeek: React.FC<SvgProps> = ({ color = 'currentColor', size, ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} {...rest} viewBox="0 0 16.301 17.367">
    <g transform="translate(0.15 0.15)">
      <path
        d="M11511.355-38.733a2.369,2.369,0,0,1-2.355-2.384v-10.75a2.369,2.369,0,0,1,2.355-2.384h1.9v-.92a.612.612,0,0,1,.618-.628.613.613,0,0,1,.621.628v.92h4.962v-.92a.613.613,0,0,1,.621-.628.614.614,0,0,1,.62.628v.92h1.9a2.438,2.438,0,0,1,2.4,2.426v10.708a2.369,2.369,0,0,1-2.355,2.384Zm-1.156-6.108v3.932a1.03,1.03,0,0,0,1.034,1.047h11.536a1.03,1.03,0,0,0,1.032-1.047v-7.907h-13.6Zm-.166-7.111V-49.9h13.644v-2.049a1.03,1.03,0,0,0-1.032-1.047h-2.025v1a.613.613,0,0,1-.62.628.611.611,0,0,1-.62-.628v-1h-4.922v1a.613.613,0,0,1-.621.628.611.611,0,0,1-.617-.628v-1h-2.026A1.238,1.238,0,0,0,11510.033-51.951Zm8.215,9.956v-4.726a.551.551,0,0,1,.537-.546.551.551,0,0,1,.537.546V-42a.55.55,0,0,1-.537.543A.55.55,0,0,1,11518.248-42Zm-3.266,0v-4.726a.549.549,0,0,1,.537-.546.55.55,0,0,1,.536.546V-42a.55.55,0,0,1-.536.543A.549.549,0,0,1,11514.982-42Z"
        transform="translate(-11509 55.799)"
        fill={color}
        stroke={color}
        strokeWidth="0.3"
      />
    </g>
  </svg>
)

export default CalendarUtilWeek
