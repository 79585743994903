import React from 'react'
import { SvgProps, WithSecondaryColor } from './typings/SvgProps'

const InstagramCircle: React.FC<SvgProps & WithSecondaryColor> = ({
  color = 'currentColor',
  secondaryColor = '#fff',
  size,
  ...rest
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} {...rest} viewBox="0 0 32 32">
    <g transform="translate(-1402 -8795)">
      <circle cx="16" cy="16" r="16" transform="translate(1402 8795)" fill={color} />
      <g transform="translate(1406.75 8800.027)">
        <path
          d="M15.054,3.756a3.778,3.778,0,0,1,3.766,3.766v7.532a3.778,3.778,0,0,1-3.766,3.766H7.523a3.778,3.778,0,0,1-3.766-3.766V7.523A3.778,3.778,0,0,1,7.523,3.756h7.532m0-1.506H7.523A5.288,5.288,0,0,0,2.25,7.523v7.532a5.288,5.288,0,0,0,5.273,5.272h7.532a5.288,5.288,0,0,0,5.272-5.273V7.523A5.288,5.288,0,0,0,15.054,2.25Z"
          fill={secondaryColor}
        />
        <path
          d="M25.692,9.76a1.128,1.128,0,1,1,.8-.33A1.13,1.13,0,0,1,25.692,9.76Z"
          transform="translate(-9.508 -2.237)"
          fill={secondaryColor}
        />
        <path
          d="M14.644,11.631a3.013,3.013,0,1,1-3.013,3.013,3.013,3.013,0,0,1,3.013-3.013m0-1.506a4.519,4.519,0,1,0,4.519,4.519A4.519,4.519,0,0,0,14.644,10.125Z"
          transform="translate(-3.356 -3.356)"
          fill={secondaryColor}
        />
      </g>
    </g>
  </svg>
)

export default InstagramCircle
