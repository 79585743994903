import styled, { css } from 'styled-components'
import { theme } from '../../Theme'

interface SelectedProp {
  selected?: boolean
  background?: string
  showDivider?: boolean
}

interface InputForValueStyles {
  cursor?: any
  small?: boolean
}

export const Box = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
`

export const TagWrapper = styled.span<SelectedProp>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 29px;
  padding: 0 16px;
  border-radius: ${theme.borderRadius.tags};
  width: fit-content;
  background-color: ${(props) => props.background ?? theme.colors.white};
  transition: all ease 0.2s;
  ${(props) =>
    props.selected &&
    css`
      background: #378ff7;
      color: #fff;
    `}
  div {
    p {
      font-size: 13px;
      color: ${(props) => (props.selected ? '#fff' : '#000')};
    }
  }
`

export const InputForValue = styled.input<InputForValueStyles>`
  all: unset;
  font-size: 13px;
  pointer-events: none;
  max-width: ${(props) => props.small && '38px'};
  ${(props) =>
    props.cursor &&
    css`
      cursor: pointer;
    `};
  display: none;
`

export const Divider = styled.span`
  width: 0.5px;
  height: 16px;
  background-color: ${theme.colors.gray3};
  margin: 0 ${theme.spacing.small};
`

export const Action = styled.button<SelectedProp>`
  all: unset;
  background: transparent;
  border: none;
  cursor: pointer;
  ${({ showDivider }) =>
    !showDivider &&
    css`
      margin-left: ${theme.spacing.xSmall};
    `}

  svg {
    width: 8px;
    height: 8px;
    ${(props) =>
      props.selected &&
      css`
        fill: #fff;
      `}
  }
`
