import React from 'react'
import { SvgProps } from './typings/SvgProps'

const GraphicComputer: React.FC<SvgProps> = ({ color = 'currentColor', size, ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} {...rest} viewBox="0 0 46.685 41.543">
    <g transform="translate(-418.75 -6585.749)">
      <g transform="translate(143.896 6371.953)">
        <line
          y2="7.49"
          transform="translate(290.81 229.747)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeWidth="2.5"
        />
        <line
          y2="9.354"
          transform="translate(298.137 227.883)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeWidth="2.5"
        />
        <line
          y2="14.268"
          transform="translate(305.62 222.969)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeWidth="2.5"
        />
        <path
          d="M317.961,287.21h-9.6a2.421,2.421,0,0,0-2.195,1.408c-.451.97-1.136,2.087-1.9,2.087H292.13c-.781,0-1.477-1.157-1.927-2.14a2.322,2.322,0,0,0-2.112-1.355H278.4a2.3,2.3,0,0,0-2.3,2.3v2.444a4.74,4.74,0,0,0,4.74,4.74h34.705a4.74,4.74,0,0,0,4.74-4.74v-2.412A2.328,2.328,0,0,0,317.961,287.21Z"
          transform="translate(0 -42.601)"
          fill="none"
          stroke={color}
          strokeWidth="2.5"
        />
        <path
          d="M291.489,244.609a2.322,2.322,0,0,1,2.112,1.355c.45.982,1.146,2.14,1.927,2.14h12.134c.767,0,1.452-1.116,1.9-2.087a2.421,2.421,0,0,1,2.195-1.408h9.346V218.7s-.428-3.657-3.995-3.657H285.855c-3.567,0-3.995,3.657-3.995,3.657v25.905Z"
          transform="translate(-3.398 0)"
          fill="none"
          stroke={color}
          strokeWidth="2.5"
        />
      </g>
    </g>
  </svg>
)

export default GraphicComputer
