import React from 'react'

const SvgEditCorpoClinico = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='12.148'
      height='12'
      viewBox='0 0 12.148 12'
      {...props}
    >
      <g
        id='Grupo_11307'
        data-name='Grupo 11307'
        transform='translate(0.5 0.5)'
      >
        <path
          id='Stroke_3'
          data-name='Stroke 3'
          d='M.595,6.692,6.689.571a1.939,1.939,0,0,1,2.749,0l.992,1a1.958,1.958,0,0,1,0,2.761l-6.124,6.15A1.767,1.767,0,0,1,3.055,11H0L.077,7.9A1.781,1.781,0,0,1,.595,6.692Z'
          fill='none'
          stroke='#a8abb1'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1'
        />
        <path
          id='Stroke_5'
          data-name='Stroke 5'
          d='M0,0,3.724,3.74'
          transform='translate(5.763 1.516)'
          fill='none'
          stroke='#a8abb1'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1'
        />
      </g>
    </svg>
  )
}

export default SvgEditCorpoClinico
