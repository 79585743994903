import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { theme } from '@cockpit/levi-ui/theme'

interface IOption {
  active?: boolean
}

interface IArrow {
  open: boolean
}

interface ICountrySelectSearch {
  disabled?: boolean
  variant?: 'standard' | 'condensed'
}

export const Container = styled.div`
  display: flex;
  position: relative;
  .input-phone {
    border-radius: 0 4px 4px 0;
  }
`
export const AreaSearch = styled.div`
  display: block;
  width: fit-content;
  height: fit-content;
  position: relative;
`

export const Arrow = styled.div<IArrow>`
  position: absolute;
  right: 10px;
  top: calc(35% - 5px);
  z-index: 1;
  transition: all 0.2s ease-in-out;
  transform: ${({ open }) => `rotate(${open ? 180 : 0}deg); ${open && 'top: calc(35% - 0px)'}`};
`

export const CountrySelectSearch = styled.input<ICountrySelectSearch>`
  padding: 0 ${theme.spacings[6]} 0 ${theme.spacings[2]};
  font-size: ${theme.font.size[2]};
  font-family: 'Inter', sans-serif;
  color: ${theme.colors.neutral[80]};
  transition: all 0.2s ease-in-out;
  border-radius: ${theme.spacings[1]} 0 0 ${theme.spacings[1]};
  width: 100%;
  outline: none;
  height: ${({ variant }) => (variant === 'condensed' ? '30px' : '38px')};
  border: 1px solid ${theme.colors.neutral[30]};
  border-right: 0px solid;
  background-color: ${theme.colors.neutral[10]};
  display: flex;
  align-items: center;
  width: 50px;
  cursor: pointer;
  ${({ disabled }) =>
    disabled &&
    css`
      background: ${theme.colors.neutral[20]}; #f6f7f9 #dee1e8
      border: 1px solid ${theme.colors.neutral[30]};
      color: ${theme.colors.neutral[50]};
    `}
`

export const CountryOptions = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
  top: calc(100% + 5px);
  background: ${theme.colors.neutral[10]} 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 30px #dfe3e87a;
  border: 1px solid ${theme.colors.neutral[40]};
  border-radius: ${theme.spacings[1]};
  opacity: 1;
  z-index: 100;
  padding: ${theme.spacings[4]};
`

export const Options = styled.div`
  overflow-y: scroll;
  width: 100%;
  height: 120px !important;
  padding-right: ${theme.spacings[4]};
  &::-webkit-scrollbar {
    width: 4px;
    border-radius: ${theme.spacings[15]};
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #d6dade;
    border-radius: ${theme.spacings[15]};
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #999999;
  }
`

export const Option = styled.button<IOption>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: ${theme.spacings[2]};
  background-color: transparent;
  border: 0px solid;
  cursor: pointer;
  border-radius: ${theme.spacings[1]};
  background-color: ${({ active }) => (active ? 'rgba(0,0,0, 0.03)' : 'transparent')};
  &:hover {
    background-color: rgba(0, 0, 0, 0.03);
  }
  span {
    font: normal normal normal 14px/20px Inter;
    letter-spacing: 0px;
    text-align: left;
    color: #2d3139;
    opacity: 1;
  }
`
