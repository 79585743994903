import React from 'react';

const SvgMessageIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={17.291}
      height={15.554}
      viewBox='0 0 21 18'
      {...props}
    >
      <g
        fill='none'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit={10}
        strokeWidth={1.5}
        data-name='Iconly/Light/Message'
      >
        <path d='M13.286 5.316l-3.5 2.82a1.825 1.825 0 01-2.255 0l-3.534-2.82' />
        <path d='M4.763.75h7.741a4.07 4.07 0 012.94 1.306 4.119 4.119 0 011.089 3.041v5.36a4.119 4.119 0 01-1.089 3.041 4.07 4.07 0 01-2.94 1.306H4.763A4.079 4.079 0 01.75 10.457v-5.36A4.079 4.079 0 014.763.75z' />
      </g>
    </svg>
  )
}

export default SvgMessageIcon
