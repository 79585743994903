import React from 'react'

const SvgLoadingIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={20}
      height={20}
      viewBox='0 0 22 22'
      {...props}
    >
      <defs>
        <clipPath id='loading-icon_svg__a'>
          <path fill='none' d='M1 1h24v24H1z' />
        </clipPath>
      </defs>
      <g
        transform='translate(-1 -1)'
        clipPath='url(#loading-icon_svg__a)'
        style={{
          isolation: 'isolate'
        }}
      >
        <path
          d='M13.269 7.934h-.544a.758.758 0 01-.757-.757v-2.92a.758.758 0 01.757-.757h.544a.758.758 0 01.757.757v2.92a.755.755 0 01-.757.757z'
          fill='currentColor'
          opacity={0.05}
        />
        <path
          d='M13.269 22.5h-.544a.758.758 0 01-.757-.757v-2.92a.758.758 0 01.757-.757h.544a.758.758 0 01.757.757v2.92a.755.755 0 01-.757.757z'
          fill='currentColor'
          opacity={0.6}
        />
        <path
          d='M18.007 20.978l-.47.27a.758.758 0 01-1.036-.279l-1.462-2.528a.761.761 0 01.279-1.036l.47-.27a.758.758 0 011.036.279l1.458 2.528a.751.751 0 01-.275 1.036z'
          fill='currentColor'
          opacity={0.5}
        />
        <path
          d='M8.857 10.209l-.27.47a.759.759 0 01-1.036.279L5.023 9.496a.759.759 0 01-.278-1.036l.27-.47a.759.759 0 011.036-.279l2.528 1.458a.767.767 0 01.278 1.04z'
          fill='currentColor'
        />
        <path
          d='M21.256 17.536l-.27.47a.759.759 0 01-1.036.278l-2.528-1.458a.759.759 0 01-.279-1.036l.27-.47a.759.759 0 011.036-.279l2.528 1.462a.755.755 0 01.279 1.033z'
          fill='currentColor'
          opacity={0.4}
        />
        <path
          d='M7.934 12.73v.544a.758.758 0 01-.757.757h-2.92a.758.758 0 01-.757-.757v-.544a.758.758 0 01.757-.757h2.92a.755.755 0 01.757.757z'
          fill='currentColor'
          opacity={0.9}
        />
        <path
          d='M22.5 12.73v.544a.758.758 0 01-.757.757h-2.92a.758.758 0 01-.757-.757v-.544a.758.758 0 01.757-.757h2.92a.755.755 0 01.757.757z'
          fill='currentColor'
          opacity={0.3}
        />
        <path
          d='M8.579 15.321l.27.47a.76.76 0 01-.279 1.036l-2.528 1.458a.757.757 0 01-1.036-.279l-.27-.47a.758.758 0 01.278-1.036l2.528-1.462a.765.765 0 011.037.283z'
          fill='currentColor'
          opacity={0.8}
        />
        <path
          d='M20.978 7.994l.27.47a.758.758 0 01-.279 1.036l-2.528 1.462a.761.761 0 01-1.036-.279l-.27-.47a.758.758 0 01.279-1.036l2.528-1.458a.751.751 0 011.036.275z'
          fill='currentColor'
          opacity={0.2}
        />
        <path
          d='M10.209 17.144l.47.27a.759.759 0 01.279 1.036l-1.462 2.528a.759.759 0 01-1.036.279l-.47-.27a.759.759 0 01-.279-1.036l1.458-2.528a.767.767 0 011.04-.279z'
          fill='currentColor'
          opacity={0.7}
        />
        <path
          d='M17.536 4.744l.47.27a.759.759 0 01.278 1.036l-1.458 2.528a.759.759 0 01-1.036.278l-.47-.27a.755.755 0 01-.279-1.031l1.462-2.528a.753.753 0 011.033-.283z'
          fill='currentColor'
          opacity={0.1}
        />
      </g>
    </svg>
  )
}

export default SvgLoadingIcon
