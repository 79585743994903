import React from 'react';

const SvgSearch = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={16.4}
      height={16.401}
      viewBox='0 0 21 21'
      {...props}
    >
      <g
        fill='none'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={2}
        data-name='Group 8106'
        transform='translate(1 1)'
      >
        <circle cx={6} cy={6} r={6} data-name='Ellipse 4' />
        <path d='M13.986 13.986l-3.579-3.579' data-name='Line 2' />
      </g>
    </svg>
  )
}

export default SvgSearch
