import { theme } from '../Theme'
import styled, { css } from 'styled-components'

export const FilterModal = styled.div<{ show: boolean }>`
  display: ${({ show }) => (show ? 'none' : 'flex')};
  position: relative;
`
export const ButtonFilter = styled.div<{ show: boolean }>`
  ${({ show }) =>
    show &&
    css`
      div {
        display: flex;
        justify-content: space-between;
      }
      button {
        height: 40px;
      }
      span {
        margin: auto 5px;
        font-weight: 500;
      }
      button:hover {
        background-color: ${theme.colors.gray6};
        color: ${theme.colors.blue3};
      }
    `}
`
