import styled, { css } from 'styled-components'
import { rgba } from 'polished'
import { theme } from '../../Theme'

interface IField {
  large?: boolean
  disable?: boolean
  error?: boolean
  active?: boolean
}

interface MessageStyles {
  error?: boolean
}

interface Input {
  error?: boolean | string
  active?: boolean | string
  direction?: string | string
}

const stateInput = {
  error: css`
    color: ${theme.colors.gray4};

    svg {
      fill: ${theme.colors.red1};
    }
  `,
  active: css`
    -webkit-box-shadow: 0px 0px 6px 0px ${rgba(theme.colors.blue3, 0.4)};
    -moz-box-shadow: 0px 0px 6px 0px ${rgba(theme.colors.blue3, 0.4)};
    box-shadow: 0px 0px 6px 0px ${rgba(theme.colors.blue3, 0.4)};
  `
}

const stateMessage = {
  error: css`
    color: ${theme.colors.red1};
  `
}

export const WrapperLight = styled.div<Input>`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: ${(props) => props.direction && props.direction};
  align-items: center;
  border-radius: ${theme.borderRadius.fields};

  svg {
    fill: transparent;
    position: absolute;
    right: 10px;
  }
  ${({ active }) => (active ? stateInput.active : null)};
  ${({ error }) => (error ? stateInput.error : null)}
`

export const Message = styled.span<MessageStyles>`
  display: block;
  color: transparent;
  font-size: 12px;
  padding-top: 4px;
  position: absolute;
  bottom: -18px;
  left: 2px;

  ${({ error }) => (error ? stateMessage.error : null)};
`

export const Container = styled.div`
  display: flex;
  position: relative;
  flex: 1;
`

export const ErrorTextAreaIcon = styled.div`
  margin-bottom: 18px;
  align-self: flex-end;
`

const Field = styled.textarea<IField>`
  flex: 1;
  padding: 8px 16px;
  resize: none;
  outline: none;
  border-radius: ${theme.borderRadius.fields};
  border: 1px solid ${theme.colors.gray3};
  min-height: ${(props) => (!props.large ? '89px' : '161px')};
  display: flex;
  flex-wrap: wrap;
  font-family: 'Inter', sans-serif;
  ${(props) =>
    props.disable &&
    css`
      background: #e9ecf3;
      border: 1px solid #d4d9de;
      pointer-events: none;
    `}

  ${(props) =>
    props.error &&
    css`
      border: 1px solid ${theme.colors.red1};
    `}
  ${(props) =>
    !props.error &&
    props.active &&
    css`
      border: 1px solid ${theme.colors.blue3};
    `}

  &::placeholder {
    color: ${theme.palette.gray4};
    opacity: 0.5;
  }

  &::-ms-input-placeholder {
    color: ${theme.colors.gray5};
    opacity: 0.5;
  }

  &::-ms-input-placeholder {
    color: ${theme.colors.gray5};
    opacity: 0.5;
  }
`

Field.displayName = 'Field'

export { Field }
