import React from 'react'
import { SvgProps, WithSecondaryColor } from './typings/SvgProps'

const Error: React.FC<SvgProps & WithSecondaryColor> = ({
  color = 'currentColor',
  size,
  ...rest
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} {...rest} viewBox="0 0 16.001 16">
    <path
      d="M-4602-1188a8.009,8.009,0,0,1-8-8,8.009,8.009,0,0,1,8-8,8.009,8.009,0,0,1,8,8A8.009,8.009,0,0,1-4602-1188Zm0-7.016h0l1.424,1.415a.66.66,0,0,0,.487.208.7.7,0,0,0,.5-.208.689.689,0,0,0,.2-.492.689.689,0,0,0-.2-.492l-1.425-1.424,1.425-1.424a.693.693,0,0,0,.2-.5.7.7,0,0,0-.2-.5.694.694,0,0,0-.494-.2.711.711,0,0,0-.5.2L-4602-1197l-1.424-1.423a.711.711,0,0,0-.5-.2.692.692,0,0,0-.493.2.7.7,0,0,0-.205.5.7.7,0,0,0,.205.5l1.424,1.424-1.424,1.416a.7.7,0,0,0,0,.991.708.708,0,0,0,.5.208.7.7,0,0,0,.5-.208l1.424-1.415Z"
      transform="translate(4610 1204)"
      fill={color}
    />
  </svg>
)

export default Error
