import React, { ReactChild, ReactFragment, ReactPortal } from 'react'
import { theme } from '@cockpit/levi-ui/theme'
import { Icon, Tooltip } from '../../atoms'
import { Chip, Container } from './styles'

type IData = ReactChild | ReactFragment | ReactPortal | string

export interface ITags {
  data: IData[]
  showDeleteButton?: boolean
  className?: string
  onDelete: (i: number) => void
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function joinLines(item: any): string {
  const arr = Array.isArray(item.props.children) ? item.props.children : [item.props.children]
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return arr.filter((line: unknown) => typeof line === 'string').join(' ')
}

const Tags: React.FC<ITags> = ({ className = '', showDeleteButton = false, data, onDelete }) => (
  <Container className={className}>
    {data.map((item, index) => (
      <Tooltip
        key={index}
        title={item}
        placement="bottom-start"
        element={
          <Chip
            className={showDeleteButton ? 'show-delete-button' : ''}
            label={typeof item === 'string' ? item : joinLines(item)}
            onDelete={() => onDelete(index)}
            deleteIcon={<Icon icon="Close" size={8} color={theme.colors.blue[40]} />}
            data-testid="tag-component"
          />
        }
      />
    ))}
  </Container>
)

export default Tags
