import React from 'react'
import { BulletContainer, Label } from './styles'

export type IBullet = {
  children: React.ReactNode
  variant: 'primary' | 'secondary'
}

const Bullet: React.FC<IBullet> = ({ children, variant }) => (
  <BulletContainer>
    <Label variant={variant}>{children}</Label>
  </BulletContainer>
)
export default Bullet
