import React from 'react'
import { SvgProps } from './typings/SvgProps'

const File: React.FC<SvgProps> = ({ color = 'currentColor', size, ...rest }) => (
<svg xmlns="http://www.w3.org/2000/svg" width={size} viewBox="0 0 18.776 20.975">
  <g id="fluxos_e_protocolos_menu" data-name="fluxos e protocolos menu" transform="translate(0.75 0.75)">
    <g id="Group_163035" data-name="Group 163035" transform="translate(-235.32 -392.263)">
      <path id="Line_182" d="M0,0V8" transform="translate(239.935 407.508) rotate(-90)" fill="none" stroke={color} stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
      <path id="Line_183" d="M0,0V8" transform="translate(239.936 403.963) rotate(-90)" fill="none" stroke={color} stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
      <path id="Line_184" d="M0,0V4" transform="translate(240 397.909) rotate(-90)" fill="none" stroke={color} stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
      <path id="Path" d="M14.3,0H5.175C1.994,0,0,2,0,4.825v7.627c0,2.827,1.985,4.825,5.175,4.825H14.3c3.19,0,5.175-2,5.175-4.825V4.825C19.475,2,17.49,0,14.3,0Z" transform="translate(235.32 411.738) rotate(-90)" fill="none" stroke={color} stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
      <path id="Line_184-2" data-name="Line_184" d="M0,0V4" transform="translate(242 396.044)" fill="none" stroke={color} stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
    </g>
  </g>
</svg>
)

export default File