import styled, { css } from 'styled-components'
import { theme } from '../../Theme'

interface ITableStyle {
  scroll?: boolean
  styleScroll?: boolean
}

export const TableContainerStyle = styled.div`
  width: 100%;
  box-sizing: border-box;
`

export const TableContent = styled.div<{ minHeight?: string }>`
  width: 100%;
  display: flex;
  margin-bottom: 24px;
  position: relative;
  min-height: ${({ minHeight }) => minHeight || '300px'};
`

export const ErrorStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 90px;
  left: 50%;
  transform: translate(-50%, 0);

  span {
    color: #6b7786;
    font-size: 14px;

    p {
      text-align: left;
      font-size: 14px;
      font-weight: 600;
      color: #6b7786;
    }
  }
`

export const LoadingContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export const TableStyle = styled.div<ITableStyle>`
  height: auto;
  padding: 8px;
  background-color: ${theme.colors.white};
  border-radius: 4px;
  box-sizing: border-box;
  overflow-x: visible;
  ${({ styleScroll }) =>
    styleScroll
      ? css`
          padding: 8px 0 8px 8px;
          box-shadow: 15px 0px 20px -25px rgb(0 0 0 / 30%);
          z-index: 10;
        `
      : css`
          width: 100%;
        `}

  ${({ scroll }) =>
    scroll &&
    css`
      width: 100%;
      padding: 8px 8px 8px 0px;
      border-radius: 0 4px 4px 0;
      overflow-x: auto;
      overflow-y: hidden;

      &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
      }
      &::-webkit-scrollbar-track {
        background: ${theme.colors.white};
      }
      &::-webkit-scrollbar-thumb {
        background: ${theme.colors.gray6};
        border-radius: 100px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: ${theme.colors.gray3};
      }
    `}
`
export const ContainerStyle = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 85vh;
  max-height: 100%;
  background-color: ${theme.colors.gray2};
  box-sizing: border-box;
  padding: 50px;
`
