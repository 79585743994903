import styled, { css } from 'styled-components'

export const Container = styled.div<{ show: boolean }>`
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: 10010;
  display: ${(props) => (props.show ? 'flex' : 'none')};
  ${(props) =>
    props.show &&
    css`
      body {
        overflow: hidden;
      }
    `}
  * {
    transition: all 0.2s linear;
  }
`

export const IconContainer = styled.div`
  display: flex;
  position: absolute;
  padding: 24px;
  width: 100%;
  justify-content: flex-end;

  svg {
    opacity: 0.8;
    cursor: pointer;
  }
`

export const Mask = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  height: 100vh;
  width: 100%;
  position: fixed;
  z-index: 10011;
  animation: appear 0.5s;
  -webkit-animation: appear 0.5s;

  @keyframes appear {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @-webkit-keyframes appear {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`

export const ModalHeader = styled.div<{
  modalMode?: 'rollback' | 'close'
  backgroundColor?: string
}>`
  display: flex;
  position: sticky;
  top: 0;
  border-bottom: 1px solid #e9ecf1;
  padding: 16px 24px;
  background-color: ${({ backgroundColor }) => backgroundColor ?? 'white'};

  align-items: center;

  ${({ modalMode }) =>
    modalMode === 'rollback'
      ? css`
          svg {
            margin-right: 16px;
            transform: rotate(90deg);
          }
        `
      : css`
          justify-content: space-between;
        `}

  @media(max-width: 550px) {
    padding: 0px 25px;
    min-height: 48px;
    height: 48px;
    svg {
      color: #a8abb1;
    }
  }
`

export const ModalTitle = styled.p`
  font-weight: 600;
`

export const ModalContent = styled.div<{
  padding?: string
  height?: string
  width?: string
  backgroundColor?: string
}>`
  position: relative;
  max-width: 100vw;
  max-height: 100vh;
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  // padding: ${({ padding }) => padding ?? '16px'};
  background-color: ${({ backgroundColor }) => backgroundColor ?? 'white'};
  border-radius: 8px;
  z-index: 10012;
  display: flex;
  flex-direction: column;
  animation: fadein 0.3s;
  overflow-y: auto;
  -webkit-animation: fadein 0.3s;

  @media (max-width: 1200px) {
    width: calc(100% - 86px);
  }

  @media (max-width: 500px) {
    height: 100%;
    width: 100%;

    border-radius: 0;

    max-width: 100%;
    min-height: 100%;
  }

  @keyframes fadein {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
  @-webkit-keyframes fadein {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
`

export const Content = styled.div<{ padding?: string }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: ${({ padding }) => padding ?? '16px'};
`
