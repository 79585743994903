import { ITheme } from '../theme'

export type IColor = 'primary' | 'blue' | 'green' | 'red' | 'yellow' | 'orange' | 'neutral'

export type ColorIndex = 10 | 20 | 30 | 40 | 50 | 60 | 70 | 80

type Styled = {
  theme: ITheme
}

const getColor = (color: IColor, intensity: ColorIndex = 40, themeParam?: ITheme) => (
  props?: Styled,
): string =>
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line implicit-arrow-linebreak, @typescript-eslint/no-unsafe-return
  (themeParam || props.theme)?.colors[color][intensity]

export default getColor
