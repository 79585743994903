import React from 'react';

const SvgFileText = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={10.497}
      height={12.872}
      viewBox='0 0 10 13'
      {...props}
    >
      <defs>
        <style>
          {
            '.file_text_svg__a{fill:none;stroke:#2699fb;stroke-linecap:round;stroke-linejoin:round}'
          }
        </style>
      </defs>
      <path
        className='file_text_svg__a'
        d='M6.436.5H1.687A1.187 1.187 0 00.5 1.687v9.5a1.187 1.187 0 001.187 1.187H8.81A1.187 1.187 0 0010 11.185V4.062z'
      />
      <path
        className='file_text_svg__a'
        d='M6.239.5v3.758h3.758M7.524 6.989h-4.39M7.524 9.623h-4.39M3.219 4.655H2.78'
      />
    </svg>
  )
}

export default SvgFileText
