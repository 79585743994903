import React from 'react'

const SvgExamIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      id='Grupo_161721'
      data-name='Grupo 161721'
      xmlns='http://www.w3.org/2000/svg'
      width={24.578}
      height={29.455}
      viewBox='0 0 24.578 29.455'
      {...props}
    >
      <g id='Iconly_Light_Paper' data-name='Iconly/Light/Paper'>
        <path
          id='Stroke_1'
          data-name='Stroke 1'
          d='M16.562,0H6.055A6.079,6.079,0,0,0,0,5.985V23.178a6.1,6.1,0,0,0,6.055,6.277H18.672a6.2,6.2,0,0,0,5.889-6.277V8.468Z'
          opacity='0.30'
        />
      </g>
      <path
        id='União_8'
        data-name='União 8'
        d='M3.818,11.177V7.451H0V3.726H3.818V0H7.641V3.726h3.818V7.451H7.641v3.725Z'
        transform='translate(6.465 10.405)'
        fill='#fff'
      />
      <path
        id='Stroke_3'
        data-name='Stroke 3'
        d='M8.424,8.468A35.257,35.257,0,0,1,4.056,8.8,4.1,4.1,0,0,1,0,4.669L.436.024Z'
        transform='translate(16.154)'
      />
    </svg>
  )
}

export default SvgExamIcon
