import React from 'react';

const ArrowDownOrder = (props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={23} height={12} viewBox="0 0 23 12" {...props}>
            <g transform="translate(1.061 0.75)">
                <path d="M0,6.86V0" transform="translate(11.34 9.257) rotate(-90)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth={1.5} />
                <path d="M0,10.143V0" transform="translate(11.34 4.591) rotate(-90)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth={1.5} />
                <path d="M0,3.235V0" transform="translate(11.34) rotate(-90)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth={1.5} />
                <path d="M.5,9.257V0" transform="translate(3.218)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth={1.5} />
                <path d="M7.436,0,3.718,3.734,0,0" transform="translate(0 5.524)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth={1.5} />
            </g>
        </svg>

    )
}

export default ArrowDownOrder
