import { theme } from '@cockpit/levi-ui/theme'
import React from 'react'
import { Button, Icon } from 'components/atoms'
import { muiTheme } from '@cockpit/levi-ui/theme/theme'
import { useMediaQuery } from '@mui/material'
import useExamResultTable from './hook'
import {
  Column,
  ColumnHeader,
  Container,
  ResultColumns,
  LeadingTitle,
  ExamColumn,
  LaboratoryLocale,
  PaginationContainer,
  TrackingDate,
  TableHeader,
  Search,
  Loader,
  EmptyContainer,
  AddButton,
  Info,
} from './styles'
import { IData, IEditable } from './typing'
import { EScrollDirection } from './constants'
import FileSearching from './FileSearching'
import ExamResult from './ExamResult'
import LeadingTitleColumn from './LeadingTitleColumn'

interface ISearchComponent {
  onSearchChange?(value: string, data?: IData): void
  onSearchBlur?(value: string, data?: IData): void
  onSearchEnter?(value: string, data?: IData): void
}

export interface IExamResultTable extends ISearchComponent, IEditable {
  data?: IData
  isLoading?: boolean
  isEmpty?: boolean
  handleAddExam?(): void
  description?: {
    addExamButtonLabel?: string
  }
}

const ExamResultTable: React.FC<IExamResultTable> = ({
  data,
  onSearchChange,
  onSearchBlur,
  onSearchEnter,
  handleAddExam,
  isLoading,
  isEmpty,
  description,
  isEditable = false,
  onBlurEditableCallback,
  onDeleteEditableCallback,
}) => {
  const { resultColumnsRef, firstDataRef, scrollBy, formatDate } = useExamResultTable()

  const canShowSearchBar = !!onSearchChange || !!onSearchBlur || !!onSearchEnter

  const matchesMobile = useMediaQuery(muiTheme.breakpoints.down('sm'))

  return (
    <Container>
      <TableHeader>
        {canShowSearchBar && (
          <Search
            data-testid="exam-result-table-search-input"
            onChange={e => {
              onSearchChange?.(e.target.value, data)
            }}
            onBlur={e => {
              onSearchBlur?.(e.target.value, data)
            }}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                onSearchEnter?.((e.target as HTMLInputElement).value, data)
                e.stopPropagation()
                e.preventDefault()
              }
            }}
            icon="Search"
            placeholder="Pesquisar"
            iconOptions={{ position: 'end' }}
          />
        )}
        {handleAddExam && (
          <AddButton
            size="small"
            data-testid="exam-result-table-add-button"
            variant="contained"
            onClick={handleAddExam}
          >
            {description?.addExamButtonLabel ? description.addExamButtonLabel : 'Adicionar exame'}
          </AddButton>
        )}
        {!matchesMobile && data && !isEmpty && (
          <PaginationContainer data-testid="exam-result-table-container">
            <Button
              data-testid="exam-result-table-pagination-button"
              onClick={() => {
                /* istanbul ignore next */
                scrollBy(EScrollDirection.previous)
              }}
            >
              <Icon icon="ChevronLeft" size={6} color={theme.colors.neutral[50]} />
            </Button>
            <Button
              data-testid="exam-result-table-pagination-button"
              /* istanbul ignore next */
              onClick={() => {
                /* istanbul ignore next */
                scrollBy(EScrollDirection.next)
              }}
            >
              <Icon icon="ChevronRight" size={6} color={theme.colors.neutral[50]} />
            </Button>
          </PaginationContainer>
        )}
      </TableHeader>
      {isEditable && <Info>Clique na célula para editar ou excluir</Info>}
      {!!isLoading && <Loader data-testid="exam-result-table-loader" />}

      {data && !isEmpty && (
        <>
          <ExamColumn>
            <LeadingTitle isLoading={isLoading}>Exame</LeadingTitle>
            {data.examsNames.map((exam, index) => (
              <LeadingTitleColumn
                data-testid="exam-result-leading-title-column"
                exam={exam}
                key={`exam-${index}`}
                mobile={matchesMobile}
                result={data}
                index={index}
              />
            ))}
          </ExamColumn>
          <ResultColumns ref={resultColumnsRef}>
            {data.examResults.map(({ date, laboratory, results }, columnIndex) => (
              <Column
                data-testid="exam-result-column"
                ref={!columnIndex ? firstDataRef : null}
                key={`col-${columnIndex}`}
              >
                <ColumnHeader
                  isLoading={isLoading}
                  lastIndex={columnIndex === data.examResults.length - 1}
                >
                  <TrackingDate>{date ? formatDate(date) : ''}</TrackingDate>
                  <LaboratoryLocale>
                    {laboratory?.description ? laboratory.description : ''}
                  </LaboratoryLocale>
                </ColumnHeader>
                {results.map((result, index) => (
                  <ExamResult
                    key={`col-${columnIndex}-examResult-${index}`}
                    index={index}
                    columnIndex={columnIndex}
                    result={result}
                    isEditable={isEditable}
                    laboratory={laboratory}
                    onBlurEditableCallback={onBlurEditableCallback}
                    onDeleteEditableCallback={onDeleteEditableCallback}
                  />
                ))}
              </Column>
            ))}
          </ResultColumns>
        </>
      )}

      {isEmpty && (
        <EmptyContainer data-testid="exam-result-table-empty-container">
          <FileSearching size={100} color="#DEE1E8" />
          <h3>Nenhum exame registrado</h3>
          <p>Não há exames para serem exibidos</p>
        </EmptyContainer>
      )}
    </Container>
  )
}

export default ExamResultTable
