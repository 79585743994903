import React from 'react'
import { SvgProps } from './typings/SvgProps'

const ClearInput: React.FC<SvgProps> = ({ color = 'currentColor', size, ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} {...rest} viewBox="0 0 6.122 6.122">
    <g transform="translate(-7.021 -7)">
      <g transform="translate(7.021 7)">
        <g>
          <path
            d="M.214.214A.729.729,0,0,1,1.163.143l.082.071L5.908,4.877a.729.729,0,0,1-.95,1.1l-.082-.071L.214,1.245A.729.729,0,0,1,.214.214Z"
            transform="translate(0 0)"
            fill={color}
          />
        </g>
        <path
          d="M4.873.214a.729.729,0,0,1,1.1.949L5.9,1.245,1.245,5.9a.729.729,0,0,1-1.1-.949l.071-.082Z"
          transform="translate(0.002 0.002)"
          fill={color}
        />
      </g>
    </g>
  </svg>
)

export default ClearInput
