import React from 'react'
import { Avatar, AvatarProps } from './style'

export interface UserAvatarProps extends AvatarProps {
  name: string
}

/**
 * This component will render a userAvatar with these infos
 * @param {string} name property of the object patient
 * @param {string} image property of the object patient - if doesn't exist it will be a background with first letters of the name
 * @param {string} size the size of width and height - if doesn't exist it will be 40px by default
 * @param {string} fontSize font-size of the first letters of the name if doesn't exist image
 * @returns {JSX.Element} return the rendered userAvatar
 **/

export function UserAvatar({
  name,
  image,
  size,
  fontSize
}: UserAvatarProps): JSX.Element {
  if (!image && name) {
    const nameArray = name.split(' ')
    const arrayNameLength = nameArray.length
    const nameInitial = nameArray[0].charAt(0)
    const surnameInitial = (nameArray[arrayNameLength - 1] || '').charAt(0)
    return (
      <Avatar size={size} fontSize={fontSize}>
        {nameInitial}
        {surnameInitial}
      </Avatar>
    )
  }

  return (
    <Avatar image={image}>
      <img src={image} alt={name} title={name} />
    </Avatar>
  )
}
