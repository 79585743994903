import React, { useState } from 'react'
import Collapsed from './Collapsed'
import Expanded from './Expanded'
import { ContainerAccordion } from './styles'

export interface CollapsedAccordionHeaderProps {
  onClick: (event: unknown) => void
  ['data-id']: string
}

interface AccordionContentProps {
  // TODO: Ajustar tipagem
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children?: any
  // TODO: Ajustar tipagem
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  id?: any
  // TODO: Ajustar tipagem
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  initial?: any
  // TODO: Ajustar tipagem
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  separetedChildren?: any
}

interface AccordionItemContentProps {
  // TODO: Ajustar tipagem
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any
}

interface AccordionCollapsedContentProps {
  // TODO: Ajustar tipagem
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  id: any
  unique?: boolean
  // TODO: Ajustar tipagem
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (props?: any) => void
  HeaderComponent?: React.FC<CollapsedAccordionHeaderProps>
}

export const AccordionContent: React.FC<AccordionContentProps> & {
  Collapsed: React.FC<AccordionCollapsedContentProps>
} & { Expanded: React.FC } & {
  Item: React.FC<AccordionItemContentProps>
} = ({ children, initial, separetedChildren }) => {
  const [open, setOpen] = useState(initial)

  // TODO: Ajustar tipagem
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleOpen = (e: any) => {
    // TODO: tipar evento
    const id = e.target.getAttribute('data-id')
    id === open ? setOpen('') : setOpen(id)
  }

  return (
    <ContainerAccordion>
      {React.Children.map(children, (child) => {
        const id = child.props.children[0].props.id

        const collapsed = React.cloneElement(child.props.children[0], {
          handleOpen,
          open
        })

        const expanded = child.props.children[1]

        return (
          <React.Fragment key={id}>
            {collapsed}
            {id === open && expanded}
          </React.Fragment>
        )
      })}
      {separetedChildren}
    </ContainerAccordion>
  )
}

AccordionContent.Collapsed = Collapsed
AccordionContent.Expanded = Expanded
AccordionContent.Item = ({ children }) => children
