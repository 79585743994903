import React from 'react'

const quitBlank = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={16}
    height={15}
    viewBox='0 0 16 15'
    {...props}
  >
    <defs>
      <clipPath>
        <rect
          width={16}
          height={15}
          transform='translate(0 0)'
          fill='none'
          stroke='currentColor'
          strokeWidth={1.25}
        />
      </clipPath>
    </defs>
    <g transform='translate(0 0)'>
      <g clipPath='url(#clip-path)'>
        <line
          x1='4.338'
          y2='4.338'
          transform='translate(15.66 5.088) rotate(180)'
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth={1.25}
        />
        <path
          d='M11.668.756,15.658.75l-.009,3.987'
          transform='translate(26.977 5.838) rotate(180)'
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth={1.25}
        />
        <line
          y1='4.338'
          x2='4.338'
          transform='translate(5.088 14.492) rotate(180)'
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth={1.25}
        />
        <path
          d='M4.74,14.485l-3.99.006L.759,10.5'
          transform='translate(5.838 24.646) rotate(180)'
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth={1.25}
        />
        <line
          x2='4.338'
          y2='4.338'
          transform='translate(5.088 5.088) rotate(180)'
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth={1.25}
        />
        <path
          d='M4.74.756.75.75.759,4.737'
          transform='translate(5.838 5.838) rotate(180)'
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth={1.25}
        />
        <line
          x1='4.338'
          y1='4.338'
          transform='translate(15.66 14.492) rotate(180)'
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth={1.25}
        />
        <path
          d='M11.668,14.485l3.99.006L15.649,10.5'
          transform='translate(26.977 24.646) rotate(180)'
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth={1.25}
        />
      </g>
    </g>
  </svg>
)

export default quitBlank
