import React from 'react';

const SvgTime24 = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='currentColor'
      width={21}
      height={21}
      viewBox='0 0 21 21'
      {...props}
    >
      <path d='M20.995 10.503v-.012a7.327 7.327 0 00-.079-.876.53.53 0 10-1.044.179 6.581 6.581 0 01.064.724 9.452 9.452 0 11-1.513-5.135l-1.487-.087a.53.53 0 00-.062 1.058l2.857.168h.031a.531.531 0 00.53-.53V3.178a.53.53 0 10-1.06 0v1.525a10.482 10.482 0 101.548 7.922c0-.011.01-.019.013-.03a7.567 7.567 0 00.202-2.092z' />
      <path d='M9.792 9.279v-.063a2.178 2.178 0 00-2.176-2.175h-.119a2 2 0 00-2 2v.473a.53.53 0 101.06 0v-.48a.938.938 0 01.937-.936h.119a1.117 1.117 0 011.116 1.116v.063a1.361 1.361 0 01-.386.959l-2.63 2.407a.771.771 0 00.545 1.316h3a.53.53 0 000-1.06H7.001l2.073-1.9a2.422 2.422 0 00.718-1.72zM13.657 13.434a.53.53 0 101.06 0v-5.86a.53.53 0 00-1.06 0v2.56h-1.976V7.575a.53.53 0 00-1.06 0v3.089a.53.53 0 00.53.53h2.506v2.24z' />
    </svg>
  )
}

export default SvgTime24
