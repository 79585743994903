import React from 'react'
import { SvgProps } from './typings/SvgProps'

const TextSize: React.FC<SvgProps> = ({ color = 'currentColor', size, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    {...rest}
    viewBox="0 0 16.461 11.875"
  >
    <g transform="translate(-544.274 -490.5)">
      <g transform="translate(545.746 487.54)">
        <g transform="translate(4.389 2.959)">
          <path
            d="M4.116,14.4h1.97L7.12,11.483h4.6L12.746,14.4h1.97L10.433,3H8.4Zm3.6-4.567L9.367,5.117h.1l1.653,4.714Z"
            transform="translate(-4.116 -3)"
            fill={color}
          />
        </g>
      </g>
      <g transform="translate(-0.563 -0.246)">
        <path
          d="M2.445,0,0,2.445H1.747V9.43H0l2.445,2.445L4.89,9.43H3.144V2.445H4.89Z"
          transform="translate(544.836 490.746)"
          fill={color}
        />
      </g>
    </g>
  </svg>
)

export default TextSize
