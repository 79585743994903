import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { FormHelperText } from '@mui/material'

export type ProportionType = string | number

type IContainer = {
  proportion: readonly [ProportionType, ProportionType]
  hasError: boolean
}

const calculateProportion = ({ proportion }: IContainer) =>
  proportion
    .map(item => {
      if (typeof item === 'number') return `${item}fr`

      return item.endsWith('px') ? `${item}` : `${item}fr`
    })
    .join(' ')

export const Container = styled.div<IContainer>`
  --error-color: ${({ theme }) => theme.palette.error.main};
  display: grid;
  align-items: end;
  grid-template-columns: ${calculateProportion};

  div:not(:first-of-type) .MuiInputBase-root {
    margin-left: -1px;
  }

  div:first-of-type .MuiInputBase-root {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  div:last-of-type .MuiInputBase-root {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .MuiInputBase-root {
    position: relative;
    z-index: 10;

    &.Mui-focused {
      z-index: 20;
      color: ${({ theme }) => theme.palette.primary.main};
    }

    ${({ hasError }) =>
      hasError &&
      css`
        border-color: var(--error-color) !important;

        input {
          color: var(--error-color);
        }
      `}
  }

  .MuiInputLabel-root {
    ${({ hasError }) =>
      hasError &&
      css`
        color: var(--error-color);
      `};
  }
`

export const ErrorMessage = styled(FormHelperText)`
  font-size: ${({ theme }) => theme.font.size[1]};
`
