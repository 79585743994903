import styled from 'styled-components'

interface FilterQueuePatientItemProps {
  onClick?: () => void
  qGroupDescription?: string
  activeItem?: string
  length?: number
}

interface CountProps {
  length?: number
}

export const FilterQueuePatientCount = styled.span<CountProps>`
  background: ${(props) =>
    props.length && props.length > 0 ? '#f1f4f6' : 'transparent'};
  font-weight: 600;
  min-width: 25px;
  min-height: 25px;
  padding: 5px;
  text-align: center;
  border-radius: 4px;
  font-size: 12px;
`

export const FilterQueuePatientContainer = styled.div`
  margin-right: 10px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  user-select: none;
  :first-of-type {
    margin-top: 36px;
    padding-top: 12px;
  }
  :last-of-type {
    padding-bottom: 12px;
  }
`

export const FilterQueuePatientItem = styled.div<FilterQueuePatientItemProps>`
  padding: 10px;
  margin: 6px 0px 0px 14px;
  justify-content: space-between;
  align-items: center;
  display: flex;
  cursor: pointer;
  font-size: 14px;
  background: ${(props) =>
    props.activeItem === props.qGroupDescription ? '#f1f4f6' : 'transparent'};
  :hover {
    background: #f1f4f6;
  }
`
