import styled, { css } from 'styled-components'
import { theme } from '../../Theme'

interface MessageStyles {
  error?: boolean
}

const stateMessage = {
  error: css`
    color: ${theme.colors.red1};
  `
}

export const Message = styled.span<MessageStyles>`
  display: block;
  color: transparent;
  font-size: 12px;
  padding-top: 4px;
  position: absolute;
  bottom: -18px;
  left: 2px;

  ${({ error }) => (error ? stateMessage.error : null)};
`

export default Message
