import React from 'react'
import { CollapsedAccordionHeaderProps } from '..'

import IconExporter from '../../IconExporter'
import { AccordionHeader } from '../styles'

interface CollapsedProps {
  // TODO: tipar corretamente
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any
  // TODO: tipar corretamente
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  id: any
  // TODO: tipar corretamente
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleOpen: any
  open: boolean
  unique?: boolean
  // TODO: tipar corretamente
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (props?: any) => void
  HeaderComponent?: React.FC<CollapsedAccordionHeaderProps>
}

const Collapsed: React.FC<CollapsedProps> = ({
  children,
  id,
  handleOpen,
  open,
  unique,
  onClick,
  HeaderComponent
}) => {
  return (
    <AccordionHeader
      onClick={(e?: React.MouseEvent<HTMLDivElement>) =>
        !unique ? handleOpen(e) : onClick && onClick()
      }
      data-id={id}
      data-testid={id}
    >
      {!HeaderComponent ? (
        <div onClick={handleOpen} data-id={id} data-testid={id}>
          {children}
        </div>
      ) : (
        <HeaderComponent onClick={handleOpen} data-id={id} data-testid={id}>
          {children}
        </HeaderComponent>
      )}

      {!unique && (
        <React.Fragment>
          {id === open ? (
            <IconExporter
              onClick={handleOpen}
              name='chevron_open'
              data-id={id}
              iconsize={15}
            />
          ) : (
            <IconExporter
              onClick={handleOpen}
              iconsize={15}
              name='chevron'
              data-id={id}
            />
          )}
        </React.Fragment>
      )}
    </AccordionHeader>
  )
}

export default Collapsed
