import React from 'react';

const SvgTimeRoundedAlt = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='currentColor'
      width={21}
      height={21}
      viewBox='0 0 21 21'
      {...props}
    >
      <path d='M10.5 21A10.363 10.363 0 010 10.676a10.5 10.5 0 1121 0A10.363 10.363 0 0110.5 21zm0-19.895a9.557 9.557 0 00-9.395 9.57 9.4 9.4 0 0018.789 0 9.557 9.557 0 00-9.394-9.57z' />
      <path d='M14.493 13.8l-3.448-3.449V4.294a.68.68 0 00-1.36 0v6.339a.684.684 0 00.052.26.669.669 0 00.148.221l3.648 3.648a.68.68 0 00.962-.962z' />
    </svg>
  )
}

export default SvgTimeRoundedAlt
