import React from 'react'
import { SvgProps } from './typings/SvgProps'

const ProfileWarning: React.FC<SvgProps> = ({ color = 'currentColor', size, ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64.148 80.467" width={size} {...rest}>
    <g id="Group_161817" data-name="Group 161817" transform="translate(-693.316 -430)">
      <g
        id="Iconly_Bold_Profile"
        data-name="Iconly/Bold/Profile"
        transform="translate(693.316 430)"
      >
        <g id="Profile">
          <path
            id="Profile-2"
            data-name="Profile"
            d="M0,53.042c0-8.709,11.832-10.884,25.684-10.884,13.93,0,25.684,2.255,25.684,10.959S39.537,64,25.684,64C11.757,64,0,61.745,0,53.042Zm8.689-36.11a17,17,0,1,1,17,16.935A16.9,16.9,0,0,1,8.689,16.932Z"
            fill={color}
          />
        </g>
      </g>
      <path
        id="Path_26979"
        data-name="Path 26979"
        d="M15.467,0A15.467,15.467,0,1,1,0,15.467,15.467,15.467,0,0,1,15.467,0Z"
        transform="translate(726.531 479.533)"
        fill="#fff"
      />
      <path
        id="Path_26978"
        data-name="Path 26978"
        d="M726.4,349.2a12.8,12.8,0,1,0,0,25.6,12.8,12.8,0,1,0,0-25.6Zm-1.1,7.9a1.1,1.1,0,1,1,2.2,0v5.7a1.1,1.1,0,1,1-2.2,0Zm1.1,10.9a1.1,1.1,0,0,1,0-2.2h0a1.1,1.1,0,0,1,0,2.2Z"
        transform="translate(15.398 132.8)"
        fill={color}
      />
    </g>
  </svg>
)

export default ProfileWarning
