// eslint-disable-next-line no-shadow
export enum ERichText {
  bold = 'bold',
  italic = 'italic',
  underline = 'underline',
  paragraph = 'paragraph',
  left = 'left',
  center = 'center',
  right = 'right',
  ul = 'ul',
  ol = 'ol',
}

export const alignOptions = [
  {
    label: 'Alinhar à esquerda',
    value: 'left',
  },
  {
    label: 'Alinhar no centro',
    value: 'center',
  },
  {
    label: 'Alinhar à direita',
    value: 'right',
  },
]

export const fontSizeOptions = [
  {
    label: '14px',
    value: '14px',
  },
  {
    label: '16px',
    value: '16px',
  },
  {
    label: '24px',
    value: '24px',
  },
  {
    label: '32px',
    value: '32px',
  },
]

export const colorOptions = [
  {
    label: '#292929',
    value: '#292929',
  },
  {
    label: '#FFFFFF',
    value: '#FFFFFF',
  },
  {
    label: '#A7ABB2',
    value: '#A7ABB2',
  },
  {
    label: '#5A6A7E',
    value: '#5A6A7E',
  },
  {
    label: '#2699FB',
    value: '#2699FB',
  },
  {
    label: '#257CE5',
    value: '#257CE5',
  },
  {
    label: '#4CAF50',
    value: '#4CAF50',
  },
  {
    label: '#FBB324',
    value: '#FBB324',
  },
  {
    label: '#FA8722',
    value: '#FA8722',
  },
  {
    label: '#F82425',
    value: '#F82425',
  },
  {
    label: '#7C51CE',
    value: '#7C51CE',
  },
  {
    label: '#122D52',
    value: '#122D52',
  },
]
