import React from 'react'

const SvgCalendar = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={18.152}
      height={18.786}
      viewBox='0 0 19 19'
      {...props}
    >
      <g transform='translate(-122.393 -49.153)'>
        <path d='M137.737 50.818h-1.831V49.9a.75.75 0 00-1.5 0v.915h-5.875V49.9a.75.75 0 00-1.5 0v.915H125.2a2.81 2.81 0 00-2.806 2.807v11.51a2.81 2.81 0 002.806 2.807h12.538a2.811 2.811 0 002.808-2.807V53.625a2.811 2.811 0 00-2.809-2.807zm-12.538 1.5h1.832v.677a.75.75 0 001.5 0v-.677h5.875v.677a.75.75 0 001.5 0v-.677h1.831a1.309 1.309 0 011.308 1.307v1.951h-15.152v-1.951a1.308 1.308 0 011.307-1.307zm12.538 14.121H125.2a1.308 1.308 0 01-1.306-1.307v-8.056h15.152v8.056a1.309 1.309 0 01-1.309 1.307z' />
        <rect
          width={1.678}
          height={1.678}
          rx={0.542}
          transform='translate(130.579 59.075)'
        />
        <rect
          width={1.678}
          height={1.678}
          rx={0.542}
          transform='translate(135.156 59.075)'
        />
        <rect
          width={1.678}
          height={1.678}
          rx={0.542}
          transform='translate(130.579 62.762)'
        />
        <rect
          width={1.678}
          height={1.678}
          rx={0.542}
          transform='translate(135.156 62.762)'
        />
        <rect
          width={1.678}
          height={1.678}
          rx={0.542}
          transform='translate(126.103 62.762)'
        />
      </g>
    </svg>
  )
}

export default SvgCalendar
