import React from 'react'
import { theme } from 'theme'
import { SvgProps, WithSecondaryColor } from './typings/SvgProps'

const MicroActive: React.FC<SvgProps & WithSecondaryColor> = ({
  color = 'currentColor',
  size,
  secondaryColor = theme.colors.neutral[50],
  ...rest
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill={color} width={size} {...rest} viewBox="0 0 16 19">
    <g transform="translate(0.6 0.6)">
      <g>
        <path
          d="M0,0A7.5,7.5,0,1,0,15,0"
          transform="translate(0 8.326)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          strokeWidth={1.25}
        />
        <path
          d="M.476,2.847V0"
          transform="translate(7.025 15.828)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          strokeWidth={1.25}
        />
        <path
          d="M3.64,12.784H3.391A3.4,3.4,0,0,1-.223,9.644c0-1.316-1.15-2.17,0-2.872,2.465.113,5.924-.012,7.477,0,.924.34,0,1.847,0,2.872A3.4,3.4,0,0,1,3.64,12.784Z"
          transform="translate(4.4 -0.758)"
          fill={secondaryColor}
        />
        <path
          d="M3.64,12.784H3.391A3.4,3.4,0,0,1-.223,9.644c0-1.316-1.15-2.17,0-2.872,2.465.113,5.924-.012,7.477,0,.924.34,0,1.847,0,2.872A3.4,3.4,0,0,1,3.64,12.784Z"
          transform="translate(4.036 -0.758)"
          fill={secondaryColor}
        />
        <path
          d="M3.964,12.027H3.708A3.708,3.708,0,0,1,0,8.318V3.708A3.709,3.709,0,0,1,3.708,0h.256A3.709,3.709,0,0,1,7.672,3.708v4.61A3.708,3.708,0,0,1,3.964,12.027Z"
          transform="translate(3.665)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          strokeWidth={1.25}
        />
      </g>
    </g>
  </svg>
)

export default MicroActive
