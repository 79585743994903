import React from 'react'

const SvgClipboardText = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      data-name='Componente 427 \u2013 50'
      xmlns='http://www.w3.org/2000/svg'
      width={31}
      height={33}
      viewBox='0 0 31 33'
      {...props}
    >
      <g
        fill='none'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit={10}
        strokeWidth={1.5}
      >
        <path data-name='Stroke 1' d='M18.988 20.674h-6.932' />
        <path data-name='Stroke 2' d='M18.988 16.58h-6.932' />
        <path data-name='Stroke 3' d='M14.701 12.496h-2.645' />
        <path
          data-name='Stroke 4'
          d='M19.173 7.5h-7.382C9.141 7.521 7.5 9.3 7.5 12v9c0 2.718 1.653 4.5 4.326 4.5h7.382c2.65-.02 4.292-1.8 4.292-4.5v-9c0-2.718-1.654-4.5-4.327-4.5z'
        />
      </g>
      <path data-name='Ret\xE2ngulo 3794' fill='none' d='M0 0h31v33H0z' />
    </svg>
  )
}

export default SvgClipboardText
