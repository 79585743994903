import * as React from 'react'
import { Icon } from 'components/atoms'
import { Button, Container, ContainerTooltip, Tooltip } from './styles'
import { IconOptions } from '../../../../atoms/Icon/icon-list'
import { SvgProps } from '../../../../../icons/typings/SvgProps'

interface ButtonIconProps extends SvgProps {
  icon: IconOptions
  onClick?: () => void
  selected?: boolean
}

const ButtonIcon = React.forwardRef<HTMLButtonElement, ButtonIconProps>(
  ({ icon, onClick, selected, ...rest }, ref) => (
    <Button
      type="button"
      ref={ref}
      onClick={onClick}
      $selected={selected}
      data-testid="rich-text-toolbar-button"
      {...rest}
    >
      <Icon icon={icon} size={rest.size} />
    </Button>
  ),
)

export interface ToolbarButtonProps extends ButtonIconProps {
  tooltipTitle: string
}

export const ToolbarButton = React.forwardRef<HTMLButtonElement, ToolbarButtonProps>(
  ({ icon, onClick, selected, tooltipTitle, ...rest }, ref) => (
    <Container>
      {selected ? (
        <ButtonIcon
          ref={ref}
          onClick={onClick}
          selected={selected}
          data-testid="rich-text-toolbar-button"
          icon={icon}
          {...rest}
        />
      ) : (
        <Tooltip
          title={<ContainerTooltip>{tooltipTitle}</ContainerTooltip>}
          element={
            <ButtonIcon
              ref={ref}
              onClick={onClick}
              selected={selected}
              data-testid="rich-text-toolbar-button"
              icon={icon}
              {...rest}
            />
          }
          placement="bottom"
        />
      )}
    </Container>
  ),
)
