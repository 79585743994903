/* eslint-disable no-unneeded-ternary */
import styled, { CSSProperties } from 'styled-components'
import { theme } from '../Theme'

export interface SlideModalContainerProps {
  openSlideModal: boolean
  leftSpace: string
  hideBackgroundOpacity?: boolean
  backgroundOpacity?: number
  style?: CSSProperties
  calculateWithSideBar?: boolean
}

export const Container = styled.div<SlideModalContainerProps>`
  position: fixed;
  display: flex;
  width: ${({ leftSpace, calculateWithSideBar }) =>
    `calc(100% - (${leftSpace} ${calculateWithSideBar && '+ 70px'}))`};
  height: auto;
  background-color: ${({ hideBackgroundOpacity, backgroundOpacity }) =>
    hideBackgroundOpacity
      ? 'rgba(0, 0, 0, 0.0)'
      : `rgba(0, 0, 0, ${backgroundOpacity ?? 0.3})`};
  transition: ${({ style }) => (style?.transition ? style.transition : '0.5s')};
  z-index: ${({ style }) => (style?.zIndex ? style.zIndex : 1)};
  transform: ${({ openSlideModal, leftSpace }) =>
    openSlideModal ? `translateX(${leftSpace})` : 'translateX(-100vw)'};
`
export interface SlideModalContentProps {
  contentStyle?: CSSProperties
}

export const Content = styled.div<SlideModalContentProps>`
  height: ${({ contentStyle }) =>
    contentStyle?.height ? contentStyle.height : '100vh'};
  width: ${({ contentStyle }) =>
    contentStyle?.width ? contentStyle.width : '80%'};
  background-color: ${({ contentStyle }) =>
    contentStyle?.backgroundColor
      ? contentStyle.backgroundColor
      : `${theme.colors.white}`};
  overflow-y: ${({ contentStyle }) =>
    contentStyle?.overflowY ? contentStyle.overflowY : 'auto'};
  padding-bottom: ${({ contentStyle }) =>
    contentStyle?.paddingBottom ? contentStyle.paddingBottom : '10%'};
`

export const CloseModal = styled.div<SlideModalContentProps>`
  display: flex;
  flex: 1;
  cursor: pointer;
`

export const CloseIconContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`
export interface CloseIconContentProps {
  paddingCloseIconContent?: string
}

export const CloseIconContent = styled.div<CloseIconContentProps>`
  width: 18px;
  height: 18px;
  cursor: pointer;
  padding-top: ${({ paddingCloseIconContent }) =>
    paddingCloseIconContent ? paddingCloseIconContent : '7%'};
  margin-right: 30px;

  & svg {
    fill: ${theme.colors.gray4};
  }
`
