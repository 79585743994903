import React from 'react'
import { SvgProps } from './typings/SvgProps'

const AttentionRounded: React.FC<SvgProps> = ({ color = 'currentColor', size, ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} {...rest} viewBox="0 0 21 21">
    <path
      d="M10.5 20.999a10.5 10.5 0 1110.5-10.5 10.512 10.512 0 01-10.5 10.5zm0-19.895a9.395 9.395 0 109.395 9.395A9.405 9.405 0 0010.5 1.105z"
      fill={color}
      stroke={color}
      strokeWidth="0.1"
    />
    <path
      d="M10.507 15.594a1.031 1.031 0 01-.773-.3 1.051 1.051 0 01-.291-.767 1.009 1.009 0 01.291-.745 1.048 1.048 0 01.773-.291 1.02 1.02 0 01.774.291 1.04 1.04 0 01.276.745 1.085 1.085 0 01-.276.767 1 1 0 01-.774.3zm0-2.951q-.553 0-.6-.653l-.422-5.424a1.116 1.116 0 01.227-.843.98.98 0 01.794-.319.944.944 0 01.774.319 1.132 1.132 0 01.219.843l-.426 5.421q-.041.656-.566.656z"
      fill={color}
      stroke={color}
      strokeWidth="0.1"
    />
  </svg>
)

export default AttentionRounded
