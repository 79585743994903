import React from 'react'
import { theme } from 'theme'
import { SvgProps, WithSecondaryColor } from './typings/SvgProps'

const DriverSupp: React.FC<SvgProps & WithSecondaryColor> = ({
  color = 'currentColor',
  secondaryColor = theme.colors.neutral[50],
  size,
  ...rest
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill={color} width={size} {...rest} viewBox="0 0 21 18">
    <g transform="translate(-22476 3585)">
      <rect
        data-name="Rectangle 141798"
        width="14"
        height="12"
        rx="2"
        transform="translate(22476 -3585)"
        fill={color}
      />
      <path
        data-name="Polygon 8"
        d="M4.5,0,9,8H0Z"
        transform="translate(22486 -3574.5) rotate(-90)"
        fill={color}
      />
      <g
        data-name="Rectangle 141799"
        transform="translate(22486 -3578)"
        fill={secondaryColor}
        stroke={theme.colors.neutral[10]}
        strokeWidth="0.8"
      >
        <rect width="11" height="11" rx="2" stroke="none" />
        <rect x="-0.4" y="-0.4" width="11.8" height="11.8" rx="2.4" fill="none" />
      </g>
      <path
        data-name="Path 27556"
        d="M10.35,5.539,9.811,5,7.675,7.136,5.539,5,5,5.539,7.136,7.675,5,9.811l.539.539L7.675,8.214,9.811,10.35l.539-.539L8.214,7.675Z"
        transform="translate(22483.824 -3580.175)"
        fill={theme.colors.neutral[10]}
      />
    </g>
  </svg>
)

export default DriverSupp
