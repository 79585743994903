import React from 'react'
import { theme } from 'theme'
import { SvgProps, WithSecondaryColor } from './typings/SvgProps'

const StopButton: React.FC<SvgProps & WithSecondaryColor> = ({
  color = 'currentColor',
  secondaryColor = theme.colors.neutral[70],
  size,
  ...rest
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill={color} width={size} {...rest} viewBox="0 0 24 24">
    <g transform="translate(-1040 -613)">
      <circle cx="12" cy="12" r="12" transform="translate(1040 613)" fill={color} opacity="0.197" />
      <g transform="translate(-1.5 -1.07)">
        <line
          y2="8"
          transform="translate(1051.5 622.07)"
          fill="none"
          stroke={secondaryColor}
          strokeLinecap="round"
          strokeWidth="1.8"
        />
        <line
          y2="8"
          transform="translate(1055.5 622.07)"
          fill="none"
          stroke={secondaryColor}
          strokeLinecap="round"
          strokeWidth="1.8"
        />
      </g>
    </g>
  </svg>
)

export default StopButton
