import React from 'react'
import { ThemeProvider as Provider } from 'styled-components'
import { theme as th, DefaultTheme } from '../Theme'

interface Props {
  theme?: DefaultTheme
}

const ThemeProvider: React.FC<Props> = ({ children, theme }) => {
  return <Provider theme={theme ?? th}>{children}</Provider>
}

export default ThemeProvider
