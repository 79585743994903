import styled from 'styled-components'

import { theme } from '../../Theme'

export const CheckboxWrapper = styled.label`
  text-transform: uppercase;
  font-size: 12px;
  font-weight: ${theme.fontWeight.regular};
  user-select: none;
  position: relative;
  height: fit-content;
  display: flex;
  align-items: center;

  input[type='checkbox']:checked + label {
    color: ${theme.colors.blue3};

    &:before {
      background: ${theme.colors.blue3};
      border: 1px solid ${theme.colors.blue3};
    }

    &:after {
      border-bottom: 2px solid ${theme.colors.white};
      border-left: 2px solid ${theme.colors.white};
    }
  }
`

export const CheckboxElement = styled.input`
  visibility: hidden;
  width: 1px;
  background-color: initial;
  cursor: default;
  appearance: auto;
  box-sizing: border-box;
  margin: 3px 3px 3px 4px;
  padding: initial;
  border: initial;
`

export const Label = styled.label`
  cursor: pointer;
  display: flex;
  align-items: center;
  color: ${theme.colors.gray4};
  padding-left: 22px;

  &:hover:before {
    border: 1px solid ${theme.colors.blue3};
  }

  &:before {
    content: '';
    width: 20px;
    height: 20px;
    position: absolute;
    background: ${theme.colors.white};
    border: 1px solid #b9c2ce;
    left: 0;
    border-radius: ${theme.borderRadius.checkbox};
    transition: all 0.2s;
  }

  &:after {
    content: '';
    width: 8px;
    height: 4px;
    border-bottom: 2px solid transparent;
    border-left: 2px solid transparent;
    transform: rotate(-45deg) translate(1px, -1px);
    position: absolute;
    left: 6px;
  }
`
