import React from 'react'

const SvgStarIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 41.011 39.111'
      width={41.011}
      height={39.111}
      {...props}
    >
      <g
        id='Componente_10064_18'
        data-name='Componente 10064 – 18'
        transform='translate(0.505 0.5)'
      >
        <path
          id='Caminho_27173'
          data-name='Caminho 27173'
          d='M99.891,25.828c.4-.8,1.042-.8,1.437,0l4.987,10.1a3.473,3.473,0,0,0,2.326,1.69l11.15,1.624c.884.128,1.084.744.444,1.367l-8.069,7.865a3.469,3.469,0,0,0-.888,2.734l1.9,11.1c.151.88-.373,1.26-1.163.845l-9.972-5.247a3.469,3.469,0,0,0-2.875,0L89.2,63.157c-.791.416-1.314.036-1.163-.845l1.9-11.1a3.469,3.469,0,0,0-.888-2.734l-8.069-7.865c-.64-.624-.44-1.238.444-1.367l11.15-1.624a3.468,3.468,0,0,0,2.326-1.69Z'
          transform='translate(-80.609 -25.227)'
          strokeWidth='1.5'
        />
      </g>
    </svg>
  )
}

export default SvgStarIcon
