import React from 'react';

const SvgClipboard = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={14.8}
      height={18.05}
      viewBox='0 0 14 18'
      {...props}
    >
      <path d='M12.275 1.625h-.5A1.9 1.9 0 009.903 0h-5a1.9 1.9 0 00-1.872 1.625h-.5A2.528 2.528 0 00.006 4.15v11.375a2.528 2.528 0 002.525 2.525h9.75a2.528 2.528 0 002.525-2.525V4.146a2.528 2.528 0 00-2.531-2.521zM4.9 1.8h5a.1.1 0 01.1.1v.009a.891.891 0 00-.25.616.877.877 0 00.154.474H4.896a.877.877 0 00.154-.474.891.891 0 00-.25-.616V1.9a.1.1 0 01.1-.1zM13 15.525a.726.726 0 01-.725.725h-9.75a.726.726 0 01-.725-.725V4.146a.726.726 0 01.725-.725h.558A1.9 1.9 0 004.9 4.796h5a1.9 1.9 0 001.817-1.375h.558a.726.726 0 01.725.725z' />
    </svg>
  )
}

export default SvgClipboard
