/* eslint-disable import/no-cycle */
import getColor from '@cockpit/levi-ui/helpers/getColor'
import { theme } from '@cockpit/levi-ui/theme'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { getColors } from 'helpers'
import { ITableVariant } from './typing'

export const ButtonsContainer = styled.div`
  position: absolute;
  cursor: pointer;
  top: 0px;
  left: -30px;
  width: 30px;
  height: 100%;
`

export const EditButton = styled.div<{ hasDeleteButton?: boolean }>`
  display: none;
  height: ${({ hasDeleteButton }) => (hasDeleteButton ? '50%' : '100%')};
  z-index: 1;
  position: relative;
  border-top-left-radius: 4px;
  border-bottom-left-radius: ${({ hasDeleteButton }) => (hasDeleteButton ? '0px' : '4px')};
  justify-content: center;
  align-items: center;
  background-color: ${getColors('neutral', 30)};
  color: ${getColors('neutral', 70)};
`

export const DeleteButton = styled.div<{ hasEditButton?: boolean }>`
  display: none;
  height: ${({ hasEditButton }) => (hasEditButton ? '50%' : '100%')};
  z-index: 1;
  position: relative;
  border-top-left-radius: ${({ hasEditButton }) => (hasEditButton ? '0px' : '4px')};
  border-bottom-left-radius: 4px;
  justify-content: center;
  align-items: center;
  background-color: ${getColors('neutral', 30)};
  color: ${getColors('neutral', 70)};
`

const tableStyles = {
  thColor: {
    default: theme.colors.neutral[60],
    clean: theme.colors.neutral[70],
  },
  border: {
    default: `1px solid ${theme.colors.neutral[50]}`,
    clean: 'none',
  },
  tdBorderBottom: {
    default: 'none',
    clean: `1px solid ${theme.colors.neutral[30]}`,
  },
}

export const TableContainer = styled.table<{ variant: ITableVariant }>`
  th,
  td {
    &:not(.no-padding) {
      padding: ${theme.spacings[2]} ${theme.spacings[4]};
    }
    font-size: ${theme.font.size[2]};
  }
  td {
    color: ${getColors('neutral', 80)};
    background-color: ${getColors('neutral', 10)};
    border-left: ${({ variant }) => tableStyles.border[variant]};
    border-top: ${({ variant }) => tableStyles.border[variant]};
    border-bottom: ${({ variant }) => tableStyles.tdBorderBottom[variant]};
    &:last-child {
      border-right: ${({ variant }) => tableStyles.border[variant]};
    }
  }
  th {
    color: ${({ variant }) => tableStyles.thColor[variant]};
    border-left: ${({ variant }) => tableStyles.border[variant]};
    border-top: ${({ variant }) => tableStyles.border[variant]};
    font-weight: normal;
  }
  thead {
    th {
      background-color: ${getColors('neutral', 20)};
      &:first-child {
        border-top-left-radius: 0.25rem;
      }
      &:last-child {
        border-top-right-radius: 0.25rem;
        border-right: ${({ variant }) => tableStyles.border[variant]};
      }
    }
  }
  tbody {
    &:first-child {
      td {
        &:first-child {
          border-top-left-radius: 0.25rem;
        }
        &:last-child {
          border-top-right-radius: 0.25rem;
        }
      }
    }
    tr {
      &.levi-tr-editable {
        position: relative;
        &:hover td {
          &:first-child {
            border-bottom-left-radius: 0;
          }
          background-color: ${getColors('neutral', 20)};
          cursor: default;
          .levi-tr-edit-button,
          .levi-tr-delete-button {
            display: flex;
          }
        }
      }
      &:last-child {
        td {
          border-bottom: ${({ variant }) => tableStyles.border[variant]};
          &:first-child {
            border-bottom-left-radius: 0.25rem;
          }
          &:last-child {
            border-bottom-right-radius: 0.25rem;
          }
        }
      }
    }
  }
`
