import {
  useLayoutEffect,
  MutableRefObject,
  useState,
  useRef,
  useMemo
} from 'react'
import { GetNewPosition } from './util'

interface Position {
  positionX: 'left' | 'right' | null
  positionY: 'top' | 'bottom' | null
}

export const useGetPosition = () => {
  const initPosition = {
    positionX: null,
    positionY: null
  }
  const tooltip: MutableRefObject<null | HTMLElement> = useRef(null)
  const [position, setPosition] = useState<Position>(initPosition)
  const sizeWidthDoc: number = document.documentElement.clientWidth
  const sizeHeightDoc: number = document.documentElement.clientHeight
  const { getNewPositionX, getNewPositionY } = useMemo(
    () => new GetNewPosition(),
    []
  )

  useLayoutEffect(() => {
    tooltip?.current &&
      setPosition({
        positionX: getNewPositionX(tooltip.current, sizeWidthDoc),
        positionY: getNewPositionY(tooltip.current, sizeHeightDoc)
      })
  }, [sizeWidthDoc, sizeHeightDoc])

  useLayoutEffect(() => {
    setPosition((prevPosition) =>
      tooltip?.current
        ? {
            positionX:
              prevPosition.positionX ||
              getNewPositionX(tooltip.current, sizeWidthDoc),
            positionY:
              prevPosition.positionY ||
              getNewPositionY(tooltip.current, sizeHeightDoc)
          }
        : prevPosition
    )
  }, [position.positionX, position.positionY])

  return {
    positionX: position.positionX,
    positionY: position.positionY,
    tooltip
  }
}
