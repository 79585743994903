import React from 'react'
import { SvgProps } from './typings/SvgProps'

const AddCircle: React.FC<SvgProps> = ({ color = 'currentColor', size, ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} viewBox="0 0 40 40" {...rest}>
    <path
      d="M20,0A20,20,0,1,0,40,20,20.059,20.059,0,0,0,20,0Zm9.1,21H21v8.1a1,1,0,0,1-1,1h0a1,1,0,0,1-1-1V21H10.9a1,1,0,0,1-1-1h0a1,1,0,0,1,1-1H19V10.9a1,1,0,0,1,1-1h0a1,1,0,0,1,1,1V19h8.1a1,1,0,0,1,1,1h0A1.075,1.075,0,0,1,29.1,21Z"
      fill={color}
    />
  </svg>
)

export default AddCircle
