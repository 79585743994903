import React from 'react'

const SvgDoctor = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      stroke='currentColor'
      width={17.8}
      height={19.85}
      viewBox='0 0 21 21'
      {...props}
    >
      <g transform='translate(-3.4 -2.3)'>
        <path
          fill='none'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth={1.8}
          d='M20.3 21.2v-2a4.012 4.012 0 00-4-4h-8a4.012 4.012 0 00-4 4v2'
        />
        <circle
          fill='none'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth={1.8}
          cx={4}
          cy={4}
          r={4}
          transform='translate(8.3 3.2)'
        />
        <circle
          className='doctor_svg__b'
          cx={1.6}
          cy={1.6}
          r={1.6}
          transform='translate(7.8 18.3)'
        />
        <path
          fill='none'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth={1.8}
          d='M9.4 15.8V18M15.2 15.8V18'
        />
        <path
          className='doctor_svg__b'
          d='M13.2 21.4v-.9a2.034 2.034 0 012.2-2.2M13.9 21.4h-.6'
        />
        <path
          className='doctor_svg__b'
          d='M17.3 21.4v-.9a2.034 2.034 0 00-2.2-2.2M16.6 21.4h.6'
        />
      </g>
    </svg>
  )
}

export default SvgDoctor
