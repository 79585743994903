import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { Container, Tab, TabContainer, Wrapper } from './styles'

export interface IFormTabs {
  labels: string[]
  children: JSX.Element[]
  onChange?: (index: number) => void
  defaultIndex?: number
}

export interface ForwardRefFormTabsProps {
  /**
   * Função que recebe um index como parâmetro e faz a ação de onClick no item da tab via ref
   * @returns não retorna valor
   */
  onClick: (index: number) => void
}

const FormTabs = forwardRef<ForwardRefFormTabsProps, IFormTabs>(
  ({ labels, children, onChange, defaultIndex = 0 }, ref) => {
    const [selectedIndex, setSelectedIndex] = useState<number>(defaultIndex)

    const updateSelectedIndex = (index: number) => {
      if (selectedIndex !== index) {
        onChange?.(index)
        setSelectedIndex(index)
      }
    }

    useImperativeHandle(ref, () => ({
      onClick: updateSelectedIndex,
    }))

    return (
      <Container data-testid="form-tabs-container">
        <TabContainer className="levi-tab-container">
          {labels.map((label, index) => (
            <Tab
              data-testid="tab-button"
              key={`tab-${index}`}
              onClick={() => updateSelectedIndex(index)}
              active={selectedIndex === index}
            >
              {label}
            </Tab>
          ))}
        </TabContainer>
        {children.map((content, index) => {
          const isActive = selectedIndex === index
          return (
            <Wrapper
              className={isActive ? 'active-content' : ''}
              data-testid="form-tabs-content"
              key={`content-${index}`}
              active={isActive}
            >
              {content}
            </Wrapper>
          )
        })}
      </Container>
    )
  },
)

export default FormTabs
