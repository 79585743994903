import { theme } from '../Theme'
import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  flex: 1;
  text-align: center;
  margin-top: ${theme.spacing.large};
  margin-bottom: ${theme.spacing.medium};
  & svg {
    fill: ${theme.colors.blue3};
  }
`

interface ILoading {
  isLoading: boolean
}

export const Title = styled.div<ILoading>`
  font-size: 16px;
  font-weight: ${({ isLoading }) =>
    isLoading ? theme.fontWeight.semibold : theme.fontWeight.medium};
  margin-top: 32px;
`

export const ContentLoading = styled.div<ILoading>`
  opacity: ${({ isLoading }) => (isLoading ? 1 : 0)};
  transition: opacity 0.3s;
  transition: all 0.3s;
  color: #2e384d;
  font-size: 16px;
  grid-area: 1 / 1;
`

export const ContentLoaded = styled.div<ILoading>`
  opacity: ${({ isLoading }) => (!isLoading ? 1 : 0)};
  visibility: ${({ isLoading }) => (!isLoading ? 'visible' : 'hidden')};
  transition: visibility 0.3s 0.3s;
  font-weight: ${theme.fontWeight.medium};
  color: #545e6c;
  font-size: 20px;
  grid-area: 1 / 1;
`
