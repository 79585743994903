import React from 'react'
import { SvgProps } from './typings/SvgProps'

const CalendarEmpty: React.FC<SvgProps> = ({ color = 'currentColor', size, ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} {...rest} viewBox="0 0 13.798 15.176">
    <g transform="translate(0.7 0.7)">
      <g>
        <path
          d="M0,.473H12.277"
          transform="translate(0.064 4.627)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1.4"
        />
        <path
          d="M.463,0V2.267"
          transform="translate(8.521 0)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1.4"
        />
        <path
          d="M.463,0V2.267"
          transform="translate(2.957 0)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1.4"
        />
        <path
          d="M9.118,0H3.286A2.964,2.964,0,0,0,0,3.2V9.431a2.992,2.992,0,0,0,3.286,3.257H9.112a2.966,2.966,0,0,0,3.286-3.2V3.2A2.953,2.953,0,0,0,9.118,0Z"
          transform="translate(0 1.088)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1.4"
        />
      </g>
    </g>
  </svg>
)

export default CalendarEmpty
