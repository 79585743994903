import React from 'react'

const SvgAttentionTriangle = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
      width={22.657}
      height={20.649}
      viewBox='0 0 23 23'
      {...props}
    >
      <path d='M9.005 1.355L.35 16.665a2.67 2.67 0 002.324 3.984h17.31a2.669 2.669 0 002.324-3.984l-8.655-15.31a2.67 2.67 0 00-4.648 0z' />
      <path
        fill='#fff'
        d='M11.337 17.11a1.159 1.159 0 01-.868-.334 1.181 1.181 0 01-.326-.861 1.133 1.133 0 01.326-.837 1.176 1.176 0 01.868-.327 1.144 1.144 0 01.869.327 1.164 1.164 0 01.31.837 1.215 1.215 0 01-.31.861 1.128 1.128 0 01-.869.334zm0-3.314q-.622 0-.669-.733l-.478-6.088a1.254 1.254 0 01.255-.948 1.1 1.1 0 01.892-.359 1.059 1.059 0 01.869.359 1.273 1.273 0 01.246.948l-.478 6.088q-.047.733-.637.732z'
      />
    </svg>
  )
}

export default SvgAttentionTriangle
