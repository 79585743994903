import React from 'react'
import { SvgProps } from './typings/SvgProps'

const TextColor: React.FC<SvgProps> = ({ color = 'currentColor', size, ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} {...rest} viewBox="0 0 15 15">
    <g>
      <rect width={size} height={size} fill="none" />
    </g>
    <g transform="translate(1.212 1.819)">
      <path
        d="M2,13.306H14.125v2.425H2Zm2.116-1.819H5.583l.77-2.17H9.778l.764,2.17h1.467L8.82,3H7.3Zm2.68-3.4,1.231-3.51H8.1l1.231,3.51Z"
        transform="translate(-2 -3)"
        fill={color}
      />
    </g>
  </svg>
)

export default TextColor
