import React from 'react'

import IconExporter from '../../IconExporter'
import { StringIcons } from '../../Resources/SvgComponents'
import { Action, Box, Divider, InputForValue, TagWrapper } from './styles'

interface Props {
  icon: StringIcons
  onClick?: () => void
  value?: string
  register?: any
  name?: string
  selected?: boolean
  onClose?: () => void
  small?: boolean
  background?: string
  hasDivider?: boolean
}

export const TagEditable: React.FC<Props> = ({
  icon,
  onClick,
  value,
  register,
  name,
  onClose,
  selected,
  background,
  hasDivider,
  ...props
}) => {
  const showDivider = hasDivider === undefined || hasDivider
  return (
    <TagWrapper
      selected={selected}
      background={background}
      showDivider={showDivider}
    >
      <InputForValue
        cursor={onClick}
        name={name}
        ref={register}
        value={value}
        {...props}
      />
      <Box onClick={onClick}>
        <p>{value}</p>
      </Box>

      {showDivider && <Divider />}

      <Action selected={selected} type='button' onClick={onClose}>
        <IconExporter name={icon} iconsize={20} />
      </Action>
    </TagWrapper>
  )
}
