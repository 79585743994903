import React from 'react'
import { SvgProps } from './typings/SvgProps'

const Clock: React.FC<SvgProps> = ({ color = 'currentColor', size, ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} {...rest} viewBox="0 0 16.5 16.5">
    <g transform="translate(0.75 0.75)">
      <g transform="translate(-0.751 -0.75)">
        <path
          d="M11.014,0H3.985A3.864,3.864,0,0,0,0,4.189v6.623A3.86,3.86,0,0,0,3.985,15h7.028A3.861,3.861,0,0,0,15,10.811V4.189A3.861,3.861,0,0,0,11.014,0Z"
          transform="translate(0.751 0.75)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1.5"
        />
        <path
          d="M2.749,5.176,0,3.536V0"
          transform="translate(8.25 4.71)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1.5"
        />
      </g>
    </g>
  </svg>
)

export default Clock
