import React from 'react'
import { SvgProps } from './typings/SvgProps'

const Pencil: React.FC<SvgProps> = ({ color = 'currentColor', size, ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} {...rest} viewBox="0 0 15.206 15.2">
    <g transform="translate(0.606 0.6)">
      <g transform="translate(-4.25 -4.25)">
        <g transform="translate(4.25 4.25)">
          <path
            d="M0,.5H5.641"
            transform="translate(8.359 13.5)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="1.2"
          />
          <path
            d="M7.612.631A1.71,1.71,0,0,1,10.149.494l1.23.975a1.753,1.753,0,0,1,.474,2.524L4.52,13.455a1.3,1.3,0,0,1-1.017.508L.675,14l-.64-2.787a1.341,1.341,0,0,1,.245-1.12Z"
            transform="translate(0 0)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="1.2"
          />
          <path
            d="M0,0,4.241,3.362"
            transform="translate(6.239 2.403)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="1.2"
          />
        </g>
      </g>
    </g>
  </svg>
)

export default Pencil
