import React from 'react';

const EyeOnIcon = (props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="14" viewBox="0 0 19.213 14.308" {...props}>
            <g transform="translate(-413.372 -292.185)">
                <g transform="translate(413.372 292.185)">
                    <g>
                        <g>
                            <path d="M422.979,306.493c-6.033,0-9.4-6.6-9.543-6.88a.621.621,0,0,1,0-.549c.142-.281,3.508-6.88,9.543-6.88s9.4,6.6,9.542,6.88a.613.613,0,0,1,0,.549C432.38,299.889,429.012,306.493,422.979,306.493Zm-8.3-7.155c.684,1.2,3.673,5.929,8.3,5.929s7.614-4.726,8.3-5.928c-.684-1.2-3.672-5.929-8.3-5.929s-7.614,4.725-8.3,5.929Z" transform="translate(-413.372 -292.185)" fill="currentColor" />
                        </g>
                        <g transform="translate(6.542 4.088)">
                            <path d="M421.661,301.581a3.067,3.067,0,1,1,3.067-3.067h0A3.07,3.07,0,0,1,421.661,301.581Zm0-4.906a1.84,1.84,0,1,0,1.84,1.84h0a1.84,1.84,0,0,0-1.84-1.835Z" transform="translate(-418.594 -295.448)" fill="currentColor" />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default EyeOnIcon
