import styled from 'styled-components'
import { theme } from '../Theme'

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`

interface IIconWrapper {
  iconSize: string
}

export const IconWrapper = styled.div<IIconWrapper>`
  display: flex;
  justify-content: center;
  width: ${(props) => props.iconSize};
  height: ${(props) => props.iconSize};
  background-color: ${theme.colors.blue7};
  color: ${theme.colors.white};
  border-radius: 50%;
`

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  div {
    margin: 0px;
  }
`

export const NameContainer = styled.div`
  display: flex;
  width: 80%;
  justify-content: center;
  text-align: center;
  font-weight: ${theme.fontWeight.semibold};
  margin-top: ${theme.spacing.base};
  font-size: 14px;
  line-height: 17px;
  text-transform: capitalize;
`

export const AgeSexContainer = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  margin-top: ${theme.spacing.small};
  font-size: 12px;
  color: ${theme.colors.blue8};
`

export const MedicalRecordContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${theme.spacing.small};
  font-size: 14px;
  color: ${theme.colors.blue9};
`
export const MedicalRecordContent = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  color: ${theme.colors.blue10};
  margin-left: 3px;
`
