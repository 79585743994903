import React, { useState } from 'react'
import { Page, Document, pdfjs } from 'react-pdf'
import {
  ModalHeader,
  Container,
  LeftContent,
  RightContent,
  Separator,
  Footer,
  LoadingContainer,
  Wrapper,
  ModalHeaderSubtitle,
  HeaderBox
} from './styles'
import { ITabDocument, Tabs } from './Tabs'
import { Modal, ModalProps } from '../Modal/Modal'
import { Button, IconExporter } from '../'
import { ImageRender } from './image'
// https://github.com/wojtekmaj/react-pdf/issues/321
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

export interface IDisplayDocument {
  file?: string
  pageNumber: number
  onLoadSuccess?: boolean
  hideLoading?: boolean
  scale?: number
  width?: number
  height?: number
  className?: string
  contentType?: string
}
export interface IPdf {
  url: string
  classification: string
  filename: string
  contentType?: string
}

export interface PdfModalProps extends ModalProps {
  pdfList: IPdf[]
  endpointUrl?: string
  modalHeight?: string | number
  isLoading: boolean
  finishCallback?: () => void
  cancelCallback?: () => void
  buttonTitle?: string
  buttonTitleCancel?: string
  print?: boolean
  title?: string
  subtitle?: string
}

/**
 * This component will render a modal with PDF documents with a callback function
 * @param {array} pdfList array of pdf to render
 * @param {string} endpointUrl needed to pass to get the PDF document
 * @param {string | number} modalHeight height of Modal. Default is 580px
 * @param {boolean} isLoading boolean to finish button when load callback
 * @param {Function} finishCallback Function to call the callback
 * @param {boolean} print boolean to show print button
 * @param {string} title string to show modal title
 * @param {string} subtitle string to show modal subtitle
 */

type IDocumentLoad = { numPages: number }

export const PdfModal: React.FC<PdfModalProps> = ({
  pdfList,
  endpointUrl,
  modalHeight,
  isLoading,
  finishCallback,
  buttonTitle,
  buttonTitleCancel,
  cancelCallback,
  print,
  title,
  subtitle,
  ...rest
}: PdfModalProps): JSX.Element => {
  const [pageNumbers, setPageNumbers] = useState<number[] | undefined>()
  const [selectDocument, setSelectDocument] = useState<
    ITabDocument | undefined
  >()

  const tabDocuments = React.useMemo(() => {
    const documentsWithUrl = pdfList
      ? pdfList.map(
          (pdf, index): ITabDocument => ({
            id: index,
            classification: pdf.classification,
            url: `${!endpointUrl ? '' : endpointUrl + '/'}${pdf.url}`,
            contentType: pdf.contentType
          })
        )
      : []
    documentsWithUrl.length > 0 && setSelectDocument(documentsWithUrl[0])
    return documentsWithUrl
  }, [endpointUrl, pdfList])

  const onDocumentLoadSuccess = ({ numPages }: IDocumentLoad) => {
    if (numPages > 1) {
      setPageNumbers(
        Array(numPages)
          .fill(null)
          .map((_u, index) => index)
      )
    } else {
      setPageNumbers(undefined)
    }
  }

  const displayDocument = React.useCallback(
    ({
      pageNumber,
      onLoadSuccess,
      hideLoading,
      file,
      className,
      scale,
      height,
      width,
      contentType
    }: IDisplayDocument) => {
      const leftContentDiv = document.getElementById('modal-pdf-left-content')
      if (leftContentDiv) leftContentDiv.scrollTop = 0

      if (
        selectDocument?.contentType?.includes('image') &&
        pageNumbers?.length
      ) {
        setPageNumbers(undefined)
      }

      if (
        contentType?.includes('image') ||
        (!contentType && selectDocument?.contentType?.includes('image'))
      ) {
        const url = file ?? selectDocument?.url
        return <ImageRender url={url ?? ''} height={height} />
      }
      return (
        <Document
          className={`file-${pageNumber}`}
          file={file ?? selectDocument?.url}
          onLoadSuccess={onLoadSuccess ? onDocumentLoadSuccess : undefined}
          loading={
            hideLoading
              ? ''
              : () => (
                  <LoadingContainer height={height}>
                    <IconExporter name='load' iconsize={50} />
                  </LoadingContainer>
                )
          }
          error='PDF não encontrado'
          key={pageNumber}
        >
          <Page
            scale={scale ?? 1.1}
            pageNumber={pageNumber}
            width={width}
            height={height}
            className={className}
          />
        </Document>
      )
    },
    [selectDocument, pageNumbers]
  )

  return (
    <Wrapper>
      <Modal
        {...rest}
        size={rest.size ?? 'big'}
        closeClickMask={rest.closeClickMask ?? false}
        customBackgroundColor={rest.customBackgroundColor ?? '#F3F5F9'}
        modalDialogProps={{
          className: 'height-fix'
        }}
        modalContainerProps={{
          className: 'modal-flex'
        }}
      >
        <HeaderBox>
          <ModalHeader>{!title ? 'Visualizar documentos' : title}</ModalHeader>
          {subtitle && <ModalHeaderSubtitle>{subtitle}</ModalHeaderSubtitle>}
        </HeaderBox>

        <Separator />
        {selectDocument && tabDocuments.length > 0 && rest.show && (
          <Container>
            <LeftContent modalHeight={modalHeight} id='modal-pdf-left-content'>
              {displayDocument({
                pageNumber: 1,
                onLoadSuccess: true,
                width: 594
              })}
              {pageNumbers?.map((pageNumber) => {
                if (pageNumber > 0) {
                  return (
                    <React.Fragment>
                      <Separator />
                      {displayDocument({
                        pageNumber: pageNumber + 1,
                        hideLoading: true,
                        width: 594
                      })}
                    </React.Fragment>
                  )
                }

                return null
              })}
            </LeftContent>
            <RightContent>
              <Tabs
                displayDocument={(props: IDisplayDocument) =>
                  displayDocument(props)
                }
                {...{
                  print,
                  modalHeight,
                  selectDocument,
                  setSelectDocument,
                  tabDocuments,
                  endpointUrl
                }}
              />
            </RightContent>
          </Container>
        )}
        {finishCallback && cancelCallback ? (
          <Footer>
            <Button
              data-testid='cancel-button'
              variant='outlined'
              disabled={isLoading}
              onClick={cancelCallback}
              minWidth='small'
            >
              {buttonTitleCancel ?? 'Cancelar'}
            </Button>
            <Button
              data-testid='finish-and-send-button'
              isLoading={isLoading}
              onClick={finishCallback}
              minWidth='small'
            >
              {buttonTitle ?? 'Finalizar e enviar'}
            </Button>
          </Footer>
        ) : finishCallback ? (
          <Footer>
            <Button
              data-testid='finish-and-send-button'
              isLoading={isLoading}
              onClick={finishCallback}
              minWidth='large'
            >
              {buttonTitle ?? 'Finalizar e enviar'}
            </Button>
          </Footer>
        ) : (
          <Footer>
            <Button
              data-testid='cancel-button'
              variant='outlined'
              isLoading={isLoading}
              onClick={cancelCallback}
              minWidth='large'
            >
              {buttonTitleCancel ?? 'Cancelar'}
            </Button>
          </Footer>
        )}
      </Modal>
    </Wrapper>
  )
}
