import React from 'react';

const SvgTrash = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={14.651}
      height={15.846}
      viewBox='0 0 15 16'
      {...props}
    >
      <g
        fill='none'
        stroke='currentColor'
        strokeLinecap='round'
        strokeMiterlimit={10}
        strokeWidth={1.5}
        data-name='Iconly/Light/Delete'
      >
        <path
          data-name='Stroke 1'
          d='M12.828 5.96s-.421 5.223-.665 7.423a1.735 1.735 0 01-1.829 1.686c-2.023.036-4.049.039-6.071 0a1.717 1.717 0 01-1.775-1.68c-.246-2.22-.665-7.429-.665-7.429'
        />
        <path
          data-name='Stroke 3'
          d='M13.901 3.456H.75'
          strokeLinejoin='round'
        />
        <path
          data-name='Stroke 5'
          d='M11.367 3.456a1.278 1.278 0 01-1.252-1.027l-.189-.943A.992.992 0 008.967.75H5.684a.992.992 0 00-.959.736l-.188.943a1.278 1.278 0 01-1.253 1.027'
        />
      </g>
    </svg>
  )
}

export default SvgTrash
