import React, { useState } from 'react'
import { Button } from '../../atoms'
import { Modal } from '../../molecules'
import useExamResultTable from './hook'
import {
  Column,
  ColumnHeader,
  Container,
  Exam,
  ExamColumn,
  ExamContainer,
  ExamResultContainer,
  ExamResultContent,
  ExamTitle,
  LaboratoryLocale,
  LeadingTitle,
  ResultColumns,
  TrackingDate,
} from './styles'
import { IData } from './typing'

export interface ILeadingTitleColumn {
  result?: IData
  exam: string
  mobile: boolean
  index: number
}

const LeadingTitleColumn: React.FC<ILeadingTitleColumn> = ({ result, mobile, exam, index }) => {
  const [openModal, setOpenModal] = useState(false)
  const { formatDate } = useExamResultTable()

  if (mobile) {
    return (
      <ExamContainer>
        <Button onClick={() => setOpenModal(true)} data-testid="exam-result-table-button-modal">
          <ExamTitle>{exam}</ExamTitle>
        </Button>

        <Modal
          data-testid="exam-result-table-modal-mobile"
          title=""
          onClose={() => setOpenModal(!openModal)}
          open={openModal}
          fullScreen
        >
          <Container>
            <>
              <ExamColumn>
                <LeadingTitle>Data</LeadingTitle>
                {result?.examResults?.map(({ date, laboratory }) => (
                  <ExamContainer>
                    <ExamTitle>
                      <TrackingDate>{date ? formatDate(date) : ''}</TrackingDate>
                      <LaboratoryLocale>
                        {laboratory?.description ? laboratory.description : ''}
                      </LaboratoryLocale>
                    </ExamTitle>
                  </ExamContainer>
                ))}
              </ExamColumn>

              <ResultColumns>
                <Column data-testid="exam-result-column">
                  <ColumnHeader>
                    <TrackingDate>Resultados</TrackingDate>
                  </ColumnHeader>
                  {result?.examResults.map(({ results }) => (
                    <ExamResultContainer>
                      <ExamResultContent>{results?.[index]?.value}</ExamResultContent>
                    </ExamResultContainer>
                  ))}
                </Column>
              </ResultColumns>
            </>
          </Container>
        </Modal>
      </ExamContainer>
    )
  }

  return (
    <ExamContainer>
      <Exam>
        <ExamTitle>{exam}</ExamTitle>
      </Exam>
    </ExamContainer>
  )
}

export default LeadingTitleColumn
