import React from 'react'

import { getCountries, getCountryCallingCode, Country } from 'react-phone-number-input'
import labels from 'react-phone-number-input/locale/pt-BR.json'
import ClickAwayListener from '@mui/material/ClickAwayListener'

import {
  Container,
  CountrySelectSearch,
  CountryOptions,
  Option,
  Options,
  AreaSearch,
  Arrow,
} from './styles'
import { Icon } from '../../atoms'
import MaskedField, { IMaskedField } from '../MaskedField'
import { usePhoneField } from './hook'

export interface IPhoneField extends Omit<IMaskedField, 'mask' | 'label' | 'multiline'> {
  error?: boolean
  message?: string
  country?: Country
  setCountry: (country: Country) => void
}

const PhoneField: React.FC<IPhoneField> = ({ value, country, setCountry, disabled, ...rest }) => {
  const {
    handleClickAway,
    handleChange,
    setShow,
    setSearch,
    search,
    show,
    maskCountryWithBrackets,
    maskCountryWithNine,
    placeholder,
    countryCode,
  } = usePhoneField({ country, setCountry })

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Container>
        <AreaSearch>
          <CountrySelectSearch
            data-testid="input-ddi-test"
            variant={rest.variant}
            onFocus={() => setShow(true)}
            value={search}
            onChange={e => {
              /* istanbul ignore next */
              setSearch(e.target.value)
            }}
            disabled={disabled}
            className="input-ddi"
          />
          <Arrow open={show}>
            <Icon icon="ChevronDown" size={10} />
          </Arrow>
        </AreaSearch>
        <MaskedField
          {...rest}
          value={String(value)}
          mask={String(value || '')?.length > 2 ? maskCountryWithBrackets : maskCountryWithNine}
          placeholder={placeholder}
          disabled={disabled}
          className="input-phone"
        />
        {show && (
          <CountryOptions data-testid="options-container">
            <Options>
              {countryCode && (
                <Option
                  data-testid="option-test"
                  type="button"
                  onClick={() => handleChange(countryCode)}
                  active
                >
                  <span>{labels[countryCode as never]}</span>
                  <span>+{getCountryCallingCode(countryCode)}</span>
                </Option>
              )}
              {getCountries()
                .filter(count =>
                  search.replace('+', '') === getCountryCallingCode(countryCode)
                    ? true
                    : `+${getCountryCallingCode(count)}`.includes(search) ||
                      labels[count].toLowerCase().includes(search.toLowerCase()),
                )
                .map(
                  count =>
                    countryCode !== count && (
                      <Option
                        data-testid="option-test"
                        key={labels[count]}
                        type="button"
                        onClick={() => handleChange(count)}
                      >
                        <span>{labels[count]}</span>
                        <span>+{getCountryCallingCode(count)}</span>
                      </Option>
                    ),
                )}
            </Options>
          </CountryOptions>
        )}
      </Container>
    </ClickAwayListener>
  )
}

export default PhoneField
