import styled, { css } from 'styled-components'

export const Container = styled.div<{ show: boolean }>`
  position: fixed;
  left: 0;
  bottom: 0;
  min-height: 100%;
  width: 100%;
  z-index: 998;
  display: ${(props) => (props.show ? 'flex' : 'none')};
`

export const Mask = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  min-height: 100vh;
  width: 100%;
  position: absolute;
  z-index: 999;
  animation: appear 0.3s;
  -webkit-animation: appear 0.3s;

  @keyframes appear {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @-webkit-keyframes appear {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`

export const ModalContent = styled.div<{
  padding: string
  buttonHeight: string
  buttonBorder: string
  disableButtonStyle: boolean
}>`
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #ffffff;
  border-radius: 5px 5px 0 0;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  padding: ${(props) => props.padding};
  animation: slidein 0.3s;
  -webkit-animation: slidein 0.3s;

  @keyframes slidein {
    from {
      margin-bottom: -100%;
    }

    to {
      margin-bottom: 0%;
    }
  }
  @-webkit-keyframes slidein {
    from {
      margin-bottom: -100%;
    }

    to {
      margin-bottom: 0%;
    }
  }

  ${({ disableButtonStyle, buttonHeight, buttonBorder }) =>
    !disableButtonStyle &&
    css`
      button {
        height: ${buttonHeight};
        margin: 8px 0;
        width: 100%;
        border: ${buttonBorder};
        background-color: #fff;
        font: normal 14px 'Inter';
        color: #6b7786;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0 20px;
        transition: 0.5s;
        :active {
          background-color: #d4ddf7;
          color: #378ff7;
        }
        svg {
          margin: 0 10px -5px 0;
          color: #a7abb2;
        }
      }
    `}
`
