import React, {
  forwardRef,
  InputHTMLAttributes,
  createRef,
  useState,
  useLayoutEffect,
  useEffect,
} from 'react'
import { FormGroup } from '@mui/material'
import { Icon } from 'components/atoms'
import { ButtonBase, CheckboxComponent, Label } from './styles'

export interface ICheckbox extends InputHTMLAttributes<HTMLInputElement> {
  label: string
  checked?: boolean
  disabled?: boolean
}

const Checkbox = forwardRef<HTMLInputElement, ICheckbox>(
  ({ label, checked, onChange, className, disabled, ...rest }, ref) => {
    const [active, setActive] = useState(checked !== undefined ? checked : false)
    const inputElement = createRef<HTMLInputElement>()

    const handleChangeInput = () => {
      /* istanbul ignore next */
      if (inputElement.current) {
        inputElement.current.click()
      }
    }

    const listenerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(e)
      }
      setActive(e.target.checked)
    }

    useEffect(() => {
      if (checked !== undefined) setActive(checked)
    }, [checked])

    useLayoutEffect(() => {
      /* istanbul ignore next */
      if (ref) {
        if (typeof ref === 'function') {
          ref(inputElement.current)
        } else {
          /* istanbul ignore next */
          ref.current = inputElement.current
        }
      }
    }, [inputElement, ref])

    return (
      <FormGroup>
        <Label
          control={
            <ButtonBase
              data-testid="checkbox-component"
              centerRipple
              onClick={handleChangeInput}
              className={className}
              disabled={disabled}
            >
              <input
                type="checkbox"
                ref={inputElement}
                onChange={listenerChange}
                checked={active}
                {...rest}
              />
              <CheckboxComponent active={active}>
                <Icon icon="Check" size={9} />
              </CheckboxComponent>
            </ButtonBase>
          }
          label={label}
        />
      </FormGroup>
    )
  },
)

export default Checkbox
