import React from 'react'
import { SvgProps } from './typings/SvgProps'

const ArrowUp: React.FC<SvgProps> = ({ color = 'currentColor', size, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    color={color}
    {...rest}
    viewBox="0 0 15.106 15.104"
  >
    <g transform="translate(10.769 10.623) rotate(-135)">
      <path
        d="M8.889,0,4.444,4.444,0,0"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.4"
      />
      <line
        y2="12.154"
        transform="translate(4.446 2.771) rotate(180)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeWidth="1.4"
      />
    </g>
  </svg>
)

export default ArrowUp
