import React from 'react';

const SvgChevron = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={12.121}
      height={6.811}
      viewBox='0 0 12 8'
      {...props}
    >
      <path
        d='M11.061 1.061l-5 5-5-5'
        fill='none'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit={10}
        strokeWidth={1.5}
      />
    </svg>
  )
}

export default SvgChevron
