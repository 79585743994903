import React from 'react'

const SvgReload = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='currentColor'
      width={25.38}
      height={21}
      viewBox='0 0 25 21'
      {...props}
    >
      <path d='M25.213 10.836a.607.607 0 00-.858-.023l-1.017.965a9.853 9.853 0 00-.948-5.783 10.969 10.969 0 00-8.2-5.859 10.152 10.152 0 00-9 2.9.607.607 0 10.872.844 8.961 8.961 0 017.946-2.541 9.757 9.757 0 017.3 5.2 8.527 8.527 0 01.84 5.027l-.612-1.262a.606.606 0 10-1.091.53l1.421 2.932a.607.607 0 00.963.175l2.365-2.242a.607.607 0 00.019-.863zM19.32 17.126a8.962 8.962 0 01-7.946 2.541 9.758 9.758 0 01-7.3-5.2 8.531 8.531 0 01-.841-5.027l.612 1.262a.607.607 0 001.091-.53L3.514 7.24a.606.606 0 00-.963-.175L.187 9.307a.607.607 0 00.835.88l1.016-.964a9.858 9.858 0 00.948 5.783 10.97 10.97 0 008.2 5.859 11.236 11.236 0 001.733.135 10 10 0 007.271-3.032.606.606 0 10-.872-.843z' />
    </svg>
  )
}

export default SvgReload
