import styled from 'styled-components'
import { theme } from '../Theme'

export const Wrapper = styled.div`
  z-index: 9999;
  position: fixed;
  width: 64px;
  left: 0;
  top: 0;
`

export const MenuToggleController = styled.div`
  color: ${theme.colors.blue5};
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    cursor: pointer;
  }
`
