import { rgba } from 'polished';
/* eslint-disable handle-callback-err */
import styled, { css } from 'styled-components';

import { theme } from '../../Theme';

interface Input {
  error?: boolean
  active: boolean
}

interface InputContainer {
  margin?: string
}

const stateInput = {
  error: css`
    color: ${theme.colors.gray4};
    border: 1px solid ${theme.colors.red1};

    svg {
      fill: ${theme.colors.red1};
    }
  `,
  active: css`
    border: 1px solid ${theme.colors.blue3};

    -webkit-box-shadow: 0px 0px 6px 0px ${rgba(theme.colors.blue3, 0.4)};
    -moz-box-shadow: 0px 0px 6px 0px ${rgba(theme.colors.blue3, 0.4)};
    box-shadow: 0px 0px 6px 0px ${rgba(theme.colors.blue3, 0.4)};
  `
}

export const Wrapper = styled.div<Input>`
  position: relative;
  display: flex;
  align-items: center;
  border-radius: ${theme.borderRadius.fields};
  height: 40px;

  svg {
    fill: transparent;
    position: absolute;
    right: 10px;
  }

  ${({ active }) => (active ? stateInput.active : null)};
  ${({ error }) => (error ? stateInput.error : null)};
  input[type='number'] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  .number-input {
    border: 1px solid #dfe3e8;
    display: inline-flex;
    background: white;
  }

  .number-input,
  .number-input * {
    box-sizing: border-box;
  }

  .button-container {
    width: 2.5rem;
    height: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: center;
    margin-top: -6px;
  }

  .number-input button {
    outline: none;
    -webkit-appearance: none;
    background-color: #ffffff;
    border: none;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 0;
    position: relative;
    padding: 0;
  }

  .number-input button.down:before {
    display: inline-block;
    position: absolute;
    content: '';
    width: 5px;
    height: 5px;
    border: solid #6b7786;
    border-width: 0 2px 2px 0;
    display: inline-block;
    transform: rotate(45deg);
  }
  .number-input button.plus:after {
    display: inline-block;
    position: absolute;
    content: '';
    width: 5px;
    height: 5px;
    border: solid #6b7786;
    border-width: 0 2px 2px 0;
    display: inline-block;
    transform: rotate(-135deg);
  }

  .number-input input[type='number'] {
    padding: 0.5rem;
    border: 0;
    text-align: center;
    outline: none;
  }
  .number-input {
    border: 1px solid #dfe3e8;
    border-radius: 4px;
    min-height: 40px;
    width: fit-content;
    display: flex;
    align-items: center;
  }
`

export const InputWrapper = styled.input`
  border-radius: ${theme.borderRadius.fields};
  padding: 8px 16px;
  font-size: 14px;
  color: ${theme.colors.black};
  transition: all 0.2s ease-in-out;
  border: 1px solid ${theme.colors.gray3};
  width: 100%;
  height: 100%;

  outline: none;

  &::placeholder {
    color: ${theme.colors.gray5};
    opacity: 0.5;
  }

  &::-ms-input-placeholder {
    color: ${theme.colors.gray5};
    opacity: 0.5;
  }

  &::-ms-input-placeholder {
    color: ${theme.colors.gray5};
    opacity: 0.5;
  }

  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    opacity: 1;
  }
  input[type='number'] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
`

export const InputContainer = styled.div<InputContainer>`
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin: ${({ margin }) => margin};
`
