import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
`

export const Image = styled.img<{ height?: number }>`
  width: 100%;
  height: ${({ height }) => height ?? 'auto'};
`
