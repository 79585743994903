import React from 'react'

const SvgModels = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={20.202}
      height={19.521}
      viewBox='0 0 20.202 19.521'
      {...props}
    >
      <g
        id='Icon_Models'
        data-name='Icon/Models'
        transform='translate(-91.595 -80.598)'
      >
        <g
          id='Grupo_163029'
          data-name='Grupo 163029'
          transform='translate(91.695 80.698)'
        >
          <path
            id='Caminho_27444'
            data-name='Caminho 27444'
            d='M93.547,80.723a3.376,3.376,0,0,1,.524-.025h7.949a4.594,4.594,0,0,1,.564.019,2.167,2.167,0,0,1,1.675,1.224,2.307,2.307,0,0,1,.212,1.046v2.044c.889,0,1.776,0,2.665,0a2.893,2.893,0,0,1,.749.063,2.272,2.272,0,0,1,.614.262,2.165,2.165,0,0,1,.956,1.287,2.4,2.4,0,0,1,.075.623v5.116a.613.613,0,0,1-.354.549.6.6,0,0,1-.736-.195.708.708,0,0,1-.115-.478V87.773a1.931,1.931,0,0,0-.025-.348,1.457,1.457,0,0,0-1.032-1.128,2.807,2.807,0,0,0-.761-.055H99.441a1.456,1.456,0,0,0-1.461,1.218,2.24,2.24,0,0,0-.017.375v7.129a2.321,2.321,0,0,0,.029.482,1.443,1.443,0,0,0,.941,1.076,1.769,1.769,0,0,0,.61.084h4.508a.625.625,0,0,1,.367.088.6.6,0,0,1-.325,1.118q-2.044,0-4.087,0c-.377,0-.757,0-1.134,0a2.166,2.166,0,0,1-2.116-2.149c0-.73,0-1.457,0-2.187H93.922a2.274,2.274,0,0,1-.545-.059,2.181,2.181,0,0,1-1.529-1.3,2.223,2.223,0,0,1-.153-.835V82.889a2.182,2.182,0,0,1,1.851-2.166Zm-.22,1.606a1.428,1.428,0,0,0-.424,1.038v7.443a1.444,1.444,0,0,0,1.44,1.451h2.413q0-2.551,0-5.1a2.177,2.177,0,0,1,2.179-2.128c1.443,0,2.885,0,4.328,0,0-.463,0-.929,0-1.394a3.389,3.389,0,0,0-.021-.526,1.42,1.42,0,0,0-.606-.952,1.472,1.472,0,0,0-.847-.258H94.367A1.467,1.467,0,0,0,93.327,82.33Z'
            transform='translate(-91.695 -80.698)'
            fill='currentColor'
            stroke='currentColor'
            strokeWidth='0.2'
          />
        </g>
        <path
          id='Caminho_27445'
          data-name='Caminho 27445'
          d='M783.512,735.938a.578.578,0,0,1,1,.409c0,.5,0,1,0,1.495.51,0,1.017,0,1.527,0a.579.579,0,0,1,.552.53.572.572,0,0,1-.318.564.72.72,0,0,1-.357.062h-1.406v1.475a.6.6,0,0,1-.181.449.579.579,0,0,1-.977-.429c0-.5,0-1,0-1.495-.5,0-1.005,0-1.507,0a.577.577,0,0,1-.54-.379.593.593,0,0,1-.006-.383.583.583,0,0,1,.548-.395c.5,0,1.005,0,1.507,0v-1.515A.587.587,0,0,1,783.512,735.938Z'
          transform='translate(-674.896 -641.107)'
          fill='currentColor'
          stroke='currentColor'
          strokeWidth='0.2'
        />
      </g>
    </svg>
  )
}

export default SvgModels
