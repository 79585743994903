import React from 'react'

const iconSupport = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={21}
    height={18}
    viewBox='0 0 21 18'
    fill='currentColor'
    {...props}
  >
    <g transform='translate(0.625 0.625)'>
      <path
        d='M3,0H4.25A.75.75,0,0,1,5,.75v4.5A.75.75,0,0,1,4.25,6H3A3,3,0,0,1,0,3V3A3,3,0,0,1,3,0Z'
        transform='translate(0 7.969)'
        fill='none'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={1.25}
      />
      <path
        d='M-1213.9,1000.6v-2.15a6.427,6.427,0,0,1,6.916-6.417'
        transform='translate(1217 -992.031)'
        fill='none'
        stroke='currentColor'
        strokeWidth={1.25}
      />
      <path
        d='M.75,0H2A3,3,0,0,1,5,3V3A3,3,0,0,1,2,6H.75A.75.75,0,0,1,0,5.25V.75A.75.75,0,0,1,.75,0Z'
        transform='translate(15.012 7.969)'
        fill='none'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={1.25}
      />
      <path
        d='M-1206.988,1000.6v-2.15a6.427,6.427,0,0,0-6.916-6.417'
        transform='translate(1223.904 -992.031)'
        fill='none'
        stroke='currentColor'
        strokeWidth={1.25}
      />
      <path
        d='M-1200.084,1007.333s.6,2.672-2.688,2.672h-3.761v-1.426'
        transform='translate(1216.745 -993.031)'
        fill='none'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={1.25}
      />
    </g>
  </svg>
)

export default iconSupport
