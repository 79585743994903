import { theme } from '../Theme'
import styled from 'styled-components'

export const Wrapper = styled.div`
  .modal-flex {
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
  .height-fix {
    margin: 10px 0;
    left: initial;
    min-height: auto;
  }
`

export const Container = styled.div`
  display: flex;

  .react-pdf__Page__textContent {
    display: none;
  }
`

export const HeaderBox = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`

export const ModalHeader = styled.div`
  font-weight: ${theme.fontWeight.semibold};
  font-size: 22px;
`

export const ModalHeaderSubtitle = styled.p`
  font-size: 14px;
`

export const Separator = styled.div`
  margin-bottom: 16px;
  margin-top: 16px;
  width: 100%;
  height: 1px;
  background-color: #e0e3e7;
`

export const LeftContent = styled.div<{ modalHeight?: string | number }>`
  flex: 1;
  height: ${({ modalHeight }) => modalHeight ?? '580px'};
  padding-right: 25px;
  overflow-y: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${theme.colors.gray8};
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track-piece {
    background-color: transparent;
  }
`

export const RightContent = styled.div``

export const Footer = styled.div`
  margin-top: 30px;
  height: auto;
  display: flex;
  justify-content: flex-end;

  button:nth-child(1) {
    margin-right: 10px;
  }
`
interface ILoadingContainer {
  height?: number
}

export const LoadingContainer = styled.div<ILoadingContainer>`
  display: flex;
  flex: 1;
  width: 100%;
  height: ${({ height }) => (height ? `${height}px` : '580px')};
  justify-content: center;
`
