import styled from 'styled-components'
import { theme } from '../../../Theme/theme'
interface Input {
  error: boolean
  isActive: boolean
}

const { red1, gray6, black } = theme.colors

export const InputStyle = styled.input<Input>`
  width: 30px;
  height: 30px;
  border: ${({ error }) =>
    error ? `1px solid  ${red1}` : `1px solid ${gray6}`};
  border-radius: 4px;
  background: ${(props) => (props.isActive ? '#fff' : '#F3F5F9')};
  margin: 0 8px;

  @media (max-width: 810px) {
    margin: 0 6px;
  }

  @media (max-width: 320px) {
    margin: 0 4px;
  }
  @media (max-width: 300px) {
    margin: 0 2px;
  }
  font: normal 14px sans-serif;
  color: ${black};
  text-align: center;
  cursor: ${(props) => (props.isActive ? 'inherit' : 'pointer')};
`
