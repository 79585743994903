import React, { useRef, useState } from 'react'
import { Icon, MenuDropdown } from '../../atoms'
import { DotButton, ExamResultContainer, ExamResultContent, TextFieldExamResult } from './styles'
import { IEditable, IResult } from './typing'

export interface IExamResult extends IEditable {
  result: IResult | null
  index: number
  columnIndex: number
  laboratory: {
    id: number
    description: string | null
  } | null
}

const ExamResult: React.FC<IExamResult> = ({
  result,
  isEditable,
  onBlurEditableCallback,
  onDeleteEditableCallback,
  index,
  columnIndex,
  laboratory,
}) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const iconRef = useRef<HTMLButtonElement>(null)
  const [menuHandler, setMenuHandler] = useState<{
    open: boolean
    action: 'edit' | 'delete' | 'none'
  }>({
    open: false,
    action: 'none',
  })
  const handleToggleMenu = () => setMenuHandler({ ...menuHandler, open: !menuHandler.open })
  const [value, setValue] = React.useState(result?.value ?? '-')

  const handleOnChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value)
  }, [])

  const handleOnBlur = React.useCallback(() => {
    onBlurEditableCallback?.({ result: result as IResult, value, laboratory })
  }, [laboratory, onBlurEditableCallback, result, value])

  React.useEffect(() => {
    switch (menuHandler.action) {
      case 'edit':
        inputRef.current?.focus()
        break
      case 'delete':
        onDeleteEditableCallback?.({ result: result as IResult, value, laboratory })
        break
      default:
        break
    }
    if (menuHandler.action !== 'none') setMenuHandler({ action: 'none', open: menuHandler.open })
  }, [laboratory, menuHandler, onDeleteEditableCallback, result, value])

  const menuOptions = [
    {
      text: 'Editar',
      startIcon: <Icon icon="Pencil" size={16} />,
      onClick: () => setMenuHandler({ action: 'edit', open: false }),
      disabled: false,
    },
    {
      text: 'Deletar',
      startIcon: <Icon icon="Trash" size={16} />,
      onClick: () => setMenuHandler({ action: 'delete', open: false }),
      disabled: false,
    },
  ]

  const handleOnKeyDown = React.useCallback((event: React.KeyboardEvent<Element>) => {
    if (event.key === 'Enter') {
      const inputs = Array.from(document.querySelectorAll('input'))
      const currentInputIndex = inputs.indexOf(event.currentTarget as HTMLInputElement)
      const nextInputId = inputs[currentInputIndex + 1].id

      const nextInput = document.getElementById(nextInputId)
      if (nextInput) {
        nextInput.focus()
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        nextInput.select()
      }
    }
  }, [])

  if (isEditable && result?.editable) {
    return (
      <ExamResultContainer
        data-testid="exam-result-cell-container"
        isEditable={isEditable}
        isMenuOpened={menuHandler.open}
      >
        <TextFieldExamResult
          ref={inputRef}
          id={`exam-result-text-field-index-${index}-columnIndex-${columnIndex}`}
          data-testid="exam-result-item-text-field"
          value={value}
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          onKeyDown={handleOnKeyDown}
          iconOptions={
            value
              ? {
                  adornment: result?.unitOfMeasure?.description ?? '',
                  position: 'end',
                }
              : undefined
          }
        />
        <DotButton
          onClick={handleToggleMenu}
          ref={iconRef}
          disableRipple
          data-testid="exam-result-item-dot-button"
          sx={{
            color: 'info.main',
            ':hover': {
              color: 'primary.main',
            },
            transform: 'rotate(90deg)',
          }}
        >
          <Icon size={16} icon="Ellipsis" />
        </DotButton>
        <MenuDropdown
          options={menuOptions}
          anchorEl={iconRef?.current}
          open={menuHandler.open}
          onClose={handleToggleMenu}
        />
      </ExamResultContainer>
    )
  }
  return (
    <ExamResultContainer isEditable={isEditable}>
      <ExamResultContent>{value}</ExamResultContent>
      {result?.unitOfMeasure?.description && (
        <ExamResultContent>{result?.unitOfMeasure?.description}</ExamResultContent>
      )}
    </ExamResultContainer>
  )
}

export default ExamResult
