import React from 'react'

const PlusDocument = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={14}
      height={17}
      viewBox='0 0 13.898 16.715'
      {...props}
    >
      <g id='Paper_Plus' data-name='Paper Plus' transform='translate(0.6 0.75)'>
        <path
          id='Stroke_1'
          data-name='Stroke 1'
          d='M8.463,0H3.094A3.138,3.138,0,0,0,0,3.12V12.2a3.051,3.051,0,0,0,3.094,3.158H9.542a3.1,3.1,0,0,0,3.01-3.158V4.414Z'
          transform='translate(0 0.01)'
          fill='none'
          stroke='#a8abb1'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.2'
        />
        <path
          id='Stroke_3'
          data-name='Stroke 3'
          d='M0,0V2.434A2.117,2.117,0,0,0,2.073,4.588c1.062,0,2.149,0,2.223,0'
          transform='translate(8.251)'
          fill='none'
          stroke='#a8abb1'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.5'
        />
        <path
          id='Stroke_5'
          data-name='Stroke 5'
          d='M3.956.5H0'
          transform='translate(4.151 8.004)'
          fill='none'
          stroke='#a8abb1'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.2'
        />
        <path
          id='Stroke_7'
          data-name='Stroke 7'
          d='M.5,4.1V0'
          transform='translate(5.629 6.454)'
          fill='none'
          stroke='#a8abb1'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.2'
        />
      </g>
    </svg>
  )
}

export default PlusDocument
