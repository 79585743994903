import React from 'react'
import { SvgProps } from './typings/SvgProps'

const Video: React.FC<SvgProps> = ({ color = 'currentColor', size, ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} {...rest} viewBox="0 0 15.243 11.338">
    <g transform="translate(-356.449 -682.992)">
      <g transform="translate(356.952 683.502)">
        <g>
          <path
            d="M9.94,7.77A2.452,2.452,0,0,1,7.3,10.319H2.637A2.446,2.446,0,0,1,0,7.77V2.555A2.454,2.454,0,0,1,2.645,0H7.3A2.458,2.458,0,0,1,9.94,2.555Z"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="1"
          />
          <path
            d="M0,2.684,3.07.171A.752.752,0,0,1,4.3.753V5.9a.752.752,0,0,1-1.227.582L0,3.972"
            transform="translate(9.94 1.831)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="1"
          />
        </g>
      </g>
    </g>
  </svg>
)

export default Video
