import { useEffect, useRef, useState } from 'react'
import * as React from 'react'
import { DropDownItemContent, Item, DropDownContainer, ItemColor } from './styles'
import { ToolbarButton, ToolbarButtonProps } from '../button'

interface DropDownProps extends ToolbarButtonProps {
  options: Array<{ label: string; value: string }>
  callback: (value: string) => void
  isColorDropDown?: boolean
}

export const ToolbarButtonDropDown: React.FC<DropDownProps> = ({
  options,
  callback,
  isColorDropDown,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onClick,
  ...rest
}) => {
  const [showDropDown, setShowDropDown] = useState(false)
  const dropDownRef = useRef<HTMLDivElement>(null)
  const buttonRef = useRef<HTMLButtonElement>(null)

  /* istanbul ignore next */
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const button = buttonRef.current

    if (button !== null && showDropDown) {
      const handle = (event: MouseEvent) => {
        const { target } = event
        if (isColorDropDown) {
          if (dropDownRef.current?.contains(target as Node)) {
            return
          }
        }
        if (!button.contains(target as Node)) {
          setShowDropDown(false)
        }
      }
      document.addEventListener('click', handle)

      return () => {
        document.removeEventListener('click', handle)
      }
    }
  }, [dropDownRef, buttonRef, showDropDown, isColorDropDown])

  return (
    <DropDownContainer>
      <ToolbarButton
        {...rest}
        onClick={() => setShowDropDown(!showDropDown)}
        ref={buttonRef}
        selected={showDropDown}
      />
      {showDropDown && (
        <DropDownItemContent $isColorDropDown={isColorDropDown}>
          {options.map(({ label, value }) =>
            isColorDropDown ? (
              <ItemColor
                $backgroundColor={value}
                key={`drop-down-item-${label}`}
                onClick={() => callback(value)}
                data-testid="rich-text-dropdown-item"
              />
            ) : (
              <Item
                key={`drop-down-item-${label}`}
                onClick={() => callback(value)}
                data-testid="rich-text-dropdown-item"
              >
                {label}
              </Item>
            ),
          )}
        </DropDownItemContent>
      )}
    </DropDownContainer>
  )
}
