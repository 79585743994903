import React from 'react'

const SvgPrintIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 14 13'
      width={13}
      height={13}
      {...props}
    >
      <g data-name='Layer 2'>
        <path
          data-name='Path 13900'
          d='M10.174 3.681h-.711V2.624a1.011 1.011 0 00-.958-1.057H4.143a1.011 1.011 0 00-.957 1.057v1.057h-.711a1.4 1.4 0 00-1.381 1.412v3.52a1.4 1.4 0 001.381 1.411h.45a1.052 1.052 0 001.046 1.057h4.707a1.052 1.052 0 001.046-1.057h.45a1.4 1.4 0 001.381-1.411v-3.52a1.4 1.4 0 00-1.381-1.412zM4.232 2.624h4.184v1.057H4.232zm-.262 7.4V7.91h4.708v2.114zm6.538-1.411a.347.347 0 01-.335.354h-.45V7.91a1.052 1.052 0 00-1.045-1.057H3.971A1.052 1.052 0 002.925 7.91v1.057h-.45a.347.347 0 01-.335-.354v-3.52a.347.347 0 01.335-.354h7.7a.347.347 0 01.335.354z'
        />
      </g>
    </svg>
  )
}

export default SvgPrintIcon
