import React from 'react'

const enterBlank = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={28}
    height={28}
    viewBox='0 0 28 28'
    {...props}
  >
    <g transform='translate(5.796 6.379)'>
      <g>
        <line
          x1='4.338'
          y2='4.338'
          transform='translate(11.32 0.75)'
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.25'
        />
        <path
          d='M11.668.756,15.658.75l-.009,3.987'
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.25'
        />
        <line
          y1='4.338'
          x2='4.338'
          transform='translate(0.75 10.154)'
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.25'
        />
        <path
          d='M4.74,14.485l-3.99.006L.759,10.5'
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.25'
        />
        <line
          x2='4.338'
          y2='4.338'
          transform='translate(0.75 0.75)'
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.25'
        />
        <path
          d='M4.74.756.75.75.759,4.737'
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.25'
        />
        <line
          x1='4.338'
          y1='4.338'
          transform='translate(11.32 10.154)'
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.25'
        />
        <path
          d='M11.668,14.485l3.99.006L15.649,10.5'
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.25'
        />
      </g>
    </g>
  </svg>
)

export default enterBlank
