import React from 'react';

const SvgForward = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <g
        fill='currentColor'
      >
        <path
          d="M9.8,24a5.364,5.364,0,0,1-5.222-5.6v-.16H6.283v.16a3.938,3.938,0,0,0,1.01,2.7,3.373,3.373,0,0,0,2.5,1.091h9.1a3.248,3.248,0,0,0,2.385-1.132,4.034,4.034,0,0,0,1.01-2.657V8.437l-1,0L20,8.438a3.892,3.892,0,0,1-3.773-3.789l-.006-.227v-2.6H9.8a3.6,3.6,0,0,0-3.513,3.6v.731H4.574V5.426A5.457,5.457,0,0,1,6.123,1.6,5.091,5.091,0,0,1,9.8.014h7.128a.813.813,0,0,1,.3,0h.148a.837.837,0,0,1,.617.278l5.77,6.374A.932.932,0,0,1,24,7.3V18.4A5.443,5.443,0,0,1,18.9,24ZM17.929,4.421A2.144,2.144,0,0,0,20,6.625h1.352L17.929,2.842ZM9.491,16.169a.947.947,0,0,1-.08-1.18l.083-.1,1.869-1.975H.79A.854.854,0,0,1,0,12.007a.874.874,0,0,1,.684-.9L.79,11.1H11.362L9.494,9.126a.946.946,0,0,1-.085-1.18l.082-.1a.82.82,0,0,1,1.112-.09l.1.088,3.217,3.4a.938.938,0,0,1,.369.745q0,.01,0,.021h0c0,.006,0,.013,0,.019v.006s0,.008,0,.012a.938.938,0,0,1-.368.728l-3.218,3.4a.82.82,0,0,1-1.209,0Z"
        />
      </g>
    </svg>
  )
}

export default SvgForward
