import styled, { css } from 'styled-components'

interface maskField {
  show: boolean
  type?: 'time' | 'date' | 'basic'
  radio?: boolean
}

export const FieldInput = styled.div<maskField>`
  display: flex;
  flex-wrap: wrap;
  column-gap: 15px;
  width: ${({ show }) => (show ? '247' : '273')}px;
  height: 64px;

  @media (max-width: 750px) {
    column-gap: 10px;
  }
`

const sizeLabel = {
  time: () => '75px',
  date: () => '104px',
  basic: () => 'calc(50% - 19.5px)'
}

export const FieldPeriod = styled.div<maskField>`
  width: ${({ show, type }) => (show ? sizeLabel[type ?? 'basic']() : '100%')};
  ${({ radio, type }) =>
    radio
      ? css`
          div {
            display: flex;
            flex-wrap: wrap;
            gap: 8px;
            margin-top: 2px;
          }
        `
      : css`
          height: 40px;
          input,
          input:hover {
            padding: ${type === 'date' ? '0px 8px' : '0px 16px'};
          }
        `}
  @media(max-width: 300px) {
    ${({ show, type }) =>
      show &&
      type === 'date' &&
      css`
        width: 73px;
      `}
  }
`
