import React from 'react'

const SvgCheck3 = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 32 32'
      width={32}
      height={32}
      {...props}
    >
      <g
        id='Grupo_161885'
        data-name='Grupo 161885'
        transform='translate(-422 -16)'
      >
        <circle
          id='Elipse_1561'
          data-name='Elipse 1561'
          cx='16'
          cy='16'
          r='16'
          transform='translate(422 16)'
        />
        <path
          id='Path'
          d='M10.772,1.869,4.833,7.809a1.1,1.1,0,0,1-1.538,0L.319,4.845a1.1,1.1,0,0,1,1.55-1.55L4.057,5.483,9.222.319a1.1,1.1,0,0,1,1.55,1.55'
          transform='translate(432.797 28.227)'
          fill='#fff'
        />
      </g>
    </svg>
  )
}

export default SvgCheck3
