import React from 'react';

const SvgWhatsIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={16.181}
      height={16.259}
      viewBox='0 0 18 18'
      {...props}
    >
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M13.823 2.363a8.059 8.059 0 00-12.68 9.721L0 16.259l4.271-1.12a8.048 8.048 0 003.85.981 8.06 8.06 0 005.7-13.756zm-5.7 12.4a6.688 6.688 0 01-3.408-.933l-.244-.145-2.534.665.676-2.471-.163-.259a6.7 6.7 0 115.673 3.138zm3.673-5.015c-.2-.1-1.191-.588-1.376-.655s-.319-.1-.453.1-.52.655-.637.789-.235.151-.436.05a5.5 5.5 0 01-1.619-1 6.072 6.072 0 01-1.12-1.394c-.117-.2 0-.3.088-.411a5.7 5.7 0 00.507-.691.37.37 0 00-.017-.353c-.05-.1-.453-1.092-.621-1.495s-.329-.339-.453-.346-.252-.007-.386-.007a.739.739 0 00-.537.252 2.258 2.258 0 00-.7 1.679 3.916 3.916 0 00.814 2.083 8.973 8.973 0 003.439 3.04 11.522 11.522 0 001.148.424 2.76 2.76 0 001.268.08 2.074 2.074 0 001.359-.957 1.682 1.682 0 00.117-.957c-.048-.085-.182-.135-.384-.236zm0 0'
      />
    </svg>
  )
}

export default SvgWhatsIcon
