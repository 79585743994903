import React from 'react'
import { SvgProps } from './typings/SvgProps'

const List: React.FC<SvgProps> = ({ color = 'currentColor', size, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    {...rest}
    viewBox="0 0 15.767 12.2"
  >
    <g transform="translate(-650.239 -490)">
      <g transform="translate(654 484)">
        <g transform="translate(1 7)">
          <path d="M0,0H10.006" fill="none" stroke={color} strokeLinecap="round" strokeWidth="2" />
          <line
            x2="10"
            transform="translate(0 10)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <line
            x2="10"
            transform="translate(0 5)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
        </g>
      </g>
      <g transform="translate(651.325 501.045)">
        <g transform="translate(-1.086 -11.045)">
          <path
            d="M2.2,1.1A1.1,1.1,0,1,1,1.1,0,1.1,1.1,0,0,1,2.2,1.1"
            transform="translate(0)"
            fill={color}
          />
        </g>
      </g>
      <g transform="translate(651.325 506.045)">
        <g transform="translate(-1.086 -11.045)">
          <path
            d="M2.2,1.1A1.1,1.1,0,1,1,1.1,0,1.1,1.1,0,0,1,2.2,1.1"
            transform="translate(0)"
            fill={color}
          />
        </g>
      </g>
      <g transform="translate(651.325 511.045)">
        <g transform="translate(-1.086 -11.045)">
          <path
            d="M2.2,1.1A1.1,1.1,0,1,1,1.1,0,1.1,1.1,0,0,1,2.2,1.1"
            transform="translate(0)"
            fill={color}
          />
        </g>
      </g>
    </g>
  </svg>
)

export default List
