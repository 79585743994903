import React from 'react';

const SvgArrowRightIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' width={16} height={16} {...props}>
      <defs>
        <clipPath id='arrow-right-icon_svg__a'>
          <path fill='none' d='M0 1h16v16H0z' />
        </clipPath>
      </defs>
      <g
        data-name='Grupo de rolagem 138'
        transform='translate(0 -1)'
        clipPath='url(#arrow-right-icon_svg__a)'
        style={{
          isolation: 'isolate'
        }}
      >
        <path
          data-name='Stroke 1'
          d='M5 2.061l7 7-7 7'
          fill='none'
          stroke='#378ff7'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          strokeWidth={1.5}
        />
      </g>
    </svg>
  )
}

export default SvgArrowRightIcon
