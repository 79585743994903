import React, { forwardRef, InputHTMLAttributes } from 'react'
import { UseFormRegister, FieldValues } from 'react-hook-form'
import { CheckboxElement, CheckboxWrapper, Label } from './styles'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label: string
  checked?: boolean
  register?: UseFormRegister<FieldValues>
  onChange?: (props?: any) => void
  v7?: boolean
}

const Checkbox = forwardRef<HTMLInputElement, Props>(
  ({ label, ...props }, reference) => {
    return (
      <CheckboxWrapper>
        <CheckboxElement
          type='checkbox'
          id={props.name}
          {...props}
          ref={reference}
        />
        <Label htmlFor={props.name}>{label}</Label>
      </CheckboxWrapper>
    )
  }
)

export default Checkbox
