import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import {
  $getSelection,
  $isRangeSelection,
  FORMAT_ELEMENT_COMMAND,
  FORMAT_TEXT_COMMAND,
  SELECTION_CHANGE_COMMAND,
} from 'lexical'
import { useCallback, useEffect, useRef, useState } from 'react'
import { $getNearestNodeOfType, mergeRegister } from '@lexical/utils'
import {
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  REMOVE_LIST_COMMAND,
  $isListNode,
  ListNode,
} from '@lexical/list'
import { $isHeadingNode } from '@lexical/rich-text'
import { $patchStyleText } from '@lexical/selection'
import { ERichText } from '../constants'

export const useToolbar = () => {
  const [editor] = useLexicalComposerContext()
  const toolbarRef = useRef(null)
  const [blockType, setBlockType] = useState('paragraph')

  const [isBold, setIsBold] = useState(false)
  const [isItalic, setIsItalic] = useState(false)
  const [isUnderline, setIsUnderline] = useState(false)

  const updateToolbar = useCallback(() => {
    const selection = $getSelection()
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode()
      const element =
        anchorNode.getKey() === 'root' ? anchorNode : anchorNode.getTopLevelElementOrThrow()
      const elementKey = element.getKey()
      const elementDOM = editor.getElementByKey(elementKey)
      if (elementDOM !== null) {
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(anchorNode, ListNode)
          const type = parentList ? parentList.getTag() : element.getTag()
          setBlockType(type)
        } else {
          const type = $isHeadingNode(element) ? element.getTag() : element.getType()
          setBlockType(type)
        }
      }
      // Update text format
      setIsBold(selection.hasFormat(ERichText.bold))
      setIsItalic(selection.hasFormat(ERichText.italic))
      setIsUnderline(selection.hasFormat(ERichText.underline))
    }
  }, [editor])

  useEffect(
    () =>
      mergeRegister(
        editor.registerUpdateListener(({ editorState }) => {
          editorState.read(() => {
            updateToolbar()
          })
        }),
        editor.registerCommand(
          SELECTION_CHANGE_COMMAND,
          () => {
            updateToolbar()
            return false
          },
          1,
        ),
      ),
    [editor, updateToolbar],
  )

  const onFormatBold = useCallback(() => {
    editor.dispatchCommand(FORMAT_TEXT_COMMAND, ERichText.bold)
  }, [editor])

  const onFormatItalic = useCallback(() => {
    editor.dispatchCommand(FORMAT_TEXT_COMMAND, ERichText.italic)
  }, [editor])

  const onFormatUnderline = useCallback(() => {
    editor.dispatchCommand(FORMAT_TEXT_COMMAND, ERichText.underline)
  }, [editor])

  const onFormatBulletList = useCallback(() => {
    if (blockType !== 'ul') {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined)
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined)
    }
  }, [editor, blockType])

  const onFormatNumberedList = useCallback(() => {
    if (blockType !== 'ol') {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined)
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined)
    }
  }, [editor, blockType])

  const applyStyleText = useCallback(
    (styles: Record<string, string>) => {
      editor.update(() => {
        const selection = $getSelection()
        if ($isRangeSelection(selection)) {
          $patchStyleText(selection, styles)
        }
      })
    },
    [editor],
  )

  const onFontColorSelect = useCallback(
    (value: string) => {
      applyStyleText({ color: value })
    },
    [applyStyleText],
  )

  const onFontSizeSelect = useCallback(
    (option: string) => {
      editor.update(() => {
        const selection = $getSelection()
        if ($isRangeSelection(selection)) {
          $patchStyleText(selection, {
            'font-size': option,
          })
        }
      })
    },
    [editor],
  )

  const onAlignSelect = useCallback(
    (option: string) => {
      if (option === ERichText.left) editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, ERichText.left)
      if (option === ERichText.center) {
        editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, ERichText.center)
      }
      if (option === ERichText.right)
        editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, ERichText.right)
    },
    [editor],
  )

  return {
    editor,
    toolbarRef,
    blockType,
    isBold,
    isItalic,
    isUnderline,
    onFormatBold,
    onFormatItalic,
    onFormatUnderline,
    onFormatBulletList,
    onFormatNumberedList,
    onFontColorSelect,
    onFontSizeSelect,
    onAlignSelect,
  }
}
