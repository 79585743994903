import { theme } from '../../Theme'
import styled from 'styled-components'

export const RowButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: ${theme.spacing.base};
  border-top: 1px solid ${theme.colors.gray3};
  padding-top: 25px;
  button {
    width: 116px;
    height: 40px;
  }
  > div {
    display: flex;
    column-gap: ${theme.spacing.base};
  }
  span {
    width: 100%;
    height: 40px;
    text-align: center;
    color: ${theme.colors.blue3};
    font: ${theme.fontWeight.semibold + ' 14px ' + theme.font.root};
    cursor: pointer;
    padding-top: 10px;
  }

  @media (max-width: 750px) {
    flex-direction: column-reverse;
    margin-top: auto;
    margin-bottom: 30px;
    align-items: center;
  }
`

export const RowDiv = styled.div`
  display: flex;
  flex-direction: row;
`
