import React from 'react'

const SvgCopyLink = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='29.058'
      height='29.325'
      viewBox='0 0 29.058 29.325'
      {...props}
    >
      <g id='link' transform='translate(-0.745 -0.755)'>
        <path
          id='Caminho_25993'
          data-name='Caminho 25993'
          d='M10,16.759a6.71,6.71,0,0,0,10.118.725l4.026-4.026A6.709,6.709,0,1,0,14.656,3.97l-2.307,2.3'
          transform='translate(2.443)'
          fill='none'
          stroke='#378ff7'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2.5'
        />
        <path
          id='Caminho_25994'
          data-name='Caminho 25994'
          d='M18.1,11.686a6.71,6.71,0,0,0-10.118-.725L3.961,14.987a6.709,6.709,0,1,0,9.488,9.488l2.294-2.3'
          transform='translate(0 2.39)'
          fill='none'
          stroke='#378ff7'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2.5'
        />
      </g>
    </svg>
  )
}

export default SvgCopyLink
