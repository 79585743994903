import React, { forwardRef, useMemo } from 'react'
import styled from 'styled-components'

import IconExporter from '../../IconExporter'
import { useMultipleCheck, IValue } from './hook'
import {
  CheckboxElement,
  CheckboxWrapper,
  ExpandedContainer,
  IconWrapper,
  Label,
  MultiCheckContainer,
  OptionsDisplay,
  WrapperFakeInput,
  ErrorContainer
} from './styles'

const FakeInput = styled.input`
  width: 0;
  height: 0;
  border: none;
  position: static;
`

interface IMultipleCheck {
  options: IValue[]
  placeholder?: string
  error?: {
    value: boolean
    message: string
  }
  name?: string
  onChange?: (props: { target: { value: IValue[] } }) => void
  value?: IValue[]
  onBlur?: () => void
  styles?: React.CSSProperties
}

const MultipleCheck = forwardRef<HTMLInputElement, IMultipleCheck>(
  (
    {
      styles,
      options,
      placeholder,
      error,
      name,
      onChange,
      onBlur,
      value: fieldValue
    },
    ref
  ) => {
    const { toggleExpanded, expanded, formatValue, wrapperRef } =
      useMultipleCheck()
    const updateOnChange = React.useCallback(
      (value) => {
        const formattedArray = formatValue(fieldValue, value)
        onChange?.({ target: { value: formattedArray } })
      },
      [fieldValue, formatValue, onChange]
    )

    const selectOptions = useMemo(
      () =>
        options?.sort((a, b) =>
          a.label < b.label ? -1 : a.label > b.label ? 1 : 0
        ),
      [options]
    )

    return (
      <MultiCheckContainer ref={wrapperRef} {...{ styles }} key={name}>
        <FakeInput
          data-testid='multiple-check-input'
          ref={ref}
          tabIndex={-1}
          onFocus={(e) => e.target.blur()}
        />
        <WrapperFakeInput
          error={error?.value}
          data-testid='wrapper-container'
          onClick={toggleExpanded}
        >
          <OptionsDisplay>
            {fieldValue?.length
              ? fieldValue.reduce(
                  (prev, { label }) => `${prev ? prev + ', ' : ''}${label}`,
                  ''
                )
              : placeholder || 'Selecione'}
          </OptionsDisplay>

          <IconWrapper
            rotate={expanded}
            data-testid='icon-container'
            error={error?.value}
            onClick={() => {
              if (expanded) {
                onBlur?.()
              }
              toggleExpanded()
            }}
          >
            <IconExporter
              onClick={toggleExpanded}
              name='chevron'
              iconsize={15}
            />
          </IconWrapper>
        </WrapperFakeInput>
        {expanded && (
          <ExpandedContainer data-testid='options-container'>
            <React.Fragment>
              {selectOptions?.map((option, index) => {
                const checked = fieldValue?.some(
                  ({ value }) => value === option.value
                )
                return (
                  <CheckboxWrapper key={`${name}-checkbox-${index}`}>
                    <CheckboxElement
                      type='checkbox'
                      data-testid='checkbox-element-test'
                      id={`${name}-checkbox-${index}`}
                      {...{ checked }}
                      onChange={() => updateOnChange(option)}
                    />
                    <Label htmlFor={`${name}-checkbox-${index}`}>
                      {option.label}
                    </Label>
                  </CheckboxWrapper>
                )
              })}
            </React.Fragment>
          </ExpandedContainer>
        )}
        {error?.message && (
          <ErrorContainer data-testid='error-container'>
            {error.message}
          </ErrorContainer>
        )}
      </MultiCheckContainer>
    )
  }
)

export default MultipleCheck
