import React from 'react'
import { SvgProps } from './typings/SvgProps'

const Location: React.FC<SvgProps> = ({ color = 'currentColor', size, ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} {...rest} viewBox="0 0 13.758 16.134">
    <g transform="translate(0.15 0.15)">
      <g>
        <path
          d="M5.949,15.582,5.9,15.545l-.327-.22a16.434,16.434,0,0,1-3.59-3.369l-.25-.327A8.471,8.471,0,0,1,0,6.75l0-.226A6.739,6.739,0,0,1,6.729,0h.023l.2,0a6.742,6.742,0,0,1,6.5,6.767v.081A9.57,9.57,0,0,1,10.514,13.1a16.76,16.76,0,0,1-2.965,2.45l-.039.033a1.337,1.337,0,0,1-1.561,0ZM6.558,1.2A5.541,5.541,0,0,0,1.2,6.729l.015.283A7.273,7.273,0,0,0,2.689,10.9,15.176,15.176,0,0,0,6.57,14.548l.065.049a.143.143,0,0,0,.189,0l.067-.051A15.531,15.531,0,0,0,9.648,12.27a8.391,8.391,0,0,0,2.613-5.429l0-.263A5.54,5.54,0,0,0,6.748,1.2ZM4.165,6.847A2.564,2.564,0,1,1,6.729,9.419,2.57,2.57,0,0,1,4.165,6.847Zm1.2,0a1.366,1.366,0,1,0,1.366-1.37A1.369,1.369,0,0,0,5.363,6.847Z"
          transform="translate(0 0)"
          fill={color}
          stroke={color}
          strokeWidth="0.3"
        />
      </g>
    </g>
  </svg>
)

export default Location
