import styled, { css } from 'styled-components'
import { theme } from '../../Theme'

interface SwitchContainer {
  bgColor?: string
  position: number
}

interface SwitchOption{
  selected: boolean
}

const state = {
  selected: css`
    color: ${theme.colors.white};
    border-radius: ${props => props.theme.borderRadius.tags};
  `,
  unselected: css`
    color: ${theme.colors.gray5};
    border-radius: ${props => props.theme.borderRadius.tags};
  `
}

export const SwitchContainer = styled.ul<SwitchContainer>`
  display: flex;
  justify-content: flex-start;
  width: fit-content;
  background: ${theme.colors.white};
  box-shadow: 0px 3px 6px #00000029;
  border-radius: ${theme.borderRadius.tags};
  padding: 0px;
  position: relative;

  white-space: nowrap;
  text-overflow: ellipsis;

  &:after {
    content: '';
    background: ${({ bgColor }) => bgColor || theme.colors.blue3};
    width: 200px;
    height: 30px;
    border-radius: ${theme.borderRadius.tags};
    position: absolute;
    transition: all 0.2s linear;

    transform: translateX( ${({ position }) => (position === 0 ? 100 / position : 100 * position)}%);
  }
`

export const SwitchOption = styled.li<SwitchOption>`
  cursor: pointer;
  list-style: none;
  user-select: none;
  width: 200px;
  height: 30px;

  white-space: nowrap;
  text-overflow: ellipsis;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 14px;
  line-height: 0px;
  font-weight: ${theme.fontWeight.semibold};
  background: transparent;

  transition: all 0.2s;
  z-index: 1;

  ${({ selected }) => (selected ? state.selected : state.unselected)};
`

export const TabContent = styled.div``
