import React from 'react'

const SvgMedicalRecord = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={24.912}
      height={29.158}
      viewBox='0 0 24.912 29.158'
      {...props}
    >
      <g id='Grupo_161644' data-name='medical_record' transform='translate(0)'>
        <g id='Grupo_10978' data-name='Grupo 10978' transform='translate(0 0)'>
          <g id='clipboard'>
            <path
              id='Caminho_5063'
              data-name='Caminho 5063'
              d='M22.684,4H25.8a3.054,3.054,0,0,1,3.114,2.989V27.916A3.054,3.054,0,0,1,25.8,30.905H7.114A3.054,3.054,0,0,1,4,27.916V6.989A3.054,3.054,0,0,1,7.114,4h3.114'
              transform='translate(-4 -1.747)'
              opacity='0.30'
            />
            <rect
              id='Retângulo_140868'
              data-name='Retângulo 140868'
              width='13.238'
              height='4.505'
              rx='1'
              transform='translate(5.836)'
            />
          </g>
        </g>
        <path
          id='Stroke_5'
          data-name='Stroke 5'
          d='M12.491.5H0'
          transform='translate(5.065 18.075)'
          stroke='#fff'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit='10'
          strokeWidth='1.5'
        />
        <path
          id='Stroke_7'
          data-name='Stroke 7'
          d='M4.287.5H0'
          transform='translate(5.006 11.513)'
          fill='#d8e1f0'
          stroke='#fff'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit='10'
          strokeWidth='1.5'
        />
      </g>
    </svg>
  )
}

export default SvgMedicalRecord
