import React from 'react'
import { SvgProps } from './typings/SvgProps'

const AsideClose: React.FC<SvgProps> = ({ color = 'currentColor', size, ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} {...rest} viewBox="0 0 16.69 16.323">
    <g transform="translate(0.7 0.7)">
      <g transform="translate(-0.771 -0.771)">
        <g transform="translate(0.771 0.771)">
          <path
            d="M8.115,15.48h-5.7A1.666,1.666,0,0,1,.75,13.815V2.415A1.666,1.666,0,0,1,2.415.75h11.4A1.666,1.666,0,0,1,15.48,2.415V6.4"
            transform="translate(-0.75 -0.75)"
            fill="none"
            stroke={color}
            strokeWidth="1.4"
          />
          <path
            d="M8.115,15.48h-5.7A1.666,1.666,0,0,1,.75,13.815V2.415A1.666,1.666,0,0,1,2.415.75h11.4A1.666,1.666,0,0,1,15.48,2.415v4.2"
            transform="translate(-0.75 -0.75)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="1.4"
          />
          <line
            y2="14.73"
            transform="translate(7.365)"
            fill="none"
            stroke={color}
            strokeMiterlimit="10"
            strokeWidth="1.4"
          />
          <line
            x2="2.568"
            transform="translate(2.415 4.458)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="1.4"
          />
          <line
            x2="2.568"
            transform="translate(2.415 7.365)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="1.4"
          />
          <line
            x2="2.568"
            transform="translate(2.415 10.272)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="1.4"
          />
          <path
            d="M16.025,11.109l-2.436,2.605a.39.39,0,0,0,.016.549l2.42,2.307"
            transform="translate(-1.025 -1.936)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="1.4"
          />
        </g>
      </g>
    </g>
  </svg>
)

export default AsideClose
