/* eslint-disable no-unneeded-ternary */
import styled from 'styled-components'

import { theme } from '../Theme'

interface IPropsStyles {
  widthSize: number
  colors: string[]
  sizes: number[]
  transitionProps?: string
  transitionDelay?: number
}

interface IRisk {
  size: number
}

export const Container = styled.div`
  border-radius: 4px;
  opacity: 1;
  width: 100%;
  height: 8px;
  background-color: ${theme.colors.gray2};
  position: relative;
  min-width: 200px;
`

export const Progress = styled.div<IPropsStyles>`
  width: ${(props) => props.widthSize}%;
  background-color: ${({ widthSize, colors, sizes }) => {
    const response = { sumSize: 0 }
    for (let i = 0; i < sizes.length; i++) {
      response.sumSize += sizes[i]
      if (sizes.length - 1 === i && widthSize <= 100) {
        return colors[i] || theme.colors.gray4
      }
      if (widthSize <= response.sumSize) {
        return colors[i] || theme.colors.gray4
      }
    }

    return theme.colors.gray4
  }};
  border-radius: 4px;
  height: 100%;
  transition: ${({ transitionProps }) =>
    transitionProps ? transitionProps : '0.4s linear'};
  transition-property: width, background-color;
  transition-delay: ${({ transitionDelay }) =>
    transitionDelay ? transitionDelay : ''}s;
`

export const StatusContainer = styled.div`
  width: 100%;
  display: flex;
  position: absolute;
  top: 0;
`

export const Risk = styled.div<IRisk>`
  width: ${({ size }) => size}%;
  display: flex;
  justify-content: flex-end;
`

export const Hr = styled.div`
  height: 8px;
  width: 2px;
  background-color: ${theme.colors.gray2};
`
