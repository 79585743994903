import React from 'react'
import { SvgProps } from './typings/SvgProps'

const CalendarMonth: React.FC<SvgProps> = ({ color = 'currentColor', size, ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} {...rest} viewBox="0 0 16.3 17.37">
    <g transform="translate(0.15 0.15)">
      <path
        d="M65.6,77.548H63.7v-.92a.62.62,0,1,0-1.24,0v.92H57.5v-.92a.62.62,0,1,0-1.24,0v.92h-1.9A2.37,2.37,0,0,0,52,79.933V90.685a2.37,2.37,0,0,0,2.357,2.385H65.643A2.37,2.37,0,0,0,68,90.685V79.975A2.441,2.441,0,0,0,65.6,77.548ZM54.191,78.8h2.026v1a.62.62,0,1,0,1.24,0v-1h4.92v1a.62.62,0,1,0,1.24,0v-1h2.026a1.03,1.03,0,0,1,1.034,1.046V81.9H53.034v-2.05A1.278,1.278,0,0,1,54.191,78.8Zm3.762,8.158V82.987h3.928v3.975Zm3.928,1.046V91.94H57.953V88.008ZM56.92,86.962H53.158V82.987H56.92Zm5.995-3.975H66.8v3.975H62.915ZM54.191,91.94a1.03,1.03,0,0,1-1.034-1.046V88.008H56.92V91.94Zm11.535,0H62.915V88.008H66.8v2.887A1.065,1.065,0,0,1,65.726,91.94Z"
        transform="translate(-52 -76)"
        fill={color}
        stroke={color}
        strokeWidth="0.3"
      />
    </g>
  </svg>
)

export default CalendarMonth
