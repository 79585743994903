import { theme } from '@cockpit/levi-ui/theme'
import styled from '@emotion/styled'
import MuiAvatar from '@mui/material/Avatar'
import { ImageSizeProp } from './typing'

const avatarSizes = {
  xs: '24px',
  sm: '32px',
  md: '40px',
  lg: '48px',
  xl: '64px',
  '2xl': '80px',
  '3xl': '88px',
  '4xl': '96px',
}

const fontSize = {
  xs: '0.875rem',
  sm: '0.875rem',
  md: '1rem',
  lg: '1.5rem',
  xl: '2rem',
  '2xl': '2.5rem',
  '3xl': '3rem',
  '4xl': '3.25rem',
}

export const iconSize = {
  xs: 12,
  sm: 16,
  md: 18,
  lg: 22,
  xl: 26,
  '2xl': 34,
  '3xl': 38,
  '4xl': 40,
}

export const StyledAvatar = styled(MuiAvatar)<{ size: ImageSizeProp }>`
  height: ${({ size }) => avatarSizes[size]};
  width: ${({ size }) => avatarSizes[size]};

  font-size: ${({ size }) => fontSize[size]};
  color: ${theme.colors.blue[30]};
  background-color: ${theme.colors.blue[20]};
  text-transform: uppercase;
`
