import React from 'react'
import { SvgProps, WithSecondaryColor } from './typings/SvgProps'

const CheckCircle: React.FC<SvgProps & WithSecondaryColor> = ({
  color = 'currentColor',
  secondaryColor = '#fff',
  size,
  ...rest
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} {...rest} viewBox="0 0 20 20">
    <g transform="translate(-281 -2603)">
      <circle cx="10" cy="10" r="10" transform="translate(281 2603)" fill={color} />
      <path
        d="M1116.4,1377.086l3.1,3.1,5.332-5.069"
        transform="matrix(0.996, -0.087, 0.087, 0.996, -945.102, 1338.262)"
        fill="none"
        stroke={secondaryColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.6"
      />
    </g>
  </svg>
)

export default CheckCircle
