import React from 'react'
import { SvgProps, WithSecondaryColor } from './typings/SvgProps'

const Envelope: React.FC<SvgProps & WithSecondaryColor> = ({
  color = 'currentColor',
  size,
  ...rest
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} {...rest} viewBox="0 0 20.502 17.5">
    <g transform="translate(0.75 0.75)">
      <g>
        <path
          d="M11.178,0,6.964,3.21a2.3,2.3,0,0,1-2.713,0L0,0"
          transform="translate(3.907 5.198)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M4.829,0h9.315a5.028,5.028,0,0,1,3.537,1.487,4.542,4.542,0,0,1,1.31,3.462v6.1a4.542,4.542,0,0,1-1.31,3.462A5.028,5.028,0,0,1,14.144,16H4.829A4.759,4.759,0,0,1,0,11.051v-6.1A4.759,4.759,0,0,1,4.829,0Z"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
    </g>
  </svg>
)

export default Envelope
