import styled from 'styled-components'
import { theme } from '../../../Theme'
interface Input {
  error: boolean
}

const { red1, gray6, black } = theme.colors

export const InputStyle = styled.input<Input>`
  width: 30px;
  height: 30px;
  border: ${({ error }) =>
    error ? `1px solid  ${red1}` : `1px solid ${gray6}`};
  border-radius: 4px;
  background: #fff;
  margin: 0 8px;

  font: normal 14px sans-serif;
  color: ${black};
  text-align: center;
`
