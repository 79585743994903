import React from 'react'

const SvgBoldPaper = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      height={29.206}
      width={25}
      viewBox='0 0 25 29.206'
      {...props}
    >
      <g
        id='Grupo_163057'
        data-name='Grupo 163057'
        transform='translate(-256 -347)'
      >
        <g
          id='Iconly_Bold_Paper'
          data-name='Iconly/Bold/Paper'
          transform='translate(252.5 345)'
        >
          <g id='Paper' transform='translate(3.5 2)'>
            <path
              id='Paper-2'
              data-name='Paper'
              d='M6.6,29A6.725,6.725,0,0,1,0,22.171V6.539A6.432,6.432,0,0,1,6.3,0h7.465a.656.656,0,0,1,.643.667V5.336a4.783,4.783,0,0,0,4.669,4.843c.6,0,1.121,0,1.584.009.362,0,.678.005.959.005.2,0,.456,0,.736-.007.323,0,.686-.008,1.011-.008a.641.641,0,0,1,.629.653V22.489A6.4,6.4,0,0,1,17.723,29Zm.014-9.221A1.082,1.082,0,0,0,7.66,20.866h7.6a1.082,1.082,0,0,0,1.048-1.087A1.062,1.062,0,0,0,15.263,18.7H7.66A1.062,1.062,0,0,0,6.612,19.779Zm0-7.236A1.062,1.062,0,0,0,7.66,13.615h4.724a1.062,1.062,0,0,0,1.048-1.073,1.082,1.082,0,0,0-1.048-1.087H7.66A1.082,1.082,0,0,0,6.612,12.542ZM19.267,8.061a2.889,2.889,0,0,1-2.819-2.925V1.314A.666.666,0,0,1,17.6.83c1.511,1.629,4.151,4.479,5.627,6.071a.694.694,0,0,1-.48,1.169l-.976,0C20.884,8.072,19.975,8.068,19.267,8.061Z'
              opacity='0.30'
            />
          </g>
        </g>
        <path
          id='Fill_1'
          data-name='Fill 1'
          d='M2.819,7.445c.974.01,2.329.014,3.478.01a.694.694,0,0,0,.479-1.17L1.149.214A.665.665,0,0,0,0,.7V4.52A2.888,2.888,0,0,0,2.819,7.445'
          transform='translate(272.447 347.615)'
          opacity='0.30'
        />
      </g>
    </svg>
  )
}

export default SvgBoldPaper
