import React from 'react';

const SvgSchedule = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={20.432}
      height={20.894}
      viewBox='0 0 21 21'
      {...props}
    >
      <path d='M8.602 16.249H2.638A1.229 1.229 0 011.41 15.02V7.446h14.24v1.936a.705.705 0 001.41 0V4.203a2.641 2.641 0 00-2.638-2.637H12.7V.705a.705.705 0 00-1.41 0v.861H5.764V.705a.705.705 0 00-1.41 0v.861H2.633A2.64 2.64 0 00-.005 4.203v10.815a2.64 2.64 0 002.638 2.638h5.964a.705.705 0 000-1.41zM1.41 4.203a1.229 1.229 0 011.228-1.228h1.721v.637a.705.705 0 001.41 0v-.637h5.521v.637a.705.705 0 001.41 0v-.637h1.722a1.23 1.23 0 011.228 1.228v1.834H1.41zM15.689 15.488v-1a.705.705 0 10-1.409 0v1.271a.7.7 0 00.226.513.767.767 0 00.056.084l.832.831a.707.707 0 101-1z' />
      <path d='M14.984 10.304a5.3 5.3 0 105.448 5.3 5.38 5.38 0 00-5.448-5.3zm0 9.181a3.888 3.888 0 114.039-3.885 3.969 3.969 0 01-4.039 3.885z' />
    </svg>
  )
}

export default SvgSchedule
