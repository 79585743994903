import React from 'react'
import { SvgProps } from './typings/SvgProps'

const AlertCircle: React.FC<SvgProps> = ({ color = 'currentColor', size, ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} {...rest} viewBox="0 0 14.3 14.3">
    <g transform="translate(0.15 0.15)">
      <path
        data-name="Info Circle"
        d="M0,7a7,7,0,1,1,7,7A7.008,7.008,0,0,1,0,7ZM1.05,7A5.95,5.95,0,1,0,7,1.05,5.957,5.957,0,0,0,1.05,7ZM6.471,9.657a.525.525,0,0,1,.454-.52L7,9.132a.525.525,0,0,1,.071,1.046l-.078,0A.525.525,0,0,1,6.471,9.657Zm.005-2.15-.005-.072V4.343a.525.525,0,0,1,1.045-.071l.005.071V7.436a.525.525,0,0,1-1.045.072Z"
        fill={color}
        stroke={color}
        strokeWidth="0.3"
      />
    </g>
  </svg>
)

export default AlertCircle
