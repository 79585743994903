import React from 'react';

const SvgWarning = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='currentColor'
      width={17.985}
      height={16.471}
      viewBox='0 0 18 18'
      {...props}
    >
      <path d='M15.511 16.471H2.474a2.471 2.471 0 01-2.151-3.687L6.842 1.255A2.443 2.443 0 018.992 0a2.442 2.442 0 012.15 1.255l6.518 11.529a2.471 2.471 0 01-2.151 3.687zM8.993.92a1.532 1.532 0 00-1.349.788l-6.518 11.53a1.55 1.55 0 001.349 2.313h13.036a1.55 1.55 0 001.349-2.313l-6.517-11.53a1.534 1.534 0 00-1.35-.787z' />
      <path d='M8.999 13.537a.872.872 0 01-.654-.251.891.891 0 01-.246-.649.855.855 0 01.246-.63.886.886 0 01.654-.246.863.863 0 01.654.246.878.878 0 01.234.63.916.916 0 01-.234.649.849.849 0 01-.654.251zm0-2.5q-.468 0-.5-.552L8.139 5.9a.944.944 0 01.192-.713.829.829 0 01.672-.27.8.8 0 01.654.27.957.957 0 01.185.713l-.36 4.585q-.039.557-.483.557z' />
    </svg>
  )
}

export default SvgWarning
