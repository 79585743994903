import React from 'react';

const SvgDownload = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={13.711}
      height={13.711}
      viewBox='0 0 21 21'
      {...props}
    >
      <g
        fill='none'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={1.5}
      >
        <path
          className='download_svg__a'
          d='M12.961 8.891v2.714a1.357 1.357 0 01-1.357 1.357h-9.5A1.357 1.357 0 01.75 11.605V8.891M3.464 5.499l3.392 3.392 3.392-3.392M6.75 8.75v-8'
        />
      </g>
    </svg >
  )
}

export default SvgDownload
