import React from 'react';

const SvgMonitor = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={19.58}
      height={19.579}
      viewBox='0 0 21 21'
      {...props}
    >
      <path d='M16.569 0H3.01A3.014 3.014 0 000 3.01v9.543a3.013 3.013 0 003.01 3.009h4.024l-.467 2.518H5.191a.75.75 0 000 1.5h9.2a.75.75 0 000-1.5h-1.376l-.467-2.518h4.023a3.014 3.014 0 003.011-3.009V3.011A3.014 3.014 0 0016.569 0zm-5.081 18.079h-3.4l.468-2.518h2.46zm6.592-5.527a1.512 1.512 0 01-1.511 1.509H3.01a1.512 1.512 0 01-1.51-1.509V3.01A1.512 1.512 0 013.01 1.5h13.559a1.512 1.512 0 011.511 1.51z' />
      <path d='M9.79 7.861a2.641 2.641 0 10-2.641-2.64 2.643 2.643 0 002.641 2.64zm0-3.781a1.141 1.141 0 11-1.141 1.141A1.142 1.142 0 019.79 4.08zM11.146 8.164H8.385a2.919 2.919 0 00-2.845 2.842v1.386a.75.75 0 001.5 0v-1.386a1.409 1.409 0 011.358-1.342h2.748a1.423 1.423 0 011.394 1.342v1.386a.75.75 0 001.5 0v-1.386a2.907 2.907 0 00-2.894-2.842z' />
    </svg>
  )
}

export default SvgMonitor
