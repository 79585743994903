import React from 'react'
import { SvgProps } from './typings/SvgProps'

const Search: React.FC<SvgProps> = ({ color = 'currentColor', size, ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} {...rest} viewBox="0 0 15.797 15.266">
    <g transform="translate(0.75 0.75)">
      <g transform="translate(0 0)">
        <circle
          cx="6"
          cy="6"
          r="6"
          transform="translate(0 0)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <line
          x1="3.579"
          y1="3.579"
          transform="translate(10.407 9.876)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
    </g>
  </svg>
)

export default Search
