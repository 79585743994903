import styled, { css } from 'styled-components'
import { theme } from '../Theme'

export type ModalWidth = 'small' | 'medium' | 'big' | 'sideMenu' | undefined

const modalSizes = {
  small: 470,
  medium: 788,
  big: 1000,
  sideMenu: 350
}

export const ModalWrapper = styled.div<{ show: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: ${({ show }) => (show ? '100%' : '0')};
  opacity: ${({ show }) => (show ? '1' : '0')};
  transition: opacity 0.7s;
  z-index: 10000;
`
export const ModalMask = styled.div<{ visible: boolean }>`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  min-height: 100vh;
  opacity: ${({ visible }) => (visible ? '1' : '0')};
  z-index: 10001;
`
interface IModalContainer {
  isMobileFullscreen: boolean
  noBodyScrollable?: boolean
}
export const ModalContainer = styled.div<IModalContainer>`
  overflow-y: auto;
  width: 100%;
  min-height: 100%;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  z-index: 10002;

  @media (min-width: 1024px) {
    overflow-y: ${(props) => (props.noBodyScrollable ? 'hidden' : 'auto')};
  }

  @media (max-width: ${theme.responsiveSize.big}) {
    ${(props) =>
      props.isMobileFullscreen
        ? css`
            position: fixed;
          `
        : css`
            top: 10px;
          `}
  }
`
interface IModalDialog {
  size: ModalWidth
  isMobileFullscreen: boolean
  customWidth: number
  customBackgroundColor?: string
  modalDialogMarginTop?: string
}

export const ModalDialog = styled.div<IModalDialog>`
  background-color: ${(props) =>
    props.customBackgroundColor ? props.customBackgroundColor : '#fff'};
  box-shadow: 0px 3px 12px #00000040;
  border-radius: 4px;
  position: relative;
  left: 50%;
  padding: 0px 10px 32px 10px;
  z-index: 14;
  margin-bottom: 100px;
  margin-top: ${(props) =>
    props.modalDialogMarginTop ? props.modalDialogMarginTop : '90px'};

  @media (max-width: ${theme.responsiveSize.big}) {
    padding: 0px 12px 20px 12px;
    ${(props) =>
      props.isMobileFullscreen
        ? css`
            width: 100%;
            min-height: 100%;
            max-height: 100vh;
            overflow-y: auto;
            margin: 0 0 0 -50%;
          `
        : css`
            width: 95%;
            margin-left: -47.5%;
          `}
  }

  ${(props) => {
    switch (props.size) {
      case 'small':
        return css`
          width: ${props.customWidth !== 0
            ? props.customWidth
            : modalSizes.small}px;
          margin-left: -${props.customWidth !== 0 ? props.customWidth / 2 : modalSizes.small / 2}px;
        `
      case 'big':
        return css`
          width: ${props.customWidth !== 0
            ? props.customWidth
            : modalSizes.big}px;
          margin-left: -${props.customWidth !== 0 ? props.customWidth / 2 : modalSizes.big / 2}px;
          @media (max-width: ${theme.responsiveSize.bigTablet}) {
            width: 95%;
            margin-left: -47.5%;
          }
          ${props.isMobileFullscreen &&
          css`
            @media (max-width: ${theme.responsiveSize.big}) {
              width: 100%;
              margin-left: -50%;
            }
          `}
        `
      case 'sideMenu':
        return css`
          margin: 0 0 0 -${props.customWidth !== 0 ? props.customWidth : modalSizes.sideMenu}px;
          padding: 0;
          top: 0;
          width: ${props.customWidth !== 0
            ? props.customWidth
            : modalSizes.sideMenu}px;
          min-height: 100%;
          left: 100%;
          border-radius: 0;
          @media (max-width: ${theme.responsiveSize.big}) {
            left: 0;
            margin-left: 0;
          }
        `
      default:
        return css`
          width: ${props.customWidth !== 0
            ? props.customWidth
            : modalSizes.medium}px;
          margin-left: -${props.customWidth !== 0 ? props.customWidth / 2 : modalSizes.medium / 2}px;
          @media (max-width: ${theme.responsiveSize.tablet}) {
            width: 95%;
            margin-left: -47.5%;
          }
          ${props.isMobileFullscreen &&
          css`
            @media (max-width: ${theme.responsiveSize.big}) {
              width: 100%;
              margin-left: -50%;
            }
          `}
        `
    }
  }}
`
interface IModalHeader {
  headerFont: string
  headerBorderBottom: boolean
  headerPaddingCloseButton?: string
  headerMarginBottom?: string
}

export const ModalHeader = styled.div<IModalHeader>`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  border-bottom: ${(props) =>
    props.headerBorderBottom ? `1px solid ${theme.colors.gray3}` : ''};
  padding: ${(props) => props.headerPaddingCloseButton ?? '12px 10px 12px 0px'};
  margin-bottom: ${(props) => props.headerMarginBottom ?? '18px'};
  h3 {
    width: 100%;
    margin-left: 10px;
    font: ${(props) => props.headerFont};
  }
  @media (max-width: ${theme.responsiveSize.big}) {
    margin-bottom: 12px;

    h3 {
      margin: 0 auto;
      text-align: center;
    }
  }
`
interface IModalCloseButton {
  width: string
  customCloseButtonMargin?: string
  hideModalCloseButton?: boolean
}

export const ModalCloseButton = styled.button<IModalCloseButton>`
  width: ${(props) => props.width};
  height: 100%;
  display: ${(props) =>
    props.hideModalCloseButton === true ? 'none' : 'flex'};
  justify-content: flex-end;
  align-items: flex-end;
  filter: invert(35%) sepia(23%) saturate(309%) hue-rotate(175deg)
    brightness(95%) contrast(91%);
  margin: ${(props) => props.customCloseButtonMargin};
  margin-left: auto;
  padding: 0;
  border: 0;
  background-color: transparent;
  svg {
    cursor: pointer;
  }
`
interface IModalFullscreenCloseButton {
  width: string
  hideModalCloseButton?: boolean
}

export const ModalFullscreenCloseButton = styled.button<IModalFullscreenCloseButton>`
  width: ${(props) => props.width};
  height: 100%;
  display: ${(props) =>
    props.hideModalCloseButton === true ? 'none' : 'flex'};
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0;
  border: 0;
  background-color: transparent;
  filter: invert(35%) sepia(23%) saturate(309%) hue-rotate(175deg)
    brightness(95%) contrast(91%);
  svg {
    cursor: pointer;
    display: ${(props) =>
      props.hideModalCloseButton === true ? 'none' : 'unset'};
  }
`
interface IModalContent {
  padding: string
  size: ModalWidth
  isMobileFullscreen: boolean
}

export const ModalContent = styled.div<IModalContent>`
  position: relative;
  ${(props) => {
    switch (props.size) {
      case 'sideMenu':
        return css`
          padding: 0;
        `
      default:
        return css`
          padding: ${props.padding};
          @media (max-width: ${theme.responsiveSize.big}) {
            padding: 0;
          }
        `
    }
  }}
`
