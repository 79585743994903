import styled, { css } from 'styled-components'
import { theme } from '../Theme'

interface INotificationWrapper {
  type: string
}

const state = {
  primary: css`
    background: ${theme.colors.blue3};
  `,
  secondary: css`
    background: ${theme.colors.gray5};
  `,
  warning: css`
    background: ${theme.colors.red1};
  `,
  success: css`
    background: ${theme.colors.green1};
  `
}

export const NotificationWrapper = styled.div<INotificationWrapper>`
  border-radius: ${theme.borderRadius.notifications};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  min-width: 16px;
  width: fit-content;
  padding: 0 6px;
  transition: all 0.2s ease-in-out;

  ${({ type }) => (type ? state[type] : state.primary)};
`

export const Number = styled.span`
  font-weight: ${theme.fontWeight.bold};
  color: ${theme.colors.white};
  font-size: 10px;
`
