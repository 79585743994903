import React from 'react';

const SvgRegistroIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={20} height={20} viewBox='0 0 21 21' {...props}>
      <g data-name='Grupo 11428'>
        <path data-name='Ret\xE2ngulo 5389' fill='none' d='M0 0h20v20H0z' />
        <path
          data-name='Caminho 5161'
          d='M5.971 13.05H11.7v1.636H5.971zm0-3.271h8.179v1.636H5.971zm0-3.271h8.179v1.635H5.971zm9.814-3.271h-3.418a2.444 2.444 0 00-4.613 0H4.336a1.433 1.433 0 00-.327.033 1.653 1.653 0 00-1.178.973 1.576 1.576 0 00-.131.628v11.45a1.678 1.678 0 00.131.638 1.733 1.733 0 00.352.523 1.642 1.642 0 00.826.45 2.122 2.122 0 00.327.025h11.45a1.641 1.641 0 001.636-1.636V4.871a1.641 1.641 0 00-1.636-1.635zm-5.725-.2a.613.613 0 11-.613.613.618.618 0 01.614-.619zm5.725 13.29H4.336V4.871h11.45z'
          fill='#6b7786'
        />
      </g>
    </svg>
  )
}

export default SvgRegistroIcon
