import React from 'react'
import { Container } from './styles'

export type ITypographySize = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10

export type ITypographyWeight = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9

export interface ITypography {
  size?: ITypographySize
  weight?: ITypographyWeight
  className?: string
  testId?: string
  tag?: 'p' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'span'
}

const Typography: React.FC<React.PropsWithChildren<ITypography>> = ({
  size = 3,
  weight = 4,
  className = '',
  testId = 'levi-typography',
  tag = 'span',
  children,
}) => (
  <Container
    className={`levi-font-size-${size} levi-font-weight-${weight} ${className}`}
    data-testid={testId}
    as={tag}
  >
    {children}
  </Container>
)

export default Typography
