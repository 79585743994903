import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { IPropsTheme } from '../../Theme'

interface WrapperProps {
  $isopen?: boolean
  $isbuttonactive?: boolean | string
  $ismenucontroller?: boolean | string
  $hasTooltip?: boolean
}

export const LinkWrapper = styled(Link)<WrapperProps>`
  position: relative;
  display: flex;
  text-decoration: none;

  ${({ $isopen }) =>
    $isopen
      ? `flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      padding: 0 10px;`
      : `flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding: 0;`}

  width: ${({ $ismenucontroller: ismenucontroller }) =>
    ismenucontroller ? 'auto' : '100%'};
  background-color: ${({ $isopen, $isbuttonactive: isbuttonactive }) =>
    $isopen && isbuttonactive
      ? ({ theme }: IPropsTheme) => theme.menu.iconContainer
      : 'transparent'};
  margin-top: 14px;
  border: none;
  cursor: pointer;
  outline: none;
  transition: background-color 0.2s, padding 0.2s;
  transition-delay: ${({ $isopen }) => (!$isopen ? '1s, .0s' : '0')};

  span {
    color: ${({ $isopen }) =>
      $isopen
        ? ({ theme }: IPropsTheme) => theme.menu.iconContainerColor
        : 'none'};
    color: ${({ $isopen, $isbuttonactive: isbuttonactive }) =>
      $isopen && isbuttonactive
        ? ({ theme }: IPropsTheme) => theme.menu.iconContainerColorHover
        : 'none'};
  }

  &:hover {
    background-color: ${({ $isopen }) =>
      $isopen ? ({ theme }: IPropsTheme) => theme.menu.background : 'none'};
    background-color: ${({ $isopen, $isbuttonactive: isbuttonactive }) =>
      $isopen && isbuttonactive
        ? ({ theme }: IPropsTheme) => theme.menu.iconContainer
        : 'none'};

    span {
      color: ${({ $hasTooltip: hasTooltip, $isopen }) =>
        hasTooltip && !$isopen
          ? ({ theme }: IPropsTheme) => theme.colors.white
          : ({ theme }: IPropsTheme) => theme.menu.iconContainerColorHover};
    }

    div {
      color: ${({ theme }: IPropsTheme) => theme.menu.iconContainerColorHover};
    }

    svg {
      color: ${({ theme }: IPropsTheme) => theme.menu.iconContainerColorHover};
      fill: ${({ theme }: IPropsTheme) => theme.menu.iconContainerColorHover};
    }
  }
`

export const ButtonWrapper = styled.button<WrapperProps>`
  position: relative;
  display: flex;
  text-decoration: none;
  z-index: 0;

  ${({ $isopen }) =>
    $isopen
      ? `flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      padding: 0 10px;`
      : `flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding: 0;`}

  width: ${({ $ismenucontroller: ismenucontroller }) =>
    ismenucontroller ? 'auto' : '100%'};
  background-color: ${({ $isopen, $isbuttonactive: isbuttonactive }) =>
    $isopen && isbuttonactive
      ? ({ theme }: IPropsTheme) => theme.menu.iconContainer
      : 'transparent'};
  margin-top: 14px;
  border: none;
  cursor: pointer;
  outline: none;
  transition: background-color 0.2s, padding 0.2s;
  transition-delay: ${({ $isopen }) => (!$isopen ? '1s, .0s' : '0')};

  span {
    color: ${({ $isopen }) =>
      $isopen
        ? ({ theme }: IPropsTheme) => theme.menu.iconContainerColor
        : 'none'};
    color: ${({ $isopen, $isbuttonactive: isbuttonactive }) =>
      $isopen && isbuttonactive
        ? ({ theme }: IPropsTheme) => theme.menu.iconContainerColorHover
        : 'none'};
  }

  &:hover {
    background-color: ${({ $isopen }) =>
      $isopen ? ({ theme }: IPropsTheme) => theme.menu.background : 'none'};
    background-color: ${({ $isopen, $isbuttonactive: isbuttonactive }) =>
      $isopen && isbuttonactive
        ? ({ theme }: IPropsTheme) => theme.menu.iconContainer
        : 'none'};

    span {
      color: ${({ $hasTooltip: hasTooltip, $isopen }) =>
        hasTooltip && !$isopen
          ? ({ theme }: IPropsTheme) => theme.colors.white
          : ({ theme }: IPropsTheme) => theme.menu.iconContainerColorHover};
    }

    div {
      color: ${({ theme }: IPropsTheme) => theme.menu.iconContainerColorHover};
    }

    svg {
      color: ${({ theme }: IPropsTheme) => theme.menu.iconContainerColorHover};
      fill: ${({ theme }: IPropsTheme) => theme.menu.iconContainerColorHover};
    }
  }
`

export const IconContainer = styled.div<{ isbuttonactive?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 50px;
  height: 41px;
  border-radius: ${({ theme }: IPropsTheme) => theme.borderRadius.buttons};
  background-color: ${({ isbuttonactive }) =>
    isbuttonactive
      ? ({ theme }: IPropsTheme) => theme.menu.iconContainer
      : 'transparent'};
  color: ${({ theme }: IPropsTheme) => theme.menu.iconContainerColor};
  transition: background-color 0.2s;

  svg {
    color: ${({ isbuttonactive }) =>
      isbuttonactive
        ? ({ theme }: IPropsTheme) => theme.menu.iconContainerColorHover
        : ({ theme }: IPropsTheme) => theme.menu.iconContainerColor};
    fill: ${({ isbuttonactive }) =>
      isbuttonactive
        ? ({ theme }: IPropsTheme) => theme.menu.iconContainerColorHover
        : ({ theme }: IPropsTheme) => theme.menu.iconContainerColor};
    transition: fill 0.5s;
  }

  &:hover {
    color: ${({ theme }: IPropsTheme) => theme.menu.iconContainerColorHover};

    svg {
      color: ${({ theme }: IPropsTheme) => theme.menu.iconContainerColorHover};
      fill: ${({ theme }: IPropsTheme) => theme.menu.iconContainerColorHover};
    }
  }
`

export const Label = styled.span<{
  showLabel: boolean
  isopen?: boolean
  fontSize?: number
}>`
  display: inline-block;
  font-weight: ${({ theme }: IPropsTheme) => theme.fontWeight.regular};
  font-family: ${({ theme }: IPropsTheme) => theme.font.root};
  font-size: ${({ fontSize, isopen }) =>
    isopen ? (fontSize ? fontSize + 'px' : '10px') : '10px'};
  color: ${({ theme }: IPropsTheme) => theme.menu.iconContainerColorHover};
  text-align: center;
  padding: 0 2px;
  white-space: nowrap;
  overflow: hidden;

  ${({ showLabel }) =>
    !showLabel &&
    css`
      display: none;
    `}

  ${({ isopen }) =>
    !isopen &&
    css`
      position: absolute;
      top: calc(100% - 5px);
      left: 0;
      width: 100%;
    `}
`

export const ContentContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  overflow-x: hidden;
`

export const AreaIconDrag = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s linear;
  cursor: move;
  svg {
    color: ${({ theme }: IPropsTheme) => theme.menu.iconContainerColor};
    fill: ${({ theme }: IPropsTheme) => theme.menu.iconContainerColor};
    transition: fill 0.5s;
  }
`

export const IconLabel = styled.span`
  display: flex;
  align-items: center;
  width: fit-content;
`
