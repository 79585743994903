import React from 'react'
import { SvgProps, WithSecondaryColor } from './typings/SvgProps'

const LinkedinCircle: React.FC<SvgProps & WithSecondaryColor> = ({
  color = 'currentColor',
  secondaryColor = '#fff',
  size,
  ...rest
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} {...rest} viewBox="0 0 32 32">
    <g transform="translate(-60.813 -60.813)">
      <circle cx="16" cy="16" r="16" transform="translate(60.813 60.814)" fill={color} />
      <g transform="translate(68.849 68.404)">
        <path
          d="M226.6,209.739a1.61,1.61,0,0,0,.113-.123,3.569,3.569,0,0,1,3.782-1.507,3.744,3.744,0,0,1,3.207,3.424,8.954,8.954,0,0,1,.1,1.362c0,1.9,0,3.793,0,5.69,0,.214-.051.276-.27.274q-1.491-.018-2.982,0c-.217,0-.273-.06-.272-.273.008-1.769.005-3.538,0-5.307a4.26,4.26,0,0,0-.141-1.191,1.716,1.716,0,0,0-2.795-.857,2.1,2.1,0,0,0-.756,1.74c.009,1.669,0,3.337,0,5.006,0,.219-.012.438,0,.656.014.189-.063.226-.235.224q-1.518-.01-3.036,0c-.184,0-.25-.036-.248-.238q.022-2.256.021-4.513,0-2.777-.016-5.553c0-.2.041-.263.25-.26,1.012.011,2.024.01,3.036,0,.182,0,.233.053.227.23C226.59,208.906,226.6,209.288,226.6,209.739Z"
          transform="translate(-217.426 -203.21)"
          fill={secondaryColor}
          fillRule="evenodd"
        />
        <path
          d="M162.94,216.236c0,1.668,0,3.336,0,5,0,.207-.041.281-.267.278-1-.013-2.005-.01-3.008,0-.186,0-.249-.045-.249-.241q.007-5.045,0-10.091c0-.175.042-.234.226-.232q1.531.013,3.063,0c.2,0,.235.072.235.252C162.937,212.882,162.94,214.559,162.94,216.236Z"
          transform="translate(-159.207 -205.869)"
          fill={secondaryColor}
          fillRule="evenodd"
        />
        <path
          d="M158.936,151.515a1.829,1.829,0,0,1,1.9,2.316,1.954,1.954,0,0,1-2.343,1.3,1.808,1.808,0,0,1-1.422-2.49A1.892,1.892,0,0,1,158.936,151.515Z"
          transform="translate(-156.934 -151.515)"
          fill={secondaryColor}
          fillRule="evenodd"
        />
      </g>
    </g>
  </svg>
)

export default LinkedinCircle
