import React from 'react';

const SvgChatPerson = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={22.535}
      height={21.52}
      viewBox='0 0 22 22'
      {...props}
    >
      <path d='M17.669 9.342a2.959 2.959 0 102.959 2.96 2.963 2.963 0 00-2.959-2.96zm0 4.482a1.522 1.522 0 111.522-1.522 1.523 1.523 0 01-1.522 1.522zM19.276 15.961h-3.269a3.286 3.286 0 00-3.2 3.2v1.642a.719.719 0 101.437 0V19.16a1.845 1.845 0 011.787-1.76h3.25a1.84 1.84 0 011.823 1.761v1.642a.718.718 0 101.436 0V19.16a3.271 3.271 0 00-3.264-3.199zM4.866 12.811a2.959 2.959 0 10-2.959-2.959 2.962 2.962 0 002.959 2.959zm0-4.481a1.522 1.522 0 11-1.522 1.522A1.523 1.523 0 014.866 8.33zM6.473 13.511H3.204a3.286 3.286 0 00-3.2 3.2v1.643a.718.718 0 101.436 0v-1.643a1.863 1.863 0 011.866-1.761h3.171A1.839 1.839 0 018.3 16.711v1.643a.719.719 0 101.437 0v-1.643a3.273 3.273 0 00-3.264-3.2zM18.047 7.42a2.885 2.885 0 002.882-2.882V2.883A2.886 2.886 0 0018.047 0h-7.8a2.887 2.887 0 00-2.883 2.883v1.655a2.886 2.886 0 002.883 2.882h.772v1.557a.717.717 0 001.228.505l2.041-2.062zm-4.568-1.223L12.457 7.23v-.527a.718.718 0 00-.718-.718h-1.49a1.448 1.448 0 01-1.446-1.446V2.883a1.447 1.447 0 011.446-1.446h7.8a1.447 1.447 0 011.445 1.446v1.655a1.447 1.447 0 01-1.445 1.446h-4.057a.716.716 0 00-.513.213z' />
    </svg>
  )
}

export default SvgChatPerson
