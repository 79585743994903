import styled, { css } from 'styled-components'

interface IContainer {
  checked: boolean
  isLabel: boolean
}

export const Container = styled.button<IContainer>`
  display: flex;
  align-items: center;
  border: 0px solid;
  background: transparent;
  text-align: left;
  font: Inter;
  font-size: 15px;
  color: #2d3139;
  cursor: pointer;
  span {
    background-color: #a7abb2;
    opacity: 1;
    width: 28px;
    height: 16px;
    border-radius: 200px;
    display: flex;
    align-items: center;
    padding: 0 4px;
    transition: all 0.2s linear;
    ${({ checked }) =>
      checked &&
      css`
        justify-content: flex-end;
        background-color: #378ff7;
      `}
    ${({ isLabel }) =>
      isLabel &&
      css`
        margin-right: 10px;
      `}
    &:before {
      content: '';
      display: block;
      width: 10px;
      height: 10px;
      border-radius: 200px;
      background-color: #ffffff;
    }
  }
`
