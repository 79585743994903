import React from 'react';

const SvgProfileIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={14.161}
      height={17.548}
      viewBox='0 0 15 18'
      {...props}
    >
      <g
        fill='none'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit={10}
        strokeWidth={1.5}
        data-name='Iconly/Light/Profile'
        transform='translate(.75 .75)'
      >
        <ellipse
          cx={3.962}
          cy={4.035}
          rx={3.962}
          ry={4.035}
          transform='translate(2.365)'
        />
        <path d='M0 13.496a1.889 1.889 0 01.183-.819 3.367 3.367 0 012.354-1.369 13.864 13.864 0 011.956-.278 20.677 20.677 0 013.66 0 14.023 14.023 0 011.957.278 3.24 3.24 0 012.354 1.368 1.937 1.937 0 010 1.646 3.208 3.208 0 01-2.354 1.36 12.98 12.98 0 01-1.956.286 21.312 21.312 0 01-2.98.046 3.356 3.356 0 01-.68-.046 12.739 12.739 0 01-1.949-.286 3.223 3.223 0 01-2.362-1.359A1.943 1.943 0 010 13.496z' />
      </g>
    </svg>
  )
}

export default SvgProfileIcon
