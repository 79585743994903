import styled from 'styled-components'
import { theme } from '../Theme'

interface StyleProps {
  fontSize?: string
}
interface TextLabelStyles {
  style: StyleProps
  normal?: boolean
  maxWidth?: string
  withoutMargin?: boolean
}

export const TextLabel = styled.p<TextLabelStyles>`
  font-size: ${(props) =>
    !props.style.fontSize ? '13px' : props.style.fontSize};
  text-transform: uppercase;
  color: ${theme.palette.black};
  margin-bottom: ${(props) => !props.withoutMargin && theme.spacing.xSmall};
  font-weight: ${(props) => (!props.normal ? '600' : 'normal')};
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : '100%')};
  white-space: normal;

  strong {
    font-weight: 500;
    text-transform: none;
    font-size: ${(props) =>
      !props.style.fontSize ? '13px' : props.style.fontSize};
  }
`
