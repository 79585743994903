export enum FormTestId {
  INPUT_MASK = 'input-mask',
  INPUT = 'input',
  SELECT_TEXT_COUNT = 'select-text-count-results',
  SELECT_OPTION_ERROR = 'select-option-error',
  SELECT_OPTION_ITEM = 'select-option-item',
  SELECT_INPUT_WRAPPER = 'select-input-wrapper',
  SELECT_INPUT = 'select-input',
  SELECT_ERROR = 'select-error',
  TABLE_LINE = 'table-line',
  TABLE_ERROR = 'table-error'
}
