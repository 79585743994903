import React from 'react'
import icons, { StringIcons } from '../Resources/SvgComponents'
import { IconContainer } from './styles'

interface IconExporterProps {
  name?: StringIcons
  margin?: string
  width?: string
  height?: string
  iconsize?: string | number
  onClick?: React.MouseEventHandler<HTMLDivElement>
}

const IconExporter: React.FC<IconExporterProps> = (props) => {
  const { name, iconsize, height, onClick, ...rest } = props

  if (!name) {
    return <span />
  }
  const Icon = icons && icons[name.toLowerCase()]
  const otherProps = { ...rest }

  return (
    <IconContainer iconsize={iconsize} onClick={onClick} {...otherProps}>
      <Icon
        width={iconsize}
        height={!height ? iconsize : height}
        {...otherProps}
        data-automation={`icon-exporter-${name}`}
      />
    </IconContainer>
  )
}

export default IconExporter
