import React from 'react'

const iconAnex = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={11}
    height={19}
    viewBox='0 0 11 19'
    fill='currentColor'
    {...props}
  >
    <path
      d='M4.441,6.241v8.28c-.039,2.7,4.157,2.7,4.118,0V5C8.539.338,1.52.33,1.5,5v9.525c.112,6.637,9.889,6.639,10,0V6.241'
      transform='translate(-0.75 -0.749)'
      fill='none'
      stroke='currentColor'
      strokeLinecap='round'
      strokeWidth={1.5}
    />
  </svg>
)

export default iconAnex
