export class GetNewPosition {
  private static checkInitLimitBreak(initPosition: number): boolean {
    return initPosition < 0
  }

  private static checkEndLimitBreak(
    sizeTooltip: number,
    initPosition: number,
    docSize: number
  ): boolean {
    return sizeTooltip + initPosition > docSize
  }

  private static getDirection(
    isBreakInit: boolean,
    isBreakEnd: boolean,
    direction: {
      positionA: 'left' | 'bottom'
      positionB: 'right' | 'top'
    }
  ): 'left' | 'right' | 'top' | 'bottom' | null {
    return isBreakInit
      ? direction.positionA
      : isBreakEnd
      ? direction.positionB
      : null
  }

  getNewPositionX(
    tooltip: HTMLElement,
    sizeWidthDoc: number
  ): 'left' | 'right' | null {
    const sizeWidthTooltip = tooltip.offsetWidth
    const leftPositionTooltip = tooltip.getBoundingClientRect().left
    const { checkInitLimitBreak, checkEndLimitBreak, getDirection } =
      GetNewPosition
    return getDirection(
      checkInitLimitBreak(leftPositionTooltip),
      checkEndLimitBreak(sizeWidthTooltip, leftPositionTooltip, sizeWidthDoc),
      { positionA: 'left', positionB: 'right' }
    ) as 'left' | 'right' | null
  }

  getNewPositionY(
    tooltip: HTMLElement,
    sizeHeightDoc: number
  ): 'top' | 'bottom' | null {
    const sizeHeightTooltip = tooltip.offsetHeight
    const topPositionTooltip = tooltip.getBoundingClientRect().top
    const { checkInitLimitBreak, checkEndLimitBreak, getDirection } =
      GetNewPosition
    return getDirection(
      checkInitLimitBreak(topPositionTooltip),
      checkEndLimitBreak(sizeHeightTooltip, topPositionTooltip, sizeHeightDoc),
      { positionA: 'bottom', positionB: 'top' }
    ) as 'top' | 'bottom' | null
  }
}
