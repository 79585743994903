import React from 'react'
import { theme } from '@cockpit/levi-ui/theme'
import { ELineChartTitle, ICustomizeDot } from './typing'

export const CustomizedDot = (props: unknown) => {
  const { cx, cy, name } = props as ICustomizeDot
  if (name === ELineChartTitle.title) {
    return (
      <svg
        x={cx - 4}
        y={cy - 4}
        width={8}
        height={8}
        fill={theme.colors.neutral[10]}
        data-testid="uterine-height-chart-customized-dot"
      >
        <g transform="translate(4 4)">
          <circle r="4" fill={theme.colors.blue[40]} />
          <circle r="2" fill={theme.colors.blue[40]} />
        </g>
      </svg>
    )
  }

  return null
}
