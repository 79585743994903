import React from 'react';

const SvgArrowLeftIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 16 16'
      width={16}
      height={16}
      {...props}
    >
      <defs>
        <clipPath id='arrow-left-icon_svg__a'>
          <path fill='none' d='M0 1h16v16H0z' />
        </clipPath>
      </defs>
      <g
        data-name='Grupo de rolagem 137'
        transform='translate(0 -1)'
        clipPath='url(#arrow-left-icon_svg__a)'
        style={{
          isolation: 'isolate'
        }}
      >
        <path
          data-name='Stroke 1'
          d='M11 16.061l-7-7 7-7'
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          strokeWidth={1.5}
        />
      </g>
    </svg>
  )
}

export default SvgArrowLeftIcon
