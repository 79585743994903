import React from 'react';

const SvgCloseRounded = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='currentColor'
      width={21}
      height={20.999}
      viewBox='0 0 21 21'
      {...props}
    >
      <path d='M11.48 10.5l2.85-2.85a.693.693 0 00-.98-.98L10.5 9.52 7.65 6.67a.693.693 0 00-.98.98l2.85 2.85-2.85 2.85a.693.693 0 000 .98.71.71 0 00.98 0l2.85-2.851 2.85 2.851a.693.693 0 00.98-.98z' />
      <path d='M10.5 0A10.5 10.5 0 1021 10.5 10.512 10.512 0 0010.5 0zm9.374 10.5A9.374 9.374 0 1110.5 1.126a9.385 9.385 0 019.374 9.374z' />
    </svg>
  )
}

export default SvgCloseRounded
