import React from 'react'

const iconCallEnd = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={25}
    height={11}
    viewBox='0 0 25 11'
    fill='currentColor'
    {...props}
  >
    <path
      d='M6.777,5.649A1.773,1.773,0,0,0,8.551,3.875v-2.1A1.773,1.773,0,0,0,6.777,0H5.123a2.185,2.185,0,0,0-.539.074l-.014,0C2.9.4,0,3.338,0,11.485,0,20.8,4,22.8,4,22.8l.005,0a2.2,2.2,0,0,0,1.117.311H6.777a1.773,1.773,0,0,0,1.773-1.773v-2.1a1.773,1.773,0,0,0-1.773-1.773H6.232A.867.867,0,0,1,5.46,17a12.05,12.05,0,0,1-1.252-5.516,11.265,11.265,0,0,1,1.263-5.42.812.812,0,0,1,.715-.416Z'
      transform='translate(23.861 0.75) rotate(90)'
      fill='none'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={1.5}
    />
  </svg>
)

export default iconCallEnd
