import styled, { keyframes } from 'styled-components'

interface Wrapper {
  color: string
}

const spin = keyframes`
  to{
    transform: rotate(360deg);
  }
`

export const Wrapper = styled.caption<Wrapper>`
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: ${({ color }) => color};
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: ${spin} 1s linear infinite;

  display: flex;
  margin: auto;
`
