import { Chip as ChipMUI } from '@mui/material'
import styled from '@emotion/styled'
import { getColors } from '@cockpit/levi-ui/helpers'
import { theme } from '@cockpit/levi-ui/theme'

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  & > div {
    margin-bottom: ${theme.spacings[2]};
    &:not(:last-child) {
      margin-right: ${theme.spacings[2]};
    }
  }
`

export const Chip = styled(ChipMUI)`
  background-color: ${getColors('neutral', 10)};
  font-size: ${theme.font.size[2]};
  font-weight: 600;
  color: ${getColors('neutral', 80)};
  height: 30px;
  &.show-delete-button svg {
    opacity: 1;
  }
  svg {
    width: 18px;
    height: 8px;
    opacity: 0;
    transition: 0.3s;
  }
  &:hover {
    svg {
      opacity: 1;
    }
  }
  .MuiChip-label {
    max-width: 150px;
    text-overflow: ellipsis;
  }
`
