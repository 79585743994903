import styled from 'styled-components'

export const SidebarWrapper = styled.div`
  z-index: 999;
`

export const GlassPane = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #00000073;
  transition: background-color 0.2s ease;
  z-index: 9999;
`
