/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react'
import { Country, getCountryCallingCode } from 'react-phone-number-input'
import { countries } from './helpers/countries'

interface IusePhoneField {
  country?: Country
  setCountry: (country: Country) => void
}

/* istanbul ignore next */
export const usePhoneField = ({ country, setCountry }: IusePhoneField) => {
  const countryCode = country || 'BR'

  const [search, setSearch] = React.useState(`+${getCountryCallingCode(countryCode)}`)
  const [show, setShow] = React.useState(false)

  useEffect(() => {
    if (country) {
      setSearch(`+${getCountryCallingCode(country)}`)
    }
  }, [country])

  const mask = countries.find(({ iso }) => iso === countryCode)?.mask as string | string[]

  const getMask = React.useMemo(() => {
    if (mask) {
      if (Array.isArray(mask)) {
        return mask[mask.length - 1]
      }
      return mask
    }
    return ''
  }, [mask])

  const maskCountryWithBrackets = getMask
    .split('')
    .map(item => (item === '#' ? '9' : item === ')' ? ') ' : item))
    .join('')

  const maskCountryWithNine = getMask
    .split('')
    .map(item => (item === '#' ? '9' : item === ')' ? ' ' : item === '(' ? '' : item))
    .join('')

  const placeholder = getMask
    .split('')
    .map(item => (item === '#' ? '0' : item === ')' ? ') ' : item))
    .join('')

  const handleClickAway = () => {
    setSearch(`+${getCountryCallingCode(countryCode)}`)
    setShow(false)
  }

  const handleChange = (val: Country) => {
    setCountry(val)
    setShow(false)

    setSearch(`+${getCountryCallingCode(val)}`)
  }

  return {
    show,
    setShow,
    search,
    setSearch,
    mask,
    getMask,
    handleClickAway,
    handleChange,
    placeholder,
    maskCountryWithNine,
    maskCountryWithBrackets,
    countryCode,
  }
}
