import { theme } from '../../Theme/theme'
import styled from 'styled-components'

export const Button = styled.button`
  border: 1px solid ${theme.palette.gray5};
  cursor: pointer;
  width: 40px;
  height: 40px;
  background: ${theme.palette.white};
  border-radius: 4px;
  color: ${theme.colors.gray8};
  transition: color 0.2s;

  &:hover {
    color: ${theme.palette.highlight.blue};
  }
`
