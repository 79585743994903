/* eslint-disable import/no-cycle */
import React from 'react'
import { Icon, Tooltip } from '../../atoms'
import { ITable } from './typing'

import { TableContainer, EditButton, DeleteButton, ButtonsContainer } from './styles'

const Table: React.FC<ITable> = ({
  headers,
  rows,
  onEdit,
  onDelete,
  columnsStyles = [],
  variant = 'default',
  ...rest
}) => (
  <TableContainer variant={variant} cellSpacing={0} {...rest}>
    {headers && (
      <thead>
        <tr>
          {headers.map((header, i) => (
            <th key={i}>{header}</th>
          ))}
        </tr>
      </thead>
    )}
    <tbody>
      {rows.map((row, i) => (
        <tr className={onEdit || onDelete ? 'levi-tr-editable' : ''} key={i}>
          {row.map((cell, index) => (
            <td
              key={index}
              style={columnsStyles[index] || {}}
              className={
                /* istanbul ignore next */
                typeof cell !== 'string' ? 'no-padding' : ''
              }
            >
              <ButtonsContainer>
                {onEdit && index === 0 && (
                  <EditButton
                    hasDeleteButton={!!onDelete}
                    className="levi-tr-edit-button"
                    data-testid="levi-tr-edit-button"
                    onClick={() => onEdit(i)}
                  >
                    <Tooltip
                      element={
                        <div>
                          <Icon icon="Pencil" size={12} />
                        </div>
                      }
                      title="Editar"
                    />
                  </EditButton>
                )}
                {onDelete && index === 0 && (
                  <DeleteButton
                    hasEditButton={!!onEdit}
                    className="levi-tr-delete-button"
                    data-testid="levi-tr-delete-button"
                    onClick={() => onDelete(i)}
                  >
                    <Tooltip
                      element={
                        <div>
                          <Icon icon="Trash" size={12} />
                        </div>
                      }
                      title="Deletar"
                    />
                  </DeleteButton>
                )}
              </ButtonsContainer>
              {cell}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  </TableContainer>
)

export default Table
