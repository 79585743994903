import React from 'react'

const Plus = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      id='Componente_3290_6'
      data-name='Componente 3290 – 6'
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      {...props}
    >
      <rect
        id='Retângulo_140541'
        data-name='Retângulo 140541'
        width='24'
        height='24'
        rx='4'
        fill='#f3f5f9'
        opacity='0'
      />
      <g
        id='Grupo_161322'
        data-name='Grupo 161322'
        transform='translate(-543.5 -1478.5)'
        opacity='0.698'
      >
        <line
          id='Linha_834'
          data-name='Linha 834'
          y2='12'
          transform='translate(555.5 1484.5)'
          fill='none'
          stroke='#192c4f'
          strokeLinecap='round'
          strokeWidth='1.5'
        />
        <line
          id='Linha_835'
          data-name='Linha 835'
          x1='12'
          transform='translate(549.5 1490.5)'
          fill='none'
          stroke='#192c4f'
          strokeLinecap='round'
          strokeWidth='1.5'
        />
      </g>
    </svg>
  )
}

export default Plus
