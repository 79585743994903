import React from 'react'

const SvgStethoscope = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      height={29.206}
      width={25}
      viewBox='0 0 19.372 19.826'
      {...props}
    >
      <path
        id='Caminho_8606'
        data-name='Caminho 8606'
        d='M291.047,508.4a3.046,3.046,0,1,0-3.936,2.9,4.773,4.773,0,0,1-.228,1.018,4.215,4.215,0,0,1-3.683,2.5,4,4,0,0,1-3.87-1.738,5.622,5.622,0,0,1-.623-2.963,5.621,5.621,0,0,0,5.467-5.327v-4.5a2.289,2.289,0,0,0-.726-1.785,2.717,2.717,0,0,0-1.941-.6h-.736v-.523a.789.789,0,0,0-1.578,0v2.624a.789.789,0,1,0,1.578,0v-.525l.818,0a1.214,1.214,0,0,1,.812.206c.048.044.195.178.195.6v4.469c-.019.39-.313,3.819-4.671,3.819s-4.652-3.428-4.671-3.785v-4.5c0-.426.147-.56.2-.6a1.313,1.313,0,0,1,.894-.2h.841v.523a.789.789,0,1,0,1.578,0v-2.624a.789.789,0,0,0-1.578,0v.525l-.753,0a2.787,2.787,0,0,0-2.029.595,2.285,2.285,0,0,0-.727,1.785v4.533a5.614,5.614,0,0,0,5.44,5.3,7.234,7.234,0,0,0,.852,3.765,5.411,5.411,0,0,0,4.772,2.536c.2,0,.4-.008.6-.026a5.791,5.791,0,0,0,5.024-3.525,6.373,6.373,0,0,0,.324-1.5A3.048,3.048,0,0,0,291.047,508.4ZM288,509.872a1.468,1.468,0,1,1,1.469-1.468A1.469,1.469,0,0,1,288,509.872Z'
        transform='translate(-271.675 -496.593)'
        fill='currentColor'
      />
    </svg>
  )
}

export default SvgStethoscope
