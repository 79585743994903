import React from 'react'

const SvgSendCheckIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={20}
      height={20}
      viewBox='0 0 22 22'
      {...props}
    >
      <defs>
        <clipPath id='send-check-icon_svg__a'>
          <path fill='none' d='M1 1h24v24H1z' />
        </clipPath>
      </defs>
      <g
        transform='translate(-1 -1)'
        clipPath='url(#send-check-icon_svg__a)'
        style={{
          isolation: 'isolate'
        }}
      >
        <g
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          strokeWidth={1.5}
        >
          <path d='M17.334 3.75H8.665c-3.021 0-4.915 2.139-4.915 5.166v8.168c0 3.027 1.885 5.166 4.915 5.166h8.668c3.031 0 4.917-2.139 4.917-5.166V8.916c0-3.027-1.886-5.166-4.916-5.166z' />
          <path d='M9.44 13l2.374 2.373 4.746-4.746' />
        </g>
      </g>
    </svg>
  )
}

export default SvgSendCheckIcon
