/* eslint-disable camelcase */
import React from 'react'
import { Button } from '../Button'
import VerificationInput from 'react-verification-input'
import {
  AuthenticatorContainer,
  AuthenticatorContent,
  AuthenticatorFooter,
  AuthenticatorHeader,
  AuthenticatorSubTitle,
  ErrorMessage
} from './styles'

export interface VerificaAuthData {
  access_token: string
  expires_in: string
  token_type?: string
  scope?: string
  provider?: string
  Authorization?: string
}

interface User {
  name: string
  gender: string
  personal_document: {
    identifier: string
  }
}

interface SessionData {
  user: User
  token: string
  refresh_token: string
}

// TODO: Ajustar tipagem
// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface Response<T = any> {
  data: T
  status: number
}

enum HttpStatusCode {
  ok = 200,
  serverError = 500
}

interface IAuthenticatorDescriptionProps {
  title: string
  subTitle: string
  confirmButtom: string
  invalidCodeError: string
  requestError: string
}

export interface ISuccessReponseMapperParams extends VerificaAuthData {
  loginDate: Date
}

export interface IAuthenticator {
  setShowModal: React.Dispatch<boolean>
  setRunCallback?: React.Dispatch<boolean>
  successRequestCallback: (param: ISuccessReponseMapperParams) => void
  // TODO: Ajustar tipagem
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  authRequest: (param: any) => Promise<Response<VerificaAuthData>>
  // TODO: Ajustar tipagem
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  authRequestParams?: any
  /**
   * @interface IAuthenticatorDescriptionProps
   * @param {string} title
   * @param {string} subTitle
   * @param {string} confirmButtom Texto do botäo de enviar.
   * @param {string} invalidCodeError Texto de erro que será mostrado quando o usuário colocar menos de 6 dígitos ou quando receber um status code de não autorizado da API.
   * @param {string} requestError Texto de erro que será mostrado quando ocorrer um erro 500+ na chamada da API.
   */
  description: IAuthenticatorDescriptionProps
}

/**
 * Este componente irá renderizar o Input de verificaçäo para códigos OTP de 6 dígitos.
 * @param {React.Dispatch<boolean>} setShowModal propiedade para repassar um state action referente ao modal, ira ser chamado (como false) após autenticar com sucesso na API.
 * @param {React.Dispatch<boolean> | undefined} setRunCallback propiedade opcional, usada para repassar um state action, irá ser chamado (como true) após autenticar com sucesso na API.
 * @param {(param: any) => Promise<Response<VerificaAuthData>>} authRequest propiedade para repassar a requisiçäo da API.
 * @param {any} authRequestParams propiedade opcional, usada para passar parametros customizados na requisiçäo da API (authRequest), será enviada da seguinte maneira: {...authRequestParams, password:'CÓDIGO OTP'}.
 * @param {(param: ISuccessReponseMapperParams) => void} successRequestCallback callback que é chamado após a authenticaçäo repassando a resposta da API (usar essa funçäo para renovar o token no localStorage).
 * @param {IAuthenticatorDescriptionProps} description propiedade para passar o titulo, subtitulo, texto do botão e textos de erro.
 */
export const Authenticator: React.FC<IAuthenticator> = ({
  setShowModal,
  setRunCallback,
  successRequestCallback,
  authRequest,
  authRequestParams,
  description: {
    title,
    subTitle,
    confirmButtom,
    invalidCodeError,
    requestError
  }
}) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>()

  const [authCode, setAuthCode] = React.useState<string>('')

  const session = localStorage.getItem('session')

  const refreshOTP = React.useCallback(
    async (password: string) => {
      setErrorMessage(undefined)
      setIsLoading(true)
      if (session) {
        const {
          user: {
            personal_document: { identifier: originalIdentifier }
          }
        } = JSON.parse(session) as SessionData

        const username = originalIdentifier?.replace(/([.-])+/g, '')

        const { status, data } = await authRequest(
          authRequestParams
            ? { ...authRequestParams, password }
            : { username, password }
        ).catch(() => ({
          status: HttpStatusCode.serverError,
          data: { expires_in: '', access_token: '' }
        }))

        if (status === HttpStatusCode.ok) {
          successRequestCallback({ ...data, loginDate: new Date() })
          setShowModal(false)
          setRunCallback && setRunCallback(true)
        } else {
          setErrorMessage(invalidCodeError)
        }
      } else {
        setErrorMessage(requestError)
      }
      setIsLoading(false)
    },
    [
      authRequest,
      authRequestParams,
      invalidCodeError,
      requestError,
      session,
      setRunCallback,
      setShowModal,
      successRequestCallback
    ]
  )

  const onClick = React.useCallback(() => {
    if (!authCode || authCode.length < 6)
      return setErrorMessage(invalidCodeError)
    refreshOTP(authCode)
  }, [authCode, invalidCodeError, refreshOTP])

  return (
    <AuthenticatorContainer
      onKeyDown={(ev) => ev.key === 'Enter' && !isLoading && onClick()}
      id='AuthenticatorContainer'
      data-testid='authenticator-container'
    >
      <AuthenticatorHeader>{title}</AuthenticatorHeader>
      <AuthenticatorSubTitle>{subTitle}</AuthenticatorSubTitle>
      <AuthenticatorContent>
        <VerificationInput
          length={6}
          validChars='0-9'
          autoFocus
          onChange={(e) => {
            setErrorMessage(undefined)
            setAuthCode(e)
          }}
          placeholder=''
          removeDefaultStyles
          classNames={{
            container: 'container',
            character: 'character',
            characterInactive: 'character--inactive',
            characterSelected: 'character--selected'
          }}
          inputProps={{
            inputMode: 'numeric'
          }}
        />
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      </AuthenticatorContent>
      <AuthenticatorFooter>
        <Button
          disabled={isLoading}
          data-testid='submit-otp'
          width='100%'
          {...{ isLoading, onClick }}
          minWidth='large'
        >
          {confirmButtom}
        </Button>
      </AuthenticatorFooter>
    </AuthenticatorContainer>
  )
}
