import React from 'react';

const SvgReasonEar = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={34} height={34} viewBox='0 0 35 35' {...props}>
      <defs>
        <radialGradient
          id='reason_ear_svg__a'
          cx={0.5}
          cy={0.5}
          r={0.5}
          gradientUnits='objectBoundingBox'
        >
          <stop offset={0} stopColor='red' />
          <stop offset={0.16} stopColor='red' stopOpacity={0.761} />
          <stop offset={0.32} stopColor='red' stopOpacity={0.529} />
          <stop offset={0.49} stopColor='red' stopOpacity={0.341} />
          <stop offset={0.64} stopColor='red' stopOpacity={0.188} />
          <stop offset={0.78} stopColor='red' stopOpacity={0.09} />
          <stop offset={0.9} stopColor='red' stopOpacity={0.02} />
          <stop offset={1} stopColor='red' stopOpacity={0} />
        </radialGradient>
        <style>
          {
            '.reason_ear_svg__b{fill:none;stroke:#2e3192;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px}'
          }
        </style>
      </defs>
      <g transform='translate(6.659 1.229)'>
        <circle
          cx={7.205}
          cy={7.205}
          r={7.205}
          transform='rotate(-6.49 90.285 5.115)'
          fill='url(#reason_ear_svg__a)'
        />
        <path
          className='reason_ear_svg__b'
          d='M.702 13.57S-2.604 5.115 5.279 1.237C10.3-1.305 16.341.284 19.01 4.037s1.4 8.963-2.289 15.447c-2.67 4.768-2.225 7.057-2.225 7.057s-.636 5.4-6.738 4.7c-4-.571-4.26-4.7-4.26-4.7'
        />
        <path
          className='reason_ear_svg__b'
          d='M15.512 13.189s1.527-4.7-1.08-7.374-9.789-1.526-9.854 2.479c-.064 2.615-.5 4 1.729 5.6.406-.028.463-.984.463-.984s1.043-2.372 3.1-1.475 1.908 3.905 0 5.613-.869 3.472-.986 4.108c-.027.724-.086 2.286-2.4 1.736-1.621-.637-1.014-4.484-1.014-4.484'
        />
      </g>
      <path fill='none' d='M0 0h34v34H0z' />
    </svg>
  )
}

export default SvgReasonEar
