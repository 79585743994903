/* eslint-disable no-unused-expressions */
import React from 'react'
import IconExporter from '../IconExporter/index'
import { theme } from '../Theme'
import {
  ModalWrapper,
  ModalMask,
  ModalContainer,
  ModalDialog,
  ModalWidth,
  ModalHeader,
  ModalCloseButton,
  ModalFullscreenCloseButton,
  ModalContent
} from './style'

/*
 * =============================
 * Componente de modal simples
 * =============================
 * Todo o controle do modal é feito pelo high-order component que estiver utilizando o modal
 *
 * O tema aqui é acessado diretamente, isso é um problema se o front usando esse componente optar
 * por prover um tema proprio, nesse caso é preciso um refactor
 */
/**
 * @param {function} onClickArrowLeft função adicional no onClick do componente <ModalFullscreenCloseButton> (que é acionada quando isMobileFullscreen é true)
 * @param {string} headerPaddingCloseButton props que define o padding do botão close no header. por default ele é 12px 10px 12px 0px
 * @param {string}  headerMarginBottom props que define margin-bottom do botão close no header. por default ele é 18px
 * @param {string} modalDialogMarginTop props que define margin-top do <ModalDialog>. por default ele é 90px
 * @param {boolean} noBodyScrollable  booleano que ativa overflow-y: hidden (apenas ativa quando a media-querie tiver como min-width: 1024px); por default o overflow-y é auto
 */

const documentBodyStyle = document.body.style
const header = document.querySelector('header')
const defaultHeaderPaddingRight =
  header && window.getComputedStyle(header).getPropertyValue('padding-right')

export interface ModalProps {
  size?: ModalWidth
  useMask?: boolean
  show: boolean
  customBackgroundColor?: string
  closeClickMask?: boolean
  hideModalCloseButton?: boolean
  onClose: () => void
  onClickArrowLeft?: () => void
  isMobileFullscreen?: boolean
  modalCloseButtonWidth?: string
  modalBodyPadding?: string
  customFullscreenCloseButton?: boolean
  headerText?: string
  headerFont?: string
  customWidth?: number
  customCloseButtonMargin?: string
  headerBorderBottom?: boolean
  enableOverflowScroll?: boolean
  testId?: string
  headerPaddingCloseButton?: string
  marginBottomCloseButton?: string
  headerMarginBottom?: string
  modalDialogMarginTop?: string
  noBodyScrollable?: boolean
  modalDialogProps?: { [key: string]: unknown }
  modalContainerProps?: { [key: string]: unknown }
}

export const Modal: React.FC<ModalProps> = ({
  children,
  size,
  useMask = true,
  customBackgroundColor,
  show = false,
  closeClickMask = true,
  hideModalCloseButton = false,
  onClose,
  onClickArrowLeft,
  isMobileFullscreen = size === 'sideMenu',
  modalCloseButtonWidth = '14px',
  modalBodyPadding = '0 30px',
  customFullscreenCloseButton = true,
  headerText = '',
  headerFont = '600 20px Inter, sans-serif',
  customWidth = 0,
  customCloseButtonMargin = '0px 0px',
  headerBorderBottom = headerText !== '',
  enableOverflowScroll = true,
  headerPaddingCloseButton,
  headerMarginBottom,
  modalDialogMarginTop,
  noBodyScrollable,
  testId,
  modalDialogProps,
  modalContainerProps
}): JSX.Element => {
  React.useEffect(() => {
    if (enableOverflowScroll) {
      const scrollbarWidth = window.innerWidth - document.body.clientWidth

      if (show) {
        documentBodyStyle.overflowY = 'hidden'
        documentBodyStyle.paddingRight = `${scrollbarWidth}px`
        if (header?.style && defaultHeaderPaddingRight) {
          header.style.paddingRight = `calc(${scrollbarWidth}px + ${defaultHeaderPaddingRight})`
        }
      }

      return () => {
        documentBodyStyle.overflowY = 'auto'
        documentBodyStyle.paddingRight = '0px'
        if (header?.style && defaultHeaderPaddingRight) {
          header.style.paddingRight = defaultHeaderPaddingRight
        }
      }
    }

    return undefined
  }, [enableOverflowScroll, show])

  return (
    <ModalWrapper show={show}>
      {show && (
        <React.Fragment>
          <ModalMask visible={useMask} />
          <ModalContainer
            data-testid={`${
              testId ? `${testId}-` : ''
            }close-background-modal-button`}
            isMobileFullscreen={isMobileFullscreen}
            onClick={() => (closeClickMask ? onClose() : null)}
            noBodyScrollable={noBodyScrollable}
            {...modalContainerProps}
          >
            <ModalDialog
              customBackgroundColor={customBackgroundColor}
              size={size}
              isMobileFullscreen={isMobileFullscreen}
              customWidth={customWidth}
              modalDialogMarginTop={modalDialogMarginTop}
              {...modalDialogProps}
            >
              {isMobileFullscreen &&
              window.innerWidth <= theme.responsiveSize.numberBig &&
              customFullscreenCloseButton ? (
                <ModalHeader
                  headerPaddingCloseButton={headerPaddingCloseButton}
                  headerFont={headerFont}
                  headerBorderBottom={
                    headerBorderBottom || customFullscreenCloseButton
                  }
                >
                  <ModalFullscreenCloseButton
                    width={modalCloseButtonWidth}
                    onClick={() => {
                      onClickArrowLeft?.()
                      onClose()
                    }}
                    type='button'
                    data-testid={`${
                      testId ? `${testId}-` : ''
                    }close-svg-modal-button`}
                    hideModalCloseButton={hideModalCloseButton}
                  >
                    <IconExporter
                      name='arrow_left'
                      width={modalCloseButtonWidth}
                    />
                  </ModalFullscreenCloseButton>
                  {headerText !== '' && <h3>{headerText}</h3>}
                </ModalHeader>
              ) : (
                !hideModalCloseButton && (
                  <ModalHeader
                    headerPaddingCloseButton={headerPaddingCloseButton}
                    headerFont={headerFont}
                    headerBorderBottom={headerBorderBottom}
                    headerMarginBottom={headerMarginBottom}
                  >
                    {headerText !== '' && <h3>{headerText}</h3>}
                    <ModalCloseButton
                      width={modalCloseButtonWidth}
                      customCloseButtonMargin={customCloseButtonMargin}
                      data-testid={`${
                        testId ? `${testId}-` : ''
                      }close-svg-modal-button`}
                      onClick={() => onClose()}
                      type='button'
                      hideModalCloseButton={hideModalCloseButton}
                    >
                      <IconExporter
                        name='close'
                        width={modalCloseButtonWidth}
                      />
                    </ModalCloseButton>
                  </ModalHeader>
                )
              )}
              <ModalContent
                padding={modalBodyPadding}
                size={size}
                isMobileFullscreen={isMobileFullscreen}
              >
                {children}
              </ModalContent>
            </ModalDialog>
          </ModalContainer>
        </React.Fragment>
      )}
    </ModalWrapper>
  )
}
