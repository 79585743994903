import React, { forwardRef, PropsWithChildren } from 'react'
import { AccordionSummary, AccordionDetails, AccordionProps } from '@mui/material'
import { ITheme } from '@cockpit/levi-ui/theme'
import Icon from '../Icon'
import { MUIAccordion } from './styles'

const AccordionComponent = (MUIAccordion as unknown) as React.FC<
  AccordionProps & {
    theme?: ITheme
    children?: React.ReactNode
  }
>

export interface IAccordion {
  title: string | React.ReactNode
  onChange?: (expanded: boolean) => void
  expanded?: boolean
  variant?: 'primary' | 'secondary'
  iconSize?: number
}

const Accordion = forwardRef<HTMLDivElement, PropsWithChildren<IAccordion>>(
  ({ title, children, onChange = () => {}, expanded, variant = 'primary', iconSize }, ref) => (
    <AccordionComponent
      elevation={0}
      onChange={(_, value) => onChange(value)}
      expanded={expanded}
      ref={ref}
    >
      <AccordionSummary
        data-testid="levi-accordion-summary"
        className={`levi-accordion-summary levi-accordion-${variant}`}
        expandIcon={
          <Icon
            icon={variant === 'primary' ? 'ChevronDownCircle' : 'ChevronDown'}
            size={iconSize || (variant === 'primary' ? 18 : 16)}
          />
        }
      >
        {title}
      </AccordionSummary>
      <AccordionDetails data-testid="levi-accordion-details" className="levi-accordion-details">
        {children}
      </AccordionDetails>
    </AccordionComponent>
  ),
)

export default Accordion
