import React, { forwardRef, ReactNode, useImperativeHandle, useState } from 'react'
import { IconButton } from '@mui/material'
import { Icon } from '../../atoms'
import { IconOptions } from '../../atoms/Icon/icon-list'
import { Container, Content, IconContainer, Typography } from './styles'

type IType = 'error' | 'warning' | 'success'

export interface ToastMethods {
  show: (type?: IType, msg?: string | ReactNode, time?: number) => void
}

export interface IToast {
  type?: IType
  show?: boolean
  floating?: boolean
  message?: string | ReactNode
  onClose?: () => void
}

const icons = {
  error: 'Error',
  warning: 'Warning',
  success: 'CheckFilled',
}

let timeout: string | number | NodeJS.Timeout | undefined

const Toast = forwardRef<ToastMethods, IToast>(
  ({ type = 'error', show, floating = true, message, onClose = () => {} }, ref) => {
    const [innerShow, setInnerShow] = useState(false)
    const [innerType, setInnerType] = useState<IType>()
    const [innerMessage, setInnerMessage] = useState<string | ReactNode>()

    /* istanbul ignore next */
    useImperativeHandle(ref, () => ({
      show(paramType = type, paramMessage = message, time = 5000) {
        setInnerShow(false)
        setTimeout(() => {
          clearTimeout(timeout)
          setInnerType(paramType)
          setInnerMessage(paramMessage)
          setInnerShow(true)
          if (time > 0) {
            timeout = setTimeout(() => setInnerShow(false), time)
          }
        })
      },
    }))

    return show || innerShow ? (
      <Container data-testid="toast-component" className={floating ? 'floating' : ''}>
        <Content className={innerType || type}>
          <IconContainer className={innerType || type}>
            <Icon icon={icons[innerType || type] as IconOptions} size={16} />
          </IconContainer>
          <Typography>{innerMessage || message}</Typography>
          <IconButton
            data-testid="toast-close-button"
            onClick={() => {
              setInnerShow(false)
              onClose()
            }}
          >
            <Icon icon="Close" size={12} />
          </IconButton>
        </Content>
      </Container>
    ) : null
  },
)

export default Toast
