import React from 'react'
import { SvgProps } from './typings/SvgProps'

const Chat: React.FC<SvgProps> = ({ color = 'currentColor', size, ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill={color} width={size} {...rest} viewBox="0 0 19 19">
    <g transform="translate(-1138.023 -89.023)">
      <g transform="translate(1138.648 89.648)">
        <g>
          <path
            d="M8.666,0A8.654,8.654,0,0,0,0,8.661a9.154,9.154,0,0,0,1.168,4.321.909.909,0,0,1,.061.78L.649,15.7a.54.54,0,0,0,.709.675L3.1,15.859a1.467,1.467,0,0,1,1.289.312A8.646,8.646,0,1,0,8.666,0Z"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth={1.25}
          />
        </g>
        <line
          x2="8.648"
          transform="translate(4.757 6.919)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeWidth={1.25}
        />
        <line
          x2="5.189"
          transform="translate(4.757 10.378)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeWidth={1.25}
        />
      </g>
    </g>
  </svg>
)

export default Chat
