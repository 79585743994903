import styled from 'styled-components'
import { theme } from '../../Theme'

export const TagWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 29px;
  padding: 0 16px;
  border-radius: ${theme.borderRadius.tags};
  margin: ${theme.spacing.xSmall};
  width: fit-content;
  box-shadow: 0px 3px 6px #00000029;
  background-color: ${theme.colors.white};
`

export const Content = styled.div`
  height: 16px;
  border-right: 2px solid ${theme.colors.gray3};
  margin: ${theme.spacing.tiny};
  padding: 0 ${theme.spacing.xSmall};
`

export const Action = styled.button`
  background: transparent;
  border: none;

  svg {
    width: 8px;
    height: 8px;
  }
`
