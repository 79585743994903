import React from 'react';

const SvgChecklistHealth = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='currentColor'
      width={19.626}
      height={20.649}
      viewBox='0 0 21 21'
      {...props}
    >
      <path d='M14.698 20.649a.744.744 0 01-.3-.063 9.618 9.618 0 01-3.961-3.213 3.915 3.915 0 01.168-4.61 3.127 3.127 0 012.243-1.005 3.35 3.35 0 012.378.955.75.75 0 01-1.055 1.066 1.8 1.8 0 00-1.292-.521 1.639 1.639 0 00-1.176.527 2.489 2.489 0 00.029 2.832 8.249 8.249 0 003.267 2.6.75.75 0 01-.3 1.437z' />
      <path d='M14.698 20.649a.75.75 0 01-.3-1.437 8.235 8.235 0 003.267-2.6 2.487 2.487 0 00.029-2.832 1.635 1.635 0 00-1.175-.527 1.834 1.834 0 00-1.292.521.75.75 0 11-1.055-1.066 3.283 3.283 0 012.378-.955 3.132 3.132 0 012.243 1.005 3.915 3.915 0 01.167 4.61 9.612 9.612 0 01-3.961 3.213.74.74 0 01-.301.068z' />
      <path d='M8.954 18.586H5.17A5.176 5.176 0 010 13.416V5.17A5.176 5.176 0 015.17 0h7.568a5.176 5.176 0 015.171 5.17v5.193a.75.75 0 11-1.5 0V5.17a3.674 3.674 0 00-3.671-3.67H5.17A3.674 3.674 0 001.5 5.17v8.246a3.674 3.674 0 003.67 3.67h3.784a.75.75 0 010 1.5z' />
      <path d='M4.447 8.133a.752.752 0 01-.51-.2l-1.084-1a.75.75 0 011.02-1.1l.579.537 1.791-1.626a.75.75 0 111.008 1.11l-2.3 2.089a.745.745 0 01-.504.19zM4.447 13.373a.756.756 0 01-.51-.2l-1.084-1a.75.75 0 111.02-1.1l.579.537 1.791-1.626a.75.75 0 111.008 1.111l-2.3 2.088a.748.748 0 01-.504.19zM14.545 7.594H9.071a.75.75 0 010-1.5h5.474a.75.75 0 010 1.5z' />
    </svg>
  )
}

export default SvgChecklistHealth
