import React from 'react';

const SvgCheck2 = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={12} height={9.6} viewBox='0 0 12 10' {...props}>
      <path
        data-name='\uD83C\uDFA8 Icon color'
        d='M11.668 2.031L4.8 9.251a1.092 1.092 0 01-1.6 0L.331 6.236a1.235 1.235 0 010-1.683 1.093 1.093 0 011.6 0L4 6.729l6.068-6.38a1.092 1.092 0 011.6 0 1.232 1.232 0 010 1.682'
        fill='#33ac2e'
      />
    </svg>
  )
}

export default SvgCheck2
