import { getColors } from '@cockpit/levi-ui/helpers'
import { theme } from '@cockpit/levi-ui/theme'
import { Theme, css } from '@emotion/react'
import styled from '@emotion/styled'
import { Button, ButtonProps } from '@mui/material'

type Props = ButtonProps & {
  theme: Theme
}

const hover = ({ variant = 'text' }: Props) => {
  const colors = {
    text: theme.colors.neutral[20],
    contained: theme.colors.blue[40],
    outlined: theme.colors.neutral[20],
  }
  return colors[variant]
}

export const MuiButton = styled(Button)<{ isLoading?: boolean }>`
  font-size: ${theme.font.size[3]};
  text-transform: none;
  font-weight: medium;
  &:not(.MuiButton-contained):hover {
    background-color: ${props => hover(props)};
  }

  box-shadow: none !important;

  max-height: 40px;

  &.MuiButton-contained:disabled {
    color: ${getColors('neutral', 10)};
    background-color: ${getColors('neutral', 40)};
  }

  ${({ isLoading }) =>
    isLoading
      ? css`
          svg {
            display: flex;
            align-items: center;
            fill: currentColor;
          }
        `
      : css`
          svg {
            height: 16px;
            width: auto !important;
          }
        `}
`
