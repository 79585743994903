import { theme } from '@cockpit/levi-ui/theme'
import styled from '@emotion/styled'

export const Container = styled.div`
  &.levi-font-size-0 {
    font-size: ${theme.font.size[0]};
  }

  &.levi-font-size-1 {
    font-size: ${theme.font.size[1]};
  }

  &.levi-font-size-2 {
    font-size: ${theme.font.size[2]};
  }

  &.levi-font-size-3 {
    font-size: ${theme.font.size[3]};
  }

  &.levi-font-size-4 {
    font-size: ${theme.font.size[4]};
  }

  &.levi-font-size-5 {
    font-size: ${theme.font.size[5]};
  }

  &.levi-font-size-6 {
    font-size: ${theme.font.size[6]};
  }

  &.levi-font-size-7 {
    font-size: ${theme.font.size[7]};
  }

  &.levi-font-size-8 {
    font-size: ${theme.font.size[8]};
  }

  &.levi-font-size-9 {
    font-size: ${theme.font.size[9]};
  }

  &.levi-font-size-10 {
    font-size: ${theme.font.size[10]};
  }

  &.levi-font-weight-1 {
    font-weight: ${theme.font.weight[1]};
  }

  &.levi-font-weight-2 {
    font-weight: ${theme.font.weight[2]};
  }

  &.levi-font-weight-3 {
    font-weight: ${theme.font.weight[3]};
  }

  &.levi-font-weight-4 {
    font-weight: ${theme.font.weight[4]};
  }

  &.levi-font-weight-5 {
    font-weight: ${theme.font.weight[5]};
  }

  &.levi-font-weight-6 {
    font-weight: ${theme.font.weight[6]};
  }

  &.levi-font-weight-7 {
    font-weight: ${theme.font.weight[7]};
  }

  &.levi-font-weight-8 {
    font-weight: ${theme.font.weight[8]};
  }

  &.levi-font-weight-9 {
    font-weight: ${theme.font.weight[9]};
  }
`
