import React from 'react'
import { SvgProps } from './typings/SvgProps'

const Stethoscope: React.FC<SvgProps> = ({ color = 'currentColor', size, ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} {...rest} viewBox="0 0 15 15">
    <g>
      <g>
        <rect width={size} height={size} fill="none" />
      </g>
      <g transform="translate(0.642 1.284)">
        <path
          d="M13.875,6.568a2.247,2.247,0,0,0-.642,4.4v1.38a3.21,3.21,0,0,1-6.419,0v-.064a4.14,4.14,0,0,0,3.531-4.108V4.963A.963.963,0,0,0,9.382,4H8.419a.642.642,0,0,0,0,1.284h.642V8.172a2.889,2.889,0,1,1-5.777,0V5.284h.642A.642.642,0,1,0,3.926,4H2.963A.963.963,0,0,0,2,4.963v3.21A4.14,4.14,0,0,0,5.53,12.281v.064a4.493,4.493,0,0,0,8.987,0v-1.38a2.247,2.247,0,0,0-.642-4.4Zm0,3.21a.963.963,0,1,1,.963-.963.931.931,0,0,1-.963.963Z"
          transform="translate(-2 -4)"
          fill={color}
          stroke={color}
          strokeWidth="0.2"
        />
      </g>
    </g>
  </svg>
)

export default Stethoscope
