import React from 'react'
import { SvgProps } from './typings/SvgProps'

const Load: React.FC<SvgProps> = ({ color = 'currentColor', size, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill={color}
    width={size}
    {...rest}
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
  >
    <circle
      cx={50}
      cy={50}
      fill="none"
      stroke={color}
      strokeWidth={5}
      r={35}
      strokeDasharray="164.93361431346415 56.97787143782138"
    >
      <animateTransform
        attributeName="transform"
        type="rotate"
        repeatCount="indefinite"
        dur="2.941176470588235s"
        values="0 50 50;360 50 50"
        keyTimes="0;1"
      />
    </circle>
  </svg>
)

export default Load
