import React from 'react'

const iconMicro = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={16}
    height={19}
    viewBox='0 0 16 19'
    fill='currentColor'
    {...props}
  >
    <g transform='translate(0.625 0.625)'>
      <g>
        <path
          d='M0,0A7.5,7.5,0,0,0,15,0'
          transform='translate(0 8.326)'
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit='10'
          strokeWidth={1.25}
        />
        <path
          d='M.476,2.847V0'
          transform='translate(7.025 15.828)'
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit='10'
          strokeWidth={1.25}
        />
        <path
          d='M3.964,12.027H3.708A3.708,3.708,0,0,1,0,8.318V3.708A3.709,3.709,0,0,1,3.708,0h.256A3.709,3.709,0,0,1,7.672,3.708v4.61A3.708,3.708,0,0,1,3.964,12.027Z'
          transform='translate(3.665)'
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit='10'
          strokeWidth={1.25}
        />
      </g>
    </g>
  </svg>
)

export default iconMicro
