import React from 'react';

const SvgClipboardCheck = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='currentColor'
      width={17.377}
      height={20.536}
      viewBox='0 0 21 21'
      {...props}
    >
      <path d='M14.748 2.114h-.769a1.159 1.159 0 00-1.074-.726h-1.177a.228.228 0 01-.228-.228A1.161 1.161 0 0010.34 0h-3.3a1.161 1.161 0 00-1.16 1.16.228.228 0 01-.227.228H4.47a1.16 1.16 0 00-1.074.726h-.769A2.631 2.631 0 00-.002 4.742v13.165a2.632 2.632 0 002.629 2.629h12.119a2.632 2.632 0 002.629-2.629V4.742a2.632 2.632 0 00-2.627-2.628zm-10.437.433a.16.16 0 01.16-.159h1.178A1.229 1.229 0 006.876 1.16a.16.16 0 01.16-.16h3.3a.16.16 0 01.16.16 1.229 1.229 0 001.228 1.228h1.177a.16.16 0 01.16.159v1.749a.16.16 0 01-.16.159H4.47a.16.16 0 01-.16-.159V2.547zm12.065 15.36a1.63 1.63 0 01-1.629 1.628H2.629A1.631 1.631 0 011 17.907V4.742a1.631 1.631 0 011.629-1.628h.683v1.182a1.161 1.161 0 001.16 1.16h8.434a1.161 1.161 0 001.16-1.16V3.114h.683a1.63 1.63 0 011.629 1.628z' />
      <path d='M11.832 9.111l-3.264 3.366-1.789-1.786a.5.5 0 00-.706.708l2.148 2.144a.5.5 0 00.353.146.5.5 0 00.355-.151l3.617-3.73a.501.501 0 10-.717-.7z' />
    </svg>
  )
}

export default SvgClipboardCheck
