import React, { forwardRef } from 'react'
import { Container, ErrorMessage, ProportionType } from './styles'

export interface IConcatInput {
  proportion?: readonly [ProportionType, ProportionType]
  children: readonly [JSX.Element, JSX.Element]
  className?: string
  error?: boolean
  message?: string
}

const ConcatInput = forwardRef<HTMLDivElement, IConcatInput>(
  ({ children, className, proportion = [2, 1], error = false, message }, ref) => (
    <>
      <Container ref={ref} className={className} proportion={proportion} hasError={error}>
        {children}
      </Container>
      {error && <ErrorMessage error>{message}</ErrorMessage>}
    </>
  ),
)

export default ConcatInput
