import React from 'react';

const SvgSoundOff = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={19} height={16} viewBox="0 0 19 16" {...props}>
      <g transform="translate(1.004 1.057)">
        <g transform="translate(0)">
          <path d="M4.1,10.76H1.984A1.734,1.734,0,0,1,.141,9.185a18.93,18.93,0,0,1,0-4.618A1.79,1.79,0,0,1,1.984,3.048H4.1L7.809.453A2.491,2.491,0,0,1,9.039,0a1.7,1.7,0,0,1,1.651,1.164,10.53,10.53,0,0,1,.253,1.518,8.545,8.545,0,0,1,.126,1.927" transform="translate(0 0.42)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width={1.5}/>
          <path d="M4.574,0a19.716,19.716,0,0,1-.123,2.237A9.267,9.267,0,0,1,4.2,3.753,1.7,1.7,0,0,1,2.548,4.918,2.161,2.161,0,0,1,1.4,4.511L0,3.538" transform="translate(6.491 9.309)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width={1.5}/>
          <path d="M17.148,0,0,14.648" transform="translate(0.053)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width={1.5}/>
        </g>
      </g>
    </svg>
  )
}

export default SvgSoundOff
