import React from 'react'
import { SvgProps, WithSecondaryColor } from './typings/SvgProps'

const FacebookCircle: React.FC<SvgProps & WithSecondaryColor> = ({
  color = 'currentColor',
  secondaryColor = '#fff',
  size,
  ...rest
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} {...rest} viewBox="0 0 32 32">
    <g transform="translate(-1492 -8795)">
      <circle cx="16" cy="16" r="16" transform="translate(1492 8795)" fill={color} />
      <path
        d="M16.038,9.288V7.863c0-.643.142-.969,1.141-.969h1.253V4.5H16.341c-2.562,0-3.407,1.175-3.407,3.191v1.6H11.251v2.394h1.683v7.182h3.1V11.682h2.11l.284-2.394H16.039Z"
        transform="translate(1493.158 8799.318)"
        fill={secondaryColor}
      />
    </g>
  </svg>
)

export default FacebookCircle
