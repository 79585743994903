import React from 'react'
import { Container, Mask, ModalContent } from './styles'

export interface IFooterModalProps {
  buttonHeight: string
  show: boolean
  children: JSX.Element | JSX.Element[]
  padding?: string
  buttonBorder?: string
  disableButtonStyle?: boolean
  onClose: () => void
}

export const FooterModal = ({
  buttonHeight,
  show,
  children,
  padding = '0',
  buttonBorder = 'none',
  disableButtonStyle = false,
  onClose
}: IFooterModalProps) => (
  <Container show={show} data-testid='footer-modal'>
    <Mask onClick={() => onClose()} data-testid='footer-modal-mask' />
    <ModalContent
      padding={padding}
      buttonHeight={buttonHeight}
      buttonBorder={buttonBorder}
      disableButtonStyle={disableButtonStyle}
    >
      {children}
    </ModalContent>
  </Container>
)
