import React from 'react';

const SvgAttendence = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={24.18}
      height={19.099}
      viewBox='0 0 24 19'
      {...props}
    >
      <g data-name='Grupo 7840' fill='#fff'>
        <path
          data-name='Caminho 6136'
          d='M19.059 2.559A2.929 2.929 0 0016.159 0H2.936A2.939 2.939 0 000 2.936v9.308a2.939 2.939 0 002.936 2.936h3.925l-.456 2.456H5.063a.732.732 0 100 1.463h8.972a.732.732 0 100-1.463H12.69l-.455-2.45h1.689a.736.736 0 100-1.471H2.933a1.474 1.474 0 01-1.473-1.473V2.936a1.473 1.473 0 011.473-1.472H16.16a1.47 1.47 0 011.418 1.1v.907a.74.74 0 001.48 0v-.91zM8.349 15.18h2.4l.456 2.456H7.89z'
        />
        <path
          data-name='Caminho 6137'
          d='M24.18 11.764a2.1 2.1 0 10-2.838 1.966 2.683 2.683 0 01-.126.492 2.551 2.551 0 01-2.232 1.506 2.411 2.411 0 01-2.337-1.04 3.372 3.372 0 01-.381-1.687 3.742 3.742 0 003.508-3.552V6.564a1.615 1.615 0 00-.516-1.258 1.9 1.9 0 00-1.347-.423h-.321v-.184a.658.658 0 00-1.316 0v1.685a.658.658 0 001.316 0v-.186h.386a.634.634 0 01.406.093.406.406 0 01.075.276v2.856c-.013.236-.2 2.305-2.845 2.305s-2.831-2.069-2.843-2.275V6.564a.4.4 0 01.076-.276.734.734 0 01.472-.09h.388v.184a.658.658 0 001.315 0V4.701a.658.658 0 00-1.315 0v.187h-.315a1.949 1.949 0 00-1.42.419 1.612 1.612 0 00-.517 1.258v2.912A3.734 3.734 0 0014.94 13a4.709 4.709 0 00.568 2.357 3.62 3.62 0 003.19 1.7c.131 0 .264 0 .4-.017a3.862 3.862 0 003.348-2.359 4.148 4.148 0 00.206-.9 2.1 2.1 0 001.528-2.017zm-2.1.79a.789.789 0 11.79-.79.79.79 0 01-.795.793z'
        />
      </g>
    </svg>
  )
}

export default SvgAttendence
