import React from 'react';

const SvgClinics = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={196.667}
      height={32.859}
      viewBox='0 0 196 32'
      {...props}
    >
      <g data-name='Grupo 608'>
        <path
          data-name='Caminho 393'
          d='M196.299 29.001H40.149a.368.368 0 01-.367-.368.368.368 0 01.367-.368h156.15a.368.368 0 01.368.368.368.368 0 01-.368.368'
          fill='#9b9c9e'
        />
        <path
          data-name='Caminho 394'
          d='M27.839 10.774c-.619-1.032-2.022-1.338-3.029-1.482l-.243-.035a39.4 39.4 0 013 5.084c.093-.2.168-.375.221-.518a3.545 3.545 0 00.046-3.049'
          fill='#004b8c'
        />
        <path
          data-name='Caminho 395'
          d='M15.576 24.217c.554 1.438 1.136 2.175 1.832 2.312.871.177 2.022-.541 3.723-2.322.348-.362.724-.792 1.115-1.289-1.941.048-4.28.24-6.957.573.057.137.287.726.287.726'
          fill='#004b8c'
        />
        <path
          data-name='Caminho 396'
          d='M11.686 16.27a37.018 37.018 0 012.874 5.461c.25-.033.508-.074.764-.113a46.716 46.716 0 018.239-.477 53.518 53.518 0 003.631-6.024 37.882 37.882 0 00-3.949-6 42 42 0 00-7.841.109 35.413 35.413 0 01-1.864 3.772c-.711 1.24-1.328 2.312-1.855 3.27'
          fill='#004b8c'
        />
        <path
          data-name='Caminho 397'
          d='M28.018 4.81A16.334 16.334 0 0016.399 0 16.254 16.254 0 006.35 3.427a16.564 16.564 0 00-5.756 8.5 46.377 46.377 0 0113.153-3.794c.347-1.081.588-2.095.807-2.991.45-1.869.77-3.219 1.645-3.46.93-.261 2.414.761 5.126 3.526l2.055 2.433a24.513 24.513 0 013.117.4l.114.026c.86.172 2.66.538 3.4 1.858a3.375 3.375 0 01.009 2.869 39.203 39.203 0 01-1.515 3.634 28.47 28.47 0 011.16 3.434c.011.054.029.142.042.236a.835.835 0 01-.186.555l-.083.108-.043-.132a28.609 28.609 0 00-1.238-3.485 31.392 31.392 0 01-2.465 4.1 28.105 28.105 0 016.055 1.029 16.432 16.432 0 001.076-5.855 16.334 16.334 0 00-4.81-11.616'
          fill='#004b8c'
        />
        <path
          data-name='Caminho 398'
          d='M10.095 21.309c.389.577 1.474.745 3.5.537a38.066 38.066 0 00-2.294-4.868c-1.092 2.058-1.711 3.58-1.208 4.33'
          fill='#004b8c'
        />
        <path
          data-name='Caminho 399'
          d='M21.996 7.587c-1.961-2.314-3.083-3.215-3.868-3.09-.818.13-1.335 1.384-2.118 3.289l-.026.066a44.651 44.651 0 016.012-.265'
          fill='#004b8c'
        />
        <path
          data-name='Caminho 400'
          d='M24.439 22.932c-.957 1.192-2.055 2.418-3.462 3.871-2.043 2.1-3.174 3.052-4.085 2.821-.631-.164-1.1-.9-1.517-2.406a63.725 63.725 0 00-1.125-3.6l-1.5.211c-3.36.487-5.227.221-5.885-.818-.943-1.476.809-4.378 3.2-8.008a27.382 27.382 0 00-1.709-2.267 1.5 1.5 0 01-.153-.183l-.085-.1.132-.012a.743.743 0 01.58.2c.54.575 1.073 1.193 1.578 1.842l.233-.362 1.083-1.639a16.488 16.488 0 001.53-2.976C7.132 10.43 2.723 12.329.861 14.739a3.964 3.964 0 00-.81 3.372 16.479 16.479 0 004.769 9.978 16.168 16.168 0 0011.575 4.771 16.419 16.419 0 0012.471-5.736c.752-1.008.979-1.846.665-2.5-.476-1-2.19-1.567-5.1-1.691'
          fill='#004b8c'
        />
        <path
          data-name='Caminho 401'
          d='M50.687 21.186a5.061 5.061 0 01-.827 2.051 4.054 4.054 0 01-1.552 1.322 4.662 4.662 0 01-2.073.459 4.681 4.681 0 01-1.79-.35 4.513 4.513 0 01-1.492-.99 3.581 3.581 0 01-.836-1.232 5.135 5.135 0 01-.315-1.484q-.064-.836-.063-2.608t.063-2.607a5.14 5.14 0 01.315-1.484 3.588 3.588 0 01.836-1.232 4.519 4.519 0 011.488-.989 4.648 4.648 0 011.794-.351 4.68 4.68 0 012.087.459 4.048 4.048 0 011.542 1.318 5.058 5.058 0 01.823 2.054H49.68a3.774 3.774 0 00-1.209-2.158 3.316 3.316 0 00-2.235-.792 3.669 3.669 0 00-1.345.252 3.321 3.321 0 00-1.147.756 2.961 2.961 0 00-.544.728 3.561 3.561 0 00-.3.953 8.929 8.929 0 00-.135 1.313q-.027.737-.027 1.78 0 1.556.063 2.369a4.979 4.979 0 00.279 1.381 2.87 2.87 0 00.665 1.016 3.4 3.4 0 001.143.756 3.58 3.58 0 001.348.261 3.524 3.524 0 001.587-.355 3.347 3.347 0 001.214-1.034 3.921 3.921 0 00.679-1.561z'
          fill='#00b1be'
        />
        <path
          data-name='Caminho 402'
          d='M62.441 24.909h-7.959V11.797h1v12.23h6.961z'
          fill='#00b1be'
        />
        <path
          data-name='Caminho 403'
          d='M67.961 7.841l-1.952 2.706h-1.07l1.78-2.706zM66.379 24.91h-1V11.798h1z'
          fill='#00b1be'
        />
        <path
          data-name='Caminho 404'
          d='M80.353 24.909h-1l-7.563-11.4v11.4h-1V11.797h1l7.563 11.358V11.797h1z'
          fill='#00b1be'
        />
        <path
          data-name='Ret\xE2ngulo 1416'
          fill='#00b1be'
          d='M84.768 11.798h.998v13.111h-.998z'
        />
        <path
          data-name='Caminho 405'
          d='M98.617 21.186a5.073 5.073 0 01-.828 2.051 4.048 4.048 0 01-1.552 1.322 4.66 4.66 0 01-2.073.459 4.681 4.681 0 01-1.79-.35 4.5 4.5 0 01-1.491-.99 3.569 3.569 0 01-.836-1.232 5.084 5.084 0 01-.315-1.484q-.064-.836-.064-2.608t.064-2.607a5.089 5.089 0 01.315-1.484 3.576 3.576 0 01.836-1.232 4.508 4.508 0 011.487-.989 4.655 4.655 0 011.794-.351 4.686 4.686 0 012.087.459 4.048 4.048 0 011.542 1.318 5.081 5.081 0 01.823 2.054h-1.008a3.773 3.773 0 00-1.209-2.158 3.314 3.314 0 00-2.235-.792 3.665 3.665 0 00-1.344.252 3.324 3.324 0 00-1.147.756 2.939 2.939 0 00-.543.728 3.536 3.536 0 00-.3.953 8.926 8.926 0 00-.136 1.313q-.027.737-.027 1.78 0 1.556.063 2.369a4.98 4.98 0 00.279 1.381 2.879 2.879 0 00.665 1.016 3.4 3.4 0 001.143.756 3.584 3.584 0 001.348.261 3.53 3.53 0 001.588-.355 3.351 3.351 0 001.213-1.034 3.921 3.921 0 00.679-1.561z'
          fill='#00b1be'
        />
        <path
          data-name='Caminho 406'
          d='M111.197 24.909h-1.07l-1.143-3.174h-6.115l-1.133 3.174h-1.07l4.82-13.111h.891zm-2.518-4.074l-2.752-7.716-2.742 7.716z'
          fill='#00b1be'
        />
        <path
          data-name='Caminho 407'
          d='M122.087 24.909h-8.911V11.797h8.911v2.869h-5.692v2.194h4.865v2.868h-4.865v2.311h5.692z'
          fill='#00b1be'
        />
        <path
          data-name='Ret\xE2ngulo 1417'
          fill='#00b1be'
          d='M124.739 11.798h3.219v13.111h-3.219z'
        />
        <path
          data-name='Caminho 408'
          d='M141.214 24.909h-2.815l-4.2-6.591v6.591h-3.22V11.797h2.816l4.2 6.591v-6.591h3.219z'
          fill='#00b1be'
        />
        <path
          data-name='Caminho 409'
          d='M153.039 20.898a3.678 3.678 0 01-.679 2.221 4.2 4.2 0 01-1.835 1.417 6.573 6.573 0 01-2.549.481 9.3 9.3 0 01-2.832-.387 5.185 5.185 0 01-2.142-1.34l2.069-2.069a2.843 2.843 0 001.277.706 6.107 6.107 0 001.645.221q1.881 0 1.88-1.178a1.069 1.069 0 00-.261-.774 1.45 1.45 0 00-.863-.351l-1.583-.225a4.394 4.394 0 01-2.634-1.16 3.626 3.626 0 01-.9-2.635 3.975 3.975 0 01.581-2.136 3.934 3.934 0 011.663-1.47 5.72 5.72 0 012.541-.53 7.77 7.77 0 012.53.373 4.841 4.841 0 011.893 1.191l-2.022 2.023a2.273 2.273 0 00-1.089-.638 6.346 6.346 0 00-1.4-.134 1.691 1.691 0 00-1.178.368 1.117 1.117 0 00-.405.846.835.835 0 00.261.611 1.523 1.523 0 00.9.387l1.583.215a4.529 4.529 0 012.58 1.089 2.867 2.867 0 01.742 1.2 5.441 5.441 0 01.23 1.677'
          fill='#00b1be'
        />
        <path
          data-name='Caminho 410'
          d='M164.423 14.667h-3.229V24.91h-3.219V14.667h-3.229v-2.869h9.676z'
          fill='#00b1be'
        />
        <path
          data-name='Caminho 411'
          d='M175.583 24.909h-8.911V11.797h8.911v2.869h-5.692v2.194h4.865v2.868h-4.865v2.311h5.692z'
          fill='#00b1be'
        />
        <path
          data-name='Ret\xE2ngulo 1418'
          fill='#00b1be'
          d='M178.235 11.798h3.219v13.111h-3.219z'
        />
        <path
          data-name='Caminho 412'
          d='M194.709 24.909h-2.815l-4.2-6.591v6.591h-3.22V11.797h2.816l4.2 6.591v-6.591h3.219z'
          fill='#00b1be'
        />
      </g>
    </svg>
  )
}

export default SvgClinics
