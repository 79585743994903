import React from 'react'
import { SvgProps } from './typings/SvgProps'

const Certificate: React.FC<SvgProps> = ({ color = 'currentColor', size, ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} {...rest} viewBox="0 0 36.533 44.033">
    <g transform="translate(-823 -6587)">
      <g transform="translate(-21 1022)">
        <g transform="translate(844 5565)">
          <g>
            <path
              d="M0,35.43V2.408a13.33,13.33,0,0,1,.768-1.4A3.169,3.169,0,0,1,2.413,0h25.5a.391.391,0,0,0,.1.073,2.967,2.967,0,0,1,2.311,3.1q0,7.3,0,14.6v.5a.763.763,0,0,0,.093.062c.065.029.132.054.2.08a8.8,8.8,0,0,1,5.666,6.355c.1.37.165.746.247,1.119v1.978a.5.5,0,0,0-.074.146,8.854,8.854,0,0,1-2.44,5.051.957.957,0,0,0-.234.6q-.022,4.448-.006,8.9a1.427,1.427,0,0,1-.418,1.086,1.385,1.385,0,0,1-1.813.121q-1.819-1.017-3.619-2.067a.6.6,0,0,0-.7-.005q-1.852,1.087-3.731,2.13a1.373,1.373,0,0,1-2.128-1.247c0-1.418,0-2.837,0-4.255v-.477H20.8q-8.871,0-17.743,0A3,3,0,0,1,.1,35.655,2.226,2.226,0,0,0,0,35.43M27.572,17.872v-.458q0-7.028,0-14.057c0-.592-.013-.6-.6-.6H3.365c-.6,0-.608.012-.608.6q0,15.562,0,31.123c0,.6.014.61.6.611H20.93c.138,0,.276-.012.439-.02,0-.464-.015-.892.005-1.319a.945.945,0,0,0-.294-.756,8.954,8.954,0,0,1,6.492-15.118M27.556,33.02a6.191,6.191,0,1,0-6.187-6.2,6.24,6.24,0,0,0,6.187,6.2M31,40.251V35.108a9.119,9.119,0,0,1-6.837.015v5.125c.79-.45,1.562-.834,2.273-1.309a1.887,1.887,0,0,1,2.306.013c.713.466,1.477.854,2.258,1.3"
              fill={color}
            />
            <path
              d="M87.269,79.845q2.863,0,5.725,0a1.379,1.379,0,1,1,.017,2.75q-5.768,0-11.537,0a1.377,1.377,0,1,1-.017-2.749q2.906,0,5.811,0"
              transform="translate(-73.115 -72.965)"
              fill={color}
            />
            <path
              d="M87.268,143.72q2.863,0,5.725,0a1.379,1.379,0,1,1,.019,2.75q-5.768,0-11.537,0a1.377,1.377,0,1,1-.019-2.749q2.906,0,5.811,0"
              transform="translate(-73.115 -131.336)"
              fill={color}
            />
            <path
              d="M84.84,210.342c-1.133,0-2.266,0-3.4,0a1.376,1.376,0,1,1,.007-2.749q3.378,0,6.756,0a1.377,1.377,0,1,1-.007,2.749c-1.119,0-2.238,0-3.356,0"
              transform="translate(-73.115 -189.704)"
              fill={color}
            />
            <path
              d="M274.928,275.959c.668-1,1.309-1.965,1.955-2.926a1.359,1.359,0,0,1,1.446-.631,1.307,1.307,0,0,1,1.071,1,1.335,1.335,0,0,1-.191,1.146c-.906,1.358-1.8,2.72-2.72,4.07a1.349,1.349,0,0,1-1.954.408c-.692-.433-1.372-.885-2.043-1.35a1.375,1.375,0,0,1-.407-1.925,1.4,1.4,0,0,1,1.969-.359c.277.175.55.359.874.571"
              transform="translate(-248.429 -248.908)"
              fill={color}
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default Certificate
