import React, { InputHTMLAttributes, useEffect } from 'react'
import { StringIcons } from '../..'
import IconExporter from '../../IconExporter'
import { useClickOutside } from '../../utils'
import {
  ErrorIconWrapper,
  ErrorWrapper,
  IconWrapper,
  Input,
  InputWrapper,
  IOptionColor,
  LoadingContainer,
  NoOptionFound,
  Option,
  OptionsWrapper,
  SelectGroup,
  SelectWrapper,
  CountResults,
  CountResultsText,
  TagsContainer,
  InputContainer
} from './styles'
import { FormTestId } from '../../utils/enum'
import { TagEditable, TagStatic } from '../../Tags'

export enum EKey {
  ArrowUp = 'ArrowUp',
  ArrowDown = 'ArrowDown',
  Enter = 'Enter',
  Tab = 'Tab',
  Escape = 'Escape'
}

interface CustomOptionProps {
  value: string | number
}

export interface ISelectOption {
  label: string
  value: string | number
}
export interface SelectProps extends InputHTMLAttributes<HTMLInputElement> {
  errors?: string
  searchable?: boolean
  customOptionTestId?: (index: number) => string
  /**
   * @interface ISelectOption options É uma lista de ISelectOption
   * @param {string} label
   * @param {string | number} value
   */
  options: ISelectOption[]
  noOptionsFoundText?: string | ((searchText: string) => string)
  multiple?: boolean
  flex?: string
  CustomOption?: React.FC<CustomOptionProps>
  apiCall?: (props: string) => Promise<ISelectOption[]>
  apiCallloadingMore?: (
    props: string,
    pagination: number,
    totalItemsPages: number
  ) => Promise<[ISelectOption]>
  totalItemsPages?: number
  removeChevron?: boolean
  removeIconError?: boolean
  onClean?: () => void
  setValue?: React.Dispatch<
    React.SetStateAction<
      string | number | readonly string[] | undefined | Array<string | number>
    >
  >
  loadingMore?: React.ReactNode
  apiCallLoadingTimer?: number
  /**
   * @interface IOptionColor
   * @param {string} onHoverBackgroundColor
   * @param {string} onHoverColor
   * @param {string} selectedBackgroundColor
   * @param {string} selectedColor
   */
  optionColor?: IOptionColor
  customIcon?: StringIcons
  positionIcon?: 'right' | 'left'
  showCountResults?: boolean
  hasMultipleTags?: boolean
  maxWidth?: string
  maxHeight?: string
  disabledBackground?: string
  allowOpenText?: boolean
}

/**
 * Este componente irá renderizar o Select, estende HTMLInputElement props
 *
 * TIPOS DE SELECT:
 * 1) Select - Padrão com as opções fixas.
 * 2) Multiple - Opção de utilizar com múltiplas seleções de opções, multiple: true. Não é possível usar com o searchable: true.
 * 3) ApiOptions - Utilizando apiCall, recebe as opções vindas do callback.
 * 4) SearchableFixOptions - Com searchable: true, permite filtrar as opções recebidas em options.
 * 5) Searchable - Com searchable: true e apiCall, permite filtrar as opções vindas da requisição da API.
 *
 * @param {string | number | readonly string[] | undefined} value prop para passar o valor atual, se passado um valor inicial, será o valor default.
 * OBS: Caso passe um valor fixo, irá causar uma má renderização.
 * @param {SetStateAction} setValue Function ou State Action necessária caso queira receber o valor selecionado nas opções no componente pai.
 * @param {Array<ISelectOption>} options Lista de opções necessário para mostrar a lista de dropdown.
 * @param {boolean} multiple Se true, pode selecionar múltiplos itens e irá retornar uma lista no setValue. Ex: [1,2,3]
 *
 * Não pode ser usado com a prop searchable
 * @param {boolean} searchable Se true, pode digitar e filtrar na lista de opções.
 *
 * Não pode ser usado com a prop multiple
 * @param {boolean} removeChevron Se true, remove ícone de chevron
 * @param {boolean} removeIconError Se true, remove ícone de erro
 * @param {string} errors mensagem de erro a ser exibida. Caso tenha a mensagem, irá exibi-la.
 * @param {string} noOptionsFoundText Mensagem de opções não encontradas customizada.
 * @param {number} apiCallLoadingTimer timer em milliseconds para fazer a requisição do apiCall, default é 500 milliseconds, 1000 = 1s
 * @param {IOptionColor} optionColor Customizar as cores das opções, hover, selected e background.
 * @param defaultValue Não utilize este parâmetro, passe o valor default em value na primeira renderização.
 * @param customIcon Ícone customizável para o chevron
 * @param showCountResults Booleana se true exibe uma contagem dos itens
 * @param hasMultipleTags Booleano opcional. Define se será exibido uma Tag para cada item selecionado
 * @param maxWidth String opcional. Define qual será o max-width do InputWrapper
 * @param maxHeight String opcional. Define qual será o max-height do InputWrapper
 * @param disabledBackground String opcional para determinar o background quando o componente estiver desabilitado
 * @param customOptionTestId Função opcional que recebe o index da option para determinar o data-testid customizado.
 * @param allowOpenText Se true, permite que o value preenchido no select se mantenha no input.
 */
const Select = React.forwardRef<HTMLInputElement, SelectProps>(
  (
    {
      errors,
      searchable,
      setValue,
      options,
      noOptionsFoundText = 'Nenhuma opção encontrada',
      multiple = false,
      totalItemsPages = 10,
      flex,
      CustomOption,
      apiCall,
      apiCallloadingMore,
      onClean,
      removeChevron,
      removeIconError,
      value,
      autoComplete = 'off',
      defaultValue,
      apiCallLoadingTimer = 500,
      optionColor,
      customIcon,
      positionIcon,
      showCountResults,
      loadingMore,
      hasMultipleTags,
      maxWidth,
      maxHeight = '230px',
      disabledBackground,
      customOptionTestId,
      allowOpenText = false,
      ...props
    }: SelectProps,
    ref
  ) => {
    const wrapperRef = React.useRef<HTMLDivElement>({} as HTMLDivElement)
    const optionWrapperRef = React.useRef<HTMLDivElement>({} as HTMLDivElement)
    const optionRef = React.useRef<HTMLDivElement>({} as HTMLDivElement)

    // close options if user click outside the component
    useClickOutside(wrapperRef, () => {
      setIsOptionsOpen(false)
      if (hasMultipleTags) setHideTags(true)
    })

    const [isOptionsOpen, setIsOptionsOpen] = React.useState(false)
    const [displayOptions, setDisplayOptions] = React.useState(options)
    const [optionSelected, setOptionSelected] = React.useState<ISelectOption>({
      label: '',
      value: ''
    })
    const [multipleValues, setMultipleValues] = React.useState<
      Array<number | string>
    >([] as Array<number | string>)
    const [isSearching, setIsSearching] = React.useState(false)

    const [optionIndex, setOptionIndex] = React.useState(0)
    const [focused, setFocused] = React.useState(false)
    const [scrollToOption, setScrollToOption] = React.useState(false)
    const [searchableLabel, setSearchableLabel] = React.useState('')
    const [lastPageLoaded, setLastPageLoaded] = React.useState(false)

    const arrayFiltered = Array.from(
      new Set(displayOptions.map((o) => JSON.stringify(o)))
    ).map((s) => JSON.parse(s))

    const [pageIndex, setPageIndex] = React.useState(0)
    const [currentText, setCurrentText] = React.useState('')
    const [isApiBusy, setIsApiBusy] = React.useState(false)

    const [tagsSelected, setTagsSelected] = React.useState<ISelectOption[]>([])
    const [hideTags, setHideTags] = React.useState<boolean>(false)
    const displayPlaceholder = React.useMemo(() => {
      if (!props.placeholder) {
        if (multiple && multipleValues.length > 0) {
          return `${multipleValues.length} itens selecionados`
        }
        if (!optionSelected.value || multipleValues.length === 0) {
          return 'Selecione'
        } else {
          return optionSelected.label
        }
      }
      return props.placeholder
    }, [props.placeholder, multiple, optionSelected, multipleValues.length])

    const handleRemoveWhitespace = (text: string) => {
      return text.replace(/\s\s+/g, ' ')
    }

    // only works when searchable props is true
    const handleOnChange = React.useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        const textValue: string = event.target.value.trimStart()
        if (props.onChange && allowOpenText) props.onChange(event)

        if (!textValue && onClean) {
          onClean()
        }

        const hasNoText =
          optionSelected.label.length === 0 && textValue.length === 0

        if (optionSelected.label.endsWith(' ') || hasNoText) {
          return setOptionSelected({
            label: handleRemoveWhitespace(textValue),
            value: ''
          })
        }
        setCurrentText(handleRemoveWhitespace(textValue))

        !isOptionsOpen && setIsOptionsOpen(true)
        !isSearching && setIsSearching(true)

        setOptionSelected({
          ...optionSelected,
          label: handleRemoveWhitespace(textValue)
        })

        optionIndex !== 0 && setOptionIndex(0)

        if (!apiCall && !apiCallloadingMore) {
          options.length > 0 &&
            setDisplayOptions(
              options.filter((option: ISelectOption) => {
                return option.label
                  .toUpperCase()
                  .includes(textValue.toUpperCase())
              })
            )
        }
      },
      [
        apiCall,
        apiCallloadingMore,
        totalItemsPages,
        isOptionsOpen,
        isSearching,
        optionIndex,
        optionSelected,
        options
      ]
    )

    const handleApiCall = React.useCallback(
      async (resetPageNumber = true) => {
        // eslint-disable-next-line no-console

        try {
          let resultApi: ISelectOption[] = []
          if (apiCall) {
            resultApi = await apiCall(optionSelected.label.trim())
          } else if (apiCallloadingMore) {
            resultApi = await apiCallloadingMore(
              currentText.trim(),
              resetPageNumber ? 1 : pageIndex + 1,
              totalItemsPages
            )
          }

          const result = Array.isArray(resultApi) ? resultApi : []
          if (apiCallloadingMore) {
            if (result.length < totalItemsPages) {
              setLastPageLoaded(true)
            } else {
              setLastPageLoaded(false)
            }

            if (resetPageNumber) {
              setPageIndex(1)
            } else if (result.length) {
              setPageIndex((stage) => stage + 1)
            }
          }

          setDisplayOptions((state) =>
            apiCallloadingMore
              ? state.concat(result)
              : result.length > 0
              ? result
              : options
          )

          setOptionIndex(0)

          isSearching && setIsSearching(false)
        } catch (error) {
          isSearching && setIsSearching(false)
          if (optionSelected.label === '') {
            setDisplayOptions(options)
          }
          setDisplayOptions([])
        }
        setIsApiBusy(false)
      },
      [
        apiCall,
        isSearching,
        optionSelected.label,
        options,
        currentText,
        pageIndex
      ]
    )

    React.useEffect(() => {
      if (isApiBusy) {
        handleApiCall(false)
      }
      return () => {}
    }, [isApiBusy])

    useEffect(() => {
      function loadMoreOptions() {
        const scrollNumberCheck =
          optionWrapperRef.current.scrollTop +
          optionWrapperRef.current.clientHeight

        if (
          apiCallloadingMore &&
          !isApiBusy &&
          scrollNumberCheck >= optionWrapperRef.current.scrollHeight
        ) {
          setIsApiBusy(true)
        }
      }
      optionWrapperRef.current.addEventListener('scroll', loadMoreOptions)
      return () => window.removeEventListener('scroll', loadMoreOptions)
    }, [optionWrapperRef, handleApiCall, pageIndex, currentText, loadingMore])

    const handleScrollToIndex = React.useCallback(
      (scrollTo?: EKey) => {
        const optionHeight = optionRef?.current?.offsetHeight ?? 40
        const currentOption = optionHeight * optionIndex

        if (scrollTo === EKey.ArrowUp) {
          return optionWrapperRef?.current?.scrollTo?.({
            behavior: 'smooth',
            top: currentOption - optionHeight * 4
          })
        }

        if (scrollTo === EKey.ArrowDown) {
          return optionWrapperRef?.current?.scrollTo?.({
            behavior: 'smooth',
            top: currentOption - optionHeight * 2
          })
        }

        return optionWrapperRef?.current?.scrollTo?.({
          behavior: 'smooth',
          top: currentOption - optionHeight * 4
        })
      },
      [optionIndex]
    )

    const handleOnClick = React.useCallback(
      (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
        props.onClick && props.onClick(event)

        if (isOptionsOpen && focused) return setFocused(false)

        if (isOptionsOpen) return setIsOptionsOpen(false)

        setIsOptionsOpen(true)
        setScrollToOption(true)
        setHideTags(false)
      },
      [focused, isOptionsOpen, props]
    )

    const handleSelectOptionItem = React.useCallback(
      (option: ISelectOption, newIndex: number) => {
        if (option) {
          const event = {
            target: {
              value: option.value
            },
            name: props.name
          } as never
          props.onChange && props.onChange(event)
          setValue && setValue(option.value)

          if (hasMultipleTags) {
            const alreadyAdded = tagsSelected.some(
              (tag) => option.value === tag.value
            )
            !alreadyAdded &&
              setTagsSelected([
                ...tagsSelected,
                {
                  label: option.label,
                  value: option.value
                }
              ])
          } else
            setOptionSelected({
              label: option.label,
              value: option.value
            })

          searchable && setSearchableLabel(option.label)
          isSearching && setIsSearching(false)
          isOptionsOpen && setIsOptionsOpen(false)
          newIndex && setOptionIndex(newIndex)
        }
      },
      [isOptionsOpen, isSearching, props, searchable, setValue]
    )

    const handleMultipleClickOptionItem = React.useCallback(
      (option: ISelectOption) => {
        let newMultipleValues = [...multipleValues]

        const hasValueSelected = newMultipleValues
          .filter((value) => value === option.value)
          .includes(option.value)

        if (!hasValueSelected) {
          newMultipleValues.push(option.value)
        } else {
          newMultipleValues = newMultipleValues.filter(
            (value) => value !== option.value
          )
        }

        setOptionSelected({
          ...optionSelected,
          label: ''
        })

        if (newMultipleValues.length === 1) {
          const actualOption = options.filter(
            (option) => option.value === newMultipleValues[0]
          )[0]

          setOptionSelected({
            label: actualOption.label,
            value: actualOption.value
          })
        }

        setValue && setValue(newMultipleValues)
        setMultipleValues(newMultipleValues)
      },
      [multipleValues, optionSelected, options, setValue]
    )

    const handleKeyUp = React.useCallback(
      (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === EKey.Tab && !isOptionsOpen) {
          setIsOptionsOpen(true)
          setScrollToOption(true)
        }

        if (event.key === EKey.Escape) {
          setIsOptionsOpen(false)
        }
      },
      [isOptionsOpen]
    )

    const handleKeyDown = React.useCallback(
      (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === EKey.ArrowUp) {
          event.preventDefault()

          if (optionIndex === 1) return setOptionIndex(1)

          if (optionIndex > 0) {
            setOptionIndex(optionIndex - 1)
            handleScrollToIndex(EKey.ArrowUp)
          }
        }
        if (event.key === EKey.ArrowDown) {
          event.preventDefault()
          if (optionIndex === arrayFiltered.length) return

          if (optionIndex < arrayFiltered.length) {
            setOptionIndex(optionIndex + 1)
            handleScrollToIndex(EKey.ArrowDown)
          }
        }

        if (event.key === EKey.Enter) {
          handleSelectOptionItem(displayOptions[optionIndex - 1], optionIndex)
          event.preventDefault()
        }

        if (event.key === EKey.Tab && isOptionsOpen) {
          setIsOptionsOpen(false)
        }
      },
      [
        isOptionsOpen,
        optionIndex,
        handleScrollToIndex,
        displayOptions,
        handleSelectOptionItem
      ]
    )

    React.useEffect(() => {
      if (scrollToOption && isOptionsOpen) {
        handleScrollToIndex()
        setScrollToOption(false)
      }
    }, [handleScrollToIndex, isOptionsOpen, scrollToOption])

    // wait to finish typing in search to call the API
    React.useEffect(() => {
      if ((apiCall || apiCallloadingMore) && isSearching && isOptionsOpen) {
        const timer = setTimeout(() => {
          setDisplayOptions([])
          handleApiCall()
        }, apiCallLoadingTimer)
        return () => clearTimeout(timer)
      }
      return () => {}
    }, [optionSelected.label])

    React.useLayoutEffect(() => {
      if (value && value !== optionSelected.value && !isOptionsOpen) {
        const defaultSelected = options.find((option) => option.value === value)
        defaultSelected &&
          handleSelectOptionItem(
            defaultSelected,
            options.indexOf(defaultSelected) + 1
          )
      }
      if (value === '' && optionSelected.value) {
        setOptionSelected({
          label: '',
          value: ''
        })
        setOptionIndex(0)
        options !== displayOptions && setDisplayOptions(options)
      }
    }, [
      displayOptions,
      handleSelectOptionItem,
      optionSelected.value,
      options,
      value,
      isOptionsOpen
    ])

    const displayCountResults = () => {
      return (
        <CountResults>
          {arrayFiltered.length === 1
            ? 'Foi encontrado '
            : 'Foram encontrados '}
          <CountResultsText data-testid={FormTestId.SELECT_TEXT_COUNT}>
            {arrayFiltered.length}{' '}
            {arrayFiltered.length === 1 ? 'resultado' : 'resultados'}
          </CountResultsText>
        </CountResults>
      )
    }

    const displayOptionsContent = React.useCallback(() => {
      if (
        !isSearching &&
        arrayFiltered.length === 0 &&
        optionSelected.label.length > 0
      ) {
        const text =
          typeof noOptionsFoundText === 'string'
            ? noOptionsFoundText
            : noOptionsFoundText(optionSelected.label)

        return (
          <NoOptionFound data-testid={FormTestId.SELECT_OPTION_ERROR}>
            {text}
          </NoOptionFound>
        )
      }
      if ((apiCall || apiCallloadingMore) && isSearching) {
        return (
          <LoadingContainer>
            <IconExporter name='load' iconsize={40} />
          </LoadingContainer>
        )
      }

      const optionsToReturn = arrayFiltered.map(
        (option: ISelectOption, index: number) => {
          return (
            <Option
              {...optionColor}
              data-testid={
                !customOptionTestId
                  ? FormTestId.SELECT_OPTION_ITEM
                  : customOptionTestId(index)
              }
              ref={optionRef}
              key={option.value}
              selected={
                multiple
                  ? multipleValues.includes(option.value)
                  : optionSelected.value === option.value
              }
              optionWithKeyDown={index + 1 === optionIndex}
              onClick={() =>
                multiple
                  ? handleMultipleClickOptionItem(option)
                  : handleSelectOptionItem(option, index + 1)
              }
            >
              {!CustomOption ? (
                option.label
              ) : (
                <CustomOption value={option.value}>{option.label}</CustomOption>
              )}
            </Option>
          )
        }
      )

      if (optionsToReturn.length) {
        return (
          <React.Fragment>
            {optionsToReturn}

            {!lastPageLoaded && loadingMore}
          </React.Fragment>
        )
      }
      return optionsToReturn
    }, [
      CustomOption,
      apiCall,
      apiCallloadingMore,
      displayOptions,
      handleMultipleClickOptionItem,
      handleSelectOptionItem,
      isSearching,
      multiple,
      multipleValues,
      noOptionsFoundText,
      optionColor,
      optionIndex,
      optionSelected.value
    ])

    const displayTagsCount = React.useCallback(() => {
      return (
        <TagStatic
          key='tags-count'
          text={`+${tagsSelected.length - 2}`}
          selected
          type='cid'
        />
      )
    }, [tagsSelected])

    const displayTags = React.useCallback(
      (option: ISelectOption, index: number) => {
        if (hideTags && index > 1) return null
        return (
          <TagEditable
            key={option.value}
            icon='close'
            background='#f0f2f5'
            value={option.label}
            hasDivider={false}
            onClose={() =>
              setTagsSelected(tagsSelected.filter((item) => item !== option))
            }
          />
        )
      },
      [tagsSelected, hideTags]
    )

    const showTagsCount = React.useMemo(
      () => hideTags && tagsSelected.length > 2,
      [hideTags, tagsSelected.length]
    )

    const showTagsInput = React.useMemo(
      () => !hideTags || tagsSelected.length === 0,
      [hideTags, tagsSelected.length]
    )

    return (
      <SelectWrapper
        onBlur={() => {
          if (searchable && optionSelected.label !== '' && !isOptionsOpen) {
            setOptionSelected({
              value: allowOpenText
                ? optionSelected.label
                : optionSelected.value,
              label: allowOpenText ? optionSelected.label : searchableLabel
            })
          }
        }}
        ref={wrapperRef}
        flex={flex}
        disabled={props.disabled}
        disabledBackground={disabledBackground}
      >
        <SelectGroup>
          <InputWrapper
            data-testid={FormTestId.SELECT_INPUT_WRAPPER}
            tabIndex={-1}
            onClick={handleOnClick}
            onKeyUp={handleKeyUp}
            onKeyDown={handleKeyDown}
            error={!!errors}
            hasMultipleTags={hasMultipleTags}
            maxWidth={maxWidth}
          >
            {!removeChevron && (
              <React.Fragment>
                {positionIcon === 'left' && (
                  <IconWrapper
                    iconLeft
                    rotate={!customIcon ? isOptionsOpen.toString() : ''}
                    error={!!errors}
                  >
                    <IconExporter
                      name={customIcon ?? 'chevron'}
                      iconsize={customIcon === 'search' ? 18 : 15}
                    />
                  </IconWrapper>
                )}
              </React.Fragment>
            )}
            {hasMultipleTags && (
              <TagsContainer hasMultipleTags={hasMultipleTags}>
                {tagsSelected.map((option, index) =>
                  displayTags(option, index)
                )}
                {showTagsCount && displayTagsCount()}
                {showTagsInput && (
                  <InputContainer>
                    <Input
                      {...props}
                      data-testid={FormTestId.SELECT_INPUT}
                      ref={ref}
                      autoComplete={autoComplete}
                      readOnly={!searchable}
                      placeholder={
                        !tagsSelected.length ? displayPlaceholder : ' '
                      }
                      value={optionSelected.label}
                      onChange={handleOnChange}
                      hasMultipleTags={hasMultipleTags}
                      onFocus={(event: React.FocusEvent<HTMLInputElement>) => {
                        props.onFocus && props.onFocus(event)
                        setIsOptionsOpen(true)
                        setScrollToOption(true)
                        setFocused(true)
                      }}
                    />
                  </InputContainer>
                )}
              </TagsContainer>
            )}
            {!hasMultipleTags && (
              <Input
                {...props}
                data-testid={FormTestId.SELECT_INPUT}
                ref={ref}
                autoComplete={autoComplete}
                readOnly={!searchable}
                placeholder={displayPlaceholder}
                value={optionSelected.label}
                onChange={handleOnChange}
                onFocus={(event: React.FocusEvent<HTMLInputElement>) => {
                  props.onFocus && props.onFocus(event)
                  setIsOptionsOpen(true)
                  setScrollToOption(true)
                  setFocused(true)
                }}
              />
            )}
            {!removeChevron && (
              <React.Fragment>
                {positionIcon !== 'left' && (
                  <IconWrapper
                    rotate={!customIcon ? isOptionsOpen.toString() : ''}
                    error={!!errors}
                  >
                    <IconExporter
                      name={customIcon ?? 'chevron'}
                      iconsize={customIcon === 'search' ? 18 : 15}
                    />
                  </IconWrapper>
                )}
              </React.Fragment>
            )}
          </InputWrapper>
          <OptionsWrapper
            isOptionsOpen={isOptionsOpen}
            ref={optionWrapperRef}
            className='select-options-wrapper'
            hasMultipleTags={hasMultipleTags}
            maxHeight={maxHeight}
          >
            {showCountResults &&
              arrayFiltered.length >= 1 &&
              displayCountResults()}

            {displayOptionsContent()}
          </OptionsWrapper>
          {errors && (
            <ErrorWrapper data-testid={FormTestId.SELECT_ERROR}>
              {errors}
            </ErrorWrapper>
          )}
        </SelectGroup>
        {!removeIconError && errors && (
          <ErrorIconWrapper>
            <IconExporter name='warning' iconsize={20} />
          </ErrorIconWrapper>
        )}
      </SelectWrapper>
    )
  }
)

Select.displayName = 'Select'
export default Select
