import React from 'react'
import { SvgProps, WithSecondaryColor } from './typings/SvgProps'

const OneCircle: React.FC<SvgProps & WithSecondaryColor> = ({
  color = 'currentColor',
  secondaryColor = '#fff',
  size,
  ...rest
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} {...rest} viewBox="0 0 14 14">
    <g fill={color} stroke={color} strokeWidth="1">
      <circle cx="7" cy="7" r="7" stroke="none" />
      <circle cx="7" cy="7" r="6.5" fill="none" />
    </g>
    <path
      d="M106.86,198.472h-.792v-5.817a.268.268,0,0,0-.021-.127c0-.021-.021-.042-.021-.063s-.021-.042-.021-.063-.021-.042-.021-.063a.074.074,0,0,0-.021-.042.644.644,0,0,0-.169-.169.074.074,0,0,1-.042-.021.078.078,0,0,0-.063-.021c-.021,0-.042-.021-.063-.021s-.042-.021-.063-.021a.516.516,0,0,0-.254,0c-.021,0-.042.021-.063.021s-.042.021-.063.021-.042.021-.063.021a.074.074,0,0,0-.042.021.372.372,0,0,0-.106.085l-1.587,1.6a.613.613,0,0,0,0,.888h0a.613.613,0,0,0,.888,0l.508-.521v4.294h-.828a.635.635,0,0,0,0,1.269h2.91a.635.635,0,0,0,0-1.269Z"
      transform="translate(-98.189 -189.265)"
      fill={secondaryColor}
    />
  </svg>
)

export default OneCircle
