import styled, { css } from 'styled-components'
import { theme } from '../../Theme'

interface TagWrapper {
  type: string
  fontWeight?: string
  backgroundColor?: string
  selected?: boolean
  onClick?: any
}

const state = {
  cid: css`
    background: ${theme.colors.gray7};
    font-size: 12px;
  `,
  info: css`
    background: ${theme.colors.blue6};
    font-size: 15px;
  `
}

export const TagWrapper = styled.span<TagWrapper>`
  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: ${(props) =>
    props.fontWeight
      ? theme.fontWeight[props.fontWeight]
      : theme.fontWeight.bold};
  color: ${theme.colors.black};
  height: 29px;
  padding: 0 16px;
  border-radius: 15px;
  width: fit-content;
  ${(props) =>
    props.onClick &&
    css`
      cursor: pointer;
      user-select: none;
    `}
  ${({ type }) => state[type]};
  ${(props) =>
    props.backgroundColor &&
    css`
      background-color: ${props.backgroundColor};
    `}
  ${(props) =>
    props.selected &&
    css`
      background: #378ff7;
      color: #fff;
    `}
`
