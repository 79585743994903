import React, { FC, ReactChildren, ReactNode } from 'react'
import { DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material'
import { Dialog } from './styles'
import { Icon } from '../../atoms'

export type IModal = {
  open: boolean
  title?: string | ReactNode
  onClose?: () => void
  size?: 'sm' | 'md' | 'lg'
  children: string | ReactChildren | Element | Element[] | JSX.Element | JSX.Element[]
  actions?: ReactNode
  disableEscapeKeyDown?: boolean
  fullScreen?: boolean
}

const Modal: FC<IModal> = ({ size = 'md', children, title, actions, ...rest }) => (
  <Dialog fullWidth maxWidth={size} data-testid="levi-modal-component" {...rest}>
    {(title || rest.onClose) && (
      <DialogTitle>
        {typeof title === 'string' ? (
          <span className="levi-modal-title-string">{title}</span>
        ) : (
          title
        )}
        {rest.onClose && (
          <IconButton
            aria-label="close"
            onClick={rest.onClose}
            data-testid="levi-modal-close-button"
          >
            <Icon icon="Close" size={14} />
          </IconButton>
        )}
      </DialogTitle>
    )}
    <DialogContent>{children}</DialogContent>
    {actions && <DialogActions>{actions}</DialogActions>}
  </Dialog>
)

export default Modal
